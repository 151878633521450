import MyStartupProfile from './MyStartupProfile'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const MANAGE_DRAFT = gql`
  mutation startupManageDraft(
    $id: ID!
    $status: String
) {
  startupManageDraft(
        id: $id
        status: $status
    )
  }
`;
const NEW_DRAFT = gql`
  mutation startupNewDraft{
    startupNewDraft
  }
`;
const JoinWithMutation = compose(
  graphql(MANAGE_DRAFT, {
    name: 'startupManageDraft',
  }),
  graphql(NEW_DRAFT, {
    name: 'startupNewDraft',
  }),
)(MyStartupProfile);
export default JoinWithMutation;