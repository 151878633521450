import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import startButton from '../../static/img/start-button.svg';
import video from '../../static/img/video.svg';
import thumbUp from '../../static/img/thumb-up.svg';
import clipBoard from '../../static/img/clipboard.svg';
import admin from '../../static/img/admin.svg';
import stats from '../../static/img/stats-alt.svg';
import Hidden from "@material-ui/core/Hidden";
import {Link} from "react-router-dom"
import Grid from "@material-ui/core/Grid";
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    main: {
        background:'#ffffff',
         maxWidth:'90px',
         padding:'25px 0',
         position:'fixed',
         height:'100%',
         overflowX:'hidden',
         boxShadow:' 0 0 6px 0 rgba(0, 0, 0, 0.16)',
    },
    item: {
        width:60,
        height:60,
        background:'#f7f7f7',
        marginBottom: 30,
        '&:hover': {
            backgroundColor: 'rgba(40, 177, 231, 0.18)',
        },
    },
    size: {
        [theme.breakpoints.up('lg')]: {
            position:'fixed',
            left:0
        },
    }
}));


export default function LeftNavigation(props) {
    const onMouseOver = (event) => {
        const el = event.target;
      };
      const onMouseOut = event => {
        const el = event.target;
      };
    const classes = useStyles();
    return(
            <Hidden mdDown>
              <Grid xs={1} className={classes.size} style={{marginTop:1}}>
                <Grid container justify="center" className={classes.main}>
                    <Grid item style={{height:'fit-content'}}>
                    {window.location.pathname === "/admin/investors" ?
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <Tooltip title="Investors" placement="right">
                                    <Link to="/admin/investors">
                                        <PeopleOutlineIcon arrow={true} style={{fontSize:32}} />
                                    </Link>
                                </Tooltip>
                            </Grid>  : 
                            <Grid container alignItems="center"  justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <Tooltip title="Investors" placement="right">
                                    <Link onMouseEnter={onMouseOver} onMouseOut={onMouseOut} to="/admin/investors">
                                        <PeopleOutlineIcon arrow={true} style={{fontSize:32}} />
                                    </Link>
                                </Tooltip>
                            </Grid> } 
                        {window.location.pathname === "/admin/startups" ? 
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <Tooltip title="Startups" placement="right">
                                    <Link to="/admin/startups">
                                        <img src={startButton} alt="startButton" />
                                    </Link>
                                </Tooltip>
                            </Grid> :
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <Tooltip title="Startups" placement="right">
                                    <Link style={{borderRadius:0,padding:18}} to="/admin/startups" onMouseEnter={onMouseOver} onMouseOut={onMouseOut}>
                                        <img src={startButton} alt="startButton" />
                                    </Link>
                                </Tooltip>
                            </Grid> 
                        } 
                        {window.location.pathname === "/admin/events" ? 
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <Tooltip title="Events" placement="right">
                                    <Link to="/admin/events">
                                        <img src={thumbUp} alt="thumbUp" />
                                    </Link>
                                </Tooltip>
                            </Grid> 
                        :
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <Tooltip title="Events" placement="right">
                                    <Link style={{borderRadius:0,padding:18}} to="/admin/events" onMouseEnter={onMouseOver} onMouseOut={onMouseOut}>
                                        <img src={thumbUp} alt="thumbUp" />
                                    </Link>
                                </Tooltip>
                            </Grid> 
                        }  
                        {window.location.pathname === "/admin/dashboard" ? 
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <Tooltip title="Dashboard" placement="right">
                                    <Link to="/admin/dashboard">
                                        <img src={clipBoard} alt="clipBoard" />
                                    </Link>
                                </Tooltip>
                            </Grid> 
                         :
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <Tooltip title="Dashboard" placement="right">
                                    <Link style={{borderRadius:0,padding:18}} to="/admin/dashboard" onMouseEnter={onMouseOver} onMouseOut={onMouseOut}>
                                        <img src={clipBoard} alt="clipBoard" />
                                    </Link>
                                </Tooltip>
                            </Grid> 
                        }    
                    </Grid>             
                </Grid>
              </Grid>
            </Hidden>  
    );
}