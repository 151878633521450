/* global d3 */
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import LeftNavigation from "../../Components/LeftNavigation";
import BottomNavigation from "../../Components/BottomNavigation";
import MobileHeader from "../../Components/MobileHeader";
import { DASHBOARD_SUMMARY } from '../../queries';
import { FIRST_EVA } from '../../queries';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import gql from 'graphql-tag';
import DataMap from 'react-datamaps'
import {Link} from 'react-router-dom';
import {Doughnut, Line, Bar} from 'react-chartjs-2';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#28b1e7',
      color: '#ffffff',
      height: 45,
      fontSize: '16px'
    },
    body: {
      fontSize: 16,
      color: '#172b4d',
      border:'none'
    },
}))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#f7fafc'
      },
      color:'#172b4d',
      fontWeight:'600'
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    logo: {
        width: 180,
        height: 50,
        marginTop:30,
    },
    tableContainer:{
        maxHeight: 590,
    },
    summaryTextColor: {
        color:'#ffffff'
    },
    summaryLeft: {
        borderRadius:7,
        background:'#ffffff',
        maxWidth:'100%',
        [theme.breakpoints.down('md')]: {
            paddingTop:25,
        }
    },
    mainCard: {
        borderRadius:'7px',
        padding:17,
        display:"flex",
        flexDirection:'column',
        justifyContent:'space-between',
        minHeight:180
    },
    evaluationCardBar: {
        marginBottom:30,
        background:'#ffffff',
        borderRadius:7,
        padding:25
    },
    evaluationCard: {
        borderRadius:'7px',
        display:"flex",
        flexDirection:'column',
        justifyContent:'space-between',
    },
    evaluationCardTop: {
        borderRadius:7,
        padding:5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    ellipses: {
        width:5,
        marginRight:10,
        height:5,
        borderRadius:'50%',
        background:'#ffffff',
    },
    countryCard: {
        background:'#ffffff',
        borderRadius:'7px',
        padding:20,
    },
    summaryContents: {
        display:'flex',
        marginLeft:20,
        marginTop:25,
    },
    doughnutGrid: {
        [theme.breakpoints.up('md')]: {
            paddingLeft:15,
        },
        [theme.breakpoints.down('md')]: {
            marginTop:25,
        }
    },
    mainGrid: {
        justifyContent:'center'
    },
    adminContainer:{
        maxWidth: '1320px',
        padding:0,
        [theme.breakpoints.down('lg')]: {
          padding:' 0 0 0 70px'
        },
        [theme.breakpoints.down('md')]: {
          padding:' 0 16px'
        },
    },
    invContainer:{
        maxWidth: '1320px',
        padding:' 0 16px'
    }
  }));
export default function Summary(props){
    const classes = useStyles(); 
    const [valueCountriesMostPasses, setValueCountriesMostPasses] = React.useState({});
    const [valueCountriesMostPassesTable, setValueCountriesMostPassesTable] = React.useState({});
    const [isCountriesMostPassesLoaded, setIsCountriesMostPassesLoaded] = React.useState(false);
    const [valueSummary, setValueSummary] = React.useState({});
    const [valueEva, setValueEva] = React.useState({});
    const [valueCountriesMostApp, setValueCountriesMostApp] = React.useState({});
    const [valueCountryMostAppTable, setValueCountryMostAppTable] = React.useState([]);
    const [isCountryMostAppLoaded, setIsCountryMostAppLoaded] = React.useState(false);
    const [valueCountryFouPrevExit, setValueCountryFouPrevExit] = React.useState({});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    const COUNTRIES_MOST_PASSES = gql`
        query countriesMostPasses{
            countriesMostPasses{
                id
                title
                code
                code3
                count
            }

        }
    `;
    const { loading:loadingCountryMostPasses, data:dataCountryMostPasses } = useQuery(COUNTRIES_MOST_PASSES, {
        fetchPolicy:"network-only",
    });
    React.useEffect(() => {
        if (!isCountriesMostPassesLoaded){
            if (!loadingCountryMostPasses){
                if (dataCountryMostPasses){
                    let dataset = {}
                    let onlyValues = dataCountryMostPasses.countriesMostPasses.map(function(obj){ return obj.count; });
                    let minValue = Math.min.apply(null, onlyValues),
                      maxValue = Math.max.apply(null, onlyValues);

                    // create color palette function
                    // color can be whatever you wish
                    let paletteScale = d3.scale.linear()
                      .domain([minValue,maxValue])
                      .range(["#b8b8f1","#02386F"]); // bl
                    // ue color
                    // fill dataset in appropriate format
                    dataCountryMostPasses.countriesMostPasses.forEach(function(item){ //
                        // item example value ["USA", 70]
                        let iso = item.code3,
                          value = item.count;
                        dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
                    });
                    setValueCountriesMostPasses(dataset)
                    setValueCountriesMostPassesTable(dataCountryMostPasses)
                    setIsCountriesMostPassesLoaded(true)
                }
            }
        }
    },
      [dataCountryMostPasses, loadingCountryMostPasses]);

    const COUNTRIES_MOST_APPLICATION = gql`
        query countriesMostApplication{
            countriesMostApplication{
                id
                title
                code
                code3
                count
            }

        }
    `;
    const { loading:loadingCountryMostApp, data:dataCountryMostApp } = useQuery(COUNTRIES_MOST_APPLICATION, {
        fetchPolicy:"network-only",
    });
    React.useEffect(() => {
          if (!isCountryMostAppLoaded){
              if (!loadingCountryMostApp){
                  if (dataCountryMostApp){
                      let dataset = {}
                      let onlyValues = dataCountryMostApp.countriesMostApplication.map(function(obj){
                          return obj.count;
                      });
                      let minValue = Math.min.apply(null, onlyValues),
                        maxValue = Math.max.apply(null, onlyValues);

                      // create color palette function
                      // color can be whatever you wish
                      let paletteScale = d3.scale.linear()
                        .domain([minValue,maxValue])
                        .range(["#b8b8f1","#02386F"]); // bl
                      // ue color
                      // fill dataset in appropriate format
                      dataCountryMostApp.countriesMostApplication.forEach(function(item){ //
                          // item example value ["USA", 70]
                          let iso = item.code3,
                            value = item.count;
                          dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
                      });
                      setValueCountriesMostApp(dataset)
                      setValueCountryMostAppTable(dataCountryMostApp)
                      setIsCountryMostAppLoaded(true);
                  }
              }
          }
      },
      [dataCountryMostApp, loadingCountryMostApp]);

    const COUNTRIES_FOUNDER_WITH_PREV_EX = gql`
        query foundersWithPrevExit{
            foundersWithPrevExit{
                firstq
                secondq
                thirdq
                fourthq
                country{
                    id
                    title
                    count
                }
            }

        }
    `;
    const { loading:loadingCountryFouPrevExit, data:dataCountryFouPrevExit } = useQuery(COUNTRIES_FOUNDER_WITH_PREV_EX, {
        fetchPolicy:"network-only",
    });
    const { loading:loadingSummary, data:dataSummary } = useQuery(DASHBOARD_SUMMARY, {
        fetchPolicy:"network-only",
    });
    const { loading:loadingEva, data:dataEva } = useQuery(FIRST_EVA, {
        fetchPolicy:"network-only",
    });
    React.useEffect(() => {
          if (!loadingCountryFouPrevExit){
              if (dataCountryFouPrevExit){
                setValueCountryFouPrevExit(dataCountryFouPrevExit)
              }
          }
        if (!loadingSummary){
            if (dataSummary){
                setValueSummary(dataSummary)
            }
        }
        if (!loadingEva){
            if (dataEva){
                setValueEva(dataEva)
            }
        }
      },
      [dataCountryFouPrevExit, loadingCountryFouPrevExit, loadingSummary, dataSummary, loadingEva, dataEva]);

    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
                {(props.authRole === 'Admin' || props.authRole === 'SuperAdmin') ? 
                <Grid container className={classes.mainGrid} style={{background:'#f7f7fc'}}>
                        <LeftNavigation>
                        </LeftNavigation>
                        <BottomNavigation>
                        </BottomNavigation>    
                    

                    <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                        <Typography variant="h4" style={{marginBottom:50}}>Summary with Numbers</Typography>
                        {Object.keys(valueSummary).length > 0 && 
                        <Grid container xs={12} style={{marginBottom:25}}>
                            <Grid item container xs={12} lg={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard}  style={{background:'#f53c56'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Archive</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.archive}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Archive'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard}  style={{background:'#2dce98'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Re-Evaluate</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.reevaluate}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Re-evaluate'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#11cdef'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Information Waiting</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.informationwaiting}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Information-Waiting'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#ffcd3c'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>In Progress</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.inprogress}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=In-Progress'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#e00078'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Voting</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.voting}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Voting'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#673cf5'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Portfolio</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.portfolio}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Portfolio'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#fa7d09'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Countries</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.countries}</Typography>
                                                <Link style={{padding:0}} to={'/startups'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#172b4d'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Total</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.total}</Typography>
                                                <Link style={{padding:0}} to={'/startups'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} lg={5} className={classes.doughnutGrid} style={{paddingBottom:15}}>
                                <Grid container item alignItems="center" justify="center" className={classes.summaryLeft}>
                                    <Grid item xs={5}>
                                    {Object.keys(valueSummary).length > 0 && 
                                        <Doughnut data={{
                                            labels: [
                                                'Archive',
                                                'Re-evaluate',
                                                'Information Waiting',
                                                'In Progress',
                                                'Voting',
                                                'Portfolio',
                                                'Countries',
                                            ],
                                            datasets: [{
                                                data: [valueSummary.dashboardSummary.archive, valueSummary.dashboardSummary.reevaluate, valueSummary.dashboardSummary.informationwaiting, 
                                                valueSummary.dashboardSummary.inprogress, valueSummary.dashboardSummary.voting, valueSummary.dashboardSummary.portfolio],
                                                backgroundColor: [
                                                '#f53c56',
                                                '#2dce98',
                                                '#11cdef',
                                                '#ffcd3c',
                                                '#e00078',
                                                '#673cf5',
                                                '#fa7d09',
                                                '#172b4d',
                                                ],
                                                hoverBackgroundColor: [
                                                    '#f53c56',
                                                    '#2dce98',
                                                    '#11cdef',
                                                    '#ffcd3c',
                                                    '#e00078',
                                                    '#673cf5',
                                                    '#fa7d09',
                                                    '#172b4d',
                                                ]
                                            }],
                                          text: '23%'
                                        }}
                                        width={10}
                                        height={10}
                                        options={{
                                            legend: {
                                              display: false
                                            },
                                            responsive: true,
                                          }}
                                         />
                                    }
                                    </Grid>
                                    {Object.keys(valueSummary).length > 0 && 
                                    <Grid item xs={8} md={'auto'} direction="column" className={classes.summaryContents} justify="center">
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #f53c56'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Archive</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.archive * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #2dce98'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Re-Evaluate</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.reevaluate * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #11cdef'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Information Waiting</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{marginLeft:10}}>{parseInt((valueSummary.dashboardSummary.informationwaiting * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #ffcd3c'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">In Progress</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.inprogress * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #e00078'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Voting</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.voting * 100) / valueSummary.dashboardSummary.total)}%</Typography>    
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #673cf5'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Portfolio</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.portfolio * 100) / valueSummary.dashboardSummary.total)}%</Typography>       
                                        </Grid>
                                    </Grid>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {Object.keys(valueEva).length > 0 && 
                        <Grid container xs={12}>
                            <Typography variant="h4" style={{marginBottom:50}}>About First Evaluation</Typography>
                            <Grid container spacing={2} style={{marginBottom:30}}>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#172b4d'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Average</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.average.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#28b1e7'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Standard Deviation</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.standardDeviation.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#172b4d'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Median</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.median.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#28b1e7'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Mode</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.modes[0].toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            </Grid>
                                <Grid container xs={12} justify="center" className={classes.evaluationCardBar} item>
                                    <Bar
                                    data={{
                                        labels: [
                                          "First Quarter",
                                          "Second Quarter",
                                          "Third Quarter",
                                          "Fourth Quarter",
                                        ],
                                        datasets: [
                                          {
                                            label: "Average",
                                            backgroundColor: "#15ceef",
                                            borderColor: "#15ceef",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#15ceef",
                                            hoverBorderColor: "#15ceef",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.average),parseInt(valueEva.dashboardAboutFirstEva.secondQ.average)
                                            , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.average), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.average)]
                                          },
                                
                                          {
                                            label: "Standard deviation",
                                            backgroundColor: "#7764e4",
                                            borderColor: "#7764e4",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#7764e4",
                                            hoverBorderColor: "#7764e4",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.standardDeviation),parseInt(valueEva.dashboardAboutFirstEva.secondQ.standardDeviation)
                                                , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.standardDeviation), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.standardDeviation)]
                                          },
                                          {
                                            label: "Median",
                                            backgroundColor: "#2dce98",
                                            borderColor: "#2dce98",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#2dce98",
                                            hoverBorderColor: "#2dce98",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.median),parseInt(valueEva.dashboardAboutFirstEva.secondQ.median)
                                                , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.median), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.median)]
                                          }
                                        ]
                                      }}
                                    width={null}
                                    height={null}
                                    options={{
                                        responsive: true,
                                        legend: {
                                          display: false
                                        },
                                        type: "bar"
                                        //   scales: {
                                        //     xAxes: [{
                                        //         stacked: true
                                        //     }],
                                        //     yAxes: [{
                                        //         stacked: true
                                        //     }]
                                        // }
                                      }}
                                />
                                </Grid>       
                        </Grid>
                        }
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50,marginTop:25}}>Top 10 Countries with Most Applications</Typography>
                            <Grid container xs={12}>
                                <Grid container xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountryMostAppTable).length > 0 &&
                                        <TableContainer className={classes.tableContainer} component={Paper}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountryMostAppTable.countriesMostApplication.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        } 
                                    </Grid>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item xs={12} lg={9}>
                                        <Grid item xs={12} className={classes.countryCard}>
                                            <DataMap
                                                style={{width:'100%',height:550,textAlign:'end'}}
                                                projection='mercator' // big world map
                                                // countries don't listed in dataset will be painted with this color
                                                fills={{defaultFill: '#F5F5F5'} }
                                                data={valueCountriesMostApp}
                                                geographyConfig={
                                                    {borderColor: '#DEDEDE',
                                                        highlightBorderWidth: 2,
                                                        // don't change color on mouse hover
                                                        highlightFillColor: function(geo) {
                                                            return geo['fillColor'] || '#F5F5F5';
                                                        },
                                                        // only change border
                                                        highlightBorderColor: '#B7B7B7',
                                                        // show desired information in tooltip
                                                        popupTemplate: function(geo, data) {
                                                            // don't show tooltip if country don't present in dataset
                                                            if (!data) { return ; }
                                                            // tooltip content
                                                            return ['<div class="hoverinfo">',
                                                                '<strong>', geo.properties.name, '</strong>',
                                                                '<br>Count: <strong>', data.numberOfThings, '</strong>',
                                                                '</div>'].join('');
                                                        }}

                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>    
                        </Grid>
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50}}>Top 10 Countries with Most Passes</Typography>
                            <Grid container xs={12}>
                                <Grid item xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountriesMostPassesTable).length > 0 &&
                                    <Paper>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountriesMostPassesTable.countriesMostPasses.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        </Paper>
                                        } 
                                    </Grid>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item xs={12} lg={9}>
                                        <Grid item xs={12} className={classes.countryCard}>
                                            <DataMap
                                                style={{width:'100%',height:550,textAlign:'end'}}
                                                projection='mercator' // big world map
                                                // countries don't listed in dataset will be painted with this color
                                                fills={{defaultFill: '#F5F5F5'} }
                                                data={valueCountriesMostPasses}
                                                geographyConfig={
                                                    {borderColor: '#DEDEDE',
                                                        highlightBorderWidth: 2,
                                                        // don't change color on mouse hover
                                                        highlightFillColor: function(geo) {
                                                            return geo['fillColor'] || '#F5F5F5';
                                                        },
                                                        // only change border
                                                        highlightBorderColor: '#B7B7B7',
                                                        // show desired information in tooltip
                                                        popupTemplate: function(geo, data) {
                                                            // don't show tooltip if country don't present in dataset
                                                            if (!data) { return ; }
                                                            // tooltip content
                                                            return ['<div class="hoverinfo">',
                                                                '<strong>', geo.properties.name, '</strong>',
                                                                '<br>Count: <strong>', data.numberOfThings, '</strong>',
                                                                '</div>'].join('');
                                                        }}

                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>    
                        </Grid>
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50}}>List of Founders with Previous Exits (Top 20)</Typography>
                            <Grid container xs={12}>
                                <Grid item xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountryFouPrevExit).length > 0 &&
                                        <TableContainer className={classes.tableContainer} component={Paper}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountryFouPrevExit.foundersWithPrevExit.country.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        } 
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                {Object.keys(valueCountryFouPrevExit).length > 0 && 
                                    <Grid item xs={12} style={{display:'flex',justifyContent:'center'}} className={classes.countryCard}>
                                        <Line data={{
                                            labels: ['First Quarter', 'Second Quarter', 'Third Quarter', 'Fourth Quarter'],
                                            datasets: [
                                                {
                                                label: 'Previous Exits',
                                                fill: false,
                                                lineTension: 0.1,
                                                backgroundColor: '#172b4d',
                                                borderColor: '#172b4d',
                                                borderCapStyle: 'butt',
                                                borderDash: [],
                                                borderDashOffset: 0.0,
                                                borderJoinStyle: 'miter',
                                                pointBorderColor: '#172b4d',
                                                pointBackgroundColor: '#fff',
                                                pointBorderWidth: 1,
                                                pointHoverRadius: 5,
                                                pointHoverBackgroundColor: '#172b4d',
                                                pointHoverBorderColor: '#172b4d',
                                                pointHoverBorderWidth: 2,
                                                pointRadius: 1,
                                                pointHitRadius: 10,
                                                data: [valueCountryFouPrevExit.foundersWithPrevExit.firstq, valueCountryFouPrevExit.foundersWithPrevExit.secondq,
                                                    valueCountryFouPrevExit.foundersWithPrevExit.thirdq, valueCountryFouPrevExit.foundersWithPrevExit.fourthq]
                                                }
                                            ]
                                            }} />
                                    </Grid>
                                }
                                </Grid>
                            </Grid>    
                        </Grid>

                    </Grid>
                </Grid> :            
                <Grid container justify="center" style={{background:'#f7f7fc'}}>  
                    
                    <Grid className={classes.invContainer} xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                        <Typography variant="h4" style={{marginBottom:50}}>Summary with Numbers</Typography>
                        {Object.keys(valueSummary).length > 0 && 
                        <Grid container xs={12} style={{marginBottom:25}}>
                            <Grid item container xs={12} lg={7}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard}  style={{background:'#f53c56'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Archive</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.archive}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Archive'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard}  style={{background:'#2dce98'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Re-Evaluate</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.reevaluate}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Re-evaluate'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#11cdef'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Information Waiting</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.informationwaiting}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Information-Waiting'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#ffcd3c'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>In Progress</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.inprogress}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=In-Progress'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#e00078'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Voting</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.voting}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Voting'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#673cf5'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Portfolio</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.portfolio}</Typography>
                                                <Link style={{padding:0}} to={'/startups?=Portfolio'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#fa7d09'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Countries</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.countries}</Typography>
                                                <Link style={{padding:0}} to={'/startups'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.mainCard} style={{background:'#172b4d'}} xs={12}>
                                            <Typography variant="body1" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>Total</Typography>
                                            <Grid item>
                                                <Typography variant="h3" style={{fontWeight:'normal',letterSpacing: '1px'}} className={classes.summaryTextColor}>{valueSummary.dashboardSummary.total}</Typography>
                                                <Link style={{padding:0}} to={'/startups'}><Typography variant="body2" align="left" style={{marginBottom:'5px'}} className={classes.summaryTextColor}>{'Go to Startups ->'}</Typography></Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} lg={5} className={classes.doughnutGrid} style={{paddingBottom:15}}>
                                <Grid container item alignItems="center" justify="center" className={classes.summaryLeft}>
                                    <Grid item xs={5}>
                                    {Object.keys(valueSummary).length > 0 && 
                                        <Doughnut data={{
                                            labels: [
                                                'Archive',
                                                'Re-evaluate',
                                                'Information Waiting',
                                                'In Progress',
                                                'Voting',
                                                'Portfolio',
                                                'Countries',
                                            ],
                                            datasets: [{
                                                data: [valueSummary.dashboardSummary.archive, valueSummary.dashboardSummary.reevaluate, valueSummary.dashboardSummary.informationwaiting, 
                                                valueSummary.dashboardSummary.inprogress, valueSummary.dashboardSummary.voting, valueSummary.dashboardSummary.portfolio],
                                                backgroundColor: [
                                                '#f53c56',
                                                '#2dce98',
                                                '#11cdef',
                                                '#ffcd3c',
                                                '#e00078',
                                                '#673cf5',
                                                '#fa7d09',
                                                '#172b4d',
                                                ],
                                                hoverBackgroundColor: [
                                                    '#f53c56',
                                                    '#2dce98',
                                                    '#11cdef',
                                                    '#ffcd3c',
                                                    '#e00078',
                                                    '#673cf5',
                                                    '#fa7d09',
                                                    '#172b4d',
                                                ]
                                            }],
                                          text: '23%'
                                        }}
                                        width={10}
                                        height={10}
                                        options={{
                                            legend: {
                                              display: false
                                            },
                                            responsive: true,
                                          }}
                                         />
                                    }
                                    </Grid>
                                    {Object.keys(valueSummary).length > 0 && 
                                    <Grid item xs={8} md={'auto'} direction="column" className={classes.summaryContents} justify="center">
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #f53c56'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Archive</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.archive * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #2dce98'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Re-Evaluate</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.reevaluate * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #11cdef'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Information Waiting</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{marginLeft:10}}>{parseInt((valueSummary.dashboardSummary.informationwaiting * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #ffcd3c'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">In Progress</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.inprogress * 100) / valueSummary.dashboardSummary.total)}%</Typography>
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #e00078'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Voting</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.voting * 100) / valueSummary.dashboardSummary.total)}%</Typography>    
                                        </Grid>
                                        <Grid container justify="space-between" style={{marginBottom:20}}>
                                            <Grid style={{display:'flex'}} alignItems="center">
                                                <div style={{border: 'solid 4px #673cf5'}} className={classes.ellipses}></div>
                                                <Typography variant="h6">Portfolio</Typography>
                                            </Grid>
                                            <Typography variant="body2" style={{float:'right'}}>{parseInt((valueSummary.dashboardSummary.portfolio * 100) / valueSummary.dashboardSummary.total)}%</Typography>       
                                        </Grid>
                                    </Grid>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                        {Object.keys(valueEva).length > 0 && 
                        <Grid container xs={12}>
                            <Typography variant="h4" style={{marginBottom:50}}>About First Evaluation</Typography>
                            <Grid container spacing={2} style={{marginBottom:30}}>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#172b4d'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Average</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.average.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#28b1e7'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Standard Deviation</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.standardDeviation.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#172b4d'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Median</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.median.toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} sm={3} style={{marginBottom:15}}>
                                        <Grid item className={classes.evaluationCard}  style={{background:'#ffffff'}} xs={12}>
                                            <Grid item container className={classes.evaluationCardTop} style={{background:'#28b1e7'}} justify="center" alignItems="center">
                                                <Typography variant="body1" align="center" style={{color:'#ffffff'}}>Mode</Typography>
                                            </Grid>
                                            <Grid item container style={{padding:27}} justify="center" alignItems="center">
                                                <Typography variant="h3">{valueEva.dashboardAboutFirstEva.modes[0].toFixed(2)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            </Grid>
                                <Grid container xs={12} justify="center" className={classes.evaluationCardBar} item>
                                    <Bar
                                    data={{
                                        labels: [
                                          "First Quarter",
                                          "Second Quarter",
                                          "Third Quarter",
                                          "Fourth Quarter",
                                        ],
                                        datasets: [
                                          {
                                            label: "Average",
                                            backgroundColor: "#15ceef",
                                            borderColor: "#15ceef",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#15ceef",
                                            hoverBorderColor: "#15ceef",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.average),parseInt(valueEva.dashboardAboutFirstEva.secondQ.average)
                                            , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.average), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.average)]
                                          },
                                
                                          {
                                            label: "Standard deviation",
                                            backgroundColor: "#7764e4",
                                            borderColor: "#7764e4",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#7764e4",
                                            hoverBorderColor: "#7764e4",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.standardDeviation),parseInt(valueEva.dashboardAboutFirstEva.secondQ.standardDeviation)
                                                , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.standardDeviation), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.standardDeviation)]
                                          },
                                          {
                                            label: "Median",
                                            backgroundColor: "#2dce98",
                                            borderColor: "#2dce98",
                                            borderWidth: 1,
                                            //stack: 1,
                                            hoverBackgroundColor: "#2dce98",
                                            hoverBorderColor: "#2dce98",
                                            data: [parseInt(valueEva.dashboardAboutFirstEva.firstQ.median),parseInt(valueEva.dashboardAboutFirstEva.secondQ.median)
                                                , parseInt(valueEva.dashboardAboutFirstEva.thirdQ.median), parseInt(valueEva.dashboardAboutFirstEva.fourthQ.median)]
                                          }
                                        ]
                                      }}
                                    width={null}
                                    height={null}
                                    options={{
                                        responsive: true,
                                        legend: {
                                          display: false
                                        },
                                        type: "bar"
                                        //   scales: {
                                        //     xAxes: [{
                                        //         stacked: true
                                        //     }],
                                        //     yAxes: [{
                                        //         stacked: true
                                        //     }]
                                        // }
                                      }}
                                />
                                </Grid>       
                        </Grid>
                        }
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50,marginTop:25}}>Top 10 Countries with Most Applications</Typography>
                            <Grid container xs={12}>
                                <Grid container xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountryMostAppTable).length > 0 &&
                                        <TableContainer className={classes.tableContainer} component={Paper}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountryMostAppTable.countriesMostApplication.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        } 
                                    </Grid>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item xs={12} lg={9}>
                                        <Grid item xs={12} className={classes.countryCard}>
                                            <DataMap
                                                style={{width:'100%',height:550,textAlign:'end'}}
                                                projection='mercator' // big world map
                                                // countries don't listed in dataset will be painted with this color
                                                fills={{defaultFill: '#F5F5F5'} }
                                                data={valueCountriesMostApp}
                                                geographyConfig={
                                                    {borderColor: '#DEDEDE',
                                                        highlightBorderWidth: 2,
                                                        // don't change color on mouse hover
                                                        highlightFillColor: function(geo) {
                                                            return geo['fillColor'] || '#F5F5F5';
                                                        },
                                                        // only change border
                                                        highlightBorderColor: '#B7B7B7',
                                                        // show desired information in tooltip
                                                        popupTemplate: function(geo, data) {
                                                            // don't show tooltip if country don't present in dataset
                                                            if (!data) { return ; }
                                                            // tooltip content
                                                            return ['<div class="hoverinfo">',
                                                                '<strong>', geo.properties.name, '</strong>',
                                                                '<br>Count: <strong>', data.numberOfThings, '</strong>',
                                                                '</div>'].join('');
                                                        }}

                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>    
                        </Grid>
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50}}>Top 10 Countries with Most Passes</Typography>
                            <Grid container xs={12}>
                                <Grid item xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountriesMostPassesTable).length > 0 &&
                                    <Paper>
                                        <TableContainer className={classes.tableContainer}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountriesMostPassesTable.countriesMostPasses.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        </Paper>
                                        } 
                                    </Grid>
                                </Grid>
                                <Hidden xsDown>
                                    <Grid item xs={12} lg={9}>
                                        <Grid item xs={12} className={classes.countryCard}>
                                            <DataMap
                                                style={{width:'100%',height:550,textAlign:'end'}}
                                                projection='mercator' // big world map
                                                // countries don't listed in dataset will be painted with this color
                                                fills={{defaultFill: '#F5F5F5'} }
                                                data={valueCountriesMostPasses}
                                                geographyConfig={
                                                    {borderColor: '#DEDEDE',
                                                        highlightBorderWidth: 2,
                                                        // don't change color on mouse hover
                                                        highlightFillColor: function(geo) {
                                                            return geo['fillColor'] || '#F5F5F5';
                                                        },
                                                        // only change border
                                                        highlightBorderColor: '#B7B7B7',
                                                        // show desired information in tooltip
                                                        popupTemplate: function(geo, data) {
                                                            // don't show tooltip if country don't present in dataset
                                                            if (!data) { return ; }
                                                            // tooltip content
                                                            return ['<div class="hoverinfo">',
                                                                '<strong>', geo.properties.name, '</strong>',
                                                                '<br>Count: <strong>', data.numberOfThings, '</strong>',
                                                                '</div>'].join('');
                                                        }}

                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Hidden>
                            </Grid>    
                        </Grid>
                        <Grid container xs={12} style={{marginBottom:50}}>
                            <Typography variant="h4" style={{marginBottom:50}}>List of Founders with Previous Exits (Top 20)</Typography>
                            <Grid container xs={12}>
                                <Grid item xs={12} lg={3} style={{marginBottom:50}}>
                                    <Grid item xs={12} lg={11}>
                                    {Object.keys(valueCountryFouPrevExit).length > 0 &&
                                        <TableContainer className={classes.tableContainer} component={Paper}>
                                            <Table stickyHeader={true} className={classes.table} aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                <StyledTableCell style={{padding:'5px 16px'}}>Country</StyledTableCell>
                                                <StyledTableCell style={{padding:'5px 16px'}} align="left">Count</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                             <TableBody>
                                                {valueCountryFouPrevExit.foundersWithPrevExit.country.map((row) => (
                                                    <StyledTableRow key={row.id}>
                                                        <StyledTableCell component="th" scope="row">
                                                        {row.title}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{fontWeight:600}} align="left">{row.count}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                            
                                            </Table>
                                        </TableContainer>
                                        } 
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={9}>
                                {Object.keys(valueCountryFouPrevExit).length > 0 && 
                                    <Grid item xs={12} style={{display:'flex',justifyContent:'center'}} className={classes.countryCard}>
                                        <Line data={{
                                            labels: ['First Quarter', 'Second Quarter', 'Third Quarter', 'Fourth Quarter'],
                                            datasets: [
                                                {
                                                label: 'Previous Exits',
                                                fill: false,
                                                lineTension: 0.1,
                                                backgroundColor: '#172b4d',
                                                borderColor: '#172b4d',
                                                borderCapStyle: 'butt',
                                                borderDash: [],
                                                borderDashOffset: 0.0,
                                                borderJoinStyle: 'miter',
                                                pointBorderColor: '#172b4d',
                                                pointBackgroundColor: '#fff',
                                                pointBorderWidth: 1,
                                                pointHoverRadius: 5,
                                                pointHoverBackgroundColor: '#172b4d',
                                                pointHoverBorderColor: '#172b4d',
                                                pointHoverBorderWidth: 2,
                                                pointRadius: 1,
                                                pointHitRadius: 10,
                                                data: [valueCountryFouPrevExit.foundersWithPrevExit.firstq, valueCountryFouPrevExit.foundersWithPrevExit.secondq,
                                                    valueCountryFouPrevExit.foundersWithPrevExit.thirdq, valueCountryFouPrevExit.foundersWithPrevExit.fourthq]
                                                }
                                            ]
                                            }} />
                                    </Grid>
                                }
                                </Grid>
                            </Grid>    
                        </Grid>

                    </Grid>
                </Grid>
                }
        </div>
    )
}