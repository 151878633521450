import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import CompanyCard from "../../Components/CompanyCard";
import RatingCard from "../../Components/RatingCard";
import NoteCard from "../../Components/NoteCard";
import TeamCard from "../../Components/TeamCard";
import TabCompanyCard from "../../Components/TabCompanyCard";
import TabProductCard from "../../Components/TabProductCard";
import TabMarketCard from "../../Components/TabMarketCard";
import TabBusinessCard from "../../Components/TabBusinessCard";
import MobileHeader from "../../Components/MobileHeader";
import MyTeamCard from "../../Components/MyTeamCard";
import MyTabCompanyCard from "../../Components/MyTabCompanyCard";
import MyTabProductCard from "../../Components/MyTabProductCard";
import MyTabMarketCard from "../../Components/MyTabMarketCard";
import MyTabBusinessCard from "../../Components/MyTabBusinessCard";
import {makeStyles} from "@material-ui/core/styles";
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {EditorState,} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import 'draft-js/dist/Draft.css';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TabDocsCard from "../../Components/TabDocsCard";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) =>({
        containerCss: {
            display: 'flex',
            justifyContent: 'center',
        },
        firstPart: {
            marginTop: 50,
            justifyContent:"center",
            maxWidth: 550,
            [theme.breakpoints.up('xl')]: {
                justifyContent:"start", 
            },

        },
        secondPart: {
            marginTop: 50,
            [theme.breakpoints.up('lg')]: {
                paddingRight:20
            },
        },
        cardContainer: {
            marginBottom: '15px',
        },
        paper: {
            padding: '27px 0',
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            '& div': {
                [theme.breakpoints.up('xl')]: {
                    justifyContent: 'center',
                },
            }
        },
        tabButton: {
            fontSize: '16px',
            color: '#00000087',
            minWidth: 0,
            textTransform:'none',
            maxWidth: 'fit-content',
            padding: '6px 0',
            margin: '0 64px',
        },
        paddingZero: {
            padding: '0',
        },
        detailContainer:{
            maxWidth: '1320px',
            padding:0,
            [theme.breakpoints.down('md')]: {
              padding:' 0 16px'
            },
        }
    }
));

export default function StartupProfile(props){
    const classes = useStyles();
    const [detail, setDetail] = React.useState({});
    const [value, setValue] = React.useState(0);
    const [isSentNote, setIsSentNote] = React.useState(false);
    const [slug,setSlug] = React.useState(props.match.params.slug);
    const ALL_STARTUPS = gql`
    query allStartups($slug:String) {
        startupDetail(slug:$slug){
            id
            title
            website
            logo
            location
            founderSize
            description
            stage
            marketTotalSize
            marketTargetRegion
            businessModel
            establishedDate
            foundationDate
            lastRoundDate
            lastRoundInvestment
            foundersPrevStartupExp
            foundersPrevCorporateExp
            competitorAmount
            postMoneyValuation
            capTableAInvestors
            capTableAFounding
            productProblem
            productSolution
            investmentRaisedAmount
            capTableAInvestors
            competitorAnalysis
            investmentNeeded
            investmentNeededDD
            pitchDeck
            businessPlan
            payrollFA
            marketingFA
            salesFA
            developmentFA
            rdFA
            otherFA
            partnerships
            goToMarketStrategy
            exitStrategy
            isFavorited
            docsRoadmap
            docsStatistics
            docsBreakdowns
            docsSummary
            docsCapTable
            industry
            totalRevenueGrowth
            averageRevenueGrowth
            revenueRecurringType
            productDifFromComp
            firstCompetitorsName
            firstCompetitorsWebsite
            secondCompetitorsName
            secondCompetitorsWebsite
            thirdCompetitorsName
            thirdCompetitorsWebsite
            sharePercent
            founderMembers{
                id
                nameSurname
                profilePhoto
                jobTitle
                socialLinkedIn
                madeExit
                exitedCompany
                exitedCompanyStatus
                career{
                  typeStartOrCorporate
                  company
                  jobTitle
                  isPresent
                }
              }
            otherMembers{
                id
                nameSurname
                jobTitle
                socialLinkedIn
            }
            city{
              id
              title
              country{
                  id
                  title
              }
            }
            country{
              id
              title
            }
            myRating
            rating
        }
  
    }
    `;
    const [ getData, { loading, data } ] = useLazyQuery(ALL_STARTUPS, {
        fetchPolicy:"network-only",
        variables: {slug:slug},
    });

    const FETCH_NOTES = gql`
        query startupDetailAdminNotes ($id: ID!) {
            startupDetailAdminNotes(id: $id){
                id
                note
                date
                user{
                    firstName
                    lastName
                    profilePhoto
                }
            }

        }
    `;

    // ADMİN NOTES'LARINI LİSTELERSEK KULLANIRIZ

    // const { loading:loadingNotes, data:dataNotes } = useQuery(FETCH_NOTES, {
    //     skip:!(props.authRole === 'Admin' || props.authRole === 'SuperAdmin'),
    //     variables: {id: props.match.params.id},
    // });
    //
    // React.useEffect(() => {
    //     if (!loadingNotes){
    //         if (dataNotes){
    //             console.log("*******************************************")
    //             console.log(dataNotes)
    //             console.log("*******************************************")
    //         }
    //     }
    // },
    //   [dataNotes, loadingNotes])

    React.useEffect(() => {
        if(props.match.params.slug !== slug){
            setSlug(props.match.params.slug)
        }
        if(!loading){
            if(!data)
            {
                getData()
            }    
            if(data){
                if(data.startupDetail[0].productProblem){
                    let htmlProduct = data.startupDetail[0].productProblem
                    if(typeof(data.startupDetail[0].productProblem) === 'string'){
                        data.startupDetail[0].productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.startupDetail[0].productProblem = new EditorState.createEmpty()}
                if(data.startupDetail[0].productDifFromComp){
                    let htmlProduct = data.startupDetail[0].productDifFromComp
                    if(typeof(data.startupDetail[0].productDifFromComp) === 'string'){
                        data.startupDetail[0].productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.startupDetail[0].productDifFromComp = new EditorState.createEmpty()}
                if(data.startupDetail[0].productSolution){
                    let htmlProduct = data.startupDetail[0].productSolution
                    if(typeof(data.startupDetail[0].productSolution) === 'string'){
                        data.startupDetail[0].productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.startupDetail[0].productSolution = new EditorState.createEmpty()}
                if(data.startupDetail[0].partnerships){
                    let htmlAbt= data.startupDetail[0].partnerships
                    if(typeof(data.startupDetail[0].partnerships) === 'string'){
                        data.startupDetail[0].partnerships = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.startupDetail[0].partnerships = new EditorState.createEmpty()}
                if(data.startupDetail[0].competitorAnalysis){
                    let htmlAbt= data.startupDetail[0].competitorAnalysis
                    if(typeof(data.startupDetail[0].competitorAnalysis) === 'string'){
                        data.startupDetail[0].competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.startupDetail[0].competitorAnalysis = new EditorState.createEmpty()}
                if(data.startupDetail[0].goToMarketStrategy){
                    let htmlAbt= data.startupDetail[0].goToMarketStrategy
                    if(typeof(data.startupDetail[0].goToMarketStrategy) === 'string'){
                        data.startupDetail[0].goToMarketStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.startupDetail[0].goToMarketStrategy = new EditorState.createEmpty()}
                if(data.startupDetail[0].exitStrategy){
                    let htmlAbt= data.startupDetail[0].exitStrategy
                    if(typeof(data.startupDetail[0].exitStrategy) === 'string'){
                        data.startupDetail[0].exitStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.startupDetail[0].exitStrategy = new EditorState.createEmpty()}
                if(data.startupDetail[0].exitStrategy){
                    let htmlAbt= data.startupDetail[0].exitStrategy
                    if(typeof(data.startupDetail[0].exitStrategy) === 'string'){
                        data.startupDetail[0].exitStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.startupDetail[0].exitStrategy = new EditorState.createEmpty()}
                if(data.startupDetail[0].founderMembers === null){
                    data.startupDetail[0].founderMembers = []
                }
                if(data.startupDetail[0].otherMembers === null){
                    data.startupDetail[0].otherMembers = []
                }
                if(!data.startupDetail[0].city){
                    data.startupDetail[0].city = " "
                }
                if(!data.startupDetail[0].country){
                    data.startupDetail[0].country = " "
                }
              setDetail(data.startupDetail[0]);
              }
            }
            },
            [data, loading, props.match.params.slug])

            const handleChange = (event, newValue) =>  {
                setValue(newValue)
            };
            
            const handleChangeIndex= (index) => {
                setValue(index)
            };
        return (

            <div className="App">
            {!loading && 
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
                <Grid container justify="center" style={{background:'#f7f7fc'}}>
                    <Grid className={classes.detailContainer} container>
                        <Grid style={{marginBottom: '50px'}} item container xs={12} sm={12} lg={4} className={classes.firstPart}>
                            <Grid item xs={12} sm={11} xl={11}>
                                {(detail.country) &&  <CompanyCard id={detail.id}  website={detail.website} authRole={props.authRole} isFavorited={detail.isFavorited} title={detail.title} businessModel={detail.businessModel}
                                logo={detail.logo} totalValuation={detail.postMoneyValuation}  investmentNeededDD={detail.investmentNeededDD} funding={detail.investmentRaisedAmount + detail.lastRoundInvestment} foundationDate={detail.establishedDate} location={detail.city}
                                country={detail.country} pitchDeck={detail.pitchDeck} businessPlan={detail.businessPlan}/>}
                                {!loading && <RatingCard getData={getData} id={detail.id} setIsSentNote={setIsSentNote} rating={detail.rating} myRating={detail.myRating}  />}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} xl className={classes.secondPart}>
                            <AppBar style={{boxShadow: 'none'}} position="static" color="default">
                                <Tabs className={classes.paper}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                >
                                    <Tab
                                        classes={{root: classes.tabButton}} label="Company" {...a11yProps(0)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Team" {...a11yProps(1)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Competition & Market" {...a11yProps(2)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Investment" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel className={classes.tabPanel} value={value} index={0}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {
                                            ((props.authRole === 'Admin' || props.authRole === 'SuperAdmin')) ?
                                        <div>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                                <MyTabCompanyCard setIsSentNote={setIsSentNote} getData={getData} detail={detail} authRole={props.authRole}/>
                                                <NoteCard startupId={detail.id} setIsSentNote={setIsSentNote}/>
                                            </div>
                                        }
                                        </div> : 
                                        <div>
                                            <TabCompanyCard setIsSentNote={setIsSentNote} getData={getData} detail={detail} authRole={props.authRole}/>
                                        </div>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={value} index={1} >
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                    {
                                            ((props.authRole === 'Admin' || props.authRole === 'SuperAdmin')) ?
                                        <div>
                                            <MyTeamCard founderMembers={detail.founderMembers} getData={getData} setIsSentNote={setIsSentNote} authRole={props.authRole} id={detail.id} otherMembers={detail.otherMembers}/>
                                            <NoteCard startupId={detail.id} setIsSentNote={setIsSentNote}/>
                                        </div> : 
                                        <div>
                                            <TeamCard founderMembers={detail.founderMembers} otherMembers={detail.otherMembers} />
                                        </div>
                                    }
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                    {
                                            ((props.authRole === 'Admin' || props.authRole === 'SuperAdmin')) ?
                                        <div>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                            <MyTabMarketCard authRole={props.authRole} getData={getData} setIsSentNote={setIsSentNote} detail={detail}/>
                                            <NoteCard startupId={detail.id} setIsSentNote={setIsSentNote}/>
                                            </div>
                                        }
                                        </div> : 
                                        <div>
                                            <TabMarketCard authRole={props.authRole} getData={getData} setIsSentNote={setIsSentNote} detail={detail}/>
                                        </div>
                                    }
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                    {
                                            ((props.authRole === 'Admin' || props.authRole === 'SuperAdmin')) ?
                                        <div>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                            <MyTabBusinessCard authRole={props.authRole} getData={getData} setIsSentNote={setIsSentNote} detail={detail}/>
                                            <NoteCard startupId={detail.id} setIsSentNote={setIsSentNote}/>
                                            </div>
                                        }
                                        </div> : 
                                        <div>
                                            <TabBusinessCard authRole={props.authRole} getData={getData} setIsSentNote={setIsSentNote} detail={detail} />
                                        </div>
                                    }
                                    </Grid>
                                </TabPanel>
                            </SwipeableViews>
                            <Snackbar autoHideDuration={5000} onClose={() => setIsSentNote(false)} open={isSentNote} >
                                <Alert severity="success">Successful.</Alert>
                            </Snackbar>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
                }  
            </div>
        )
    }
