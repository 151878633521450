import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Power from '../../static/icon/powerpoint.png';
import Word from '../../static/icon/word.png';
import Excel from '../../static/icon/excel.png';
import Button from "@material-ui/core/Button";
import Pdf from '../../static/icon/pdf.png';
import Picture from '../../static/icon/picture.png';
import Paper from "@material-ui/core/Paper";
import editImg from '../../static/icon/edit.svg';
import Typography from "@material-ui/core/Typography";
import { Form, Formik , Field } from 'formik';
import Dropzone from 'react-dropzone';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';

const useStyles = makeStyles((theme) =>({
        cardContainer: {
            marginBottom: '15px',
        },
        borderRight: {
            [theme.breakpoints.up('sm')]: {
                borderRight: '0.2px solid'
            },
        },
        secondExp: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: '20px!important'
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '5px'
            },
        },
        namePart: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },  
        },
        companyPart: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '50px'
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },    
        },
        paper: {
            padding: 25,
            [theme.breakpoints.up('lg')]: {
                padding: 50,
                paddingTop: 25
            },
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff'
        },
        workExperience: {
            textAlign: 'start',
        },
        labelFirst: {
            textTransform: 'capitalize',
            color: '#ffffff',
        },
        img: {
            width: 120,
            height:120,
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
        },
        baseStyle: {
            flex: 1,
            marginTop:10,
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '73px!important',
            borderWidth: 2,
            borderRadius: 7,
            borderColor: '#dddddd',
            borderStyle: 'dashed',
            backgroundColor: '#ffffff',
            color: '#172b4d',
            fontWeight: '600',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
    }));

export default function TabDocsCard(props) {
        const [edit, setEdit] = React.useState(false);
        const [detail, setDetail] = React.useState(props.detail);
        const [approved, setApproved] = React.useState(true);
        const classes = useStyles();

        const handleClick = () => {
            props.investorBeApproved().then(r => {
                setApproved(false)
                if (r.data.investorBeApproved === true){
                    props.setIsSentNote(true)   
                }
            })
        } 
        const handleSubmit = variables => {
            if(props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
                props.editStartup({ variables })
                .then(data => {
                    if (data.data.editStartup) {
                        props.setIsSentNote(true)                    
                        props.getData()
                    } 
                })
            }
            else {
                variables.id = props.params.id
                props.startupEditStartup({ variables })
                .then(data => {
                  if (data.data.startupEditStartup) {
                    props.setIsSentNote(true)
                    props.setSendChanges(true)  
                    props.getData()
                  } 
                })
            }
        };
        return (
            <Paper style={{marginBottom: '30px'}} className={classes.paper}>
                {!edit ?
                <div>
                    <Grid container justify="flex-end" style={{marginBottom:15}}>
                        { (window.location.href.indexOf("draft") > -1 && (props.authRole !== 'Admin' && props.authRole !== 'SuperAdmin')) && 
                            <Button variant="containedSecondary" style={{padding:"10px 25px"}}  onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{marginRight:'5px'}}/> Edit </Button> 
                        }
                        { ((props.authRole === 'Admin' || props.authRole === 'SuperAdmin') && window.location.href.indexOf("draft") === -1) && 
                            <Button variant="containedSecondary" style={{padding:"10px 25px"}}  onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{marginRight:'5px'}}/> Edit </Button> 
                        }
                    </Grid>
                    <Grid style={{marginBottom: '25px'}} item sm={12} container>
                        {(props.detail.docsRoadmap || props.detail.docsStatistics || props.detail.docsBreakdowns || props.detail.docsSummary || props.detail.docsCapTable) ? 
                        <Grid item xs={12} md={6} container style={{height:'fit-content'}}>
                            <Grid item container alignItems="center" justify="space-between" style={{marginBottom:25,height:'fit-content'}}>
                                    <Typography gutterBottom={true} align={"left"}
                                                variant="h5">
                                            Docs
                                    </Typography>
                            </Grid>
                            {props.detail.docsRoadmap &&
                                <Grid container alignItems="center" style={{marginBottom:15,height:'fit-content'}}>
                                    <a href={props.detail.docsRoadmap} alt="docsRoadmap" style={{padding:0,display:'flex'}} download>
                                        <img src={Power} alt="power"/>
                                        <Typography align={"left"}
                                                        variant="body1"
                                                        style={{marginLeft:10}}>
                                                    Product roadmap
                                        </Typography>
                                    </a>
                                </Grid>
                            }
                            {props.detail.docsStatistics &&
                            <Grid container alignItems="center" style={{marginBottom:15,height:'fit-content'}}>
                                <a href={props.detail.docsStatistics} alt="docsStatistics" style={{padding:0,display:'flex'}} download>
                                    <img src={Word} alt="word"/>
                                    <Typography align={"left"}
                                                    variant="body1" style={{marginLeft:10}}>
                                                Rentover Statistics
                                    </Typography>
                                </a>    
                            </Grid>
                            }
                            {props.detail.docsBreakdowns &&
                            <Grid container alignItems="center" style={{marginBottom:15,height:'fit-content'}}>
                                <a href={props.detail.docsBreakdowns} alt="docsBreakdowns" style={{padding:0,display:'flex'}} download>
                                    <img src={Excel} alt="excel"/>
                                    <Typography align={"left"}
                                                    variant="body1" style={{marginLeft:10}}>
                                                User Breakdowns
                                    </Typography>
                                </a>
                            </Grid>
                            }
                            {props.detail.docsSummary &&
                            <Grid container alignItems="center" style={{marginBottom:15,height:'fit-content'}}>
                                <a href={props.detail.docsSummary} alt="docsSummary" style={{padding:0,display:'flex'}} download>
                                    <img src={Pdf} alt="pdf"/>
                                    <Typography align={"left"}
                                                    variant="body1" style={{marginLeft:10}}>
                                                Excutive Summary
                                    </Typography>
                                </a>
                            </Grid>
                            }
                            {props.detail.docsCapTable &&
                            <Grid container alignItems="center" style={{marginBottom:15,height:'fit-content'}}>
                                <a href={props.detail.docsCapTable} alt="docsCapTable" style={{padding:0,display:'flex'}} download>
                                    <img src={Picture} alt="picture"/>
                                    <Typography align={"left"}
                                                    variant="body1" style={{marginLeft:10}}>
                                                Rentover Cap Table
                                    </Typography>
                                </a>
                            </Grid>
                            }
                        </Grid>
                        :                         
                        <Grid item xs={12} container >
                            <Grid item container alignItems="center" justify="center" style={{marginBottom:25,marginTop:25}}>
                                    <Typography gutterBottom={true} align={"left"}
                                                variant="h5">
                                            There are no additional documents..
                                    </Typography>
                            </Grid>
                        </Grid>}
                        {props.authRole === 'Investor' &&
                        <Grid item xs={12} md={6} container>
                            <Grid item container xs={9} alignItems="center" justify="center" style={{marginBottom:25, border:'1px solid #dddddd', borderRadius:'7px',minHeight:250}}>
                                <Grid item xs={9}>
                                    <Typography gutterBottom={true} align={"center"}
                                                variant="h5">
                                            Special documents can only be viewed by approved investors.
                                    </Typography>
                                    {(!props.getApprovedStatus && approved) && 
                                    <Button onClick={handleClick}
                                        variant="containedPrimary" size={"medium"} style={{top:45,padding:10,fontSize:15,background:'#172b4d'}}>
                                        Be an approved investor
                                    </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </div>
                : 
                <Grid style={{marginBottom: '25px'}} item sm={12} container>
                    <Formik
                        initialValues={{
                            id:props.detail.id,
                            docsRoadmap:null,
                            docsStatistics:null,
                            docsBreakdowns:null,
                            docsSummary:null,
                            docsCapTable:null,

                        }}
                        enableReinitialize={true}
                        onSubmit={values => {
                            handleSubmit(values);
                        }}
                        >
                        {props => (
                            <Form style={{width:'100%'}}>
                                <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                    <Grid item container alignItems="center" justify="flex-end">
                                        <Button variant="containedSecondary" style={{padding:"10px 25px"}}  type="submit"> Save </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography align="left" variant="h5">
                                            Product roadmap
                                        </Typography>
                                        <Field
                                        name="docsRoadmap">{({ field, form , meta}) => (
                                            <div>
                                                <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("docsRoadmap", acceptedFiles)}>
                                                    {({getRootProps, getInputProps, acceptedFiles}) => (
                                                        <section>
                                                            <div {...getRootProps({className: classes.baseStyle})}>
                                                                <input {...getInputProps()} />
                                                            {acceptedFiles.length === 0 ? <Typography variant="h6" style={{display:'flex'}}><OpenInBrowser style={{marginRight:'5px'}}></OpenInBrowser> Browse...</Typography> : <Typography variant="h6" style={{display:'flex'}}>{acceptedFiles[0].name} added.You can select another one</Typography>} 
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                <Grid container justify="space-between" style={{marginBottom:25}}>{detail.docsRoadmap && 
                                                    <a href={detail.docsRoadmap} alt="pitch-deck" style={{padding:0}} download><Typography variant="body2" align="left">{detail.docsRoadmap.split("/").pop().replace(".png","")} is existing file</Typography></a> }
                                                    <Typography variant="body2" align="right"><span style={{color: '#28b1e7'}}>*</span>Max 20MB files can be uploaded</Typography>
                                                </Grid>
                                            </div>    
                                        )}
                                        </Field>                   
                                        <Typography variant="h5" align="left">
                                            Rentover Statistics
                                        </Typography>
                                        <Field
                                        name="docsStatistics">
                                        {({ field, form, meta}) => (
                                        <div>
                                            <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("docsStatistics", acceptedFiles)}>
                                                {({getRootProps, getInputProps , acceptedFiles}) => (
                                                    <section>
                                                        <div {...getRootProps({className: classes.baseStyle})}>
                                                            <input {...getInputProps()} />
                                                            {acceptedFiles.length === 0 ? <Typography variant="h6" style={{display:'flex'}}><OpenInBrowser style={{marginRight:'5px'}}></OpenInBrowser> Browse...</Typography> : <Typography variant="h6" style={{display:'flex'}}>{acceptedFiles[0].name} added. You can select another one</Typography>} 
                                                        </div>
                                                    </section>
                                                )}
                                                </Dropzone>
                                                <Grid container justify="space-between" style={{marginBottom:25}}>{detail.docsStatistics && 
                                                    <a href={detail.docsStatistics} alt="businessPlan" style={{padding:0}} download><Typography variant="body2" align="left">{detail.docsStatistics.split("/").pop().replace(".png","")} is existing file</Typography> </a>}
                                                    <Typography variant="body2" align="right"><span style={{color: '#28b1e7'}}>*</span>Max 20MB files can be uploaded</Typography>
                                                </Grid>
                                        </div>
                                        )}
                                        </Field>
                                        <Typography variant="h5" align="left">
                                            User Breakdowns
                                        </Typography>
                                        <Field
                                        name="docsBreakdowns">
                                        {({ field, form, meta}) => (
                                        <div>
                                            <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("docsBreakdowns", acceptedFiles)}>
                                                {({getRootProps, getInputProps , acceptedFiles}) => (
                                                    <section>
                                                        <div {...getRootProps({className: classes.baseStyle})}>
                                                            <input {...getInputProps()} />
                                                            {acceptedFiles.length === 0 ? <Typography variant="h6" style={{display:'flex'}}><OpenInBrowser style={{marginRight:'5px'}}></OpenInBrowser> Browse...</Typography> : <Typography variant="h6" style={{display:'flex'}}>{acceptedFiles[0].name} added. You can select another one</Typography>} 
                                                        </div>
                                                    </section>
                                                )}
                                                </Dropzone>
                                                <Grid container justify="space-between" style={{marginBottom:25}}>{detail.docsBreakdowns && 
                                                    <a href={detail.docsBreakdowns} alt="businessPlan" style={{padding:0}} download><Typography variant="body2" align="left">{detail.docsBreakdowns.split("/").pop().replace(".png","")} is existing file</Typography> </a>}
                                                    <Typography variant="body2" align="right"><span style={{color: '#28b1e7'}}>*</span>Max 20MB files can be uploaded</Typography>
                                                </Grid>
                                        </div>
                                        )}
                                        </Field>
                                        <Typography variant="h5" align="left">
                                            Excutive Summary
                                        </Typography>
                                        <Field
                                        name="docsSummary">
                                        {({ field, form, meta}) => (
                                        <div>
                                            <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("docsSummary", acceptedFiles)}>
                                                {({getRootProps, getInputProps , acceptedFiles}) => (
                                                    <section>
                                                        <div {...getRootProps({className: classes.baseStyle})}>
                                                            <input {...getInputProps()} />
                                                            {acceptedFiles.length === 0 ? <Typography variant="h6" style={{display:'flex'}}><OpenInBrowser style={{marginRight:'5px'}}></OpenInBrowser> Browse...</Typography> : <Typography variant="h6" style={{display:'flex'}}>{acceptedFiles[0].name} added. You can select another one</Typography>} 
                                                        </div>
                                                    </section>
                                                )}
                                                </Dropzone>
                                                <Grid container justify="space-between" style={{marginBottom:25}}>{detail.docsSummary && 
                                                    <a href={detail.docsSummary} alt="businessPlan" style={{padding:0}} download><Typography variant="body2" align="left">{detail.docsSummary.split("/").pop().replace(".png","")} is existing file</Typography> </a>}
                                                    <Typography variant="body2" align="right"><span style={{color: '#28b1e7'}}>*</span>Max 20MB files can be uploaded</Typography>
                                                </Grid>
                                        </div>
                                        )}
                                        </Field>
                                        <Typography variant="h5" align="left">
                                        Rentover Cap Table
                                        </Typography>
                                        <Field
                                        name="docsCapTable">
                                        {({ field, form, meta}) => (
                                        <div>
                                            <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("docsCapTable", acceptedFiles)}>
                                                {({getRootProps, getInputProps , acceptedFiles}) => (
                                                    <section>
                                                        <div {...getRootProps({className: classes.baseStyle})}>
                                                            <input {...getInputProps()} />
                                                            {acceptedFiles.length === 0 ? <Typography variant="h6" style={{display:'flex'}}><OpenInBrowser style={{marginRight:'5px'}}></OpenInBrowser> Browse...</Typography> : <Typography variant="h6" style={{display:'flex'}}>{acceptedFiles[0].name} added. You can select another one</Typography>} 
                                                        </div>
                                                    </section>
                                                )}
                                                </Dropzone>
                                                <Grid container justify="space-between" style={{marginBottom:25}}>{detail.docsCapTable && 
                                                    <a href={detail.docsCapTable} alt="businessPlan" style={{padding:0}} download><Typography variant="body2" align="left">{detail.docsCapTable.split("/").pop().replace(".png","")} is existing file</Typography> </a>}
                                                    <Typography variant="body2" align="right"><span style={{color: '#28b1e7'}}>*</span>Max 20MB files can be uploaded</Typography>
                                                </Grid>
                                        </div>
                                        )}
                                        </Field>
                                    </Grid>   
                                </Grid>
                            </Form>
                        )}
                    </Formik> 
                </Grid>}
            </Paper>
        );
}