import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import Grid from "@material-ui/core/Grid";
import MobileHeader from "../../Components/MobileHeader";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
}));
export default function SuperAdminVideos(props){
  const classes = useStyles();

    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                  <MobileHeader></MobileHeader>
                </Hidden>
            <Grid container style={{background:'#f7f7fc'}}>
              <LeftNavigation>
              </LeftNavigation>
            </Grid>
        </div>
    )
}