import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import RatingCard from "./RatingCard";

const RATE_STARTUP_MUTATION = gql`
    mutation startupRating(
        $startupId: ID!,
        $rate: Int
    ) {
        startupRating(
            startupId: $startupId,
            rate: $rate
        )
    }
`;

const JoinWithMutation = compose(
  graphql(RATE_STARTUP_MUTATION, {
    name: 'startupRating',
  }),
)(RatingCard);
export default JoinWithMutation;