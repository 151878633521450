import React, { useState } from 'react';
import { Switch, Route as RRRoute, Redirect } from 'react-router-dom';
import Test from './Pages/Test';
import Login from './Pages/Login';
import StartupApplicationForm from './Pages/StartupApplicationForm';
import DeepDiveForm from './Pages/DeepDiveForm';
import Startups from './Pages/Startups';
import FavoriteStartups from './Pages/FavoriteStartups';
import SuperAdminStartups from './Pages/SuperAdminStartups';
import MyStartupProfile from './Pages/MyStartupProfile';
import DraftProfile from './Pages/DraftProfile';
import InvestorEdit from './Pages/InvestorEdit';
import SuperAdminVideos from './Pages/SuperAdminVideos';
import SuperAdminLogs from './Pages/SuperAdminLogs';
import SuperAdminReports from './Pages/SuperAdminReports';
import SuperAdminInvestorAdd from './Pages/SuperAdminInvestorAdd';
import SuperAdminProfile from './Pages/SuperAdminProfile';
import SuperAdminMessages from './Pages/SuperAdminMessages';
import SuperAdminSettings from './Pages/SuperAdminSettings';
import SuperAdminApproveList from './Pages/SuperAdminApproveList';
import SuperAdminEvents from './Pages/SuperAdminEvents';
import SuperAdminInvestorApproveList from './Pages/SuperAdminInvestorApproveList';
import FirstLogin from './Pages/FirstLogin';
import Summary from './Pages/Summary';
import Settings from './Pages/Settings';
import InvestorLanding from './Pages/InvestorLanding';
import SuperAdminInvestors from './Pages/SuperAdminInvestors';
import InvestorJoinUs from './Pages/InvestorJoinUs';
import SuperAdminInvestorDetail from './Pages/SuperAdminInvestorDetail';
import StartupProfile from './Pages/StartupProfile';
import SuperAdminStartupDetail from './Pages/SuperAdminStartupDetail';
import SuccessApplication from './Pages/SuccessApplication';
import SuperAdminStartupEdit from './Pages/SuperAdminStartupEdit';
import SuperAdminInvestorEdit from './Pages/SuperAdminInvestorEdit';
import SuperAdminInvestmentList from './Pages/SuperAdminInvestmentList';
import SetPassword from './Pages/SetPassword';
import LoginWithCode from './Pages/LoginWithCode';
import { AuthContext, useAuth } from './context/auth';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FourOneFour from './Pages/FourTwoFour';
import ForgotPassword from './Pages/ForgotPassword';
import get from 'lodash/get';

function Route({ render, ...rest }) {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const { loading, data, refetch: refetchMyProfile } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  const firstName = get(data, 'myProfile.firstName');
  const lastName = get(data, 'myProfile.lastName');
  const profilePhoto = get(data, 'myProfile.profilePhoto');
  const email = get(data, 'myProfile.email');
  const socialLinkedIn = get(data, 'myProfile.socialLinkedIn');
  const phoneNumber = get(data, 'myProfile.phoneNumber');
  const id = get(data, 'myProfile.id');
  const getApprovedStatus = get(data, 'myProfile.getApprovedStatus');
  // make this true to debug rendered routes
  if (process.env.NODE_ENV !== 'production' && false) {
    console.log('Rendered:', rest);
  }

  return (
    <RRRoute
      {...rest}
      authToken={authToken}
      authRole={authRole}
      refetchMyProfile={refetchMyProfile}
      getApprovedStatus={getApprovedStatus}
      firstName={firstName}
      email={email}
      lastName={lastName}
      profilePhoto={profilePhoto}
      socialLinkedIn={socialLinkedIn}
      phoneNumber={phoneNumber}
      id={id}
      render={(props) => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        return render({ authToken, authRole, id, getApprovedStatus, firstName, lastName, profilePhoto, socialLinkedIn, phoneNumber, email, refetchMyProfile, ...props });
      }}
    />
  );
}

function checkUserRole(route, role) {
  if (route === 'InvestorRoute') {
    if (role === 'Investor' || role === 'Admin' || role === 'SuperAdmin') {
      return true;
    }
  } else if (route === 'AdminRoute') {
    if (role === 'SuperAdmin' || role === 'Admin') {
      return true;
    }
  } else if (route === 'SuperAdminRoute') {
    if (role === 'SuperAdmin') {
      return true;
    }
  }

  return false;
}

function SuperAdminRoute({ component: Component, ...rest }) {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  return (
    <Route
      {...rest}
      render={(props) =>  {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (checkUserRole('SuperAdminRoute', authRole)) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}
function AdminRoute({ component: Component, ...rest }) {
  const { loading, data, refetch, error } = LoggedInProfile();
  refetch().catch(() => <Redirect to="/" />)
  const authRole = get(data, 'myProfile.role');
  const userId = get(data, 'myProfile.id');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (!error&&checkUserRole('AdminRoute', authRole)) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

function InvestorRoute({ component: Component, ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  const contractSigned = get(data, 'myProfile.contractSigned');
  const isApprovedInvestor = get(data, 'myProfile.isApprovedInvestor');
  if (window.location.pathname === "/startups/favorites" || window.location.pathname === "/startups"){
    if (authRole === 'Investor' && !isApprovedInvestor){
      return <Redirect to="/" />;
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (rest.path === '/first-login' && contractSigned) {
          return <Redirect to="/404" />;
        }
        if (checkUserRole('InvestorRoute', authRole)) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

function StartupContactRoute({ component: Component, ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (authRole === 'StartupContact') {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}
function AuthenticationRoute({ component: Component, ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');
  const userId = get(data, 'myProfile.id');
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return (
            <div>
              <center>Loading...</center>
            </div>
          );
        }
        if (!userId) {
          return <Component {...props} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
}

function RedirectWithUserRole({ ...rest }) {
  const { loading, data } = LoggedInProfile();
  const authRole = get(data, 'myProfile.role');

  function getRedirectRoute() {
    if (authRole === 'StartupContact') {
      return '/my-startup';
    } else if (authRole === 'Investor') {
      return '/landing';
    } else if (authRole === 'Admin' || authRole === 'SuperAdmin') {
      return '/admin/dashboard';
    }
    return '/login';
  }

  return (
    <Route
      {...rest}
      render={(props) => (authRole !== '' ? <Redirect to={getRedirectRoute()} /> : <Redirect to="/login" />)}
    />
  );
}

function LoggedInProfile() {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const MY_PROFILE = gql`
    query myProfile {
      myProfile {
        id
        role
        firstName
        email
        lastName
        profilePhoto
        socialLinkedIn
        phoneNumber
        contractSigned
        getApprovedStatus
        isApprovedInvestor
      }
    }
  `;
  return useQuery(MY_PROFILE, {
      fetchPolicy:'network-only',
    variables: {},
  });
}
function Routes() {
  const authToken = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN);
  const setToken = (data) => {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, data);
  };
  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      <Switch>
        <Route path="/404" component={FourOneFour} />
        <Route path="/test" component={Test} />
        <Route path="/application-form" component={StartupApplicationForm} />
        <Route path="/continue-application" component={LoginWithCode} />
        <Route path="/set-password" component={SetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />

        <AuthenticationRoute path="/login" component={Login} />
        <AuthenticationRoute path="/join-us" component={InvestorJoinUs} />
        {/*ONLY STARTUPCONTACT*/}
        <StartupContactRoute path="/continue-application" component={LoginWithCode} />
        <StartupContactRoute path="/application-form" component={StartupApplicationForm} />
        <StartupContactRoute path="/my-startup" component={MyStartupProfile} />
        <StartupContactRoute path="/draft-startup/:id" component={DraftProfile} />
        <StartupContactRoute path="/deep-dive" component={DeepDiveForm} />
        <StartupContactRoute path="/application-success" component={SuccessApplication} />
        {/*INVESTOR, ADMIN AND SUPERADMIN*/}
        <InvestorRoute path="/startups/favorites" component={FavoriteStartups} />
        <InvestorRoute path="/startups/" component={Startups} />
        <InvestorRoute path="/startup/:slug" component={StartupProfile} />
        <InvestorRoute path="/landing" component={InvestorLanding} />
        <InvestorRoute path="/edit-profile" component={InvestorEdit} />
        <InvestorRoute path="/settings" component={Settings} />
        
        {/* moved this check to the route */}
        <InvestorRoute path="/first-login" component={FirstLogin} />
        {/*ADMIN AND SUPERADMIN*/}
        <AdminRoute path="/admin/dashboard" component={Summary} />
        <AdminRoute path="/admin/videos" component={SuperAdminVideos} />
        <AdminRoute path="/admin/logs" component={SuperAdminLogs} />
        <AdminRoute path="/admin/events" component={SuperAdminEvents} />
        <AdminRoute path="/admin/draft-startup/:id" component={DraftProfile} />
        <AdminRoute path="/admin/reports" component={SuperAdminReports} />
        <AdminRoute path="/admin/profile" component={SuperAdminProfile} />
        <AdminRoute path="/admin/messages" component={SuperAdminMessages} />
        <AdminRoute path="/admin/settings" component={SuperAdminSettings} />
        <AdminRoute path="/admin/startups" component={SuperAdminStartups} />
        <AdminRoute path="/admin/startup/:slug" component={SuperAdminStartupDetail} />
        <AdminRoute path="/admin/investor/add" component={SuperAdminInvestorAdd} />
        <AdminRoute path="/admin/startup-edit/:slug" component={SuperAdminStartupEdit} />
        <AdminRoute path="/admin/investors/approve-list/" component={SuperAdminInvestorApproveList} />
        <AdminRoute path="/admin/investors/request-list/" component={SuperAdminInvestmentList} />
        <AdminRoute path="/admin/investors" component={SuperAdminInvestors} />
        <AdminRoute path="/startups" component={Startups} />
        <AdminRoute path="/admin/investor/:id" component={SuperAdminInvestorDetail} />
        <AdminRoute path="/admin/approve-list/" component={SuperAdminApproveList} />
        <AdminRoute path="/admin/investor-edit/:id" component={SuperAdminInvestorEdit} />
        <RedirectWithUserRole exact path="/" />
        <Route component={FourOneFour} />
      </Switch>
    </AuthContext.Provider>
  );
}

export default Routes;
