import { TextField, Typography, Grid } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import useFastField from './useFastField.js'

const useStyles = makeStyles((theme) =>({
  inputField: {
    width: '100%',
    marginTop: 10,
  },
}))


export default function FormikTextField({ type, label,disabled, ...props }) {
  const [field, meta] = useFastField(props);
  const classes = useStyles();
  return (
    <>
    <Grid style={{minHeight:95}}>
        {disabled ?<TextField
            variant="outlined"
            disabled
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}
            />:         <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{maxLength: props.maxLength}}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}
        />}

          {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
          </Typography>
          ) : null}
  </Grid>        
    </>
  );
}
