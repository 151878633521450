import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Default from '../../static/img/empty-company.png';

const useStyles = makeStyles((theme) =>({
    startupCard: {
        background:'#ffffff',
        minHeight: '325px',
        borderRadius:'7px',
        padding: '26px 29px',
        [theme.breakpoints.down('xs')]: {
            minHeight: '0'
        }, 
    },
    noStartupCard: {
        background:'#ffffff8a',
        minHeight: '300px',
        borderRadius:'7px',
        padding: '26px 29px',
        [theme.breakpoints.down('xs')]: {
            minHeight: '0'
        }, 
    },
    startupGrid:{
        height:'fit-content',
        marginBottom:30,
        [theme.breakpoints.down('xs')]: {
            padding: '0 !important'
        },
    },
    values:{
        color:'#28b1e7',
        wordBreak:'break-word',
        marginTop:5,
        fontSize:13,
        width:'fit-content',
        fontWeight:600
    },
    startupLogo: {
        width:80,
        height:80,
        [theme.breakpoints.down('lg')]: {
            width:60,
            height:60,
        },  
        background:'#423eff',
        borderRadius: '10px', 
    },
    img: {
        width: 65,
        height: 65,
        marginRight:12,
        objectFit:'cover',
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
        boxShadow: '-1px -1px 5px 2px #f3f3f3',
    },
    Title: {
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight:  1.35,
        textAlign:'left',
        color: '#172b4d',
        wordBreak:"break-word",
    },
    favIcon: {
        cursor:'pointer',
        marginTop:'-15px',
        marginRight:'-15px'
    },
    desc: {
        marginTop: 20,
        color:'#777777',
        wordBreak:'break-word',
        minHeight:42
    }
}));

export default function StartupCard(props) {
    const [fav, setFav] = React.useState(null);
    const addFavorite = (id) => {
        props.addFavorite({ variables: { startupId: id } })
        .then(data => {
          if (data.data.addFavorite) {
            props.refetch()
          } 
        });
    };
    React.useEffect(() => {
        if(fav === null){
            setFav(props.isFavorited)
        }
    },
     [fav])
    function abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "K", "M", "B","T"];
            var suffixNum = Math.floor( (""+value).length/3 );
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
            newValue = shortValue+suffixes[suffixNum];
        }
        return newValue;
    }
    function truncateString(str, num) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '...'
    }
    const removeFavorite = (id) => {
        props.removeFavorite({ variables: { startupId: id } })
        .then(data => {
          if (data.data.removeFavorite) {
            props.refetch()
          } 
        });
    };
    const classes = useStyles();
    return (
      <>
        {' '}
        <Grid item md={6} lg={4} sm={6} xs={12} className={classes.startupGrid}>
          <Grid item container direction="column" justify="end" xs={12} className={classes.startupCard}>
            {props.isFavorited ? (
              <Grid xs={12} onClick={() => removeFavorite(props.id)} container justify="flex-end">
                <BookmarkIcon
                  style={{ color: '#ffe200', cursor: 'pointer', fontSize: 32 }}
                  className={classes.favIcon}
                />
              </Grid>
            ) : (
              <Grid xs={12} onClick={() => addFavorite(props.id)} container justify="flex-end">
                <BookmarkBorderIcon
                  className={classes.favIcon}
                  style={{ color: '#ffe200', cursor: 'pointer', fontSize: 32 }}
                />
              </Grid>
            )}
            <Link to={`/startup/${props.slug}`} style={{ padding: 0 }}>
              <Grid xs={12} container item justify="end">
                <Grid item>
                  {props.logo ? (
                    <img src={props.logo} className={classes.img} alt="logo"></img>
                  ) : (
                    <img src={Default} className={classes.img} alt="logo"></img>
                  )}
                </Grid>
                <Grid container direction="column" item xs className={classes.titleBar} justify="center">
                  <Typography className={classes.Title}>{props.name}</Typography>
                  {props.businessModel && (
                    <Typography variant="body2" align="left">
                      {props.businessModel}
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item style={{ height: '100%' }}>
                {props.desc && (
                  <Typography variant="body2" align="left" className={classes.desc}>
                    {truncateString(props.desc, 120)}
                  </Typography>
                )}
                <Grid xs={12} direction="column" container>
                  <Grid xs={12} container justify="space-between" alignItems="center" style={{ marginTop: 20 }}>
                    <Typography
                      variant="body2"
                      align="left"
                      style={{ color: '#172b4d', width: 'fit-content', fontWeight: 'bold' }}
                    >
                      Investment Needed
                    </Typography>
                    {typeof props.investmentNeededDD !== 'string' && props.investmentNeededDD ? (
                      <Typography variant="body2" align="left" className={classes.values}>
                        ${abbreviateNumber(props.investmentNeededDD)}
                      </Typography>
                    ) : (
                      <Typography variant="body2" align="left" className={classes.values}>
                        -
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} container justify="space-between" alignItems="center" style={{ marginTop: 15 }}>
                    <Typography
                      variant="body2"
                      align="left"
                      style={{ color: '#172b4d', width: 'fit-content', fontWeight: 'bold' }}
                    >
                      Competitor Amount
                    </Typography>
                    {props.competitorAmount ? (
                      <Typography variant="body2" align="left" className={classes.values}>
                        {props.competitorAmount}
                      </Typography>
                    ) : (
                      <Typography variant="body2" align="left" className={classes.values}>
                        -
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Link>
          </Grid>
        </Grid>
      </>
    );
}