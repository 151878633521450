import React from 'react';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import folder from '../../static/icon/folder.svg';
import file from '../../static/icon/file.svg';
import dolar from '../../static/icon/dolar.svg';
import drop from '../../static/icon/drop.svg';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Default from '../../static/img/empty-company.png';

const useStyles = makeStyles((theme) => ({

    textFields: {
        width: 100
    },
    img: {
        maxWidth: 120,
        minHeight: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '7px',
        boxShadow: '-1px -1px 5px 2px #f3f3f3',
    },
    buttonCardFirst: {
        width: '100%',
        padding: '13.5px 25px',
        borderRadius: 2,
        background: '#5bb3ff',
        boxShadow: 'none',
    },
    buttonCardSecond: {
        width: '100%',
        padding: '13.5px 25px',
        borderRadius: 2,
        background: '#dbeeff',
        boxShadow: 'none'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        borderRadius: 2,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
        fontWeight: 'bold',
    },
    labelSecond: {
        textTransform: 'capitalize',
        color: '#5bb3ff',
        fontWeight: 'bold',
    },
    mainGridCss: {
        marginTop: 50,
    },
    mainCompany: {
        margin: 20,
        [theme.breakpoints.up('xl')]: {
            margin: 50,
        }
    },
    favIcon: {
        fontSize: '38px',
        marginTop: 15,
        marginRight: 15
    },
    businessPlan: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 15,
        }
    },
}));

export default function MyCompanyCard(props) {

    // const firstButtonColor = (e) =>  {
    //     e.target.style.background = '#5bb3ff';
    // };

    // const secondButtonColor = (e) =>  {
    //     e.target.style.background = '#dbeeff';
    // };
    function abbreviateNumber(value) {
        var newValue = value;
        if (value >= 1000) {
            var suffixes = ["", "K", "M", "B", "T"];
            var suffixNum = Math.floor(("" + value).length / 3);
            var shortValue = '';
            for (var precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 2) { break; }
            }
            if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
            newValue = shortValue + suffixes[suffixNum];
        }
        return newValue;
    }
    const classes = useStyles();
    return (

        <Paper className={classes.paper}>
            <Grid container justify="center">
                <Grid className={classes.mainCompany} container xs={10}>
                    <Grid container justify="space-between">
                        <Grid xs={"auto"} item style={{ borderRadius: 7 }}>
                            {props.logo ? (
                                <img src={props.logo} className={classes.img} alt="logo"></img>
                            ) : (
                                <img src={Default} className={classes.img} alt="logo"></img>
                            )}
                        </Grid>
                        <Grid item xs={7} md={7} container style={{ paddingLeft: 25 }}>
                            <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                <Grid item xs>
                                    <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={"left"}
                                        variant="h4">
                                        {props.title}
                                    </Typography>
                                    <Typography align={"left"}
                                        color={"textSecondary"} style={{ color: '#172b4d', fontSize: 16 }} variant="body1">
                                        {props.businessModel}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "50px" }} container>
                        <Grid item xs={7} container alignItems={"center"}>
                            <Grid item xs style={{ marginBottom: "15px" }}>
                                <Typography style={{ display: 'flex' }} gutterBottom={true} align={"left"}
                                    variant="h5">
                                    <Grid style={{ display: 'flex', borderRadius: '50%', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginRight: 10 }}>
                                        <img src={dolar} alt="dolar" />
                                    </Grid>
                                    Valuation
                                </Typography>
                                <Grid item xs={11}>
                                    {props.totalValuation &&
                                        <Typography align={"left"}
                                            color={"primary"} variant="h5" style={{ wordBreak: "break-word" }}>
                                            ${abbreviateNumber(parseInt(props.totalValuation))}
                                        </Typography>}

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={5} container>
                            <Grid item xs>
                                <Typography gutterBottom={true} align={"left"}
                                    variant="h5" style={{ display: 'flex' }}>
                                    <Grid style={{ display: 'flex', borderRadius: '50%', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginRight: 10 }}>
                                        <img src={drop} alt="drop" />
                                    </Grid>
                                    Need
                                </Typography>
                                {props.investmentNeededDD &&
                                    <Typography align={"left"}
                                        color={"primary"} variant="h5" style={{ wordBreak: "break-word" }}>
                                        ${abbreviateNumber(parseInt(props.investmentNeededDD))}
                                    </Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "16px" }} direction={"row"} container alignItems="center">
                        <Typography align={"left"}
                            variant="h6">
                            Location:
                        </Typography>
                        <Typography align={"center"}
                            variant="body1" style={{ marginLeft: "12px" }}>
                            {props.country.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "16px" }} direction={"row"} container alignItems="center">
                        <Typography align={"left"}
                            variant="h6">
                            Website:
                        </Typography>
                        <Typography align={"center"}
                            variant="body1" style={{ marginLeft: "12px" }}>
                            {props.website}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "35px" }} container >
                        {props.pitchDeck &&
                            <Grid item xs={12} sm={6} md={12} lg={6} container alignItems={"center"}>
                                <a href={props.pitchDeck} alt="pitch-deck" style={{ padding: 0, width: '100%' }} download>
                                    <Grid item container xs alignItems={"center"}>
                                        <img src={folder} alt="folder" style={{ marginRight: 10 }}></img>
                                        <Typography align={"left"}
                                            color={"primary"} variant="h6">
                                            Pitch Deck
                                    </Typography>
                                    </Grid>
                                </a>
                            </Grid>}
                        {props.businessPlan &&
                            <Grid item xs={12} sm={6} md={12} lg={6} container alignItems={"center"} className={classes.businessPlan}>
                                <a href={props.businessPlan} alt="businessPlan" style={{ padding: 0, width: '100%' }} download>
                                    <Grid item container xs alignItems={"center"}>
                                        <img src={file} alt="file" style={{ marginRight: 10 }}></img>
                                        <Typography align={"left"}
                                            color={"primary"} variant="h6">
                                            Business Plan
                                </Typography>
                                    </Grid>
                                </a>
                            </Grid>
                        }
                    </Grid>

                    {/* <Grid item style={{marginTop: "40px"}} container spacing={2}>
                        <Grid item xs={12} sm={6} container alignItems={"center"}>
                            <Button onMouseEnter={firstButtonColor}
                                    classes={{label: classes.labelFirst, root: classes.buttonCardFirst}}
                                    variant="contained" size={"medium"}>
                                Invest Now
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems={"center"}>
                            <Button onMouseEnter={secondButtonColor}
                                    classes={{label: classes.labelSecond, root: classes.buttonCardSecond}}
                                    variant="contained" size={"medium"}>
                                Voting
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Paper>
    );
}