import StartupCard from './StartupCard'
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
const ADD_FAVORITE_MUTATION = gql`
  mutation addFavorite(
    $startupId: ID!
  ) {
    addFavorite(
        startupId: $startupId
      )
    }
`;
const REMOVE_FAVORITE_MUTATION = gql`
  mutation removeFavorite(
    $startupId: ID!
  ) {
    removeFavorite(
        startupId: $startupId
      )
    }
`;

const JoinWithMutation = compose(
    graphql(ADD_FAVORITE_MUTATION, {
    name: 'addFavorite',
    }),
    graphql(REMOVE_FAVORITE_MUTATION, {
      name: 'removeFavorite',
    }),
  )(StartupCard);
  export default JoinWithMutation;