import SuperAdminInvestorApproveList from "./SuperAdminInvestorApproveList";
import * as compose from "lodash.flowright";
import {graphql} from "react-apollo";
import gql from 'graphql-tag';

const MANAGE_APPROVAL = gql`
    mutation adminInvestorApproval(
        $id: ID!
        $status: String!
        $description: String
    ){
        adminInvestorApproval
        (
            id: $id
            status: $status
            description: $description
        )
    }
`;
const JoinWithMutation = compose(
  graphql(MANAGE_APPROVAL, {
    name: 'adminInvestorApproval',
  }),
)(SuperAdminInvestorApproveList);
export default JoinWithMutation;