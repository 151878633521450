import InvestorLanding from './InvestorLanding'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const ADMIN_INVESTOR_MUTATIONS = gql`
    mutation adminInvestorMutations($id:ID!, $inputType: String!) {
        adminInvestorMutations(id:$id, input: {type: $inputType})
    }
`;
const ADD_FAVORITE_MUTATION = gql`
  mutation addFavorite(
    $startupId: ID!
  ) {
    addFavorite(
        startupId: $startupId
      )
    }
`;

const EDIT_INVESTMENT_MUTATION = gql`
  mutation editInvestmentRequest(
    $requestId: ID!,
    $size: String!
  ) {
    editInvestmentRequest(
      requestId: $requestId,
      size: $size
      )
    }
`;

const REMOVE_FAVORITE_MUTATION = gql`
  mutation removeFavorite(
    $startupId: ID!
  ) {
    removeFavorite(
        startupId: $startupId
      )
    }
`;


const JoinWithMutation = compose(
  graphql(ADD_FAVORITE_MUTATION, {
  name: 'addFavorite',
  }),
  graphql(REMOVE_FAVORITE_MUTATION, {
    name: 'removeFavorite',
  }),
  graphql(EDIT_INVESTMENT_MUTATION, {
    name: 'editInvestmentRequest',
  }),
)(InvestorLanding);
export default JoinWithMutation;