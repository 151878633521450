import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import moment from 'moment'
import BottomNavigation from "../../Components/BottomNavigation";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import MobileHeader from "../../Components/MobileHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Field, Form, Formik, useField} from "formik";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';
import {fieldToTextField} from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa;',
    height: 30,
    fontSize: '12px',
    padding:'14px 10px',
  },
  body: {
    fontSize: 14,
    padding:'20px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        marginTop: 30
    }, 
  },
  accordionGrid: {
      height:'fit-content',
      marginBottom:40,
      justifyContent:'center',
      [theme.breakpoints.up('xl')]: {
          maxWidth: 330
      },  
  },
  multiSelect: {
    maxHeight: '40px',
    background: '#ffffff',
    '& div': {
        background: 'none!important',
    }
  },
  exportButton: {
    padding:'9.5px 17px',
    color: '#28b1e7',
    borderRadius: 3,
    fontSize: 15,
    border: '1px solid #28b1e7',
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    height:'35px',
    borderRadius:'7px',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'1px solid' ,
    padding: '2px 8px',
    marginRight:15
  },
  accordionPanel: {
      maxWidth: 240,
      width: '90%',
      boxShadow: 'none',
      borderBottom: 'none',
      background: '#f6f9fc',
      marginTop: '25px!important',
      borderRadius: '7px'
  },
  sticker: {
      marginRight:20,
      [theme.breakpoints.down('sm')]: {
        marginBottom:10
    }, 

  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rightPart: {
    height:'fit-content',
    background:"#ffffff"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  containerCss: {
    display: 'flex',
    justifyContent: 'center',
  },
  firstPart: {
    marginTop: 20,
    justifyContent:"center",
    maxWidth: 550,
    [theme.breakpoints.up('xl')]: {
      justifyContent:"start",
    },
  },
  myDialog:{
    overflowY: 'unset',
    overflowX:'hidden',
    padding:40

  },
  secondPart: {
    marginTop: 20,
    [theme.breakpoints.up('lg')]: {
      paddingRight:20
    },
  },
  cardContainer: {
    marginBottom: '15px',
  },

    tabPanel: {
      '& div': {
        padding: 0,
      }
    },
    paper: {
      padding: '36px 0',
      textAlign: 'center',
      borderRadius: 4,
      color: theme.palette.text.secondary,
      boxShadow: 'none',
      background: '#ffffff',
      '& div': {
        [theme.breakpoints.up('xl')]: {
          justifyContent: 'center',
        },
      }
    },
    favButton: {
      padding: '5.5px 14px',
      fontSize:15,
      [theme.breakpoints.up('sm')]: {
          marginRight:25,
      },
  },
    tabButton: {
      fontSize: '20px',
      color: '#000000',
      minWidth: 0,
      textTransform:'none',
      maxWidth: 'fit-content',
      padding: '6px 0',
      margin: '0 27px',
    },
    paddingZero: {
      padding: '0',
    },
    mainGrid: {
      justifyContent:'center'
    },
    adminContainer:{
      maxWidth: '1320px',
      padding:0,
      [theme.breakpoints.down('lg')]: {
        padding:' 0 0 0 70px'
      },
      [theme.breakpoints.down('md')]: {
        padding:' 0 16px'
      },
    }, 
  }
));

function FormikTextField({ type, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
      <Grid style={{width:'100%'}}>
        <TextField
          variant="outlined"
          className={classes.inputField}
          {...field}
          {...props}
          error={meta.touched && meta.error}
          placeholder={props.placeholder}
          type={type}
        />

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
export default function SuperAdminInvestmentList(props){
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [rows, setRows] = React.useState([]);
  const [dataAll, setDataAll] = React.useState([]);
  const [changeStatus, setChangeStatus] = React.useState(false);
  let status = ['Approved', 'Rejected']

  const handleClickOpen = (id) => {
    setChangeStatus(true);
    setSelectedRow(id)
  };
  const handleClose = (value) => {
    setChangeStatus(false);
  };

  const FormikNewDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
      <>
        <Autocomplete
          style={{marginTop:10}}
          {...props}
          {...field}
          className={classes.multiSelect}
          onChange={ (_, value) => setFieldValue(name, value) }
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } label="Please Select" {...textFieldProps} variant="outlined" />}
        >
        </Autocomplete>

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  }
  function AdminRequestDialog(props) {
    const classes = useStyles();
    const { id, onClose, open } = props;

    const handleSubmit = (variables) => {
      props.adminHandleInvestmentRequest({ variables })
        .then(data => {
          if (data.data.adminHandleInvestmentRequest) {
            onClose(null);
            props.refetch();
          }
      });
    };

    const handleClose = () => {
      onClose(null);
    };

    return (
      <Dialog style={{padding:20}} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          <Typography align="center" variant="h4" style={{marginBottom:20}}>Status</Typography>
        </DialogTitle>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                requestId: id,
                status: '',
              }}
              onSubmit={values => {
                handleSubmit(values);
              }}
            >
              {props => (
                <Form>
                  <Grid xs={12} justify="center">
                    <Grid spacing={3} item container alignItems="flex-end">
                      <Grid item container xs={12}>
                        <Typography variant="h5" style={{marginBottom:10}}>Change Status</Typography>
                        <Field component={FormikNewDropdown} style={{width:'100%',marginBottom:20}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="status" options={status} />
                      </Grid>
                      <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}>
                        <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} onClick={() => handleSubmit(props.values)} type="submit">Change</Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  AdminRequestDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  const REQUEST_LIST = gql`
  query adminInvestmentRequests{
    adminInvestmentRequests{
      id
      createdAt
      user{
        id
        firstName
        lastName
      }
      startup{
        id
        slug
        title
      }
      status
      size

    }
  }
  `;
  const { loading, data, refetch } = useQuery(REQUEST_LIST, {
      fetchPolicy:"network-only",
      variables: {},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          if (data.adminInvestmentRequests){
            setDataAll(data.adminInvestmentRequests);
            setRows(data.adminInvestmentRequests.slice(0, pageSize));
            setPage(1);
            setPageCount(1);
            setPageCount(Math.ceil(data.adminInvestmentRequests.length / pageSize))
          }else{
            setPage(0);
            setPageCount(0);
          }
        }
    }
},
[data, loading, pageSize])
    const classes = useStyles();

    const handlePageChange = (event, value) => {
      setPage(value);
      let temp = value * pageSize;
      setRows(dataAll.slice(temp - pageSize, temp));
    };

  const handleSubmit = (variables) => {
    props.adminInvestmentRequests({ variables }).then(data => {
      if (data.data.adminInvestmentRequests){
        window.location.reload();
      }
    })
  };
    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
            <Grid container className={classes.mainGrid}  style={{background:'#f7f7fc'}}>
              <LeftNavigation>
              </LeftNavigation>
              <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className={classes.topPart}>
                        <Typography variant="h4"> Approve Requests </Typography>
                    </Grid>
                    <Grid container xs={12} style={{margin:'50px 0'}}>
                        <Grid item container xs={12} className={classes.rightPart}>
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Investor's Name</StyledTableCell>
                                  <StyledTableCell>Startup's Name</StyledTableCell>
                                  <StyledTableCell align="left">Request Time</StyledTableCell>
                                  <StyledTableCell align="left">Admin Status</StyledTableCell>
                                  <StyledTableCell align="left">Size</StyledTableCell>
                                  <StyledTableCell align="left">Actions</StyledTableCell>
                                </TableRow>
                              </TableHead>
                             {rows.length > 0 &&  <TableBody>
                                {rows.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600'}} scope="row">
                                      <Link style={{
                                        color: '#8898aa;',
                                        height: 30,
                                        fontSize: '12px',
                                        padding:'14px 10px',}} to={`/admin/investor/${row.user.id}`}>{row.user.firstName + ' '  + row.user.lastName}</Link>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600'}} scope="row">
                                      <Link style={{
                                        color: '#8898aa;',
                                        height: 30,
                                        fontSize: '12px',
                                        padding:'14px 10px',}} to={`/admin/startup/${row.startup.slug}`}>{row.startup.title}</Link>
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontWeight:'600'}} align="left">{moment(parseInt(row.createdAt)).format('MMMM Do YYYY, h:mm:ss a')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.status === 'Waiting for Approved' ? 'Waiting for Approval' : row.status}</StyledTableCell>
                                    <StyledTableCell align="left">{row.size ? row.size : '-'}</StyledTableCell>
                                      <StyledTableCell align="left">
                                      {(row.status === 'Waiting for Approved') &&
                                        <Grid container >
                                          <Button variant="containedPrimary" className={classes.favButton} onClick={() => {handleClickOpen(row.id)}}>
                                            Handle Request</Button>
                                          <AdminRequestDialog adminHandleInvestmentRequest={props.adminHandleInvestmentRequest} refetch={refetch} id={selectedRow} open={changeStatus} onClose={handleClose}/>
                                        </Grid>
                                      }
                                      </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>} 
                             
                            </Table>
                          </TableContainer>
                          {rows.length > 0 && 
                          <Grid container item style={{margin:'30px 0'}} justify="center">
                            <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                        variant="outlined" color="primary" />
                          </Grid>
                          }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}