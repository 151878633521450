import DeepDiveForm from './DeepDiveForm';
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
const TEAM_MUTATION = gql`
  mutation deepDiveTeamMember(
    $startupID: ID!
    $founderMembers: [TeamMemberInput]
    $otherMembers: [TeamMemberInput] 
    $applicationStepDD: Int
  ) {
      deepDiveTeamMember(
        startupID: $startupID
        founderMembers: $founderMembers
        otherMembers: $otherMembers
        applicationStepDD: $applicationStepDD
      )
    }
`;
const DEEP_DIVE_FORM = gql`
  mutation deepDiveStartup(
    $id: ID!
    $title: String
    $contactEmail: String
    $website: String
    $city: ID
    $socialLinkedin: String
    $socialFacebook: String
    $socialTwitter: String
    $socialInstagram: String
    $webApp: String
    $iosApp: String
    $androidApp: String
    $socialPrimary: String
    $socialSecondary: String
    $productProblem: String
    $productSolution: String
    $productDifFromComp: String
    $productAdvantages: String
    $aboutStartup: String
    $kpi: [StartupKPIInput]
    $havePayingCustomer: String
    $payingCustomerAmount: BigInt
    $averageUserGrowth: BigInt
    $channelsGainedCustomer: String
    $revenueModel: String
    $revenueExplain: String
    $averageRevenueGrowth: BigInt
    $revenueGross: BigInt
    $revenueNet: BigInt
    $netProfit: BigInt
    $revenueRecurring: String
    $revenueRecurringTypeMRR: Boolean
    $revenueRecurringTypeARR: Boolean
    $valueMRR: BigInt
    $valueARR: BigInt
    $longOfMonthlyRecRevMRR: String
    $longOfMonthlyRecRevARR: String
    $businessPlanDD: Upload
    $businessModel: String
    $partnerships: String
    $competitorAnalysis: String
    $goToMarketStrategy: String
    $exitStrategy: String
    $logo: Upload
    $stage: String
    $investmentRaisedAmount: BigInt
    $investmentNeededDD: String
    $lastRoundDate: String
    $lastRoundInvestment: String
    $longOfSustainBusiness: String
    $postMoneyValuation: BigInt
    $capTableAInvestors: String
    $capTableAFounding: String
    $payrollFA: String
    $marketingFA: String
    $salesFA: String
    $developmentFA: String
    $rdFA: String
    $otherFA: String
    $haveReferenceAEInvestor: String
    $referenceAEInvestor: String
    $pressNewsLinks: [String]
    $applicationStepDD: Int
) {
    deepDiveStartup(
        id:$id
        title:$title
        contactEmail: $contactEmail
        website: $website
        city: $city
        socialLinkedin: $socialLinkedin
        socialFacebook: $socialFacebook
        socialTwitter: $socialTwitter
        socialInstagram:  $socialInstagram
        webApp: $webApp
        iosApp: $iosApp
        androidApp: $androidApp
        socialPrimary: $socialPrimary
        socialSecondary: $socialSecondary
        productProblem: $productProblem
        productSolution: $productSolution
        productDifFromComp: $productDifFromComp
        productAdvantages: $productAdvantages
        aboutStartup: $aboutStartup
        kpi: $kpi
        havePayingCustomer: $havePayingCustomer
        payingCustomerAmount: $payingCustomerAmount
        averageUserGrowth: $averageUserGrowth
        channelsGainedCustomer: $channelsGainedCustomer
        revenueModel: $revenueModel
        revenueExplain:  $revenueExplain
        averageRevenueGrowth: $averageRevenueGrowth
        revenueGross: $revenueGross
        revenueNet: $revenueNet
        netProfit: $netProfit
        revenueRecurring: $revenueRecurring
        revenueRecurringTypeMRR: $revenueRecurringTypeMRR
        revenueRecurringTypeARR: $revenueRecurringTypeARR
        valueMRR: $valueMRR
        valueARR: $valueARR
        longOfMonthlyRecRevARR: $longOfMonthlyRecRevARR
        longOfMonthlyRecRevMRR: $longOfMonthlyRecRevMRR
        businessPlanDD: $businessPlanDD
        businessModel: $businessModel
        partnerships: $partnerships
        competitorAnalysis: $competitorAnalysis
        goToMarketStrategy: $goToMarketStrategy
        exitStrategy: $exitStrategy
        logo: $logo
        stage: $stage
        investmentRaisedAmount: $investmentRaisedAmount
        investmentNeededDD: $investmentNeededDD
        lastRoundDate: $lastRoundDate
        lastRoundInvestment: $lastRoundInvestment
        longOfSustainBusiness: $longOfSustainBusiness
        postMoneyValuation: $postMoneyValuation
        capTableAInvestors: $capTableAInvestors
        capTableAFounding: $capTableAFounding
        payrollFA: $payrollFA
        marketingFA: $marketingFA
        salesFA: $salesFA
        developmentFA: $developmentFA
        rdFA: $rdFA
        otherFA: $otherFA
        haveReferenceAEInvestor: $haveReferenceAEInvestor
        referenceAEInvestor: $referenceAEInvestor
        pressNewsLinks: $pressNewsLinks
        applicationStepDD: $applicationStepDD
    )
  }
`;

const JoinWithMutation = compose(
  graphql(TEAM_MUTATION, {
  name: 'deepDiveTeamMember',
  }),
  graphql(DEEP_DIVE_FORM, {
    name: 'deepDiveStartup',
  }),
)(DeepDiveForm);
export default JoinWithMutation;