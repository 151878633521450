import TabDocsCard from './TabDocsCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $docsRoadmap: Upload
    $docsStatistics: Upload
    $docsBreakdowns: Upload
    $docsSummary: Upload
    $docsCapTable: Upload
) {
  editStartup(
        id: $id
        docsRoadmap: $docsRoadmap
        docsStatistics: $docsStatistics
        docsBreakdowns: $docsBreakdowns
        docsSummary: $docsSummary
        docsCapTable: $docsCapTable
    )
  }
`;
const ADMIN_INVESTOR_APPROVE = gql`
    mutation investorBeApproved {
      investorBeApproved
    }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $docsRoadmap: Upload
    $docsStatistics: Upload
    $docsBreakdowns: Upload
    $docsSummary: Upload
    $docsCapTable: Upload
) {
  startupEditStartup(
        id: $id
        docsRoadmap: $docsRoadmap
        docsStatistics: $docsStatistics
        docsBreakdowns: $docsBreakdowns
        docsSummary: $docsSummary
        docsCapTable: $docsCapTable
    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
  graphql(ADMIN_INVESTOR_APPROVE, {
    name: 'investorBeApproved',
  }),
)(TabDocsCard);
export default JoinWithMutation;