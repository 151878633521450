import MyTabCompanyCard from './MyTabCompanyCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $description: String
    $productProblem: String
    $productSolution: String
    $stage: String
    $businessModel: String
    $industry: [String]
    $totalRevenueGrowth: BigInt
    $averageRevenueGrowth: BigInt
    $revenueRecurringType: String
) {
  editStartup(
        id: $id
        description: $description
        productProblem: $productProblem
        productSolution: $productSolution
        stage: $stage
        businessModel: $businessModel
        industry: $industry
        totalRevenueGrowth: $totalRevenueGrowth
        averageRevenueGrowth: $averageRevenueGrowth
        revenueRecurringType: $revenueRecurringType
    )
  }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $description: String
    $productProblem: String
    $productSolution: String
    $stage: String
    $businessModel: String
    $industry: [String]
    $totalRevenueGrowth: BigInt
    $averageRevenueGrowth: BigInt
    $revenueRecurringType: String
) {
  startupEditStartup(
    id: $id
    description: $description
    productProblem: $productProblem
    productSolution: $productSolution
    stage: $stage
    businessModel: $businessModel
    industry: $industry
    totalRevenueGrowth: $totalRevenueGrowth
    averageRevenueGrowth: $averageRevenueGrowth
    revenueRecurringType: $revenueRecurringType
    )
  }
`;
const MANAGE_DRAFT = gql`
  mutation startupManageDraft(
    $id: ID!
    $status: String
) {
  startupManageDraft(
        id: $id
        status: $status
    )
  }
`;
const NEW_DRAFT = gql`
  mutation startupNewDraft{
    startupNewDraft
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
  graphql(MANAGE_DRAFT, {
    name: 'startupManageDraft',
  }),
  graphql(NEW_DRAFT, {
    name: 'startupNewDraft',
  }),
)(MyTabCompanyCard);
export default JoinWithMutation;