import MyTabProductCard from './MyTabProductCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $website: String
    $productProblem: String
    $productSolution: String
) {
  editStartup(
        id: $id
        website: $website
        productProblem: $productProblem
        productSolution: $productSolution

    )
  }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $website: String
    $productProblem: String
    $productSolution: String
) {
  startupEditStartup(
        id: $id
        website: $website
        productProblem: $productProblem
        productSolution: $productSolution

    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
)(MyTabProductCard);
export default JoinWithMutation;