import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import ForgotPassword from "./ForgotPassword";


const START_FORGOT_PASSWORD = gql`
    mutation startForgotPassword(
        $email: String!
    ) {
        startForgotPassword(
            email: $email
        )
    }
`;

const FORGOT_PASSWORD = gql`
    mutation forgotPassword(
        $code: String!
        $password: String!
        $passwordAgain: String!
    ) {
        forgotPassword(
            code: $code
            password: $password
            passwordAgain: $passwordAgain
        )
    }
`;

const JoinWithMutation = compose(
  graphql(START_FORGOT_PASSWORD, {
    name: 'startForgotPassword',
  }),
  graphql(FORGOT_PASSWORD, {
    name: 'forgotPassword',
  }),
)(ForgotPassword);
export default JoinWithMutation;