import { createMuiTheme } from '@material-ui/core';

const base = {
  palette: {
    primary: {
      main: '#28b1e7',
    },
    secondary: {
      main: '#8898aa',
    },
    common: {
      white: '#ffffff',
    },
    shape: {
      borderRadius: 7,
    },
    iconColor: {
      main: '#172b4d',
    },
    borderColor: {
      main: '#DDDDDD',
    },

    background: {
      main: '#f7f7fc',
      secondary: '#f6f9fc',
      containedPrimary: '#28b1e7',
      containedSecondary: '#28b1e733',
      outlinedSecondary: '#ffffff',
      outlinedPrimary: '#ffffff',
    },
    text: {
      main: '#172b4d',
      secondary: '#777777',
    },
  },
  shadows: [
    'none', // 0
    '0 0 6px 0 rgba(0, 0, 0, 0.16)', // 1
    '0 0 10px 0 rgba(0, 0, 0, 0.16)', // 2
    'none', // 3
    'none', // 4
    'none', // 5
    'none', // 6
    'none', // 7
    'none', // 8
    'none', // 9
    'none', // 10
    'none', // 11
    'none', // 12
    'none', // 13
    'none', // 14
    'none', // 15
    'none', // 16
    'none', // 17
    'none', // 18
    'none', // 19
    'none', // 20
    'none', // 21
    'none', // 22
    'none', // 23
    'none', // 24
  ],
  typography: {
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    h1: {
      fontSize: '40px',
      color: '#172b4d',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '35px',
      color: '#172b4d',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '30px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.37',
      letterSpacing: '0.75px',
      color: '#172b4d',
    },
    h4: {
      fontSize: '25px',
      color: '#172b4d',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '18px',
      color: '#172b4d',
      fontWeight: 'bold',

      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.25',
      letterSpacing: 'normal',
    },

    h6: {
      fontSize: '15px',
      color: '#172b4d',
      fontWeight: 'bold',
    },

    body1: {
      fontSize: '18px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.67',
      letterSpacing: '0.18px',

      color: '#172b4d',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.38px',
    },
  },
};

var theme = createMuiTheme(base);

theme = {
  ...theme,

  overrides: {
    MuiTypography: {
      color: '#172b4d',
    },

    MuiAppBar: {
      root: {
        background: '#ffffff',
      },
    },
    MuiTabs:{
      flexContainer: {
        [theme.breakpoints.up('lg')]: { 
          justifyContent:'center'
        }
      },
      scrollable: {
        [theme.breakpoints.up('md')]: { 
          overflowX: 'unset'
        }
      }
    },
    MuiTab:{
      textColorPrimary: {
        '&$selected': {
          color:'#172b4d',
          fontWeight:600
        }
      },
    },
    MuiTableContainer:{
      root:{
        '&::-webkit-scrollbar': {
          display:'none'
        },
      }
    },
    MuiSlider:{
      rail:{
        height: '8px'
      },
      track:{
        height: '8px'
      }
    },
    MuiCheckbox: {
      root: {
        color: '#e8e9ec',
        borderRadius: '5px',
      },
    },
    MuiStepLabel: {
      label:{
        marginTop: '5px!important',
        fontSize: '13px',
      },
    },
    MuiStepConnector: {
      line:{
        borderColor:'#f7f7fc'
      },
    },
    MuiStepIcon: {
      root: {
        color: 'rgb(255, 255, 255)',
        borderRadius: '50%',
        border: '1px solid #28b1e7',
        MuiStepIcon: {}
      },
      active: {
        color: 'rgb(255, 255, 255)!important',
      },
      text:{
        fill: 'rgb(40, 177, 231)',
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '18.5px 20px',
        fontSize: 16,
      },
      root: {
        '&$focused $notchedOutline': {
          borderColor: '#172B4D',
          borderWidth: 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#DDDDDD',
        },
        borderRadius: base.palette.shape.borderRadius,
      },
      notchedOutline: {
        borderColor: '#DDDDDD',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        bottom:-27,
        height:4,
        borderRadius:4
      }
    },
    MuiFilledInput: {
      input: {
        padding: '18.5px 20px',
        fontSize: 18,
      },
      root: {
        '&$focused': {
          borderColor: '#172B4D',
          backgroundColor: '#ffffff',
        },
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        backgroundColor: '#ffffff',
        border: 'solid 1px ',
        '&:hover:not($disabled):not($focused):not($error)': {
          borderColor: '#DDDDDD',
        },
        borderRadius: base.palette.shape.borderRadius,
      },

      underline: {
        border: '1px solid',
        borderColor: '#DDDDDD',

        '&:after': {
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '""',
          position: 'absolute',
          right: 0,
          transform: 'scaleX(0)',
          transition: 'none',
          width: 0,
          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&$focused:after': {
          transform: 'scaleX(1)',
        },
        '&$hover:before': {},
        '&$error:after': {
          borderBottomColor: '#f44336',
          transform: 'scaleX(1)', // error is always underlined in red
        },
        '&:before': {
          left: 0,
          bottom: 0,
          // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
          content: '"\\00a0"',
          position: 'absolute',
          transition: 'none',
          right: 0,
          width: 0,

          pointerEvents: 'none', // Transparent to the hover style.
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          /*        borderBottom: `2px solid #ffffff`, */
          /* borderColor : "#DDDDDD", */

          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            /*     borderBottom: `1px solid #ffffff`, */
          },
        },
        '&$disabled:before': {},
      },
    },

    MuiToolbar: {
      dense: {
        minHeight: 100,
      },
      regular: {
        height: 60,
      }
    },

    MuiInputBase: {
      root: {
        borderRadius: base.palette.shape.borderRadius,
      },
      input: {
        borderRadius: base.palette.shape.borderRadius,
        color: base.palette.text.main,
        fontSize: 18,
        lineHeight: 0.82,
        '&::placeholder': {
          color: base.palette.text.secondary,
        },
      },
    },
    MuiFormHelperText:{
      contained:{
        marginLeft:0
      }
    },
    MuiCollapse: {
      container: {
        borderTop: '1px solid rgb(255, 255, 255)',
        background: '#ffffff',
        padding:'0 10px',
      },
      wrapper: {
        background: '#f6f9fc',
        borderRadius:4,
        maxHeight:350,
        overflowY:'auto'
      }
    },
    MuiButton: {
      root: {
        fontSize: '16px',
        fontWeight: '600',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.45px',
        textAlign: 'center',

        textTransform: 'capitalize',
        padding: '13.5px 45px',
      },

      containedPrimary: {
        backgroundColor: base.palette.background.containedPrimary,
        color: base.palette.common.white,
        '&:hover': {
          backgroundColor: base.palette.background.containedPrimary,
        },
      },
      containedSecondary: {
        backgroundColor: base.palette.background.containedSecondary,
        color: base.palette.primary.main,
        '&:hover': {
          backgroundColor: base.palette.background.containedSecondary,
        },
      },

      outlinedPrimary: {
        border: `1px solid ${base.palette.primary.main}`,
        '&:hover': {
          backgroundColor: base.palette.background.outlinedSecondary,
        },
      },
      outlinedSecondary: {
        padding: '13.5px 45px',
        backgroundColor: base.palette.common.white,
        border: `1px solid ${base.palette.secondary.main}`,
        '&:hover': {
          backgroundColor: base.palette.background.outlinedSecondary,
        },
      },
    },
    MuiBox: {
      root: {
        padding:0
      }
    },
    MuiPaginationItem: {
      root:{
        margin: '0 5px'
      },
      outlinedPrimary:{
        fontSize:13,
        color:'#28b1e7',
        border:'solid 1px #28b1e7',
        backgroundColor:'#ffffff',
        '&$selected': {
          backgroundColor: '#28b1e7',
          color:'#ffffff'
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 50,
        height: 50,
      },
    },
    MuiListItem:{
      root: {
        paddingTop:0,
        paddingBottom:0    
      }
    },
    MuiAlert: {
      icon: {
        alignItems:'center'
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          borderColor: '#172B4D',
          backgroundColor: '#ffffff',
          color: '#172B4D',
        },
      },
    },
    MuiInput: {
      root: {
        border: 'solid 1px ',
        borderColor: theme.palette.borderColor.main,
      },
      underline: {
        '&:before': {
          borderBottom: 'none',
        },
        /* https://github.com/mui-org/material-ui/issues/12192#issuecomment-406182809 */
        '&&&&:hover:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        padding: '18.5px 20px',
      },
      icon :{
        right : 18
      }
    },
    MuiSelectIcon: {
      transition: 'all 0.3s ease-out',
    },
    MuiCheckbox :{
      color : theme.palette.primary.main
    },
    MuiIconButton  :{
      colorPrimary : {
        "&:hover" : {
          backgroundColor : "#ffffff"
        }
      }
    },
    MuiTooltip:{
      popper:{
        borderRadius:4,
      },
      tooltip: {
        fontSize:16,
        color:'#ffffff',
        backgroundColor:'#172b4d',
        padding:12,
      }
    }

    // Text Field label edit
    /*   MuiInputLabel: {
      formControl: {
        top: '12.5px',
        left: '20px',
      },
    }, */
    /*     MuiInputLabel: {
      outlined: {
        transform: "translate(0px, -40px) scale(1)",

        top: "-60px",
        background: '#ffffff',
      },
    }, */
  },
};

export default theme;
