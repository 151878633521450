import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: 'center',
        borderRadius: 2,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        marginTop: '35px',
    },        
        rating: {
            fontSize:30, 
        },
        generalRating: {
            width: 35,
            background: '#28b1e7',
            display: 'flex',
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            borderBottomLeftRadius: 5,
            borderTopRightRadius: 5,
        }
}));

export default function RatingCard(props) {
    const [rate, setRate] = React.useState(props.myRating ? props.myRating : 0);
    const startupRating = (rate) => {
        props.startupRating({ variables: { startupId: props.id, rate:rate } })
          .then(data => {
              if (data.data.startupRating) {
                  setRate(rate)
                  props.setIsSentNote(true)
              }
          });
    };
    const classes = useStyles();
        return (
            <Paper className={classes.paper}>
                <Grid container justify="flex-end">
                    <Grid className={classes.generalRating}>
                        <Typography variant="body2" style={{color:'#ffffff'}}>{props.rating}</Typography>  
                    </Grid>
                </Grid>
                <Grid xs={12} style={{padding: '15px 40px 40px 40px',}} container>
                    <Grid item xs={4} container>
                        <Typography align={"left"}
                                    style={{display: 'flex', alignItems: 'center'}}
                                    variant="h5">
                            Rating
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container>
                        {rate ? (
                          <Rating
                            name="simple-controlled"
                            size={"large"}
                            value={rate}
                            className={classes.rating}
                            onChange={(event, newValue) => {
                                startupRating(newValue)
                            }}
                          />
                          ) :(
                            <Rating
                            name="simple-controlled"
                            size={"large"}
                            value={props.myRating ? props.myRating : Math.round(props.rating * 1)}
                            className={classes.rating}
                            onChange={(event, newValue) => {
                                startupRating(newValue)
                            }}
                            />
                            )}
                    </Grid>
                </Grid>
            </Paper>
    );
}