import { Button, Grid, withStyles } from '@material-ui/core';
import { Formik,Form } from 'formik';
import { withRouter } from 'react-router';
import React, { Fragment } from 'react';
import { deepDiveSchema } from '../../yupSchemas';
import Snackbar from '@material-ui/core/Snackbar';
import Stepper from '@material-ui/core/Stepper';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {stateToHTML} from 'draft-js-export-html'
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
//import tealCheck from 'static/img/tealCheck.svg';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '73vh',
    width: '100%',
  },
  progresText: {
    backgroundColor: 'white',
    bottom: 0,
    justifyContent: 'flex-end',

    position: 'fixed',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },

  root: {
    padding: 0,
  },
});
class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitting:false,
      serverSuccess:false,
      sentLogo:false,
      sentBusinessPlanDD:false,
      activeStep:0,
      page: 0,
      values: [],
      serverMessage:{
        detail: '',
        message: '',
        type: false,
      }
    };
  }
  
  componentDidUpdate(prevProps){
    if (
      this.props.berk!== prevProps.berk 
    ) {
      this.setState({page:this.props.berk})
      this.setState({activeStep:this.props.berk})
    }
  }

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = async variablesAll => {
    const { ...variables } = variablesAll;
    variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent())
    variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent())
    variables.productDifFromComp = stateToHTML(variables.productDifFromComp.getCurrentContent())
    variables.productAdvantages = stateToHTML(variables.productAdvantages.getCurrentContent())
    variables.aboutStartup = stateToHTML(variables.aboutStartup.getCurrentContent())
    variables.partnerships = stateToHTML(variables.partnerships.getCurrentContent())
    variables.competitorAnalysis = stateToHTML(variables.competitorAnalysis.getCurrentContent())
    variables.goToMarketStrategy = stateToHTML(variables.goToMarketStrategy.getCurrentContent())
    variables.applicationStepDD = 6
    variables.exitStrategy = stateToHTML(variables.exitStrategy.getCurrentContent())
    if(variables.id === 0){
        variables.id =this.props.startupKey;
    }
    if(variables.kpi.length > 0){
      const newArray = variables.kpi.map(({__typename, ...keepAttrs}) => keepAttrs)
      variables.kpi = newArray
    }
    if(variables.investmentRaisedAmount !== null){
      variables.investmentRaisedAmount = parseInt(variables.investmentRaisedAmount.toString().split('.').join("")); 
    }
    if(variables.postMoneyValuation !== null){
    variables.postMoneyValuation = parseInt(variables.postMoneyValuation.toString().split('.').join(""));
    }
    if(variables.city === null){
      variables.city.id = null
    }
    if(variables.revenueRecurringTypeARR === "")
    {
      variables.revenueRecurringTypeARR = false
    }
    if(variables.revenueRecurringTypeMRR === "")
    {
      variables.revenueRecurringTypeMRR = false
    }
    if(variables.city !== null){
      variables.city = variables.city.id
    }
    else{
      variables.city = null
    }
    if(variables.payingCustomerAmount !== null){
      variables.payingCustomerAmount = parseInt(variables.payingCustomerAmount.toString().split('.').join(""));
      }
    if(variables.valueMRR !== null){
      variables.valueMRR = parseInt(variables.valueMRR.toString().split('.').join(""));
    }
    if(variables.valueARR !== null){
      variables.valueARR = parseInt(variables.valueARR.toString().split('.').join(""));
    }  
    if(variables.netProfit !== null){
      variables.netProfit = parseInt(variables.netProfit.toString().split('.').join(""));
    }
    if(variables.revenueGross !== null){
      variables.revenueGross = parseInt(variables.revenueGross.toString().split('.').join(""));
    }
    if(variables.revenueNet !== null){
      variables.revenueNet = parseInt(variables.revenueNet.toString().split('.').join(""));
    }
    if(variables.averageUserGrowth !== null){
      variables.averageUserGrowth = parseInt(variables.averageUserGrowth.toString().split('.').join(""));
    }
    if(variables.averageRevenueGrowth !== null){
      variables.averageRevenueGrowth = parseInt(variables.averageRevenueGrowth.toString().split('.').join(""));
    }
    if(this.state.sentBusinessPlanDD){
      variables.businessPlanDD = null
    }
    if(variables.logo !== null){
      if(typeof variables.logo === 'string'){
        variables.logo = ""
      }
    }
    if(variables.businessPlanDD !== null){
      if(typeof variables.businessPlanDD === 'string'){
        variables.businessPlanDD = ""
      }
    }
    this.setState({submitting: true});
    this.props
    .deepDiveStartup({ variables })
    .then(data => {
        if (data.data.deepDiveStartup) {
        let params = new URLSearchParams(this.props.history.location.search);
        this.props.history.push(params.get('next') ? params.get('next') : `/my-startup`);
        } else {
          this.setState({serverMessage:{
            detail: get(data, 'errors[0].functionError.detail', ' '),
            message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
            type: false,
          }})
        this.setState({serverSuccess: true})
        }
    })
    .then(() => this.setState({submitting: false}));
};
handleNext = async (variablesAll, validateForm) => {
  const { ...variables }  = variablesAll;
  if(variables.id === 0){
      variables.id =this.props.startupKey;
  }
  variables.founderMembers = null
  variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent())
  variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent())
  variables.productDifFromComp = stateToHTML(variables.productDifFromComp.getCurrentContent())
  variables.productAdvantages = stateToHTML(variables.productAdvantages.getCurrentContent())
  variables.aboutStartup = stateToHTML(variables.aboutStartup.getCurrentContent())
  variables.partnerships = stateToHTML(variables.partnerships.getCurrentContent())
  variables.competitorAnalysis = stateToHTML(variables.competitorAnalysis.getCurrentContent())
  variables.goToMarketStrategy = stateToHTML(variables.goToMarketStrategy.getCurrentContent())
  variables.exitStrategy = stateToHTML(variables.exitStrategy.getCurrentContent())
  
  
  
  
  
  if(variables.kpi.length > 0){
    const newArray = variables.kpi.map(({__typename, ...keepAttrs}) => keepAttrs)
    variables.kpi = newArray
  }
  if(variables.city !== null){
    variables.city = variables.city.id
  }
  else{
    variables.city = null
  }
  variables.applicationStepDD = this.state.page + 1;
  if(variables.investmentRaisedAmount !== null){
    variables.investmentRaisedAmount = parseInt(variables.investmentRaisedAmount.toString().split('.').join("")); 
  }
  if(variables.postMoneyValuation !== null){
  variables.postMoneyValuation = parseInt(variables.postMoneyValuation.toString().split('.').join(""));
  }
  if(variables.revenueRecurringTypeARR === "")
  {
    variables.revenueRecurringTypeARR = false
  }
  if(variables.revenueRecurringTypeMRR === "")
  {
    variables.revenueRecurringTypeMRR = false
  }
  if(variables.payingCustomerAmount !== null){
    variables.payingCustomerAmount = parseInt(variables.payingCustomerAmount.toString().split('.').join(""));
    }
  if(variables.valueMRR !== null){
    variables.valueMRR = parseInt(variables.valueMRR.toString().split('.').join(""));
  }
  if(variables.valueARR !== null){
    variables.valueARR = parseInt(variables.valueARR.toString().split('.').join(""));
  }
  if(variables.netProfit !== null){
    variables.netProfit = parseInt(variables.netProfit.toString().split('.').join(""));
  }
  if(variables.revenueGross !== null){
    variables.revenueGross = parseInt(variables.revenueGross.toString().split('.').join(""));
  }
  if(variables.revenueNet !== null){
    variables.revenueNet = parseInt(variables.revenueNet.toString().split('.').join(""));
  }
  if(variables.averageUserGrowth !== null){
    variables.averageUserGrowth = parseInt(variables.averageUserGrowth.toString().split('.').join(""));
  }
  if(variables.averageRevenueGrowth !== null){
    variables.averageRevenueGrowth = parseInt(variables.averageRevenueGrowth.toString().split('.').join(""));
  }
  if(this.state.sentBusinessPlanDD){
    variables.businessPlanDD = null
  }
  if(this.state.sentLogo){
    variables.logo = null
  }
  if(variables.logo !== null){
    if(typeof variables.logo === 'string'){
      variables.logo = ""
    }
    else{
      this.setState({sentLogo : true});
    }
  }
  if(variables.businessPlanDD !== null){
    if(typeof variables.businessPlanDD === 'string'){
      variables.businessPlanDD = ""
    }
    else{
      this.setState({sentBusinessPlanDD : true});
    }
  }

  this.setState({submitting: true});

  this.props
  .deepDiveStartup({ variables })
  .then(data => {
      if (data.data.deepDiveStartup) {
        this.setState({activeStep : this.state.activeStep + 1});
        this.setState({page : this.state.page + 1});
        window.scrollTo(0,0);
         } else {
        this.setState({serverMessage:{
          detail: get(data, 'errors[0].functionError.detail', ' '),
          message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
          type: false,
        }})
      this.setState({serverSuccess: true})
      }
  })
  .then(() => this.setState({submitting: false},() => {validateForm().then(r => {})}));
};



handleTeam = async (variablesAll, validateForm) => {
  const { ...variables }  = variablesAll;
  if(variables.id === 0){
      variables.id =this.props.startupKey;
  }
  if(variables.otherMembers.length > 0){
    const newArray2 = variables.otherMembers.map(({__typename,  ...value})  => value)   
    variables.otherMembers = newArray2
  }
  variables.applicationStepDD = this.state.page + 1; 
  this.setState({submitting: true});

  this.props
  .deepDiveTeamMember({ variables:{
    startupID:variables.id,
    founderMembers:variables.founderMembers,
    otherMembers:variables.otherMembers,
    applicationStepDD:variables.applicationStepDD

  } })
  .then(data => {
      if (data.data.deepDiveTeamMember) {
        this.setState({activeStep : this.state.activeStep + 1});
        this.setState({page : this.state.page + 1});
        window.scrollTo(0,0);
         } else {
        this.setState({serverMessage:{
          detail: get(data, 'errors[0].functionError.detail', ' '),
          message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
          type: false,
        }})
      this.setState({serverSuccess: true})
      }
  })
  .then(() => this.setState({submitting: false}))
  .then(() => this.setState({submitting: false},() => {validateForm().then(r => {})}));
};
handleBack = async (variablesAll, validateForm) => {
  const { ...variables }  = variablesAll;
  if(variables.id === 0){
      variables.id =this.props.startupKey;
  }

  this.setState({submitting: true});

  this.props
  .deepDiveStartup({ variables:{
    id:variables.id,
    applicationStepDD:this.state.page - 1,
    location:variables.location
  } })
  .then(data => {
      if (data.data.deepDiveStartup) {
        this.setState({activeStep : this.state.activeStep - 1});
        this.setState({page : this.state.page - 1});
        window.scrollTo(0,0);
        } else {
        this.setState({serverMessage:{
          detail: get(data, 'errors[0].functionError.detail', ' '),
          message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
          type: false,
        }})
        this.setState({serverSuccess: true})
      }
  })
  .then(() => this.setState({submitting: false}))
  .then(() => this.setState({submitting: false},() => {validateForm().then(r => {})}));
};
  render() {
    const { children, classes } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const pageCount = React.Children.toArray(children).length - 1; // toArray removes null values count doesn't
    const isLastPage = page === pageCount;
    const steps = ['Profile', 'Team', 'Product & Services', 'Business & Competition', 'Company'];
    const isFirstPage = page === 0;
    return (
      <Fragment>
        <Formik
          initialValues={this.props.initialValues}
          enableReinitialize={true}
          validationSchema={deepDiveSchema[page]}
          validate={this.validate}
          validateOnMount={true}
          onSubmit={this.handleSubmit}
          render={({ values, handleSubmit, isSubmitting, handleReset, isValid, validateForm}) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
                 <Grid container>
                 <Hidden smDown>
                    {(!isFirstPage && 
                      <Grid container xs={12} justify="center">
                        <Grid item xs={10} xl={9} style={{marginTop:50}}>
                          <Typography variant="h4">Deep Dive</Typography>
                          <Typography variant="body1" color="textSecondary" style={{marginTop:20}}>Congratulations! You passed to the second stage of the process.<br/>
                          We wish you good luck!</Typography>
                        </Grid>
                        <Grid item xs={12} xl={11}>
                          <Stepper alternativeLabel activeStep={this.state.activeStep}>
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Grid>
                      </Grid>
                    )}
                    </Hidden>
                {activePage}
              
                {isLastPage ? 
                 <Grid item container xs={12} direction="row-reverse" justify="center" style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>
                    <Grid xs={12} item container xl={9} sm={10} justify="start">
                      <Button onClick={()=>this.handleBack(values, validateForm)} variant="outlinedSecondary" style={{fontWeight: 'normal', marginRight: '25px'}}>BACK</Button>  
                      { this.state.submitting ?<Button variant="containedPrimary" disabled style={{fontWeight: 'normal'}}><CircularProgress style={{color:'#ffffff',width: '25px',height: '25px',marginRight: '10px',marginBottom: 0}}/> LOADING..</Button>: <Button variant="containedPrimary" style={{fontWeight: 'normal'}} disabled={!isValid} onClick={()=>this.handleSubmit(values)}> SUBMIT </Button> }
                    </Grid>
                 </Grid>
                  :isFirstPage ? 
                  <Grid item container xs={12}  direction="row"  style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>
                    <Grid xs={12} md={6}  item container justify="center">
                      <Button variant="containedPrimary" style={{fontWeight: 'normal'}} onClick={()=>this.handleNext(values, validateForm)}> START </Button>
                    </Grid>
                  </Grid> 
                  :
                  <Grid item container xs={12} direction="row-reverse"  justify="center" style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>   
                    <Grid item container xl={9} sm={10} justify="center">
                {page>1 &&  <Button onClick={()=>this.handleBack(values, validateForm)} variant="outlinedSecondary" style={{fontWeight: 'normal', marginRight: '25px'}}>BACK</Button>  }
                { this.state.submitting ? <Button variant="containedPrimary" disabled style={{fontWeight: 'normal'}}><CircularProgress style={{color:'#ffffff',width: '25px',height: '25px',marginRight: '10px',marginBottom: 0}}/> LOADING..</Button> : <>{ page===2 ? <Button variant="containedPrimary" disabled={!isValid} style={{fontWeight: 'normal'}} onClick={()=>this.handleTeam(values, validateForm)}> NEXT </Button> : <Button variant="containedPrimary" disabled={!isValid} style={{fontWeight: 'normal'}} onClick={()=>this.handleNext(values, validateForm)}> NEXT </Button>}</>}
                    </Grid>   
                  </Grid>
                }
                  </Grid>
            </Form>
          )}
        />
        <Snackbar data={this.state.serverMessage} onClose={() => this.setState({serverSuccess : false})} open={this.state.serverSuccess} />
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Wizard));
