import DraftProfile from './DraftProfile'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const MANAGE_DRAFT = gql`
  mutation startupManageDraft(
    $id: ID!
    $status: String!
) {
  startupManageDraft(
        id: $id
        status: $status
    )
  }
`;
const MANAGE_APPROVAL = gql`
  mutation adminManageApproval(
    $id: ID!
    $status: String!
    $description: String
){
    adminManageApproval
    (
        id: $id
        status: $status
        description: $description
    )
  }
`;
const JoinWithMutation = compose(
  graphql(MANAGE_DRAFT, {
    name: 'startupManageDraft',
  }),
  graphql(MANAGE_APPROVAL, {
    name: 'adminManageApproval',
  }),
)(DraftProfile);
export default JoinWithMutation;