import React from 'react';
import logo from '../../static/img/new-ae-logo.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from "@material-ui/core/Grid";
import Hidden  from '@material-ui/core/Hidden';
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme) =>({
  logo: {
    height: 50,
    [theme.breakpoints.down('md')]: {
        marginTop: '30px'
    },
  },
  toolbar: {
    padding: '0px 100px',
  },
  applicationCard: {
    background:'#ffffff',
    marginTop:100,
    marginBottom:50,
    [theme.breakpoints.down('lg')]: {
        marginBottom:100   
    },
}, 
}));
function FourOneFour(props) {
  const classes = useStyles();
  return (
          <div>
            <Hidden mdDown>
                <AppBar position="static" style={{background : '#ffffff'}} elevation={1}>
                    <Toolbar variant="dense" disableGutters className={classes.toolbar}>
                        <Link to="./" style={{padding:0}}>
                            <img src={logo} className={classes.logo} alt="angel-effect"></img>
                        </Link>  

                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden mdUp>
                <Grid container justify="center" style={{background: '#ffffff',paddingBottom:30}}>
                    <img src={logo} alt="angel-effect" className={classes.logo} style={{marginRight: '10px'}}></img>
                </Grid>
            </Hidden>
            <Grid container justify="center">
                    <Grid className={classes.applicationCard} container xs={12} lg={10} xl={9}>
                        <Grid item container direction="column" xs={12} style={{marginTop:60}} alignItems="center">
                                <Typography variant="h2" style={{marginTop:'25px'}} align="center">
                                    Page Not Found!
                                </Typography>
                                <Link to="/">
                                  <Typography variant="h5" style={{marginTop:'15px',color:'#28b1e7',marginBottom:'50px'}} align="center">
                                      Follow this link to return back to mainpage
                                  </Typography>
                                </Link>
                        </Grid>
                    </Grid>
                </Grid>
          </div>
  );
}

export default FourOneFour;
