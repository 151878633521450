import MyTabBusinessCard from './MyTabBusinessCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $payrollFA: String
    $salesFA: String
    $rdFA: String
    $marketingFA: String
    $developmentFA: String
    $otherFA: String
    $sharePercent: String
    $investmentNeededDD: String
    $investmentRaisedAmount: BigInt
    $postMoneyValuation: BigInt
) {
  editStartup(
        id: $id
        payrollFA: $payrollFA
        salesFA: $salesFA
        rdFA: $rdFA
        marketingFA: $marketingFA
        developmentFA: $developmentFA
        otherFA: $otherFA
        sharePercent:$sharePercent
        investmentRaisedAmount: $investmentRaisedAmount
        investmentNeededDD: $investmentNeededDD
        postMoneyValuation: $postMoneyValuation

    )
  }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $payrollFA: String
    $salesFA: String
    $rdFA: String
    $marketingFA: String
    $developmentFA: String
    $otherFA: String
    $sharePercent: String
    $investmentNeededDD: String
    $investmentRaisedAmount: BigInt
    $postMoneyValuation: BigInt
) {
  startupEditStartup(
        id: $id
        payrollFA: $payrollFA
        salesFA: $salesFA
        rdFA: $rdFA
        marketingFA: $marketingFA
        developmentFA: $developmentFA
        otherFA: $otherFA
        sharePercent:$sharePercent
        investmentRaisedAmount: $investmentRaisedAmount
        investmentNeededDD: $investmentNeededDD
        postMoneyValuation: $postMoneyValuation

    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
)(MyTabBusinessCard);
export default JoinWithMutation;