import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useField, Form, Formik, Field } from 'formik';
import Slider from '@material-ui/core/Slider';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NumberFormat from 'react-number-format';
import editImg from '../../static/icon/edit.svg';
import { fieldToTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '15px',
    },
    baseStyle: {
        flex: 1,
        marginTop: 10,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '73px!important',
        borderWidth: 2,
        borderRadius: 7,
        borderColor: '#dddddd',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#172b4d',
        fontWeight: '600',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    namePart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    companyPart: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    paper: {
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff'
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 120,
        height: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
    },
}));

const money = (value) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    if (formatter.format(parseInt(value)) !== '$NaN') {
        return formatter.format(parseInt(value))
    }
    else {
        return '-'
    }

}

function NumberFormatPercentage(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            isAllowed={(values) => {
                if (values) {
                    let { floatValue } = values;
                    if (floatValue === undefined) {
                        floatValue = 0;
                    }
                    return floatValue >= 0 && floatValue <= 100;
                }
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            suffix="%"
        />
    );
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}

const FormikNumberField = ({ type, label, inputProps, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        textmask: '(1  )    -    ',
        numberformat: '1320',
    });
    return (
        <>
            <Grid style={{ minHeight: 95 }}>
                <TextField
                    variant="outlined"
                    className={classes.inputField}
                    {...field}
                    {...props}
                    inputProps={{
                        maxLength: 15
                    }}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                />

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </Grid>
        </>
    );
}

const FormikPercentageField = ({ type, label, inputProps, maxLen, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        textmask: '(1  )    -    ',
        numberformat: '1320',
    });
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <>
            <Grid style={{ minHeight: 95 }}>
                <TextField
                    variant="outlined"
                    className={classes.inputField}
                    {...field}
                    {...props}
                    inputProps={{
                        maxLength: maxLen
                    }}
                    InputProps={{
                        inputComponent: NumberFormatPercentage,
                    }}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                />

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </Grid>
        </>
    );
}

const running = (investmentRaisedAmount, investmentNeededDD) => {
    if (parseInt(investmentNeededDD) > parseInt(investmentRaisedAmount)) {
        let percent = (parseInt(investmentRaisedAmount) * 100) / parseInt(investmentNeededDD)
        percent = ~~percent
        return percent;
    }
    return 100;
}

function sumFund(payrollFA, marketingFA, salesFA, developmentFA, rdFA, otherFA) {
    if (payrollFA === null || payrollFA === '') {
        payrollFA = "0"
    }
    if (marketingFA === null || marketingFA === '') {
        marketingFA = "0"
    }
    if (salesFA === null || salesFA === '') {
        salesFA = "0"
    }
    if (developmentFA === null || developmentFA === '') {
        developmentFA = "0"
    }
    if (rdFA === null || rdFA === '') {
        rdFA = "0"
    }
    if (otherFA === null || otherFA === '') {
        otherFA = "0"
    }
    return parseInt(payrollFA) + parseInt(marketingFA) + parseInt(salesFA) + parseInt(developmentFA) + parseInt(rdFA) + parseInt(otherFA)
}

export default function MyTabBusinessCard(props) {
    const [edit, setEdit] = React.useState(false);
    const [detail, setDetail] = React.useState(props.detail);
    const classes = useStyles();
    const businessModel = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
    const handleSubmit = variables => {
        if (props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
            props.editStartup({ variables })
                .then(data => {
                    if (data.data.editStartup) {
                        props.setIsSentNote(true)
                        props.getData()
                    }
                    else {

                    }
                })
        }
        else {
            variables.id = props.params.id
            props.startupEditStartup({ variables })
                .then(data => {
                    if (data.data.startupEditStartup) {
                        props.setIsSentNote(true)
                        props.setSendChanges(true)
                        props.getData()
                    }
                    else {

                    }
                })
        }
    };
    const FormikNewDropdown = ({ textFieldProps, ...props }) => {
        const [meta] = useField(props);
        const { form: { setTouched, setFieldValue } } = props;
        const { ...field } = fieldToTextField(props);
        const { name } = field;
        const classes = useStyles();
        return (
            <>
                <Autocomplete
                    style={{ marginTop: 10 }}
                    {...props}
                    {...field}
                    className={classes.multiSelect}
                    onChange={(_, value) => setFieldValue(name, value)}
                    onBlur={() => setTouched({ [name]: true })}
                    renderInput={(props) => <TextField {...props} label="Please Select" {...textFieldProps} variant="outlined" />}
                >
                </Autocomplete>

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </>
        );
    }
    return (
        <>
            <Paper style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column' }} className={classes.paper}>
                {!edit ?
                    <div>
                        <Grid container justify="flex-end" style={{ marginBottom: 15 }}>
                            {((props.authRole === 'Admin' || props.authRole === 'SuperAdmin') && window.location.href.indexOf("draft") === -1) &&
                                <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                            }
                            {(window.location.href.indexOf("draft") > -1 && (props.authRole !== 'Admin' && props.authRole !== 'SuperAdmin')) &&
                                <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                            }
                        </Grid>
                        <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                            <Grid item container alignItems="center" justify="space-between">
                                <Typography gutterBottom={true} align={'left'} variant="h5">
                                    Current Investment Round
                                </Typography>
                            </Grid>
                            <Grid item container>
                                <Grid xs={4}>
                                    <Typography
                                        gutterBottom={true}
                                        align={'left'}
                                        variant="body2"
                                        style={{ marginTop: 15, fontWeight: 'bold' }}
                                    >
                                        Received This Round
                                    </Typography>
                                    <Typography gutterBottom={true} align={'left'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                        {props.detail.investmentRaisedAmount ? money(props.detail.investmentRaisedAmount) : '-'}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography
                                        gutterBottom={true}
                                        align={'center'}
                                        variant="body2"
                                        style={{ marginTop: 15, fontWeight: 'bold' }}
                                    >
                                        Post Money Valuation
                                    </Typography>
                                    <Typography gutterBottom={true} align={'center'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                        {props.detail.postMoneyValuation ? money(props.detail.postMoneyValuation) : '-'}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography
                                        gutterBottom={true}
                                        align={'right'}
                                        variant="body2"
                                        style={{ marginTop: 15, fontWeight: 'bold' }}
                                    >
                                        Needed This Round
                                    </Typography>
                                    <Typography gutterBottom={true} align={'right'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                        {props.detail.investmentNeededDD ? money(props.detail.investmentNeededDD) : '-'}
                                    </Typography>
                                </Grid>
                                {props.detail.investmentRaisedAmount && (
                                    <Grid xs={12}>
                                        <Slider
                                            disabled
                                            defaultValue={running(props.detail.investmentRaisedAmount, props.detail.investmentNeededDD)}
                                            aria-labelledby="disabled-slider"
                                        />
                                    </Grid>
                                )}
                                {props.detail.investmentRaisedAmount && props.detail.investmentNeededDD && (
                                    <>
                                        <Grid xs={12} container justify="space-between">
                                            <Grid xs="auto">
                                                <Typography gutterBottom={true} color="primary" align={'right'} variant="h6">
                                                    {running(props.detail.investmentRaisedAmount, props.detail.investmentNeededDD)}%
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </>
                                )}
                                <Grid xs={12}>
                                    <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                </Grid>
                                {props.detail.sharePercent && (
                                    <>
                                        <Grid xs={12} style={{ marginTop: '-50px' }}>
                                            <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                        </Grid>
                                        <Grid item container alignItems="center" justify="space-between">
                                            <Typography gutterBottom={true} align={'left'} variant="h5">
                                                Cap Table Allocation
                                            </Typography>
                                        </Grid>
                                        <Grid xs={12} container style={{ marginTop: 35 }} spacing={4}>
                                            {props.detail.sharePercent && (
                                                <Grid xs={12} lg={6} item>
                                                    <Typography
                                                        color="primary"
                                                        variant="h6"
                                                        id="disabled-slider"
                                                        style={{ width: 'fit-content' }}
                                                    >
                                                        %{100 - props.detail.sharePercent}
                                                    </Typography>
                                                    <Slider
                                                        disabled
                                                        defaultValue={100 - props.detail.sharePercent}
                                                        aria-labelledby="disabled-slider"
                                                    />
                                                    <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                        Investors
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {props.detail.sharePercent && (
                                                <Grid xs={12} lg={6} item>
                                                    <Typography
                                                        color="primary"
                                                        variant="h6"
                                                        id="disabled-slider"
                                                        style={{ width: 'fit-content' }}
                                                    >
                                                        %{props.detail.sharePercent}
                                                    </Typography>
                                                    <Slider
                                                        disabled
                                                        defaultValue={props.detail.sharePercent}
                                                        aria-labelledby="disabled-slider"
                                                    />
                                                    <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                        Founding Team
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </>
                                )}
                                {(props.detail.payrollFA ||
                                    props.detail.marketingFA ||
                                    props.detail.developmentFA ||
                                    props.detail.salesFA ||
                                    props.detail.rdFA ||
                                    props.detail.otherFA) && (
                                        <>
                                            <Grid xs={12}>
                                                <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                            </Grid>
                                            <Grid item container alignItems="center" justify="space-between">
                                                <Typography gutterBottom={true} align={'left'} variant="h5">
                                                    Funding Allocation
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12} container style={{ marginTop: 35 }} spacing={4}>
                                                {props.detail.payrollFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.payrollFA}
                                                        </Typography>
                                                        <Slider
                                                            disabled
                                                            defaultValue={props.detail.payrollFA}
                                                            aria-labelledby="disabled-slider"
                                                        />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            Payroll
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.detail.salesFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.salesFA}
                                                        </Typography>
                                                        <Slider disabled defaultValue={props.detail.salesFA} aria-labelledby="disabled-slider" />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            Sales
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.detail.rdFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.rdFA}
                                                        </Typography>
                                                        <Slider disabled defaultValue={props.detail.rdFA} aria-labelledby="disabled-slider" />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            {'R & D'}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.detail.marketingFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.marketingFA}
                                                        </Typography>
                                                        <Slider
                                                            disabled
                                                            defaultValue={props.detail.marketingFA}
                                                            aria-labelledby="disabled-slider"
                                                        />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            Marketing
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.detail.developmentFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.developmentFA}
                                                        </Typography>
                                                        <Slider
                                                            disabled
                                                            defaultValue={props.detail.developmentFA}
                                                            aria-labelledby="disabled-slider"
                                                        />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            Product Development
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {props.detail.otherFA && (
                                                    <Grid xs={12} lg={6} item>
                                                        <Typography
                                                            color="primary"
                                                            variant="h6"
                                                            id="disabled-slider"
                                                            style={{ width: 'fit-content' }}
                                                        >
                                                            %{props.detail.otherFA}
                                                        </Typography>
                                                        <Slider disabled defaultValue={props.detail.otherFA} aria-labelledby="disabled-slider" />
                                                        <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                            Other
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                    </div> : <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                        <Formik
                            initialValues={{
                                id: props.detail.id,
                                investmentRaisedAmount: props.detail.investmentRaisedAmount,
                                postMoneyValuation: props.detail.postMoneyValuation,
                                investmentNeededDD: props.detail.investmentNeededDD,
                                sharePercent: props.detail.sharePercent,
                                payrollFA: props.detail.payrollFA,
                                salesFA: props.detail.salesFA,
                                rdFA: props.detail.rdFA,
                                marketingFA: props.detail.marketingFA,
                                developmentFA: props.detail.developmentFA,
                                otherFA: props.detail.otherFA,
                            }}
                            enableReinitialize={true}
                            onSubmit={values => {
                                handleSubmit(values);
                            }}
                        >
                            {props => (
                                <Form style={{ width: '100%' }}>
                                    <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                        <Grid item container alignItems="center" justify="flex-end">
                                            <Button variant="containedSecondary" style={{ padding: "10px 25px" }} type="submit"> Save </Button>
                                        </Grid>
                                        <Grid item container spacing={3} style={{ marginTop: 25 }}>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                                                    Received This Round
                                                </Typography>
                                                <FormikNumberField
                                                    name="investmentRaisedAmount"
                                                    style={{ width: '100%' }}
                                                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                    placeholder="Please write"
                                                ></FormikNumberField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                                                    Valuation
                                                </Typography>
                                                <FormikNumberField
                                                    name="postMoneyValuation"
                                                    style={{ width: '100%' }}
                                                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                    placeholder="Please write"
                                                ></FormikNumberField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                                                    Needed This Round
                                                </Typography>
                                                <FormikNumberField
                                                    name="investmentNeededDD"
                                                    style={{ width: '100%' }}
                                                    onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                    placeholder="Please write"
                                                ></FormikNumberField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Founding Team’s Share Percentage (%)
                                            </Typography>
                                                <FormikPercentageField name="sharePercent" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - Payroll (%)
                                            </Typography>
                                                <FormikPercentageField name="payrollFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - Sales (%)
                                            </Typography>
                                                <FormikPercentageField name="salesFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - R&D (%)
                                            </Typography>
                                                <FormikPercentageField name="rdFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - Marketing (%)
                                            </Typography>
                                                <FormikPercentageField name="marketingFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - Product Development (%)
                                            </Typography>
                                                <FormikPercentageField name="developmentFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                            </Grid>
                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                <Typography variant="h5" align="left" style={{ marginBottom: 10 }}>
                                                    Use of Fund - Other (%)
                                            </Typography>
                                                <FormikPercentageField name="otherFA" maxLen={4} style={{ width: '100%' }} placeholder="%"></FormikPercentageField>
                                                <Field>
                                                    {({ field, form, meta }) => (
                                                        sumFund(
                                                            form.values.payrollFA,
                                                            form.values.marketingFA,
                                                            form.values.salesFA,
                                                            form.values.developmentFA,
                                                            form.values.rdFA,
                                                            form.values.otherFA,
                                                        ) > 100 ?
                                                            <Typography align="right" variant="h5" style={{ marginTop: -15, color: 'rgb(255, 0, 0)' }}>
                                                                Use of Fund Total:
                                                        {sumFund(
                                                                form.values.payrollFA,
                                                                form.values.marketingFA,
                                                                form.values.salesFA,
                                                                form.values.developmentFA,
                                                                form.values.rdFA,
                                                                form.values.otherFA,
                                                            )}
                                                        %
                                                        </Typography>
                                                            :
                                                            <Typography align="right" variant="h5" style={{ marginTop: -15 }}>
                                                                Use of Fund Total:
                                                        {sumFund(
                                                                form.values.payrollFA,
                                                                form.values.marketingFA,
                                                                form.values.salesFA,
                                                                form.values.developmentFA,
                                                                form.values.rdFA,
                                                                form.values.otherFA,
                                                            )}
                                                         %
                                                        </Typography>
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>}
            </Paper>
        </>
    );
}