import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import SuperAdminEvents from './SuperAdminEvents';

const CREATE_EVENT = gql`
  mutation createEvent
    (
        $name: String!
        $startDate: String!
        $endDate: String!
        $startupIds: [ID!]
        $link: String!
    )
    {
    createEvent
    (
        name: $name,
        startDate: $startDate,
        endDate: $endDate,
        startupIds: $startupIds,
        link: $link,
     ) 
    }
`;

const EDIT_EVENT = gql`
  mutation editEvent
    (
        $eventId: ID!
        $name: String!
        $startDate: String!
        $endDate: String!
        $startupIds: [ID!]
        $link: String!
    )
    {
    editEvent
    (
        eventId: $eventId,
        name: $name,
        startDate: $startDate,
        endDate: $endDate,
        startupIds: $startupIds,
        link: $link,
     ) 
    }
`;

const REMOVE_EVENT = gql`
  mutation removeEvent
    (
    $eventId: ID!
    )
    {
        removeEvent
        (
        eventId: $eventId,
        ) 
    }
`;

const JoinWithMutation = compose(
  graphql(CREATE_EVENT, {
    name: 'createEvent',
  }),
  graphql(EDIT_EVENT, {
    name: 'editEvent',
  }),
  graphql(REMOVE_EVENT, {
    name: 'removeEvent',
  }),
)(SuperAdminEvents);
export default JoinWithMutation;