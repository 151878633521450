import React,{useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { MY_PROFILE } from '../../queries';
import { useQuery, useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import Popover from '@material-ui/core/Popover';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Logo from '../../static/img/new-ae-logo.png';
import Avatar from '../../static/img/default-avatar.png';
import { Link } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import startButton from '../../static/img/start-button.svg';
import Grid from "@material-ui/core/Grid";
import { DebounceInput } from 'react-debounce-input';
import gql from 'graphql-tag';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '0px 100px',
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      flexGrow: '1'
  }, 
  },
  grow2: {
    flexGrow: 1,
    [theme.breakpoints.up('xl')]: {
      flexGrow: '0.7'
  }, 
  },
  angelLogo: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: '150px'
  }, 
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  iconList: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  search: {
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '175px',
      marginLeft: 75,
    },  
    marginLeft: 90,
  },
  searchBox: {
    position:'absolute',
    marginLeft: 75,
    width:'100%',
    background:'#ffffff',
    borderRadius:2,
    marginTop:5,
    boxShadow:'1px 1px 2px 1px rgba(201,201,201,1)',
    maxHeight: 130,
    overflowY:'auto',
    [theme.breakpoints.up('xl')]: {
      marginLeft: 90,
      maxWidth: 278
    },  
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    zIndex: 99999,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.iconColor.main,
  },

  inputInput: {
    padding: theme.spacing(2, 1, 2, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    outline:'none',
    border:'none',
    borderRadius:7,
    width: '100%',
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.text.main,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  popover: {
    pointerEvents: "none"
  },
  popoverContent: {
    padding:'20px 0',
    minWidth:'100px',
    textAlign:'center',
    marginTop:5,
    pointerEvents: "auto",
    boxShadow:"0px 2px 8px 0px rgba(201,201,201,1)"
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  linkFont: {
    fontSize:15,
  },
}));

export default function Header(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopover, setOpenedPopover] = React.useState(false);
  const popoverAnchor = useRef(null);
  const [user, setUser] = React.useState({});
  const [search, setSearch] = React.useState("");
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const client = useApolloClient();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handlePopoverOpen = ({ currentTarget }) => {
    setOpenedPopover(true);
  };
  const SEARCH_STARTUPS = gql`
  query topStartupSearch($search: String){
        topStartupSearch(search:$search){
        id
        title
        description
        status
        slug
      }
  }
  `;
  const handlePopoverClose = ({ currentTarget }) => {
    setOpenedPopover(false);
  };
  const { loading, data } = useQuery(MY_PROFILE, {
    fetchPolicy: "cache-first",
    variables: {},
  });
  const[getData, { loading:loadingSearch, data:dataSearch } ]= useLazyQuery(SEARCH_STARTUPS, {
    fetchPolicy:'network-only',
    variables: {search:search},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          setUser(data.myProfile)
        }
    }
  },
[data, loading,])

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const onMouseOver = (event) => {
    const el = event.target;
    el.style.background = 'rgb(40 177 231/ 18%)';
  };
  const onMouseOut = event => {
    const el = event.target;
    el.style.background = '#ffffff';
  };
  const logout = () =>  {
    localStorage.clear();
    client.clearStore().then(() => props.history.push('/login'));
  };
  const HandleSearch = event => {
    setSearch(event.target.value);
    getData()
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar position="sticky" style={{background : '#ffffff'}} elevation={1}>
      <Toolbar variant="dense" disableGutters className={classes.toolbar}>

      <Link to="/" style={{padding:0}}>
        <img src={Logo} className={classes.angelLogo} alt="angel-effect"></img>
      </Link>
      {(user.role === 'Admin' || user.role === 'SuperAdmin' || (user.role === 'Investor' && user.isApprovedInvestor))  ?
      <>
      <Hidden mdDown>
        <Grid item>
        <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <DebounceInput
                                class="inputTypeSearch"
                                name="search"
                                value={search}
                                placeholder="Search by Name.."
                                className={classes.inputInput}
                                minLength={2}
                                debounceTimeout={800}
                                onChange={HandleSearch} />
          </div>
        { (search && dataSearch) &&
          <Grid className={classes.searchBox} xs={3}>
            <List component="nav" aria-label="secondary mailbox folders">
            {dataSearch.topStartupSearch.map(searchResult => (
              <>
              {searchResult.status !== null && 
              <Grid>
                  <Link to={`/startup/${searchResult.slug ? searchResult.slug : searchResult.id}`} style={{padding:0}}>
                    <ListItem button style={{minHeight:50}}>
                      <Grid container>
                        <img src={startButton} alt="rocket" style={{width:'20px',marginRight:10}}/>
                        <Typography variant="body1" color="primary">
                          {searchResult.title}
                        </Typography>
                      </Grid>
                    </ListItem>
                  </Link> 
                </Grid>}
              </>
              ))}
              {dataSearch.topStartupSearch.length === 0 && 
                <ListItem button>
                  <Typography variant="body1">
                    No results found...
                  </Typography>
                </ListItem> 
              }
            </List>
          </Grid>
          }
        </Grid>
       </Hidden> 
       <div>
          <Link to="/startups" className={classes.linkFont} onMouseEnter={onMouseOver} onMouseOut={onMouseOut} style={{ marginLeft: "100px", transition:'0.5s' }}>
            Startups
          </Link>
        </div>
      <div className={classes.grow}></div>
          

          </>
        : <div className={classes.grow}></div>}
        {Object.keys(user).length > 0 ? <div style={{display : "flex", alignItems :"center"}}>
          <Hidden mdDown>
            <div>
              <Typography className={classes.linkFont}
              style={{margin : "0 20px"}}  >{user.firstName}</Typography>
            </div>
          </Hidden>
          <div onMouseEnter={handlePopoverOpen} aria-owns='mouse-over-popover' ref={popoverAnchor}
            aria-haspopup="true" onMouseLeave={handlePopoverClose} style={{display : "flex", alignItems :"center" , cursor:"pointer"}} >
            {user.profilePhoto ? (
              <img src={user.profilePhoto} alt="avatar"  style={{maxWidth:50,borderRadius:'50%',objectFit:'contain'}}/>
            ) : (
              <img src={Avatar} alt="avatar"  style={{maxWidth:50,borderRadius:'50%'}}/>
            )}
          </div>
          <Popover
                  id="mouse-over-popover"
                  open={openedPopover}
                  anchorEl={popoverAnchor.current}
                  className={classes.popover}
                  classes={{
                    paper: classes.popoverContent,
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={handlePopoverClose}
                  PaperProps={{ onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose }}
                >
                  {user.role === 'Investor' &&  
                  <Link to="/settings" style={{padding:0}}>
                    <Typography style={{fontSize:18,cursor:'pointer',marginBottom:10}}>Settings</Typography>
                  </Link>
                  }
                  <Typography style={{fontSize:18,cursor:'pointer',transition:'0.5s'}} onClick={logout}>Log Out</Typography>
            </Popover>
          
        </div> : <div style={{display : "flex", alignItems :"center"}}>
          <Typography 
            style={{margin : "0px 20px"}}>Login</Typography>
        </div>}
       
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
