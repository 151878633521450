import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '15px',
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    namePart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    companyPart: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    paper: {
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff'
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 120,
        height: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
    },
}));

const money = (value) => {
    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    if (formatter.format(parseInt(value)) !== '$NaN') {
        return formatter.format(parseInt(value))
    }
    else {
        return '-'
    }
}

const running = (investmentRaisedAmount, investmentNeededDD) => {
    if (parseInt(investmentNeededDD) > parseInt(investmentRaisedAmount)) {
        let percent = (parseInt(investmentRaisedAmount) * 100) / parseInt(investmentNeededDD)
        percent = ~~percent
        return percent;
    }
    return 100;
}

export default function TabBusinessCard(props) {

    const classes = useStyles();
    return (
        <>
            <Paper style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} className={classes.paper}>
                <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                    <Grid item container alignItems="center" justify="space-between">
                        <Typography gutterBottom={true} align={'left'} variant="h5">
                            Current Investment Round
                        </Typography>
                    </Grid>
                    <Grid item container>
                        <Grid xs={4}>
                            <Typography
                                gutterBottom={true}
                                align={'left'}
                                variant="body2"
                                style={{ marginTop: 15, fontWeight: 'bold' }}
                            >
                                Received This Round
                            </Typography>
                            <Typography gutterBottom={true} align={'left'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                {props.detail.investmentRaisedAmount ? money(props.detail.investmentRaisedAmount) : '-'}
                            </Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography
                                gutterBottom={true}
                                align={'center'}
                                variant="body2"
                                style={{ marginTop: 15, fontWeight: 'bold' }}
                            >
                                Post Money Valuation
                                    </Typography>
                            <Typography gutterBottom={true} align={'center'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                {props.detail.postMoneyValuation ? money(props.detail.postMoneyValuation) : '-'}
                            </Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Typography
                                gutterBottom={true}
                                align={'right'}
                                variant="body2"
                                style={{ marginTop: 15, fontWeight: 'bold' }}
                            >
                                Needed This Round
                            </Typography>
                            <Typography gutterBottom={true} align={'right'} variant="h6" style={{ wordBreak: 'break-all' }}>
                                {props.detail.investmentNeededDD ? money(props.detail.investmentNeededDD) : '-'}
                            </Typography>
                        </Grid>
                        {props.detail.investmentRaisedAmount && (
                            <Grid xs={12}>
                                <Slider
                                    disabled
                                    defaultValue={running(props.detail.investmentRaisedAmount, props.detail.investmentNeededDD)}
                                    aria-labelledby="disabled-slider"
                                />
                            </Grid>
                        )}
                        {props.detail.investmentRaisedAmount && props.detail.investmentNeededDD && (
                            <>
                                <Grid xs={12} container justify="space-between">
                                    <Grid xs="auto">
                                        <Typography gutterBottom={true} color="primary" align={'right'} variant="h6">
                                            {running(props.detail.investmentRaisedAmount, props.detail.investmentNeededDD)}%
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </>
                        )}
                        <Grid xs={12}>
                            <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                        </Grid>
                        {props.detail.sharePercent && (
                            <>
                                <Grid xs={12} style={{ marginTop: '-50px' }}>
                                    <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                </Grid>
                                <Grid item container alignItems="center" justify="space-between">
                                    <Typography gutterBottom={true} align={'left'} variant="h5">
                                        Cap Table Allocation
                                    </Typography>
                                </Grid>
                                <Grid xs={12} container style={{ marginTop: 35 }} spacing={4}>
                                    {props.detail.sharePercent && (
                                        <Grid xs={12} lg={6} item>
                                            <Typography
                                                color="primary"
                                                variant="h6"
                                                id="disabled-slider"
                                                style={{ width: 'fit-content' }}
                                            >
                                                %{100 - props.detail.sharePercent}
                                            </Typography>
                                            <Slider
                                                disabled
                                                defaultValue={100 - props.detail.sharePercent}
                                                aria-labelledby="disabled-slider"
                                            />
                                            <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                Investors
                                            </Typography>
                                        </Grid>
                                    )}
                                    {props.detail.sharePercent && (
                                        <Grid xs={12} lg={6} item>
                                            <Typography
                                                color="primary"
                                                variant="h6"
                                                id="disabled-slider"
                                                style={{ width: 'fit-content' }}
                                            >
                                                %{props.detail.sharePercent}
                                            </Typography>
                                            <Slider
                                                disabled
                                                defaultValue={props.detail.sharePercent}
                                                aria-labelledby="disabled-slider"
                                            />
                                            <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                Founding Team
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        )}
                        {(props.detail.payrollFA ||
                            props.detail.marketingFA ||
                            props.detail.developmentFA ||
                            props.detail.salesFA ||
                            props.detail.rdFA ||
                            props.detail.otherFA) && (
                                <>
                                    <Grid xs={12}>
                                        <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                    </Grid>
                                    <Grid item container alignItems="center" justify="space-between">
                                        <Typography gutterBottom={true} align={'left'} variant="h5">
                                            Funding Allocation
                                                </Typography>
                                    </Grid>
                                    <Grid xs={12} container style={{ marginTop: 35 }} spacing={4}>
                                        {props.detail.payrollFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.payrollFA}
                                                </Typography>
                                                <Slider
                                                    disabled
                                                    defaultValue={props.detail.payrollFA}
                                                    aria-labelledby="disabled-slider"
                                                />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    Payroll
                                                        </Typography>
                                            </Grid>
                                        )}
                                        {props.detail.salesFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.salesFA}
                                                </Typography>
                                                <Slider disabled defaultValue={props.detail.salesFA} aria-labelledby="disabled-slider" />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    Sales
                                                        </Typography>
                                            </Grid>
                                        )}
                                        {props.detail.rdFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.rdFA}
                                                </Typography>
                                                <Slider disabled defaultValue={props.detail.rdFA} aria-labelledby="disabled-slider" />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    {'R & D'}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {props.detail.marketingFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.marketingFA}
                                                </Typography>
                                                <Slider
                                                    disabled
                                                    defaultValue={props.detail.marketingFA}
                                                    aria-labelledby="disabled-slider"
                                                />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    Marketing
                                                        </Typography>
                                            </Grid>
                                        )}
                                        {props.detail.developmentFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.developmentFA}
                                                </Typography>
                                                <Slider
                                                    disabled
                                                    defaultValue={props.detail.developmentFA}
                                                    aria-labelledby="disabled-slider"
                                                />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    Product Development
                                                        </Typography>
                                            </Grid>
                                        )}
                                        {props.detail.otherFA && (
                                            <Grid xs={12} lg={6} item>
                                                <Typography
                                                    color="primary"
                                                    variant="h6"
                                                    id="disabled-slider"
                                                    style={{ width: 'fit-content' }}
                                                >
                                                    %{props.detail.otherFA}
                                                </Typography>
                                                <Slider disabled defaultValue={props.detail.otherFA} aria-labelledby="disabled-slider" />
                                                <Typography variant="h6" id="disabled-slider" style={{ width: 'fit-content' }}>
                                                    Other
                                                        </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}