import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import sticker from'../../static/img/sticker.svg';
import edit from'../../static/img/new.svg';
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {DebounceInput} from "react-debounce-input";
import {CSVLink} from "react-csv";
import Pagination from "@material-ui/lab/Pagination";
import BottomNavigation from "../../Components/BottomNavigation";
import MobileHeader from "../../Components/MobileHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa',
    height: 30,
    fontSize: '12px',
    padding:'8px',
  },
  body: {
    fontSize: 13,
    padding:'20px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      marginTop: 30
    },
  },
  accordionGrid: {
      height:'fit-content',
      marginBottom:40,
  },
  multiSelect: {
    maxHeight: '40px',
    border: '1px solid #bdbdbd',
    background: '#ffffff',
    borderRadius:4,
    '& div': {
        background: 'none!important',
    }
},
  exportButton: {
    padding:'9.5px 16px',
    color: '#28b1e7',
    borderRadius: 3,
    fontWeight:'normal',
    display:'flex',
    alignItems:'center',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    border: '1px solid #28b1e7',
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  investorButton: {
    padding:'9.5px 16px',
    color: '#172b4d',
    borderRadius: 3,
    fontWeight:'normal',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    border: '1px solid #172b4d',
    "&:hover" : {
      border: '1px solid #172b4d',
    },
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  accordionPanel: {
    maxWidth: 240,
    width: '100%',
    boxShadow: 'none',
    borderBottom: 'none',
    background: '#f6f9fc',
    marginTop: '25px!important',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
    },
    '&:hover': {
      backgroundColor: '#e7eff7',
    },
  },
  sticker: {
      marginRight:20,
      [theme.breakpoints.down('sm')]: {
        marginBottom:10
    }, 
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:14,
    height:'35px',
    borderRadius:'4px',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'1px solid #bdbdbd' ,
    outline:'none',
    padding: '2px 8px 2px 20px',
    marginRight:15,
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rightPart: {
    height:'fit-content',
    background:"#ffffff",
    borderRadius:10
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  ellipse:{
    width:10,
    height:10,
    borderRadius:'50%',
    marginRight:5
  },
  heading: {
    fontSize:16
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
    },
  },
  mainGrid: {
      justifyContent:'center'
  }  
}));
export default function SuperAdminInvestors(props){
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [dataAll, setDataAll] = React.useState([]);
  const [planInvestTwoYear, setPlanInvestTwoYear] = React.useState([]);
  const [isApprovedInvestor, setIsApprovedInvestor] = React.useState([]);
  const ALL_INVESTORS = gql`
  query allInvestors($order:String, $planInvestTwoYear:[String], $isApprovedInvestor:[Boolean], $search: String) {
    allInvestors(orderBy:[$order],filter : { planInvestTwoYear : $planInvestTwoYear, isApprovedInvestor : $isApprovedInvestor }, search:$search){
          id
          firstName
          lastName
          email
          getRole
          phoneNumber
          socialLinkedIn
      }

  }
  `;
  const { loading, data } = useQuery(ALL_INVESTORS, {
    fetchPolicy:"network-only",
    variables: {order:order, planInvestTwoYear:planInvestTwoYear, isApprovedInvestor:isApprovedInvestor, search:search},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
            if (data.allInvestors){
              setDataAll(data.allInvestors);
              setRows(data.allInvestors.slice(0, pageSize));
              setPage(1);
              setPageCount(1);
              setPageCount(Math.ceil(data.allInvestors.length / pageSize))
            }else{
              setPage(0);
              setPageCount(0);
            }
        }
    }
},
[data, loading])
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [sort, setSort] = React.useState([]);
    const options = [
      {name:'A-Z', value:'firstName__ASC'},
      {name:'Z-A', value:'firstName__DESC'},
      {name:'Latest', value:'id__DESC'},
      {name:'Oldest', value:'id__ASC'},
    ];
  const planInvestTwoYearOptions = ['0', '1-3', '3-5', '5-10', '10+'];
  const isApprovedInvestorOptions = ['Yes', 'No'];

  const [state, setState] = React.useState({
      filter1: false,
      filter2: false,
      filter3: false,
      filter4: false,
      filter5: false,
      filter6: false,
    });
  
    const handlePlanInvestTwoYear = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (planInvestTwoYear.includes(event.target.value)){
        let a = planInvestTwoYear.filter(e => e !== event.target.value);
        setPlanInvestTwoYear(a);
      }
      else{
        setPlanInvestTwoYear([...planInvestTwoYear, event.target.value]);
      }
    };
    const handleIsApprovedInvestor = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      let val = event.target.value === 'Yes'
      if (isApprovedInvestor.includes(val)){
        let a = isApprovedInvestor.filter(e => e !== val);
        setIsApprovedInvestor(a);
      }
      else{
        setIsApprovedInvestor([...isApprovedInvestor, val ]);
      }
    };
    const handleSort = event => {
      setSort(event.target.value);
    };
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const handleSearch = event => {
      setSearch(event.target.value);
    };
    const handlePageChange = (event, value) => {
      setPage(value);
      let temp = value * pageSize;
      setRows(dataAll.slice(temp - pageSize, temp));
    };
    return (
      <div>
        <Hidden smDown>
          <Header></Header>
        </Hidden>
        <Hidden mdUp>
          <MobileHeader></MobileHeader>
        </Hidden>
        <Grid container className={classes.mainGrid} style={{ background: '#f7f7fc' }}>
          <LeftNavigation></LeftNavigation>
          <BottomNavigation></BottomNavigation>
          <Grid
            className={classes.adminContainer}
            container
            xs={12}
            lg={11}
            xl={12}
            style={{ marginTop: 50, height: 'fit-content' }}
          >
            <Grid container justify="space-between" alignItems="center" className={classes.topPart}>
              <Typography variant="h4"> Listing Investors </Typography>

              <Grid item container className={classes.Select} md={5} xs={12}>
                <DebounceInput
                  class="inputTypeSearch"
                  name="search"
                  className={classes.Debounce}
                  value={search}
                  placeholder="Search by Name.."
                  minLength={2}
                  debounceTimeout={800}
                  onChange={handleSearch}
                />
                <FormControl placeholder="Please Select" style={{ width: '100%', maxWidth: '129px' }}>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    displayEmpty
                    value={sort}
                    className={classes.multiSelect}
                    onChange={handleSort}
                    input={<Input />}
                    /*   MenuProps={MenuProps} */
                  >
                    <MenuItem value="" disabled>
                      <ListItemText className={classes.ListItemText} primary="Sorting" />
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                        <ListItemText className={classes.ListItemText} primary={option.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ margin: '50px 0' }}>
              <Grid item container md={3} sm={5} xs={12} className={classes.accordionGrid}>
                <Grid
                  item
                  md={11}
                  xs={12}
                  sm={5}
                  alignItems="center"
                  style={{
                    background: '#ffffff',
                    borderRadius: '7px',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '20px',
                    paddingBottom: 40,
                  }}
                >
                  <ExpansionPanel className={classes.accordionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} style={{ fontWeight: 600 }}>
                        Approved Investor
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                          {isApprovedInvestorOptions.map((value, i) => {
                            // Return the element. Also pass key
                            return (
                              <FormControlLabel
                                className="dropdown"
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={handleIsApprovedInvestor}
                                    name="filter3"
                                    value={value}
                                  />
                                }
                                label={value}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                  <ExpansionPanel className={classes.accordionPanel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} style={{ fontWeight: 600 }}>
                        Plan to Invest
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                          {planInvestTwoYearOptions.map((value, i) => {
                            // Return the element. Also pass key
                            return (
                              <FormControlLabel
                                className="dropdown"
                                control={
                                  <Checkbox
                                    color="primary"
                                    onChange={handlePlanInvestTwoYear}
                                    name="filter4"
                                    value={value}
                                  />
                                }
                                label={value}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Grid>
              </Grid>
              <Grid item container xs sm={12} md={9} className={classes.rightPart}>
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{ margin: '20px 0', padding: '0 40px' }}
                >
                  <Typography variant="h5" style={{ fontSize: 18 }}>
                    Investors
                  </Typography>
                  <Grid item style={{ display: 'flex' }}>
                    <Hidden mdDown>
                      <Link style={{ padding: 0 }} to={`/admin/investors/approve-list`}>
                        <Button
                          variant="outlinedPrimary"
                          className={classes.investorButton}
                          style={{ marginRight: 20 }}
                        >
                          {' '}
                          Approve Requests{' '}
                        </Button>
                      </Link>
                      <Link style={{ padding: 0 }} to={`/admin/investors/request-list`}>
                        <Button
                          variant="outlinedPrimary"
                          className={classes.investorButton}
                          style={{ marginRight: 20 }}
                        >
                          {' '}
                          Approve Investments{' '}
                        </Button>
                      </Link>
                    </Hidden>
                    <Link style={{ padding: 0 }} to={`/admin/investor/add`}>
                      <Button variant="outlinedPrimary" className={classes.investorButton} style={{ marginRight: 20 }}>
                        {' '}
                        Add Investor{' '}
                      </Button>
                    </Link>
                    <CSVLink
                      data={dataAll}
                      filename={'investors.csv'}
                      target="_blank"
                      variant="outlinedPrimary"
                      className={classes.exportButton}
                    >
                      Export Data
                    </CSVLink>
                  </Grid>
                </Grid>
                <TableContainer style={{ boxShadow: 'none' }} component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{ paddingLeft: 40 }}>NAME SURNAME</StyledTableCell>
                        <StyledTableCell align="left">MAIL ADDRESS</StyledTableCell>
                        <StyledTableCell align="left">PHONE NUMBER</StyledTableCell>
                        <StyledTableCell align="left">LINKEDIN</StyledTableCell>
                        <StyledTableCell align="left">INVESTOR TYPE</StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    {!loading && (
                      <TableBody>
                        {rows.map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell
                              component="th"
                              style={{ color: '#28b1e7', fontWeight: '600', maxWidth: 150, paddingLeft: 40 }}
                              scope="row"
                            >
                              <Link
                                style={{ padding: 0, color: '#28b1e7', fontWeight: '600', fontSize: 14 }}
                                to={`/admin/investor/${row.id}`}
                              >
                                {row.firstName} {row.lastName}
                              </Link>
                            </StyledTableCell>

                            <StyledTableCell align="left" style={{ fontWeight: 600 }}>
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="left">{row.phoneNumber}</StyledTableCell>
                            {row.socialLinkedIn ? (
                              <StyledTableCell align="left">{truncateString(row.socialLinkedIn, 12)}</StyledTableCell>
                            ) : (
                              <StyledTableCell align="left">-</StyledTableCell>
                            )}
                            <StyledTableCell align="left">
                              <Grid container alignItems="center">
                                {row.getRole === 'Investor' ? (
                                  <Grid className={classes.ellipse} style={{ background: '#2dce98' }}></Grid>
                                ) : (
                                  <Grid className={classes.ellipse} style={{ background: '#11cdef' }}></Grid>
                                )}
                                <Typography variant="body2">{row.getRole}</Typography>
                              </Grid>
                            </StyledTableCell>
                            <StyledTableCell align="left" style={{ paddingRight: 35, width: 65 }}>
                              <Grid container>
                                <Link style={{ padding: 0 }} to={`/admin/investor/${row.id}`}>
                                  <img src={sticker} alt="sticker" className={classes.sticker} />
                                </Link>
                                <Link style={{ padding: 0 }} to={`/admin/investor-edit/${row.id}`}>
                                  <img src={edit} alt="sticker" />
                                </Link>
                              </Grid>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <Grid container item style={{ margin: '30px 0', paddingRight: 35 }} justify="flex-end">
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    color="primary"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
}