
import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import StartupApplicationForm from './StartupApplicationForm';
import gql from 'graphql-tag';
const APPLICATION_MUTATION = gql`
  mutation registerStartupContact(
    $firstName: String!
    $lastName: String!
    $jobTitle: String!
    $email: String!
    $linkedin: String!
  ) {
    registerStartupContact(
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      email: $email
      linkedin: $linkedin
    )
    {
      token
      user{
        id
        startup{
          id
        }
      }
    }
  }
`;
const FIRST_APP_STARTUP = gql`
  mutation firstApplicationStartup(
    $id: ID!
    $totalRevenueGrowth: BigInt
    $firstCompetitorsName: String
    $firstCompetitorsWebsite: String
    $secondCompetitorsName: String
    $secondCompetitorsWebsite: String
    $thirdCompetitorsName: String
    $thirdCompetitorsWebsite: String
    $roundPercent: String
    $sharePercent: String
    $logo: Upload
    $website: String
    $productProblem: String
    $productSolution: String
    $averageRevenueGrowth: BigInt
    $revenueRecurring: String
    $revenueRecurringType: String
    $productDifFromComp: String
    $investmentNeededDD: String
    $payrollFA: String
    $salesFA: String
    $rdFA: String
    $marketingFA: String
    $developmentFA: String
    $otherFA: String
    $title: String
    $description: String
    $industry: [String]
    $stage: String
    $businessModel: String
    $country: ID
    $establishedDate: String
    $haveCustomer: String
    $havePayingCustomer: String
    $pitchDeck: Upload
    $businessPlan: Upload
    $founderSize: String
    $foundersPrevStartupExp: String
    $foundersPrevCorporateExp: String
    $teamMemberSize: String
    $founderMembers: [TeamMemberInput]
    $otherMembers: [TeamMemberInput]
    $competitorHave: String
    $competitorAmount: String
    $marketTotalSize: String
    $marketAddressableSize: String
    $marketTargetRegion: String
    $investmentRaised: String
    $investmentRaisedAmount: BigInt
    $postMoneyValuation: BigInt
    $applicationStepFA: Int
    $submit: Boolean
) {
  firstApplicationStartup(
    id: $id
    totalRevenueGrowth: $totalRevenueGrowth
    firstCompetitorsName: $firstCompetitorsName
    firstCompetitorsWebsite: $firstCompetitorsWebsite
    secondCompetitorsName: $secondCompetitorsName
    secondCompetitorsWebsite: $secondCompetitorsWebsite
    thirdCompetitorsName: $thirdCompetitorsName
    thirdCompetitorsWebsite: $thirdCompetitorsWebsite
    roundPercent: $roundPercent
    sharePercent: $sharePercent
    logo: $logo
    website: $website
    productProblem: $productProblem
    productSolution: $productSolution
    averageRevenueGrowth: $averageRevenueGrowth
    revenueRecurring: $revenueRecurring
    revenueRecurringType: $revenueRecurringType
    productDifFromComp: $productDifFromComp
    payrollFA: $payrollFA
    salesFA: $salesFA
    rdFA: $rdFA
    marketingFA: $marketingFA
    developmentFA: $developmentFA
    otherFA: $otherFA
    title: $title
    description: $description
    industry: $industry
    stage: $stage
    businessModel: $businessModel
    country: $country
    establishedDate: $establishedDate
    haveCustomer: $haveCustomer
    havePayingCustomer: $havePayingCustomer
    pitchDeck: $pitchDeck
    businessPlan: $businessPlan
    founderSize: $founderSize
    foundersPrevStartupExp: $foundersPrevStartupExp
    foundersPrevCorporateExp: $foundersPrevCorporateExp
    teamMemberSize: $teamMemberSize
    founderMembers: $founderMembers
    otherMembers: $otherMembers
    competitorHave: $competitorHave
    competitorAmount: $competitorAmount
    marketTotalSize: $marketTotalSize
    marketAddressableSize: $marketAddressableSize
    marketTargetRegion: $marketTargetRegion
    investmentRaised: $investmentRaised
    investmentRaisedAmount: $investmentRaisedAmount
    investmentNeededDD: $investmentNeededDD
    postMoneyValuation: $postMoneyValuation
    applicationStepFA: $applicationStepFA
    submit: $submit
    )
  }
`;

const JoinWithMutation = compose(
  graphql(APPLICATION_MUTATION, {
  name: 'registerStartupContact',
  }),
  graphql(FIRST_APP_STARTUP, {
    name: 'firstApplicationStartup',
  }),
)(StartupApplicationForm);
export default JoinWithMutation;