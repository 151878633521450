import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Rating from "@material-ui/lab/Rating";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 50,
        textAlign: 'center',
        borderRadius: 2,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        marginTop: '35px',
        [theme.breakpoints.down('xs')]: { 
            padding: '50px 20px'
        },    
    },        
        rating: {
            fontSize:30,
        },
}));

export default function MyRatingCard(props) {
    const [value, setValue] = React.useState();
    const classes = useStyles();
        return (
            <div>
            {props.rating && <Paper className={classes.paper}>
            <Grid xs={12} container>
                <Grid item xs={4} container>
                    <Typography align={"left"}
                                style={{display: 'flex', alignItems: 'center'}}
                                variant="h4">
                        Rating
                    </Typography>
                </Grid>
                <Grid item xs={8} container>
                    <Rating
                        name="simple-controlled"
                        size={"large"}
                        value={value}
                        className={classes.rating}
                        readOnly
                        defaultValue={props.rating} 
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>}
        </div>
            
    );
}