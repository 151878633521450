import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { RichEditorReadOnly } from "../RichEditorReadOnly/RichEditorReadOnly.js";
import { RichEditor } from "../../Components/RichEditor/RichEditor.js";
import Grid from "@material-ui/core/Grid";
import { Form, Formik, Field } from 'formik';
import { stateToHTML } from 'draft-js-export-html'
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import editImg from '../../static/icon/edit.svg';
import Typography from "@material-ui/core/Typography";
import FormikTextField from "../../Components/FormikTextField 2";
import { EditorState, } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html'

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '15px',
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    namePart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    companyPart: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    paper: {
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff'
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 120,
        height: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
    },
}));

export default function MyTabProductCard(props) {
    const classes = useStyles();
    const [detail, setDetail] = React.useState(props.detail);
    function validateLinkedinUrl(value) {
        if (value !== '' && value !== null) {
            let error;
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            error = !pattern.test(value) ? 'Not valid URL.' : false;
            return error;
        }
        return false;
    }
    const handleSubmit = variables => {
        variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent())
        variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent())
        if (props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
            props.editStartup({
                variables: {
                    id: variables.id,
                    website: variables.website,
                    productProblem: variables.productProblem,
                    productSolution: variables.productSolution,
                }
            })
                .then(data => {
                    if (data.data.editStartup) {
                        if (variables.productProblem) {
                            let htmlProduct = variables.productProblem
                            if (typeof (variables.productProblem) === 'string') {
                                variables.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productProblem = new EditorState.createEmpty() }
                        if (variables.productSolution) {
                            let htmlProduct = variables.productSolution
                            if (typeof (variables.productSolution) === 'string') {
                                variables.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productSolution = new EditorState.createEmpty() }
                        props.setIsSentNote(true)
                        props.getData()
                    }
                    else {

                    }
                })
        } else {
            variables.id = props.params.id
            props.startupEditStartup({
                variables: {
                    id: variables.id,
                    website: variables.website,
                    productProblem: variables.productProblem,
                    productSolution: variables.productSolution,
                }
            })
                .then(data => {
                    if (data.data.startupEditStartup) {
                        if (variables.productProblem) {
                            let htmlProduct = variables.productProblem
                            if (typeof (variables.productProblem) === 'string') {
                                variables.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productProblem = new EditorState.createEmpty() }
                        if (variables.productSolution) {
                            let htmlProduct = variables.productSolution
                            if (typeof (variables.productSolution) === 'string') {
                                variables.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productSolution = new EditorState.createEmpty() }
                        props.setIsSentNote(true)
                        props.setSendChanges(true)
                        props.getData()
                    }
                    else {

                    }
                })
        }
    };
    const [edit, setEdit] = React.useState(false);

    function https(value) {
        const prefix = 'https://';
        if (value.substr(0, prefix.length) !== prefix) {
            value = prefix + value;
        }
        return value
    }
    return (
        <Paper style={{ marginBottom: '30px' }} className={classes.paper}>
            {!edit ?
                <div>
                    <Grid container justify="flex-end" style={{ marginBottom: 15 }}>
                        {((props.authRole === 'Admin' || props.authRole === 'SuperAdmin') && window.location.href.indexOf("draft") === -1) &&
                            <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                        }
                        {(window.location.href.indexOf("draft") > -1 && (props.authRole !== 'Admin' && props.authRole !== 'SuperAdmin')) &&
                            <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                        }
                    </Grid>
                    <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                        {props.detail.website &&
                            <>
                                <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                    <Grid item container alignItems="center" justify="space-between">
                                        <Typography gutterBottom={true} align={"left"}
                                            variant="h5" style={{ display: 'flex', alignItems: 'center', marginTop: 25 }}>
                                            Product Link : <a target="_blank" rel="noopener noreferrer" href={https(props.detail.website)} color="primary" style={{ marginLeft: '15px', padding: 0, color: '#28b1e7', wordBreak: 'break-word' }}>{props.detail.website}</a>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid xs={12}>
                                    <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                </Grid>
                            </>
                        }
                        {props.detail.productProblem &&
                            <>
                                <Grid item container alignItems="center" justify="space-between">
                                    <Typography gutterBottom={true} align={"left"}
                                        variant="h5">
                                        Problem
                            </Typography>
                                </Grid>
                                <Grid item container>
                                    <RichEditorReadOnly
                                        editorState={props.detail.productProblem}
                                        name="productProblem"
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                </Grid>
                            </>
                        }
                        <Grid item container alignItems="center" justify="space-between">
                            <Typography gutterBottom={true} align={"left"}
                                variant="h5">
                                Solution
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <RichEditorReadOnly
                                editorState={props.detail.productSolution}
                                name="productSolution"
                            />
                        </Grid>
                    </Grid>
                </div> :
                <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                    <Formik
                        initialValues={{
                            id: props.detail.id,
                            website: props.detail.website,
                            productProblem: props.detail.productProblem,
                            productSolution: props.detail.productSolution
                        }}
                        enableReinitialize={true}
                        onSubmit={values => {
                            handleSubmit(values);
                        }}
                    >
                        {props => (
                            <Form style={{ width: '100%' }}>
                                <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                    <Grid item container alignItems="center" justify="flex-end">
                                        <Button variant="containedSecondary" style={{ padding: "10px 25px" }} type="submit"> Save </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5" align="left">
                                            Company Website
                                        </Typography>
                                        <FormikTextField name="website" type="text" validate={validateLinkedinUrl} placeholder="https://"></FormikTextField>
                                    </Grid>
                                    <Grid item container spacing={3} style={{ marginTop: 0 }}>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6} style={{ paddingTop: 0, marginBottom: 25 }}>
                                                    <Typography variant="h5" align="left">
                                                        Product Problem
                                                        </Typography>
                                                    <RichEditor
                                                        editorState={form.values.productProblem}
                                                        onChange={(form.setFieldValue)}
                                                        name="productProblem"
                                                        onBlur={form.handleBlur}
                                                    />
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                    <Typography variant="h5" align="left">
                                                        Product Solutions
                                                        </Typography>
                                                    <RichEditor
                                                        editorState={form.values.productSolution}
                                                        onChange={(form.setFieldValue)}
                                                        name="productSolution"
                                                        onBlur={form.handleBlur}
                                                    />
                                                </Grid>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            }
        </Paper>
    );
}