import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import ClearIcon from '@material-ui/icons/Clear';
import edit from'../../static/img/new.svg';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { eventSchema } from '../../yupSchemas';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { Form, Formik, Field, useField, } from 'formik';
import FormikTextField from "../../Components/FormikTextField 2";
import Pagination from "@material-ui/lab/Pagination";
import BottomNavigation from "../../Components/BottomNavigation";
import MobileHeader from "../../Components/MobileHeader";
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import moment from 'moment'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa',
    height: 30,
    fontSize: '12px',
    padding:'8px',
  },
  body: {
    fontSize: 13,
    padding:'20px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      marginTop: 30
    },
  },
  accordionGrid: {
      height:'fit-content',
      marginBottom:40,
  },
  multiSelect: {
    background: '#ffffff',
    borderRadius:4,
    '& div': {
        background: 'none!important',
    }
},
  exportButton: {
    padding:'9.5px 16px',
    color: '#28b1e7',
    borderRadius: 3,
    fontWeight:'normal',
    display:'flex',
    alignItems:'center',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    border: '1px solid #28b1e7',
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  investorButton: {
    padding:'9.5px 16px',
    borderRadius: 3,
    fontWeight:'normal',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  accordionPanel: {
    maxWidth: 240,
    width: '100%',
    boxShadow: 'none',
    borderBottom: 'none',
    background: '#f6f9fc',
    marginTop: '25px!important',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
    },
    '&:hover': {
      backgroundColor: '#e7eff7',
    },
  },
  sticker: {
      marginRight:10,
      [theme.breakpoints.down('sm')]: {
        marginBottom:10
    }, 
  },
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:14,
    height:'35px',
    borderRadius:'4px',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'1px solid #bdbdbd' ,
    outline:'none',
    padding: '2px 8px 2px 20px',
    marginRight:15,
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rightPart: {
    height:'fit-content',
    background:"#ffffff",
    borderRadius:10
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  ellipse:{
    width:10,
    height:10,
    borderRadius:'50%',
    marginRight:5
  },
  heading: {
    fontSize:16
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
    },
  },
  mainGrid: {
      justifyContent:'center'
  }  
}));
function validateLinkedinUrl(value) {
  if(value !== '' && value !== null){
      let error;
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      error = !pattern.test(value) ? 'Not valid URL.' : false;
      return error;
  }
  return false;
}

function ExpandingRow(props){
  const classes = useStyles();
  const [showEvent, setShowEvent] = React.useState(false);
  const { row } = props;
  
  const deleteEvent = () => {
    props.removeEvent({ variables: { eventId: row.id } })
      .then(data => {
        if (data.data.removeEvent) {
          props.refetch()
        } 
      })
  };

  return(
    <>
      <StyledTableRow key={row.id}>
        <StyledTableCell align="left" style={{paddingLeft:40}}><Typography color="primary" variant="h6">{row.name ? row.name : '-'}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{row.startDate ? moment(parseInt(row.startDate)).format('Do MMMM YYYY, H:mm') : '-'}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="h6" style={{fontSize:14}}>{row.endDate ? moment(parseInt(row.endDate)).format('Do MMMM YYYY, H:mm') : '-'}</Typography></StyledTableCell>
        <StyledTableCell align="left"><Typography variant="body2">{row.link ? row.link : '-'}</Typography></StyledTableCell>
        {row.status === 'Live' && 
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid className={classes.ellipse} style={{background:'#f53c56'}}></Grid>
            <Typography variant="body2">{row.status}</Typography>
          </Grid>
        </StyledTableCell>
        }
        {row.status === 'Pending' && 
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid className={classes.ellipse} style={{background:'#28b1e7'}}></Grid>
            <Typography variant="body2">{row.status}</Typography>
          </Grid>
        </StyledTableCell>
        }
        {row.status === 'Past' && 
        <StyledTableCell align="left">
          <Grid container alignItems="center">
            <Grid className={classes.ellipse} style={{background:'#777777'}}></Grid>
            <Typography variant="body2">{row.status}</Typography>
          </Grid>
        </StyledTableCell>
        }
        <StyledTableCell align="right" style={{paddingRight:35,width:65}}>
          <Grid container justify="flex-end">
              <img src={edit} style={{cursor:'pointer',width:20}} onClick={() => setShowEvent(true)} alt="sticker" className={classes.sticker} />  
              <Grid item style={{cursor:'pointer'}} onClick={() => deleteEvent()}><ClearIcon alt="sticker" style={{marginRight:5,fontSize:25,marginTop:5}} className={classes.sticker} /></Grid>                                          
              <EditEventDialog editEvent={props.editEvent} refetch={props.refetch} open={showEvent} events={row} onClose={() => setShowEvent(false)}/>
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}
const FormikStartupDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10,minHeight:80}}
          {...props}
          {...field}
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionLabel={(option) => option.title}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props } label="Please Select"  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}

function AddEventDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, groups } = props;
  const [startups, setStartups] = React.useState();
  
  const ALL_STARTUPS = gql`
  query allStartupsWithTitle {
    allStartupsWithTitle{
        id
        title
    }
  }
  `;
  const { loading, data } = useQuery(ALL_STARTUPS, {
    fetchPolicy:"network-only",
    variables: {},
  });

  React.useEffect(() => {
    if(!loading){
        if(data){
          setStartups(data.allStartupsWithTitle)
        }
    }
},
[data, loading])

  const handleSubmit = variables => {
    let tempSp = []

    variables.startupIds.forEach(element => {
      tempSp.push(element.id)
    });
    variables.startupIds = tempSp

    props.createEvent({ variables })
      .then(data => {
        if (data.data.createEvent) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Add Event</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
          validationSchema={eventSchema}
          initialValues={{
            name: '',
            startDate: '',
            endDate: '',
            link: '',
            startupIds:[],
          }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Name</Typography> 
                                    <FormikTextField name="name" style={{width:'100%'}} type="text"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Start Date</Typography> 
                                    <FormikTextField name="startDate" style={{width:'100%'}} type="datetime-local"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>End Date</Typography> 
                                    <FormikTextField name="endDate" style={{width:'100%'}} type="datetime-local"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Startups</Typography> 
                                    <Field component={FormikStartupDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="startupIds" options={startups}></Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Link</Typography> 
                                    <FormikTextField name="link" validate={validateLinkedinUrl} style={{width:'100%'}} type="text"/>
                                  </Grid>
                                  <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                    <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} disabled={!props.isValid} onClick={() => handleSubmit(props.values)} >Add Event</Button>
                                  </Grid>
                                </Grid> 
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

AddEventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function EditEventDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, events } = props;
  const [startups, setStartups] = React.useState();
  
  const ALL_STARTUPS = gql`
  query allStartupsWithTitle {
    allStartupsWithTitle{
        id
        title
    }
  }
  `;
  const { loading, data } = useQuery(ALL_STARTUPS, {
    fetchPolicy:"network-only",
    variables: {},
  });

  React.useEffect(() => {
    if(!loading){
        if(data){
          setStartups(data.allStartupsWithTitle)
        }
    }
},
[data, loading])

  const handleSubmit = variables => {
    let tempSp = []

    variables.startupIds.forEach(element => {
      tempSp.push(element.id)
    });
    variables.startupIds = tempSp

    props.editEvent({ variables })
      .then(data => {
        if (data.data.editEvent) {
          refetch()
          onClose(null);
        } 
      })
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog style={{padding:20}} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle style={{marginTop:25}} id="simple-dialog-title">
        <Typography align="center" variant="h4">Edit Event</Typography>
      </DialogTitle>
      <Grid container justify="center" style={{marginBottom:50}} xs={12}>
        <Grid container justify="space-between" xs={9}>
        <Formik
          validationSchema={eventSchema}
          initialValues={{
            eventId: events.id,
            name: events.name,
            startDate: moment(parseInt(events.startDate)).format("YYYY-MM-DDTkk:mm"),
            endDate: moment(parseInt(events.endDate)).format("YYYY-MM-DDTkk:mm"),
            link: events.link,
            startupIds:events.startups,
          }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid item container alignItems="flex-end">
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Name</Typography> 
                                    <FormikTextField name="name" style={{width:'100%'}} type="text"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Start Date</Typography> 
                                    <FormikTextField name="startDate" style={{width:'100%'}} type="datetime-local"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>End Date</Typography> 
                                    <FormikTextField name="endDate" style={{width:'100%'}} type="datetime-local"/>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Startups</Typography> 
                                    <Field component={FormikStartupDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="startupIds" options={startups}></Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h6" style={{width:'100%'}}>Link</Typography> 
                                    <FormikTextField name="link" validate={validateLinkedinUrl} style={{width:'100%'}} type="text"/>
                                  </Grid>
                                  <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                    <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} disabled={!props.isValid} onClick={() => handleSubmit(props.values)} >Edit Event</Button>
                                  </Grid>
                                </Grid> 
                              </Grid>     
                            </Grid>            
                          </Form> 
                         )}
                       </Formik> 
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditEventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default function SuperAdminEvents(props){
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [order, setOrder] = React.useState("id__DESC");
  const [events, setEvents] = React.useState();
  const [addEvent, setAddEvent] = React.useState();

  const ALL_EVENTS = gql`
  query adminAllEvents($orderBy:[String], $page: Int, $pageSize: Int) {
    adminAllEvents(orderBy:$orderBy, pagination:{page:$page, pageSize:$pageSize}){
        count
        pageCount
        events{
          id
          startups{
            id
            title
          }
          name
          link
          startDate
          endDate
          status
        }
      }

  }
  `;
  const { loading, data, refetch } = useQuery(ALL_EVENTS, {
    fetchPolicy:"network-only",
    variables: {orderBy:order, page:page, pageSize:pageSize},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          setEvents(data.adminAllEvents.events)
          setPageCount(data.adminAllEvents.pageCount)
        }
    }
},
[data, loading])
    const classes = useStyles();

    const handlePageChange = (event, value) => {
      window.scrollTo(0,0);
      setPage(value);
    };
    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
            <Grid container className={classes.mainGrid} style={{background:'#f7f7fc'}}>
              <LeftNavigation>
              </LeftNavigation>
              <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12}  style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className={classes.topPart}>
                        <Typography variant="h4"> Events </Typography>
                    </Grid>
                    <Grid container xs={12} style={{margin:'50px 0'}}>
                        <Grid item container xs sm={12} className={classes.rightPart}>
                          <Grid item container justify="space-between" alignItems="center" style={{margin:'20px 0', padding:'0 40px'}}>
                            <Typography variant="h5" style={{fontSize:18}}>Events</Typography>
                            <Grid item style={{display:"flex"}} >
                              <Grid item>
                                <Button variant="outlinedPrimary" onClick={() => setAddEvent(true)} className={classes.investorButton}> Add Event </Button>
                                <AddEventDialog createEvent={props.createEvent} refetch={refetch} open={addEvent} onClose={() => setAddEvent(false)}/>
                              </Grid>
                            </Grid>
                          </Grid>
                          <TableContainer style={{boxShadow:'none'}} component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell style={{paddingLeft:40}}>EVENT NAME</StyledTableCell>
                                  <StyledTableCell align="left">START DATE</StyledTableCell>
                                  <StyledTableCell align="left">END DATE</StyledTableCell>
                                  <StyledTableCell align="left">EVENT LINK</StyledTableCell>
                                  <StyledTableCell style={{width:100}} align="left">EVENT STATUS</StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                              {events &&  
                              <TableBody>
                                {events.map((row) => (
                                  <ExpandingRow createEvent={props.createEvent} editEvent={props.editEvent} removeEvent={props.removeEvent} row={row} refetch={refetch} /> 
                              ))}
                              </TableBody>
                              } 
                             
                            </Table>
                          </TableContainer>
                          <Grid container item style={{margin:'30px 0',paddingRight:35}} justify="flex-end">
                               <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                           variant="outlined" color="primary" />
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}