import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import logo from '../../static/img/new-ae-logo.png';
import idea from '../../static/img/idea.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Hidden from '@material-ui/core/Hidden';
import TextField from '@material-ui/core/TextField';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import Button from '@material-ui/core/Button';
import Wizard from './Wizard.js';
import success from '../../static/img/success.png';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format';
import Dropzone from 'react-dropzone';
import {Field, FieldArray, useField} from 'formik';
import {useQuery} from '@apollo/react-hooks';
import {Link} from 'react-router-dom';
import gql from 'graphql-tag';
import {fieldToTextField} from 'formik-material-ui';
import ImageCrop from "../../Components/ImageCrop";
import Checkbox from "@material-ui/core/Checkbox";
import {RichEditor} from "../../Components/RichEditor/RichEditor.js";
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'

const useStyles = makeStyles((theme) => ({
    baseStyle: {
        flex: 1,
        marginTop: 10,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '150px',
        width: '150px',
        borderWidth: 2,
        borderRadius: 7,
        borderColor: '#dddddd',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#172b4d',
        fontWeight: '600',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },

    baseStyleFile: {
        flex: 1,
        marginTop: 10,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 72,
        borderWidth: 2,
        borderRadius: 7,
        borderColor: '#dddddd',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#172b4d',
        fontWeight: '600',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },

    logo: {
        width: 150,
        height: 50,
        [theme.breakpoints.down('md')]: {
            marginTop: '30px'
        },
    },
    multiSelect: {
        '& div': {
            background: 'none!important',
        },
    },
    startImage: {
        width: '100%',
        objectFit: 'contain',
        marginTop: 60
    },
    contactInfo: {
        textDecoration: 'underline',
        marginLeft: 5,
        fontWeight: 'normal',
    },
    inputSpace: {
        marginTop: 30
    },
    inputField: {
        width: '100%',
        marginTop: 15,
    },
    applicationCard: {
        background: '#ffffff',
        marginTop: 100,
        marginBottom: 50,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 100
        },
    },
    AddButton: {
        marginTop: 30,
        fontWeight: 'normal',
        padding: '8px 32pxsad'
    },
    tableRows: {
        border: '1px solid #f7f7fc',
        borderRadius: '7px'
    },
    SpacingName: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '0!important',
        }
    },
    SpacingSurname: {
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0!important',
        }
    },
    toolbar: {
        padding: '0px 100px',
    },

}));
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
}
const FormikRadioGroup = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" {...field} {...props}>
                    {props.values.map(value => (
                        <FormControlLabel style={{ marginRight: 30 }} value={value} control={<Radio color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label={value} />
                    ))}
                </RadioGroup>
            </FormControl>
            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}
const FormikCheckBox = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
        <>
            <FormControlLabel style={{ marginRight: 10 }} control={<Checkbox {...field} {...props} checked={field.value} icon={<CircleUnchecked />}
                checkedIcon={<CircleCheckedFilled />} color="primary" />} label={label} />

            {meta.touched && meta.error ? (
                <Typography className={classes.label} style={{ color: '#FF0000' }} variant="subtitle2">
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
};

function NumberFormatPercentage(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            isAllowed={(values) => {
                if (values) {
                    let { floatValue } = values;
                    if (floatValue === undefined) {
                        floatValue = 0;
                    }
                    return floatValue >= 0 && floatValue <= 100;
                }
            }}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            suffix="%"
        />
    );
}

const FormikPercentageField = ({ type, label, inputProps, maxLen, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        textmask: '(1  )    -    ',
        numberformat: '1320',
    });
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <>
            <Grid style={{ minHeight: 95 }}>
                <TextField
                    variant="outlined"
                    className={classes.inputField}
                    {...field}
                    {...props}
                    inputProps={{
                        maxLength: maxLen
                    }}
                    InputProps={{
                        inputComponent: NumberFormatPercentage,
                    }}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                />

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </Grid>
        </>
    );
}
function validateLinkedinUrl(value) {
    if (value !== '' && value !== null && value !== undefined) {
        let error;
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        error = !pattern.test(value) ? 'Not valid URL.' : false;
        return error;
    }
    return false;
}
function FounderPart(props) {
    const { index, remove, form } = props;
    const classes = useStyles();
    const [files, setFiles] = React.useState([]);
    const companyType = ['Startup Experience', 'Corporate Experience'];
    const role = ['CEO', 'CTO', 'CMO', 'Designer', 'Other'];
    const startupExperience = ['Founder', 'Team Member'];
    const corporateExperience = ['Manager', 'Employee'];
    const companyStatus = ['Alive', 'Dead'];
    const yesNo = ['Yes', 'No'];
    const [openPhoto, setOpenPhoto] = React.useState(false);

    const FormikRoleDropdown = ({ textFieldProps, ...props }) => {
        const [meta] = useField(props);
        const { form: { setTouched, setFieldValue } } = props;
        const { ...field } = fieldToTextField(props);
        const { name } = field;
        const classes = useStyles();
        return (
            <>
                <Autocomplete
                    style={{ marginTop: 15 }}
                    {...props}
                    {...field}
                    className={classes.multiSelect}
                    onChange={(_, value) => setFieldValue(name, value)}
                    onBlur={() => setTouched({ [name]: true })}
                    renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Role" />}
                >
                </Autocomplete>
    
                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </>
        );
    }

    const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 7,
        objectFit: 'contain'
    };

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 7,
        border: '1px solid #eaeaea',
        maxWidth: 135,
        height: 150,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    return (
        <Grid
            item
            xs={12}
            md={6}
            container
            justify="center"
            style={{ height: 'fit-content', paddingTop: 0 }}
        >
            <Grid xs={12} container justify="flex-end">
                <Button
                    variant="containedSecondary"
                    style={{
                        padding: '4.5px 15px',
                        fontSize: 14,
                        marginBottom: 5,
                        height: 'fit-content',
                    }}
                    onClick={() => remove(index)}
                >
                    Remove
                </Button>
            </Grid>
            <Grid
                item
                container
                justify="center"
                xs={12}
                style={{
                    border: 'solid 1px #172b4d',
                    borderRadius: 7,
                    height: 'fit-content',
                }}
            >
                <Grid item xs={11}>
                    <Grid item container spacing={3} style={{ marginTop: 0 }}>
                        <Grid item sm={4} xs={12}>
                            <Dropzone
                                maxSize={20971520}
                                accept={'image/*'}
                                onDrop={(acceptedFiles) =>
                                    setFiles(
                                        acceptedFiles.map((file) =>
                                            Object.assign(file, { preview: URL.createObjectURL(file) }),
                                        ),
                                        form.setFieldValue(
                                            `founderMembers.${index}.profilePhoto`,
                                            acceptedFiles,
                                            setOpenPhoto(true),
                                        ),
                                    )
                                }
                            >
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                    <section style={{ textAlign: 'center' }}>
                                        <div {...getRootProps({ className: classes.baseStyle })}>
                                            <input {...getInputProps()} />
                                            {console.log(form.values.founderMembers[index], 'photo')}
                                            {!form.values.founderMembers[index].profilePhoto ? (
                                                <Typography variant="h6" style={{ display: 'flex' }}>
                                                    {' '}
                                                Add Picture
                                                </Typography>
                                            ) : (
                                                <aside style={thumbsContainer}>
                                                    {acceptedFiles[0] && (
                                                        <div
                                                            id={form.values.founderMembers[index].profilePhoto[0].name}
                                                            style={thumb}
                                                            key={form.values.founderMembers[index].profilePhoto[0].name}
                                                        >
                                                            <div style={thumbInner}>
                                                                <img
                                                                    src={form.values.founderMembers[index].profilePhoto[0].preview}
                                                                    style={img}
                                                                    alt="file"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {form.values.founderMembers[index].profilePhoto && !acceptedFiles[0] && (
                                                        <div
                                                            id={form.values.founderMembers[index].profilePhoto}
                                                            style={thumb}
                                                            key={form.values.founderMembers[index].profilePhoto}
                                                        >
                                                            <div style={thumbInner}>
                                                                <img
                                                                    src={form.values.founderMembers[index].profilePhoto}
                                                                    style={img}
                                                                    alt="file"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </aside>
                                            )}
                                        </div>
                                        {form.values.founderMembers[index].profilePhoto && (
                                            <Button
                                                variant="containedSecondary"
                                                style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5 }}
                                                onClick={() =>
                                                    form.setFieldValue(
                                                        `founderMembers.${index}.profilePhoto`,
                                                        '',
                                                    )
                                                }
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </section>
                                )}
                            </Dropzone>
                        </Grid>
                        <Field>
                            {({ field, form, meta }) => (
                                <ImageCrop
                                    setFieldValue={form.setFieldValue}
                                    files={files}
                                    index={index}
                                    founder={form.values.founderMembers[index]}
                                    setOpenPhoto={setOpenPhoto}
                                    openPhoto={openPhoto}
                                />
                            )}
                        </Field>
                        <Grid item sm={8} xs={12} style={{ paddingBottom: 0 }}>
                            <FormikTextField
                                placeholder="Name Surname"
                                name={`founderMembers.${index}.nameSurname`}
                                style={{ paddingBottom: 0 }}
                            ></FormikTextField>
                            <Field component={FormikRoleDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.jobTitle`} options={role}></Field>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <FormikTextField
                                placeholder="https://linkedin.com/in/"
                                validate={validateLinkedinUrl}
                                name={`founderMembers.${index}.socialLinkedIn`}
                                style={{ paddingBottom: 0 }}
                            ></FormikTextField>
                        </Grid>
                        <FieldArray
                            name={`founderMembers.${index}.career`}
                            render={({ insert, remove, push, form }) => (
                                <>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        justify="space-between"
                                        style={{ paddingTop: 0 }}
                                    >
                                        <Typography
                                            color="textSecondary"
                                            variant="h6"
                                            style={{ marginLeft: 5 }}
                                        >
                                            Career
                                        </Typography>
                                        <Button
                                            variant="containedSecondary"
                                            style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                                            onClick={() =>
                                                push({
                                                    typeStartOrCorporate: '',
                                                    company: '',
                                                    jobTitle: '',
                                                })
                                            }
                                        >
                                            Add Career
                                        </Button>
                                    </Grid>
                                    {form.values.founderMembers && form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career.map((label, ind) => (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            style={{ paddingTop: 0, marginBottom: 40 }}
                                        >
                                            <Grid
                                                item
                                                container
                                                justify="center"
                                                xs={12}
                                                style={{ border: 'solid 1px #172b4d', borderRadius: 7 }}
                                            >
                                                <Button
                                                    variant="containedSecondary"
                                                    style={{
                                                        padding: '4.5px 15px',
                                                        fontSize: 14,
                                                        marginBottom: 5,
                                                        marginTop: 10,
                                                    }}
                                                    onClick={() => remove(ind)}
                                                >
                                                    Remove
                                                </Button>
                                                <Grid
                                                    item
                                                    container
                                                    spacing={2}
                                                    xs={11}
                                                    style={{ marginTop: 15 }}
                                                >
                                                    <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                        <FormikRadioGroup
                                                            name={`founderMembers.${index}.career.${ind}.typeStartOrCorporate`}
                                                            values={companyType}
                                                        ></FormikRadioGroup>
                                                    </Grid>
                                                    {form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career[ind].typeStartOrCorporate &&
                                                        <>
                                                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                                <FormikTextField
                                                                    placeholder="Company Name"
                                                                    name={`founderMembers.${index}.career.${ind}.company`}
                                                                    style={{ paddingTop: 0 }}
                                                                ></FormikTextField>
                                                            </Grid>
                                                            {form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career[ind].typeStartOrCorporate === 'Startup Experience' ?
                                                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                                    <Field component={FormikNewDropdownExperience} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.career.${ind}.jobTitle`} options={startupExperience}></Field>
                                                                </Grid>
                                                                :
                                                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                                    <Field component={FormikNewDropdownExperience} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.career.${ind}.jobTitle`} options={corporateExperience}></Field>
                                                                </Grid>

                                                            }
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </>
                            )}
                        />
                        <Grid
                            container
                            item
                            xs={12}
                            justify="space-between"
                            style={{ paddingTop: 0 }}
                        >
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                style={{ marginLeft: 5 }}
                            >
                                Exit
                            </Typography>
                            <Grid
                                item
                                container
                                xs={12}
                                style={{ marginTop: 12, marginBottom: 40 }}
                            >
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    xs={12}
                                    style={{ border: 'solid 1px #172b4d', borderRadius: 7 }}
                                >
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        xs={11}
                                        style={{ padding: '10px 0' }}
                                    >
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="body1" style={{ marginTop: 12 }}>Has he/she made an exit before?</Typography>
                                            <FormikRadioGroup
                                                name={`founderMembers.${index}.madeExit`}
                                                values={yesNo}
                                            ></FormikRadioGroup>
                                        </Grid>
                                        {form.values.founderMembers[index].madeExit === 'Yes' &&
                                            <>
                                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                    <FormikTextField
                                                        placeholder="Company Name"
                                                        name={`founderMembers.${index}.exitedCompany`}
                                                        style={{ paddingTop: 0 }}
                                                    ></FormikTextField>
                                                </Grid>
                                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                    <Field component={FormikNewDropdownExit} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.exitedCompanyStatus`} options={companyStatus}></Field>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
const FormikTextField = ({ type, autofocus, label, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
        <>
            <Grid style={{ minHeight: 95 }}>
                {disabled ? <TextField
                    variant="outlined"
                    disabled
                    className={classes.inputField}
                    {...field}
                    {...props}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                    type={type}

                /> : <TextField
                    variant="outlined"
                    className={classes.inputField}
                    {...field}
                    {...props}
                    inputProps={{ maxLength: props.maxLength }}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                    type={type}
                    data-date-inline-picker="true"
                    autoFocus={autofocus} />}

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </Grid>
        </>
    );
}
const FormikNumberField = ({ type, label, inputProps, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
        textmask: '(1  )    -    ',
        numberformat: '1320',
    });
    return (
        <>
            <Grid style={{ minHeight: 95 }}>
                <TextField
                    variant="outlined"
                    className={classes.inputField}
                    {...field}
                    {...props}
                    inputProps={{
                        maxLength: 15
                    }}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                    }}
                    error={meta.touched && meta.error}
                    placeholder={props.placeholder}
                />

                {meta.touched && meta.error ? (
                    <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                        {meta.error}
                    </Typography>
                ) : null}
            </Grid>
        </>
    );
}

const FormikNewDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
                style={{ marginTop: 15 }}
                {...props}
                {...field}
                className={classes.multiSelect}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Please Select" />}
            >
            </Autocomplete>

            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}
const FormikNewDropdownExperience = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
                style={{ marginTop: 15 }}
                {...props}
                {...field}
                className={classes.multiSelect}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Role" />}
            >
            </Autocomplete>

            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}
const FormikNewDropdownExit = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
                style={{ marginTop: 15 }}
                {...props}
                {...field}
                className={classes.multiSelect}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Company Status" />}
            >
            </Autocomplete>

            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}
const FormikCountryDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
                style={{ marginTop: 15 }}
                {...props}
                {...field}
                options={props.options}
                getOptionLabel={(option) => option.title}
                className={classes.multiSelect}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                renderInput={(props) => <TextField {...props} label="Please Select"  {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>

            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}
const FormikMultiSelect = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
                multiple
                style={{ marginTop: 15 }}
                {...props}
                {...field}
                disableCloseOnSelect
                className={classes.multiSelect}
                onChange={(_, value) => setFieldValue(name, value)}
                onBlur={() => setTouched({ [name]: true })}
                renderInput={(props) => <TextField {...props} label="Please Select"  {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>

            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                    {meta.error}
                </Typography>
            ) : null}
        </>
    );
}

function sumFund(payrollFA, marketingFA, salesFA, developmentFA, rdFA, otherFA) {
    if (payrollFA === null || payrollFA === '') {
        payrollFA = "0"
    }
    if (marketingFA === null || marketingFA === '') {
        marketingFA = "0"
    }
    if (salesFA === null || salesFA === '') {
        salesFA = "0"
    }
    if (developmentFA === null || developmentFA === '') {
        developmentFA = "0"
    }
    if (rdFA === null || rdFA === '') {
        rdFA = "0"
    }
    if (otherFA === null || otherFA === '') {
        otherFA = "0"
    }
    return parseInt(payrollFA) + parseInt(marketingFA) + parseInt(salesFA) + parseInt(developmentFA) + parseInt(rdFA) + parseInt(otherFA)
}

export default function StartupApplicationForm(props) {
    const competitors = ['1-3', '4-10', '10+'];
    const stage = ['Idea', 'Prototype', 'MVP (Minimum Viable Product)', 'Early', 'Scale-up', 'Growth',];
    const businessModel = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
    const industry = ['Agriculture', 'Biotechnology', 'Business Services', 'Clean Technology', 'Construction', 'Consulting', 'Consumer Products', 'Consumer Services', 'Digital Marketing',
        'Education', 'Electronics / Instrumentation', 'Fashion', 'Financial Services', 'Fintech', 'Food and Beverage', 'Gaming', 'Healthcare Services', 'Energy', 'Internet / Web Services', 'IT Services',
        'Legal', 'Lifestyle', 'Marine', 'Maritime/Shipping', 'Marketing / Advertising', 'Media and Entertainment', 'Medical Devices and Equipment', 'Mobility', 'Nanotechnology', 'Other', 'Real Estate',
        'Retailing / Distribution', 'Robotics', 'Security', 'Software', 'Sports', 'Telecommunications', 'Transportation', 'Travel']
    const need = ['0 - 10.000', '11.000 - 25.000', '26.000 - 50.000', '51.000 - 100.000', '101.000 - 250.000', '251.000 - 500.000', '501.000 - 1.000.000', '+1.000.000'];
    const yesNo = ['Yes', 'No',];
    const revenueRecurringTypeMRR = ['MRR', 'ARR'];
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [berk, setBerk] = React.useState(0);
    const [pitchDeckName, setPitchDeckName] = React.useState();
    const [businessPlanName, setBusinessPlanName] = React.useState();
    const [country, setCountry] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [openPhoto, setOpenPhoto] = React.useState(false);
    const [initialValuess, setInitialValuess] = React.useState(
        {
            id: 0,
            firstName: '',
            lastName: '',
            jobTitle: '',
            email: '',
            linkedin: '',
            founderSize: '',
            teamMemberSize: '',
            foundersPrevStartupExp: '',
            foundersPrevCorporateExp: '',
            exitedCompany: '',
            exitedCompany2: '',
            title: '',
            industry: [],
            founderMembers: [{
                jobTitle: '',
                nameSurname: '',
                madeExit: '',
                exitedCompany: '',
            }],
            description: '',
            stage: '',
            businessModel: '',
            country: '',
            pitchDeck: null,
            businessPlan: null,
            haveCustomer: '',
            havePayingCustomer: '',
            competitorHave: '',
            competitorAmount: '',
            marketTotalSize: '',
            marketAddressableSize: '',
            marketTargetRegion: '',
            investmentRaised: '',
            selectedValue: '',
            investmentRaisedAmount: 0,
            investmentNeededDD: '',
            establishedDate: '',
            postMoneyValuation: 0,
            applicationStepFA: 0,

        }
    );

    const img = {
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 7,
        objectFit: 'contain'
    };

    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
        flexWrap: 'wrap',
    };

    const thumb = {
        display: 'inline-flex',
        borderRadius: 7,
        border: '1px solid #eaeaea',
        maxWidth: 135,
        height: 150,
        padding: 4,
        boxSizing: 'border-box'
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const MY_STARTUP = gql`
    query myStartup {
        myStartup{
            id
            logo
            founderSize
            teamMemberSize
            foundersPrevStartupExp
            foundersPrevCorporateExp
            title
            industry
            founderMembers{
                profilePhoto
                jobTitle
                nameSurname
                madeExit
                socialLinkedIn
                exitedCompany
                exitedCompanyStatus
                career{
                    typeStartOrCorporate
                    company
                    jobTitle
                }
            }
            otherMembers{
                jobTitle
                nameSurname
                socialLinkedIn
            }
            description
            stage
            businessModel
            country{
                id
                title
            }
            haveCustomer
            havePayingCustomer
            competitorHave
            competitorAmount
            marketTotalSize
            marketAddressableSize
            marketTargetRegion
            pitchDeck
            businessPlan
            investmentRaised
            investmentRaisedAmount
            investmentNeeded
            establishedDate
            postMoneyValuation
            applicationStage
            applicationStepFA
            totalRevenueGrowth
            firstCompetitorsName
            firstCompetitorsWebsite
            secondCompetitorsName
            secondCompetitorsWebsite
            thirdCompetitorsName
            thirdCompetitorsWebsite
            roundPercent
            sharePercent
            website
            productProblem
            productSolution
            averageRevenueGrowth
            revenueRecurring
            revenueRecurringType
            productDifFromComp
            investmentNeededDD
            payrollFA
            salesFA
            rdFA
            marketingFA
            developmentFA
            otherFA
        }

    }
    `;
    const ALL_COUNTRIES = gql`
    query allCountries {
        allCountries{
            id
            title
        }

    }
    `;
    const { loading, data, refetch } = useQuery(MY_STARTUP, {
        variables: {},
    });
    const { loading: loading2, data: data2 } = useQuery(ALL_COUNTRIES, {
        variables: {},
    });
    React.useEffect(() => {
        if (!loading) {
            if (data) {
                setPitchDeckName(data.myStartup.pitchDeck)
                setBusinessPlanName(data.myStartup.businessPlan)
                if (data.myStartup.productDifFromComp) {
                    let htmlDiff = data.myStartup.productDifFromComp
                    if (typeof (data.myStartup.productDifFromComp) === 'string') {
                        data.myStartup.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlDiff))
                    }
                }
                else { data.myStartup.productDifFromComp = new EditorState.createEmpty() }
                if (data.myStartup.productProblem) {
                    let htmlProduct = data.myStartup.productProblem
                    if (typeof (data.myStartup.productProblem) === 'string') {
                        data.myStartup.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else { data.myStartup.productProblem = new EditorState.createEmpty() }
                if (data.myStartup.productSolution) {
                    let htmlSolution = data.myStartup.productSolution
                    if (typeof (data.myStartup.productSolution) === 'string') {
                        data.myStartup.productSolution = EditorState.createWithContent(stateFromHTML(htmlSolution))
                    }
                }
                else { data.myStartup.productSolution = new EditorState.createEmpty() }
                data.myStartup.reCaptcha = "";
                data.myStartup.selectedValue = "yes"
                if (data.myStartup.industry === null) {
                    data.myStartup.industry = [];
                }
                if (data.myStartup.founderMembers !== []) {
                    const newArray = data.myStartup.founderMembers.map(({ __typename, ...value }) => value)
                    data.myStartup.founderMembers = newArray
                    data.myStartup.founderMembers.forEach(function (v) {
                        if (v.career !== null) {
                            v.career.forEach(function (a) {
                                if (a !== null) {
                                    delete a.__typename
                                }

                            })
                        }
                        else {
                            v.career = [];
                        }
                    });
                }
                setBerk(data.myStartup.applicationStepFA)
                setInitialValuess(data.myStartup)
            }
        }
        if (!loading2) {
            if (data2) {
                setCountry(data2.allCountries)
            }
        }
    },
        [data, loading, data2, loading2])
    function validateLinkedinUrl(value) {
        if (value !== '' && value !== null && value !== undefined) {
            let error;
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            error = !pattern.test(value) ? 'Not valid URL.' : false;
            return error;
        }
        return false;
    }
    const classes = useStyles();
    return (
        <div>

            <Hidden mdDown>
                <AppBar position="static" style={{ background: '#ffffff' }} elevation={1}>
                    <Toolbar variant="dense" disableGutters className={classes.toolbar}>
                        <img src={logo} className={classes.logo} alt="angel-effect"></img>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <Hidden mdUp>
                <Grid container justify="center" style={{ background: '#ffffff', paddingBottom: 30 }}>
                    <Link to="/" style={{ padding: 0 }}>
                        <img src={logo} alt="angel-effect" className={classes.logo} style={{ marginRight: '10px' }}></img>
                    </Link>
                </Grid>
            </Hidden>
            <Grid container justify="center">
                <Grid className={classes.applicationCard} container xs={12} lg={10} xl={9}>
                    {!loading && <Wizard
                        initialValues={initialValuess}
                        registerStartupContact={props.registerStartupContact}
                        firstApplicationStartup={props.firstApplicationStartup}
                        refetch={refetch}
                        uploadFile={props.uploadFile}
                        enableReinitialize={true}
                        berk={berk}
                        onSubmit={async (values) => {
                        }}
                    >

                        <Wizard.Page>
                            <Grid item container xs={12} md={6} style={{ marginTop: 75 }} justify="center">
                                <Grid item xs={8}>
                                    <Typography variant="h4" align="center">
                                        Startup Application
                            </Typography>
                                    <Typography variant="body1" color="secondary" style={{ marginTop: 30 }}>
                                        Welcome! Please fill in the blanks for us to get to know
                                        you better and to contact you as soon as possible.
                            </Typography>
                                    <img src={success} alt="start-image" className={classes.startImage}></img>
                                    <Grid item container style={{ marginTop: 50 }}>
                                        <Grid container xs={12} md={6}>
                                            <Typography variant="h6" >
                                                Email:
                                            </Typography>
                                            <Typography variant="h6" color="secondary" className={classes.contactInfo}>
                                                info@angeleffect.co
                                            </Typography>
                                        </Grid>
                                        <Grid container xs={12} md={6}>
                                            <Typography variant="h6" >
                                                For Questions:
                                            </Typography>
                                            <Typography variant="h6" color="secondary" className={classes.contactInfo}>
                                                Go to FAQ page
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} md={6} style={{ marginTop: 75 }} justify="center">
                                <Grid item xs={8}>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                                        Contact Informations
                                    </Typography>
                                    <Grid item container spacing={3}>
                                        <Grid item container xs={12} md={6} className={classes.SpacingName}>
                                            <Typography variant="h5" className={classes.inputSpace}>
                                                First Name <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <FormikTextField name="firstName" placeholder="First Name"></FormikTextField>
                                        </Grid>
                                        <Grid item container xs={12} md={6} className={classes.SpacingSurname}>
                                            <Typography variant="h5" className={classes.inputSpace}>
                                                Last Name <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <FormikTextField name="lastName" placeholder="Last Name"></FormikTextField>
                                        </Grid>
                                    </Grid>
                                    <Typography variant="h5">
                                        Role  <span style={{ color: '#28b1e7' }}>*</span>
                                    </Typography>
                                    <FormikTextField name="jobTitle" placeholder="Role"></FormikTextField>
                                    <Typography variant="h5">
                                        Email Address  <span style={{ color: '#28b1e7' }}>*</span>
                                    </Typography>
                                    <FormikTextField name="email" placeholder="Email Address"></FormikTextField>
                                    <Typography variant="h5">
                                        Linkedin  <span style={{ color: '#28b1e7' }}>*</span>
                                    </Typography>
                                    <FormikTextField name="linkedin" placeholder="Linkedin"
                                        validate={validateLinkedinUrl}></FormikTextField>

                                </Grid>
                            </Grid>
                        </Wizard.Page>
                        <Wizard.Page>
                            <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                                <Grid item xs={10} xl={9}>
                                    <Typography variant="h4" style={{ marginBottom: 50 }}>
                                        Team Informations
                                    </Typography>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                                        Founding Team
                                    </Typography>
                                    <FieldArray
                                        name="founderMembers"
                                        render={({ insert, remove, push, form }) => (
                                            <div>
                                                {form.values.founderMembers.length < 6 && (
                                                    <Button
                                                        variant="containedSecondary"
                                                        className={classes.AddButton}
                                                        onClick={() =>
                                                            push({
                                                                jobTitle: '',
                                                                nameSurname: '',
                                                                socialLinkedIn: '',
                                                                profilePhoto: null,
                                                                career: [],
                                                            })
                                                        }
                                                    >
                                                        Add Member
                                                    </Button>
                                                )}
                                                <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                                    {form.values.founderMembers.length > 0 &&
                                                        form.values.founderMembers.map((teamMember, index) => (
                                                            <FounderPart
                                                                index={index}
                                                                remove={remove}
                                                                form={form}
                                                            />
                                                        ))}
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                    <Typography variant="h4" style={{ marginBottom: 10, marginTop: 50 }}>
                                        Other Team Members
                                    </Typography>
                                    <FieldArray
                                        name="otherMembers"
                                        render={({ insert, remove, push, form }) => (
                                            <div>
                                                <Button
                                                    variant="containedSecondary"
                                                    className={classes.AddButton}
                                                    onClick={() => push({ jobTitle: '', nameSurname: '', socialLinkedIn: '' })}
                                                >
                                                    Add Member
                                                </Button>
                                                <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                                    {form.values.otherMembers && form.values.otherMembers.length > 0 &&
                                                        form.values.otherMembers.map((otherMember, index) => (
                                                            <Grid item xs={12} md={6} container justify="center">
                                                                <Grid xs={12} container justify="flex-end">
                                                                    <Button
                                                                        variant="containedSecondary"
                                                                        style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    justify="center"
                                                                    xs={12}
                                                                    style={{ border: 'solid 1px #dddddd', borderRadius: 7 }}
                                                                >
                                                                    <Grid item xs={11} style={{ marginTop: 10 }}>
                                                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                                            <FormikTextField
                                                                                placeholder="Name Surname"
                                                                                name={`otherMembers.${index}.nameSurname`}
                                                                                style={{ paddingTop: 0 }}
                                                                            ></FormikTextField>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                                            <FormikTextField
                                                                                placeholder="Role"
                                                                                name={`otherMembers.${index}.jobTitle`}
                                                                                style={{ paddingTop: 0 }}
                                                                            ></FormikTextField>
                                                                        </Grid>
                                                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                                            <FormikTextField
                                                                                placeholder="https://linkedin.com/in/"
                                                                                validate={validateLinkedinUrl}
                                                                                name={`otherMembers.${index}.socialLinkedIn`}
                                                                                style={{ paddingTop: 0 }}
                                                                            ></FormikTextField>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Grid>
                                            </div>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Wizard.Page>
                        <Wizard.Page>
                            <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                                <Grid item xs={10} xl={9}>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                                        Company Informations
                                    </Typography>
                                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                        <Grid item xs={12} md={2}>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    <Dropzone
                                                        maxSize={20971520}
                                                        accept={'image/*'}
                                                        onDrop={(acceptedFiles) =>
                                                            setFiles(
                                                                acceptedFiles.map((file) =>
                                                                    Object.assign(file, { preview: URL.createObjectURL(file) }),
                                                                ),
                                                                form.setFieldValue(`logo`,
                                                                    acceptedFiles,
                                                                    setOpenPhoto(true),
                                                                ),
                                                            )
                                                        }
                                                    >
                                                        {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                            <section style={{ textAlign: 'center' }}>
                                                                <div {...getRootProps({ className: classes.baseStyle })}>
                                                                    <input {...getInputProps()} />
                                                                    {!form.values.logo ? (
                                                                        <Typography variant="h6" style={{ display: 'flex' }}>
                                                                            {' '}
                                                                        Add Picture
                                                                        </Typography>
                                                                    ) : (
                                                                        <aside style={thumbsContainer}>
                                                                            {acceptedFiles[0] && (
                                                                                <div
                                                                                    id={form.values.logo[0].name}
                                                                                    style={thumb}
                                                                                    key={form.values.logo[0].name}
                                                                                >
                                                                                    <div style={thumbInner}>
                                                                                        <img
                                                                                            src={form.values.logo[0].preview}
                                                                                            style={img}
                                                                                            alt="file"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                            {form.values.logo && !acceptedFiles[0] && (
                                                                                <div
                                                                                    id={form.values.logo}
                                                                                    style={thumb}
                                                                                    key={form.values.logo}
                                                                                >
                                                                                    <div style={thumbInner}>
                                                                                        <img
                                                                                            src={form.values.logo}
                                                                                            style={img}
                                                                                            alt="file"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </aside>
                                                                    )}
                                                                </div>
                                                                {form.values.logo && (
                                                                    <Button
                                                                        variant="containedSecondary"
                                                                        style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5 }}
                                                                        onClick={() =>
                                                                            form.setFieldValue(
                                                                                `logo`,
                                                                                '',
                                                                            )
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                )}
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                )}
                                            </Field>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    <ImageCrop
                                                        setFieldValue={form.setFieldValue}
                                                        files={files}
                                                        logo={form.values.logo}
                                                        setOpenPhoto={setOpenPhoto}
                                                        openPhoto={openPhoto}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <Typography variant="h5">
                                                Startup's Name <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <FormikTextField name="title" placeholder="Write Your Startup's Name"></FormikTextField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Company Website <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikTextField
                                                name="website"
                                                type="text"
                                                validate={validateLinkedinUrl}
                                                placeholder="https://"
                                            ></FormikTextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Describe your startup in one line.<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                                            </Typography>
                                            <FormikTextField name="description" maxLength="140" placeholder="Oneliner"></FormikTextField>
                                        </Grid>
                                        <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                                                            What problem do you solve? <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                                        </Typography>
                                                        <RichEditor
                                                            editorState={form.values.productProblem}
                                                            onChange={form.setFieldValue}
                                                            name="productProblem"
                                                            onBlur={form.handleBlur}
                                                        />
                                                    </Grid>
                                                )}
                                            </Field>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                                        {console.log(form.values.productSolution, 'val')}
                                                        <Typography variant="h5" style={{ marginBottom: 10 }}>
                                                            What is your solution to the addressed problem?<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                                        </Typography>
                                                        <RichEditor
                                                            editorState={form.values.productSolution}
                                                            onChange={form.setFieldValue}
                                                            name="productSolution"
                                                            onBlur={form.handleBlur}
                                                        />
                                                    </Grid>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Stage<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                                            </Typography>
                                            <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="stage" options={stage}></Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Business Model
                                            </Typography>
                                            <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="businessModel" options={businessModel}></Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Industry <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <Field component={FormikMultiSelect} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="industry" options={industry}></Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Headquarters Country <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <Field component={FormikCountryDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="country" options={country}></Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Pitch Deck <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <Field
                                                name="pitchDeck">{({ field, form, meta }) => (
                                                    <div>
                                                        <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("pitchDeck", acceptedFiles)}>
                                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                                <section>
                                                                    <div {...getRootProps({ className: classes.baseStyleFile })}>
                                                                        <input {...getInputProps()} />
                                                                        <Typography variant="h6" style={{ display: 'flex' }}><OpenInBrowser style={{ marginRight: '5px' }}></OpenInBrowser> Browse...</Typography>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        {form.values.pitchDeck ?
                                                            <Grid container justify="space-between" direction="row-reverse" style={{ marginBottom: 25 }}>
                                                                <Typography variant="body2" align="right"><span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded</Typography>
                                                                {(typeof form.values.pitchDeck !== 'string') ? <Typography variant="h6" align="left">{form.values.pitchDeck[0].name} is existing file</Typography> : <Typography variant="h6" align="left">{form.values.pitchDeck.split("/").pop().replace(".png", "")} is existing file</Typography>}
                                                            </Grid> :
                                                            <Grid container justify="space-between" direction="row-reverse" style={{ marginBottom: 25 }}>
                                                                <Typography variant="body2" align="right"><span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded</Typography>
                                                                {pitchDeckName &&
                                                                    <Typography variant="h6" align="left">{pitchDeckName.split("/").pop().replace(".png", "")} is existing file</Typography>}
                                                            </Grid>
                                                        }
                                                    </div>
                                                )}
                                            </Field>

                                            <Typography variant="h5" className={classes.inputSpace}>
                                                Business Plan <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <Field
                                                name="businessPlan">
                                                {({ field, form, meta }) => (
                                                    <div>
                                                        <Dropzone maxSize={20971520} onDrop={acceptedFiles => form.setFieldValue("businessPlan", acceptedFiles)}>
                                                            {({ getRootProps, getInputProps, acceptedFiles }) => (
                                                                <section>
                                                                    <div {...getRootProps({ className: classes.baseStyleFile })}>
                                                                        <input {...getInputProps()} />
                                                                        <Typography variant="h6" style={{ display: 'flex' }}><OpenInBrowser style={{ marginRight: '5px' }}></OpenInBrowser> Browse...</Typography>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                        {form.values.businessPlan ?
                                                            <Grid container justify="space-between" direction="row-reverse" style={{ marginBottom: 25 }}>
                                                                <Typography variant="body2" align="right"><span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded</Typography>
                                                                {(typeof form.values.businessPlan !== 'string') ? <Typography variant="h6" align="left">{form.values.businessPlan[0].name} is existing file</Typography> : <Typography variant="h6" align="left">{form.values.businessPlan.split("/").pop().replace(".png", "")} is existing file</Typography>}
                                                            </Grid> :
                                                            <Grid container justify="space-between" direction="row-reverse" style={{ marginBottom: 25 }}>
                                                                <Typography variant="body2" align="right"><span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded</Typography>
                                                                {businessPlanName &&
                                                                    <Typography variant="h6" align="left">{businessPlanName.split("/").pop().replace(".png", "")} is existing file</Typography>}
                                                            </Grid>
                                                        }
                                                    </div>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">
                                                Do you generate revenue?<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                                            </Typography>
                                            <Field name="havePayingCustomer">
                                                {({
                                                    field,
                                                    form,
                                                    meta,
                                                }) => (
                                                    <>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup row aria-label="position" {...field} {...props}>
                                                                {yesNo.map(value => (
                                                                    <FormControlLabel key={value} style={{ marginRight: 30 }} handleChange={form.values.haveCustomer === 'No' ? form.values.havePayingCustomer = 'No' : null} value={value} control={<Radio color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label={value} />
                                                                ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {meta.touched && meta.error ? (
                                                            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                                                                {meta.error}
                                                            </Typography>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Field>
                                        </Grid>
                                        { }
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Total revenue for the last 6 months? ($)
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikNumberField
                                                name="totalRevenueGrowth"
                                                onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                maxLen={15}
                                                placeholder="Average revenue growth"
                                            ></FormikNumberField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Avg. revenue growth per month for the last 6 months?
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikPercentageField name="averageRevenueGrowth" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="h5">
                                                        Do you have recurring revenue?
                                            <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                                    </Typography>
                                                    <FormikRadioGroup
                                                        name="revenueRecurring"
                                                        handleChange={
                                                            form.values.revenueRecurring === 'No'
                                                                ? ((form.values.revenueRecurringTypeMRR = ''),
                                                                    (form.values.revenueRecurringTypeARR = ''))
                                                                : null
                                                        }
                                                        values={yesNo}
                                                    ></FormikRadioGroup>
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.revenueRecurring === 'Yes' && (
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                Please choose your recurring revenue types
                                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                                            </Typography>
                                                            <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="revenueRecurringType" options={revenueRecurringTypeMRR}></Field>
                                                        </Grid>
                                                    )}
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Wizard.Page>
                        <Wizard.Page>
                            <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                                <Grid item xs={10} xl={9}>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                                        Competition
                                    </Typography>
                                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="h5">
                                                        Who is your primary competitor in the market?<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                                                    </Typography>
                                                    <FormikTextField
                                                        name="firstCompetitorsName"
                                                        type="text"
                                                        placeholder="Competitor's Name"
                                                    ></FormikTextField>
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="h5">
                                                        Competitor’s Website<span style={{ color: '#28b1e7', marginLeft: 5 }}>*</span>
                                                    </Typography>
                                                    <FormikTextField
                                                        name="firstCompetitorsWebsite"
                                                        validate={validateLinkedinUrl}
                                                        type="text"
                                                        placeholder="Competitor's Website"
                                                    ></FormikTextField>
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                Who is your secondary competitor in the market?
                                                            </Typography>
                                                            <FormikTextField
                                                                name="secondCompetitorsName"
                                                                type="text"
                                                                placeholder="Competitor's Name"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                Competitor’s Website
                                                            </Typography>
                                                            <FormikTextField
                                                                name="secondCompetitorsWebsite"
                                                                validate={validateLinkedinUrl}
                                                                type="text"
                                                                placeholder="Competitor's Website"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite && form.values.secondCompetitorsName && form.values.secondCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                Who is your third competitor in the market?
                                                            </Typography>
                                                            <FormikTextField
                                                                name="thirdCompetitorsName"
                                                                type="text"
                                                                placeholder="Competitor's Name"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite && form.values.secondCompetitorsName && form.values.secondCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                Competitor’s Website
                                                            </Typography>
                                                            <FormikTextField
                                                                name="thirdCompetitorsWebsite"
                                                                validate={validateLinkedinUrl}
                                                                type="text"
                                                                placeholder="Competitor's Website"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                                    <Typography variant="h5" style={{ marginBottom: 10 }}>
                                                        Please specify three points that differentiate you from the competition and your key advantages.
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                                    </Typography>
                                                    <RichEditor
                                                        editorState={form.values.productDifFromComp}
                                                        onChange={form.setFieldValue}
                                                        name="productDifFromComp"
                                                        onBlur={form.handleBlur}
                                                    />
                                                </Grid>
                                            )}
                                        </Field>
                                    </Grid>
                                    <Typography variant="h4" style={{ marginTop: 50, marginBottom: 10 }}>
                                        Market Size
                                    </Typography>
                                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Total Market Size ($) <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <FormikNumberField onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} inputProps={{ inputComponent: NumberFormatCustom }} name="marketTotalSize" placeholder="Eg: 300,000" helperText="Please fill as “0” if not applicable."></FormikNumberField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Target Market Region <span style={{ color: '#28b1e7' }}>*</span>
                                            </Typography>
                                            <FormikTextField name="marketTargetRegion" placeholder="Eg: MENA, Europe, North America…"></FormikTextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Wizard.Page>
                        <Wizard.Page>
                            <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                                <Grid item xs={10} xl={9}>
                                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                                        Investment Needs
                                    </Typography>
                                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                How much investment do you need in this round?
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikNumberField name="investmentNeededDD" onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} placeholder="Please write"></FormikNumberField>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                What is your post-money valuation? ($)<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikNumberField name="postMoneyValuation" onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} placeholder="Eg:100,000,000"></FormikNumberField>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                What percent of your round is completed?
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikPercentageField name="roundPercent" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Founding Team’s Share Percentage (%)
                                            </Typography>
                                            <FormikPercentageField name="sharePercent" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - Payroll (%)
                                            </Typography>
                                            <FormikPercentageField name="payrollFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - Sales (%)
                                            </Typography>
                                            <FormikPercentageField name="salesFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - R&D (%)
                                            </Typography>
                                            <FormikPercentageField name="rdFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - Marketing (%)
                                            </Typography>
                                            <FormikPercentageField name="marketingFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - Development (%)
                                            </Typography>
                                            <FormikPercentageField name="developmentFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                        </Grid>
                                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5">
                                                Use of Fund - Other (%)
                                            </Typography>
                                            <FormikPercentageField name="otherFA" maxLen={4} placeholder="%"></FormikPercentageField>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    sumFund(
                                                        form.values.payrollFA,
                                                        form.values.marketingFA,
                                                        form.values.salesFA,
                                                        form.values.developmentFA,
                                                        form.values.rdFA,
                                                        form.values.otherFA,
                                                    ) > 100 ?
                                                        <Typography align="right" variant="h5" style={{ marginTop: -15, color:'rgb(255, 0, 0)' }}>
                                                            Use of Fund Total:
                                                        {sumFund(
                                                            form.values.payrollFA,
                                                            form.values.marketingFA,
                                                            form.values.salesFA,
                                                            form.values.developmentFA,
                                                            form.values.rdFA,
                                                            form.values.otherFA,
                                                        )}
                                                        %
                                                        </Typography>
                                                        :
                                                        <Typography align="right" variant="h5" style={{ marginTop: -15 }}>
                                                            Use of Fund Total:
                                                        {sumFund(
                                                            form.values.payrollFA,
                                                            form.values.marketingFA,
                                                            form.values.salesFA,
                                                            form.values.developmentFA,
                                                            form.values.rdFA,
                                                            form.values.otherFA,
                                                        )}
                                                         %
                                                        </Typography>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h5">
                                                Have you raised any investment so far?
                                            </Typography>
                                            <Field name="investmentRaised">
                                                {({
                                                    field,
                                                    form,
                                                    meta,
                                                }) => (
                                                    <>
                                                        <FormControl component="fieldset">
                                                            <RadioGroup row aria-label="position" {...field} {...props}>
                                                                {yesNo.map(value => (
                                                                    <FormControlLabel key={value} style={{ marginRight: 30 }} handleChange={form.values.investmentRaised === 'No' ? form.values.investmentRaisedAmount = null : null} value={value} control={<Radio color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label={value} />
                                                                ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                        {meta.touched && meta.error ? (
                                                            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                                                                {meta.error}
                                                            </Typography>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Field>
                                            {({
                                                field,
                                                form,
                                                meta,
                                            }) => (
                                                <>
                                                    {form.values.investmentRaised === "Yes" &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5">
                                                                How much investment have you raised so far? ($)
                                                            </Typography>
                                                            <FormikNumberField onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} name="investmentRaisedAmount" placeholder="Please write"></FormikNumberField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Wizard.Page>
                        <Wizard.Page>
                            <Grid item container direction="column" xs={12} style={{ marginTop: 60 }} alignItems="center">
                                <Typography variant="h4" style={{ marginBottom: 10 }}>
                                    Submit Your Startup Application
                            </Typography>
                                <img src={idea} alt="idea" style={{ maxWidth: '460px' }}></img>
                            </Grid>
                        </Wizard.Page>
                    </Wizard>
                    }
                </Grid>
            </Grid>
        </div>
    )
}