import Settings from './Settings'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_PROFILE = gql`
  mutation editProfile(
    $firstName: String!
    $lastName: String!
    $email: String!
    $profilePhoto: Upload
    $phoneNumber: String!
    $socialLinkedIn: String
)
  {
  editProfile(
    firstName: $firstName,
    lastName: $lastName,
    email: $email,
    profilePhoto: $profilePhoto,
    phoneNumber: $phoneNumber,
    socialLinkedIn: $socialLinkedIn,
  )
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword1: String!
    $newPassword2: String!
  ) 
  {
    changePassword(
      oldPassword:  $oldPassword
      newPassword1: $newPassword1
      newPassword2: $newPassword2
    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_PROFILE, {
  name: 'editProfile',
  }),
  graphql(CHANGE_PASSWORD, {
    name: 'changePassword',
    }),
)(Settings);
export default JoinWithMutation;