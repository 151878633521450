import MyTeamCard from './MyTeamCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const TEAM_MUTATION = gql`
  mutation deepDiveTeamMember(
    $startupID: ID!
    $founderMembers: [TeamMemberInput]
    $otherMembers: [TeamMemberInput] 
  ) {
      deepDiveTeamMember(
        startupID: $startupID
        founderMembers: $founderMembers
        otherMembers: $otherMembers
      )
    }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $founderMembers: [TeamMemberInputForDraft]
    $otherMembers: [TeamMemberInputForDraft] 
) {
  startupEditStartup(
        id: $id
        founderMembers: $founderMembers
        otherMembers: $otherMembers
    )
  }
`;
const MANAGE_DRAFT = gql`
  mutation startupManageDraft(
    $id: ID!
    $status: String
) {
  startupManageDraft(
        id: $id
        status: $status
    )
  }
`;
const NEW_DRAFT = gql`
  mutation startupNewDraft{
    startupNewDraft
  }
`;
const JoinWithMutation = compose(
  graphql(TEAM_MUTATION, {
  name: 'deepDiveTeamMember',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
  graphql(MANAGE_DRAFT, {
    name: 'startupManageDraft',
  }),
  graphql(NEW_DRAFT, {
    name: 'startupNewDraft',
  }),
)(MyTeamCard);
export default JoinWithMutation;