import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import editImg from '../../static/icon/edit.svg';
import TextField from '@material-ui/core/TextField';
import { Form, Formik, useField, Field } from 'formik';
import Typography from "@material-ui/core/Typography";
import NumberFormat from 'react-number-format';
import FormikTextField from "../../Components/FormikTextField 2";
import { RichEditorReadOnly } from "../RichEditorReadOnly/RichEditorReadOnly.js";
import { RichEditor } from "../../Components/RichEditor/RichEditor.js";
import { stateToHTML } from 'draft-js-export-html'
import { EditorState, } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '15px',
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    namePart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    companyPart: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    marketDiv: {
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        },
    },
    paper: {
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff'
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 120,
        height: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#dbeeff',
        color: '#5bb3ff;',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f7fafc'
        },
        color: '#172b4d',
        fontWeight: '600'
    },
}))(TableRow);

function https(value) {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix) {
        value = prefix + value;
    }
    return value
}

function validateLinkedinUrl(value) {
    if (value !== '' && value !== null && value !== undefined) {
        let error;
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        error = !pattern.test(value) ? 'Not valid URL.' : false;
        return error;
    }
    return false;
}

export default function MyTabMarketCard(props) {
    const classes = useStyles();
    const [detail, setDetail] = React.useState(props.detail);

    const handleSubmit = variables => {
        variables.productDifFromComp = stateToHTML(variables.productDifFromComp.getCurrentContent());
        if (props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
            props.editStartup({ variables })
                .then(data => {
                    if (data.data.editStartup) {
                        if (variables.productDifFromComp) {
                            let htmlProduct = variables.productDifFromComp
                            if (typeof (variables.productDifFromComp) === 'string') {
                                variables.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productDifFromComp = new EditorState.createEmpty() }
                        props.setIsSentNote(true)
                        props.getData()
                    }
                    else {

                    }
                })
        } else {
            variables.id = props.params.id
            props.startupEditStartup({ variables })
                .then(data => {
                    if (data.data.startupEditStartup) {
                        if (variables.productDifFromComp) {
                            let htmlProduct = variables.productDifFromComp
                            if (typeof (variables.productDifFromComp) === 'string') {
                                variables.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlProduct))
                            }
                        }
                        else { variables.productDifFromComp = new EditorState.createEmpty() }
                        props.setIsSentNote(true)
                        props.setSendChanges(true)
                        props.getData()
                    }
                    else {

                    }
                })
        }
    };
    function NumberFormatCustom(props) {
        const { inputRef, onChange, ...other } = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                isNumericString
            />
        );
    }
    const [edit, setEdit] = React.useState(false);
    const money = (value) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(parseInt(value))
    }
    const FormikNumberField = ({ type, label, inputProps, maxLen, ...props }) => {
        const [field, meta] = useField(props);
        const classes = useStyles();
        const [values, setValues] = React.useState({
            textmask: '(1  )    -    ',
            numberformat: '1320',
        });
        const handleChange = (event) => {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
        };
        return (
            <>
                <Grid style={{ minHeight: 95 }}>
                    <TextField
                        variant="outlined"
                        className={classes.inputField}
                        {...field}
                        {...props}
                        inputProps={{
                            maxLength: maxLen
                        }}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                        error={meta.touched && meta.error}
                        placeholder={props.placeholder}
                    />

                    {meta.touched && meta.error ? (
                        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                            {meta.error}
                        </Typography>
                    ) : null}
                </Grid>
            </>
        );
    }
    return (
        <>
            <Paper style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column' }} className={classes.paper}>
                {!edit ?
                    <div>
                        <Grid container justify="flex-end" style={{ marginBottom: 15 }}>
                            {((props.authRole === 'Admin' || props.authRole === 'SuperAdmin') && window.location.href.indexOf("draft") === -1) &&
                                <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                            }
                            {(window.location.href.indexOf("draft") > -1 && (props.authRole !== 'Admin' && props.authRole !== 'SuperAdmin')) &&
                                <Button variant="containedSecondary" style={{ padding: "10px 25px" }} onClick={() => setEdit(true)}><img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit </Button>
                            }
                        </Grid>
                        <Grid style={{ marginBottom: '25px', marginTop: '25px' }} item sm={12} container>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>#</StyledTableCell>
                                            <StyledTableCell align="left">Company Name</StyledTableCell>
                                            <StyledTableCell align="left">Website</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.detail.firstCompetitorsName &&
                                            <StyledTableRow key={props.detail.firstCompetitorsName}>
                                                <StyledTableCell component="th" scope="row">
                                                    {1}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{props.detail.firstCompetitorsName}</StyledTableCell>
                                                <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.firstCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                            </StyledTableRow>
                                        }
                                        {props.detail.secondCompetitorsName &&
                                            <StyledTableRow key={props.detail.secondCompetitorsName}>
                                                <StyledTableCell component="th" scope="row">
                                                    {2}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{props.detail.secondCompetitorsName}</StyledTableCell>
                                                <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.secondCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                            </StyledTableRow>
                                        }
                                        {props.detail.thirdCompetitorsName &&
                                            <StyledTableRow key={props.detail.thirdCompetitorsName}>
                                                <StyledTableCell component="th" scope="row">
                                                    {2}
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{props.detail.thirdCompetitorsName}</StyledTableCell>
                                                <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.thirdCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                            </StyledTableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid xs={12}>
                                <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                            </Grid>
                            <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                {props.detail.productDifFromComp &&
                                    <>
                                        <Grid item container alignItems="center" justify="space-between">
                                            <Typography gutterBottom={true} align={"left"}
                                                variant="h5">
                                                Differentiates & Key Advantages
                                            </Typography>
                                        </Grid>
                                        <Grid item container>
                                            <RichEditorReadOnly
                                                editorState={props.detail.productDifFromComp}
                                                name="productDifFromComp"
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                        </Grid>
                                    </>
                                }
                                <Grid item container alignItems="center" className={classes.marketDiv}>
                                    <Grid item xs="auto" style={{ marginBottom: 25 }}>
                                        <Typography gutterBottom={true} align={"left"}
                                            variant="h5" style={{ display: 'flex' }}>
                                            Total Market Size
                                        </Typography>
                                        <Typography variant="h4" color="primary" align={"left"}>{props.detail.marketTotalSize ? money(props.detail.marketTotalSize) : '-'}</Typography>
                                    </Grid>
                                    <Grid item xs="auto" style={{ marginBottom: 25 }}>
                                        <Typography gutterBottom={true} align={"left"}
                                            variant="h5" style={{ display: 'flex' }}>
                                            Target Market Region
                                        </Typography>
                                        <Typography variant="h4" color="primary" align={"left"}>{props.detail.marketTargetRegion ? props.detail.marketTargetRegion : '-'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div> :
                    <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                        <Formik
                            initialValues={{
                                id: props.detail.id,
                                marketTargetRegion: props.detail.marketTargetRegion,
                                marketTotalSize: props.detail.marketTotalSize,
                                productDifFromComp: props.detail.productDifFromComp,
                                firstCompetitorsName: props.detail.firstCompetitorsName,
                                firstCompetitorsWebsite: props.detail.firstCompetitorsWebsite,
                                secondCompetitorsName: props.detail.secondCompetitorsName,
                                secondCompetitorsWebsite: props.detail.secondCompetitorsWebsite,
                                thirdCompetitorsName: props.detail.thirdCompetitorsName,
                                thirdCompetitorsWebsite: props.detail.thirdCompetitorsWebsite,
                            }}
                            enableReinitialize={true}
                            onSubmit={values => {
                                handleSubmit(values);
                            }}
                        >
                            {props => (
                                <Form style={{ width: '100%' }}>
                                    <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                                        <Grid item container alignItems="center" justify="flex-end">
                                            <Button variant="containedSecondary" style={{ padding: "10px 25px" }} type="submit"> Save </Button>
                                        </Grid>

                                        <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                            <Field>
                                                {({ field, form, meta }) => (
                                                    <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 25 }}>
                                                        <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                                                            Differentiates & Key Advantages
                                                        </Typography>
                                                        <RichEditor
                                                            editorState={form.values.productDifFromComp}
                                                            onChange={(form.setFieldValue)}
                                                            name="productDifFromComp"
                                                            onBlur={form.handleBlur}
                                                        />
                                                    </Grid>
                                                )}
                                            </Field>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5" align="left">
                                                    Total Market Size ($)
                                                </Typography>
                                                <FormikNumberField onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()} style={{ marginTop: 10, width: '100%' }} inputProps={{ inputComponent: NumberFormatCustom }} name="marketTotalSize" placeholder="Eg: 300,000"></FormikNumberField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="h5" align="left">
                                                    Target Market Region
                                                </Typography>
                                                <FormikTextField name="marketTargetRegion" placeholder="Eg: MENA, Europe, North America…"></FormikTextField>
                                            </Grid>
                                            <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="h5" align="left">
                                                        First Competitor
                                                    </Typography>
                                                    <FormikTextField
                                                        name="firstCompetitorsName"
                                                        type="text"
                                                        placeholder="Competitor's Name"
                                                    ></FormikTextField>
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="h5" align="left">
                                                        Competitor’s Website
                                                    </Typography>
                                                    <FormikTextField
                                                        name="firstCompetitorsWebsite"
                                                        validate={validateLinkedinUrl}
                                                        type="text"
                                                        placeholder="Competitor's Website"
                                                    ></FormikTextField>
                                                </Grid>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5" align="left">
                                                                Second Competitor
                                                            </Typography>
                                                            <FormikTextField
                                                                name="secondCompetitorsName"
                                                                type="text"
                                                                placeholder="Competitor's Name"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5" align="left">
                                                                Competitor’s Website
                                                            </Typography>
                                                            <FormikTextField
                                                                name="secondCompetitorsWebsite"
                                                                validate={validateLinkedinUrl}
                                                                type="text"
                                                                placeholder="Competitor's Website"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite && form.values.secondCompetitorsName && form.values.secondCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5" align="left">
                                                                Third Competitor
                                                            </Typography>
                                                            <FormikTextField
                                                                name="thirdCompetitorsName"
                                                                type="text"
                                                                placeholder="Competitor's Name"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        <Field>
                                            {({ field, form, meta }) => (
                                                <>
                                                    {form.values.firstCompetitorsName && form.values.firstCompetitorsWebsite && form.values.secondCompetitorsName && form.values.secondCompetitorsWebsite &&
                                                        <Grid item xs={12} md={6}>
                                                            <Typography variant="h5" align="left">
                                                                Competitor’s Website
                                                            </Typography>
                                                            <FormikTextField
                                                                name="thirdCompetitorsWebsite"
                                                                validate={validateLinkedinUrl}
                                                                type="text"
                                                                placeholder="Competitor's Website"
                                                            ></FormikTextField>
                                                        </Grid>
                                                    }
                                                </>
                                            )}
                                        </Field>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>}
            </Paper>
        </>
    );
}