import React, {Component} from "react"




  export default class LoginWithCode extends Component{
      state = {
          redirect: false,
          error: false,
          errorCustom: null
      }
      componentDidMount(){
          let params = new URLSearchParams(this.props.location.search);
          let variables = {
              code: params.get("code")
          }
          this.setRedirect({variables})
      }
      setRedirect = variables => {
          if (this.state.redirect === false){
              this.props.authenticateWithCode(variables)
                  .then(data => {
                      if (data.data.authenticateWithCode) {
                          const token = data.data.authenticateWithCode.token;
                          /*  const userId = data.data.authenticateUser.id; */

                          // TODO: change it to setAuthToken(token)
                          localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, token);
                          // can't use because gives error: Invalid hook call.
                          // setAuthToken(token);

                          this.props.history.push(`/application-form`);
                          this.setState({
                              redirect: true,
                              error: false,
                          })
                          return
                      } else {
                          // HATA DÖNMELİ Mİ ???
                          this.setState({
                              redirect: true,
                              error: true,
                          })
                          return
                      }
                  }).then().catch(error => {
                      //   HATA ERROR
                      this.setState({
                          redirect: true,
                          error: true,
                          errorCustom: error.message.split(':')[1]
                      })
                      return
                 });
          }
      }
      renderRedirect = () => {
          if (!this.state.redirect) {
              return (<div><center>Redirecting</center></div>)
          }
      }
      renderError = () => {

          if (this.state.errorCustom) {
              return (<div><center>{this.state.errorCustom}</center></div>)
          }else if(this.state.error) {
              return (<div><center>ERROR</center></div>)
          }
      }
      render () {
          return (
              <div>
                {this.renderRedirect()}
                {this.renderError()}
              </div>
      )
      }
  }