import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import folder from '../../static/icon/folder.svg';
import Button from "@material-ui/core/Button";
import file from '../../static/icon/file.svg';
import dolar from '../../static/icon/dolar.svg';
import drop from '../../static/icon/drop.svg';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import Default from '../../static/img/empty-company.png';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField, Field, Form, Formik } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

  textFields: {
    width: 100
  },
  img: {
    maxWidth: 90,
    minHeight: 90,
    border: '1px solid #f3f3f3',
    borderRadius: '5px',
    boxShadow: '-1px -1px 5px 2px #f3f3f3',
  },
  buttonCardFirst: {
    width: '100%',
    padding: '13.5px 25px',
    borderRadius: 2,
    background: '#5bb3ff',
    boxShadow: 'none',
  },
  buttonCardSecond: {
    width: '100%',
    padding: '13.5px 25px',
    borderRadius: 2,
    background: '#dbeeff',
    boxShadow: 'none'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: 2,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
  },
  labelFirst: {
    textTransform: 'capitalize',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  labelSecond: {
    textTransform: 'capitalize',
    color: '#5bb3ff',
    fontWeight: 'bold',
  },
  mainGridCss: {
    marginTop: 50,
  },
  mainCompany: {
    marginTop: 0,
    margin: 20,
    [theme.breakpoints.up('xl')]: {
      margin: 50,
      marginTop: 0,
    }
  },
  favIcon: {
    fontSize: '38px',
    marginTop: 15,
    marginRight: 15
  },
  businessPlan: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 15,
    }
  },
}));

const FormikNewDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 15 }}
        {...props}
        {...field}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Please Select" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}
function InvestmentDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, faq } = props;
  const investmentSize = ['$5.000', '$10.000', '$15.000', '$20.000', '$25.000', 'Other']

  const handleSubmit = (variables) => {
    props.createInvestmentRequest({ variables })
      .then(data => {
        if (data.data.createInvestmentRequest) {
          onClose(null);
          props.setInvestmentSent(true)
        } else {
          props.setInvestmentError(true);
        }
      }).then(() => {
      }).catch(error => {
        props.setInvestmentError(true);
        onClose(null);
      });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h5">
          Investment Request
          </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginTop: 15 }}>
        <Grid xs={'auto'} item>
          {props.logo ? (
            <img src={props.logo} className={classes.img} alt="logo"></img>
          ) : (
            <img src={Default} className={classes.img} alt="logo"></img>
          )}
        </Grid>
        <Grid item xs={7} container>
          <Grid item xs container alignItems={'center'} direction="row" style={{ paddingLeft: 20 }} spacing={2}>
            <Grid item xs>
              <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={'left'} variant="h4">
                {props.title}
              </Typography>
              <Typography
                align={'left'}
                color={'textPrimary'}
                style={{ color: '#172b4d', fontSize: 16 }}
                variant="body2"
              >
                {props.businessModel}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            startupId: props.id,
            size: '',
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <Form style={{ width: '80%', marginTop: 15 }}>
              <Field
                component={FormikNewDropdown}
                textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                name="size"
                options={investmentSize}
              ></Field>
              <Grid item container xs={12} justify="center" style={{ margin: '20px 0', height: 'fit-content' }}>
                <Button
                  variant="containedPrimary"
                  style={{ fontWeight: 'normal', width: '100%' }}
                  onClick={() => handleSubmit(props.values)}
                >
                  Send Request
                  </Button>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Dialog>
  );
}

InvestmentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function CompanyCard(props) {

  const firstButtonColor = (e) => {
    e.target.style.background = '#5bb3ff';
  };
  
  const [fav, setFav] = React.useState(props.isFavorited);
  const [investment, setInvestment] = React.useState(false);
  const [investmentSent, setInvestmentSent] = React.useState(false);
  const [investmentError, setInvestmentError] = React.useState(false);

  const addFavorite = (id) => {
    props.addFavorite({ variables: { startupId: id } })
      .then(data => {
        if (data.data.addFavorite) {
          setFav(true)
        }
      });
  };
  const removeFavorite = (id) => {
    props.removeFavorite({ variables: { startupId: id } })
      .then(data => {
        if (data.data.removeFavorite) {
          setFav(false)
        }
      });
  };
  // const money = (value) => {
  //     let formatter = new Intl.NumberFormat('en-US', {
  //         style: 'currency',
  //         currency: 'USD',
  //       });
  //       return formatter.format(parseInt(value))
  // }
  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "M", "B", "T"];
      var suffixNum = Math.floor(("" + value).length / 3);
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
        var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
        if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
      newValue = shortValue + suffixes[suffixNum];
    }
    return newValue;
  }

  const classes = useStyles();
  return (

    <Paper className={classes.paper}>
      <Grid container justify="center">
        {fav ? <Grid style={{ cursor: 'pointer' }} xs={12} onClick={() => removeFavorite(props.id)} container justify="flex-end">
          <BookmarkIcon style={{ color: '#ffe200' }} className={classes.favIcon} />
        </Grid> : <Grid style={{ cursor: 'pointer' }} xs={12} onClick={() => addFavorite(props.id)} container justify="flex-end">
          <BookmarkBorderIcon className={classes.favIcon} style={{ color: '#ffe200' }} /></Grid >
        }
        <Grid className={classes.mainCompany} container xs={10}>
          <Grid container justify="space-between">
            <Grid xs={"auto"} item style={{ borderRadius: 7 }}>
              {props.logo ? <img src={props.logo} className={classes.img} alt="logo"></img> : <img src={Default} className={classes.img} alt="logo"></img>}
            </Grid>
            <Grid item xs={7} container style={{ paddingLeft: 25 }}>
              <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                <Grid item xs>
                  <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={"left"}
                    variant="h4">
                    {props.title}
                  </Typography>
                  <Typography align={"left"}
                    color={"textPrimary"} style={{ color: '#172b4d', fontSize: 16 }} variant="body2">
                    {props.businessModel}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "30px" }} container>
            {props.totalValuation &&
              <Grid item xs={12} lg={7} container alignItems={"center"}>
                <Grid item xs style={{ marginBottom: "15px" }}>
                  <Typography style={{ display: 'flex' }} gutterBottom={true} align={"left"}
                    variant="h5">
                    <Grid style={{ display: 'flex', borderRadius: '50%', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginRight: 10 }}>
                      <img src={dolar} alt="dolar" />
                    </Grid>
                                    Valuation
                                </Typography>
                  <Grid item xs={11}>

                    <Typography align={"left"}
                      color={"primary"} variant="h5" style={{ wordBreak: "break-word" }}>
                      ${abbreviateNumber(parseInt(props.totalValuation))}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
            {props.investmentNeededDD &&
              <Grid item xs={12} lg={5} container>
                <Grid item xs>
                  <Typography gutterBottom={true} align={"left"}
                    variant="h5" style={{ display: 'flex' }}>
                    <Grid style={{ display: 'flex', borderRadius: '50%', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginRight: 10 }}>
                      <img src={drop} alt="drop" />
                    </Grid>
                                    Need
                                </Typography>

                  <Typography align={"left"}
                    color={"primary"} variant="h5" style={{ wordBreak: "break-word" }}>
                    ${abbreviateNumber(parseInt(props.investmentNeededDD))}
                  </Typography>
                </Grid>
              </Grid>
            }
          </Grid>
          {props.foundationDate &&
            <Grid item xs={12} style={{ marginTop: "25px" }} direction={"row"} container alignItems="center">
              <Typography align={"left"}
                variant="h5">
                Founded Date:
                        </Typography>
              <Typography align={"center"}
                variant="body1" style={{ marginLeft: "12px" }}>
                {props.foundationDate}
              </Typography>
            </Grid>
          }
          <Grid item xs={12} style={{ marginTop: "16px" }} direction={"row"} container alignItems="center">
            <Typography align={"left"}
              variant="h6">
              Location:
                        </Typography>
            <Typography align={"center"}
              variant="body1" style={{ marginLeft: "12px" }}>
              {props.country.title}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "16px" }} direction={"row"} container alignItems="center">
            <Typography align={"left"}
              variant="h6">
              Website:
                        </Typography>
            <Typography align={"center"}
              variant="body1" style={{ marginLeft: "12px" }}>
              {props.website}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "35px" }} container >
            {props.pitchDeck &&
              <Grid item xs={12} sm={6} md={12} lg={6} container alignItems={"center"}>
                <a href={props.pitchDeck} alt="pitch-deck" style={{ padding: 0, width: '100%' }} download>
                  <Grid item container xs alignItems={"center"}>
                    <img src={folder} alt="folder" style={{ marginRight: 10 }}></img>
                    <Typography align={"left"}
                      color={"primary"} variant="h6">
                      Pitch Deck
                                    </Typography>
                  </Grid>
                </a>
              </Grid>}
            {props.businessPlan &&
              <Grid item xs={12} sm={6} md={12} lg={6} container alignItems={"center"} className={classes.businessPlan}>
                <a href={props.businessPlan} alt="businessPlan" style={{ padding: 0, width: '100%' }} download>
                  <Grid item container xs alignItems={"center"}>
                    <img src={file} alt="file" style={{ marginRight: 10 }}></img>
                    <Typography align={"left"}
                      color={"primary"} variant="h6">
                      Business Plan
                                </Typography>
                  </Grid>
                </a>
              </Grid>
            }
          </Grid>
          {props.authRole && props.authRole === 'Investor' &&
            <Grid xs style={{ marginTop: "40px" }}>
              <Button onMouseEnter={firstButtonColor}
                classes={{ label: classes.labelFirst, root: classes.buttonCardFirst }}
                onClick={() => setInvestment(true)}
                variant="contained" size={"medium"}>
                Investment Request
                        </Button>
              <InvestmentDialog setInvestmentError={setInvestmentError} setInvestmentSent={setInvestmentSent} createInvestmentRequest={props.createInvestmentRequest} logo={props.logo} title={props.title} id={props.id} businessModel={props.businessModel} open={investment} onClose={() => setInvestment(false)} />
              <Snackbar autoHideDuration={5000} onClose={() => setInvestmentSent(false)} open={investmentSent}>
                <Alert severity="success">Successfully Sent</Alert>
              </Snackbar>
              <Snackbar autoHideDuration={5000} onClose={() => setInvestmentError(false)} open={investmentError}>
                <Alert severity="error">Already Sent</Alert>
              </Snackbar>
            </Grid>
          }
        </Grid>
      </Grid>
    </Paper>
  );
}