import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import Slider from '@material-ui/core/Slider';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Form, Formik, useField, Field } from 'formik';
import folder from '../../static/icon/folder.svg';
import arrow from '../../static/icon/arrow-left.svg';
import file from '../../static/icon/file.svg';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import { useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import gql from 'graphql-tag';
import { RichEditorReadOnly } from "../../Components/RichEditorReadOnly/RichEditorReadOnly.js";
import { fieldToTextField } from 'formik-material-ui';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'draft-js/dist/Draft.css';
import MobileHeader from "../../Components/MobileHeader";
import BottomNavigation from "../../Components/BottomNavigation";

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
    padding: 40,
    },
    padding: 25,
    background: '#ffffff'
  },
  topPart: {
      padding: '0 10px'
  },
  myDialog:{
    overflowY: 'unset',
    overflowX:'hidden',
    padding:40

  },
  inputField: {
    width: '100%',
  },
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
      justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },    

}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa;',
    height: 30,
    fontSize: '12px'
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);
function FormikTextField({ type, ...props }) {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
    <Grid style={{width:'100%'}}>
            <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}
            />

          {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
          </Typography>
          ) : null}
  </Grid>        
    </>
  );
}
export default function SuperAdminStartupDetail(props){
  const [detail, setDetail] = React.useState({});
  const [id, setId] = React.useState();
  const [changeStatus, setChangeStatus] = React.useState(false);
  const [isSentApproved, setIsSentApproved] = React.useState(false);
  let status = ['Pool','Pitch Day','Deep Dive','Portfolio','Archive','Eliminated in First Evaluation','Eliminated in Second Evaluation','On Hold','Re-evaluate'
  ,'Waiting to complete profile','Information Waiting','Waiting for feedback','Waiting for 2nd Evaluation','Waiting for shareholders\' votes',
  'Congratulations!','Exited']

  const FormikNewDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
        <>
            <Autocomplete
            style={{marginTop:10}}
            {...props}
            {...field}
            className={classes.multiSelect}
            onChange={ (_, value) => setFieldValue(name, value) }
            onBlur={ () => setTouched({ [name]: true }) }
            renderInput={(props ) => <TextField {...props } label="Please Select" {...textFieldProps} variant="outlined" />}
            >
            </Autocomplete>

        {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
            </Typography>
        ) : null}
        </>
    );
}

  const handleSubmit = variables => {
    variables.id = id
    if(variables.statusAdmin === "Archive" || variables.statusAdmin === "Eliminated in First Evaluation" || 
    variables.statusAdmin === "Eliminated in Second Evaluation" || variables.statusAdmin === "On Hold"){
      variables.status = "Archive"
    } else if(variables.statusAdmin === "Re-evaluate"){
      variables.status = "Re-evaluate"
    }
    else if(variables.statusAdmin === "Waiting to complete profile" || variables.statusAdmin === "Information Waiting"){
      variables.status = "Information Waiting"
    }
    else if(variables.statusAdmin === "Waiting for feedback" || variables.statusAdmin === "Waiting for 2nd Evaluation"){
      variables.status = "In Progress"
    }
    else if(variables.statusAdmin === "Waiting for shareholders\' votes"){
      variables.status = "Voting"
    }
    else if(variables.statusAdmin === "Portfolio" || variables.statusAdmin === "Congratulations!"){
      variables.status = "Portfolio"
    }
    else if(variables.statusAdmin === "Exited"){
      variables.status = "Exited"
    }
    else if(variables.statusAdmin === "Pool"){
      variables.status = "Pool"
    }
    else if(variables.statusAdmin === "Pitch Day"){
      variables.status = "Pitch Day"
    }
    else if(variables.statusAdmin === "Deep Dive"){
      variables.status = "Deep Dive"
    }
    props.adminStartupChangeStatus({ variables })
      .then(data => {
        if (data.data.adminStartupChangeStatus) {
          setIsSentApproved(true)
        } 
        setChangeStatus(false)
      })
  };
  function SendMailDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;
  
    const handleClose = () => {
      onClose(null);
    };
  
    return (
      <Dialog style={{padding:20}} fullWidth={true} maxWidth="lg" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          <Typography align="center" variant="h4" style={{marginBottom:20}}>Status</Typography>
        </DialogTitle>
        <Grid container justify="center" xs={12}>
                    <Grid item xs={12}>
                      <Formik
                      initialValues={{
                        statusAdmin: '',
                        description: '',
                      }}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Grid xs={12} justify="center">
                              <Grid spacing={3} item container alignItems="flex-end">
                                <Grid item container xs={12} md={3}>
                                  <Typography variant="h6" style={{marginBottom:10}}>Change Status</Typography> 
                                  <Field component={FormikNewDropdown} style={{width:'100%',marginBottom:20}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="statusAdmin" options={status} />
                                </Grid> 
                                <Grid item container xs={12} md={8} style={{height:'fit-content'}}>
                                  <Typography variant="h6" style={{marginBottom:10}}>Status Change Description</Typography>  
                                  <FormikTextField name="description" style={{width:'100%',marginBottom:20}} type="text" placeholder="Description" />
                                </Grid>  
                                <Grid item container xs={12} md={1} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                  <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} type="submit">Change</Button>
                                </Grid>
                              </Grid>     
                            </Grid>
                            <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                            <Typography variant="h6" style={{marginBottom:20}}>Status Timeline</Typography>
                            <TableContainer component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell style={{padding:'5px 16px'}}>WHO</StyledTableCell>
                                    <StyledTableCell style={{padding:'5px 16px'}} align="left">WHEN</StyledTableCell>
                                    <StyledTableCell style={{padding:'5px 16px'}} align="left">TO</StyledTableCell>
                                    <StyledTableCell style={{padding:'5px 16px'}} align="left">DESCRIPTION</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                              <TableBody>
                                  {detail.statusTimeline.map((row) => (
                                    <StyledTableRow key={row.timeline}>
                                      <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600'}} scope="row">
                                        {row?.user?.firstName || ""} {row?.user?.lastName || " - "}
                                      </StyledTableCell>
                                      {/*<StyledTableCell align="left">{new Date(row.date).toDateString()}</StyledTableCell>*/}
                                      <StyledTableCell align="left">{row.date.replace(/T/g, ' ').replace(/Z/g, '')}</StyledTableCell>
                                      <StyledTableCell align="left">{row.status}</StyledTableCell>
                                      <StyledTableCell align="left">{row.description}</StyledTableCell>
                                    </StyledTableRow>
                                  ))}
                                </TableBody>
                              
                              </Table>
                            </TableContainer>                
                          </Form> 
                         )}
                       </Formik> 
                    </Grid>
        </Grid>
      </Dialog>
    );
  }
  
  SendMailDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  const ALL_STARTUPS = gql`
  query allStartups($slug:String) {
          allStartups(slug:$slug){
          id
          title
          website
          location
          founderSize
          description
          marketTotalSize
          marketTargetRegion
          businessModel
          establishedDate
          foundersPrevStartupExp
          foundersPrevCorporateExp
          competitorAmount
          postMoneyValuation
          capTableAInvestors
          capTableAFounding
          investmentRaisedAmount
          competitorAnalysis
          investmentNeededDD
          pitchDeck
          businessPlan
          statusTimeline{
            id
            user{
              id
              firstName
              lastName
            }
            description
            date
            status
          }
          founderMembers{
            nameSurname
            jobTitle
            socialLinkedIn
          }
          country{
            id
            title
          }
      }

  }
  `;
    const { loading, data } = useQuery(ALL_STARTUPS, {
      variables: {slug:props.match.params.slug},
  });
  const handleClickOpen = () => {
    setChangeStatus(true);
  };
  const handleClose = (value) => {
    setChangeStatus(false);
  };
  function abbreviateNumber(value) {
    var newValue = value;
    if (value >= 1000) {
        var suffixes = ["", "K", "M", "B","T"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
            shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
            var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
            if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
    }
    return newValue;
}
  React.useEffect(() => {
    if(!loading){
        if(data){
          if(data.allStartups[0].competitorAnalysis){
            let htmlAbt= data.allStartups[0].competitorAnalysis
            if(typeof(data.allStartups[0].competitorAnalysis) === 'string'){
              data.allStartups[0].competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
            }
          }
          else{
            data.allStartups[0].competitorAnalysis = new EditorState.createEmpty()
          }
          setDetail(data.allStartups[0]);
          setId(data.allStartups[0].id)
          }
        }
},
[data, loading])
  const classes = useStyles();
  return(
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                  <MobileHeader></MobileHeader>
                </Hidden>
              {Object.keys(detail).length > 0 && <Grid className={classes.mainGrid} container style={{background:'#f7f7fc'}}>
                <LeftNavigation>
                </LeftNavigation>
                <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                                className={classes.topPart}>
                        <Grid item style={{display:'flex'}}
                                alignItems="center">
                          <Link to={"/admin/startups"} style={{padding:0}}><img src={arrow} alt="arrow" style={{marginRight:25,padding:0}}></img></Link>
                          <Typography variant="h3">{detail.title}</Typography>
                        </Grid>
                        <Button variant="outlinedPrimary" style={{padding: '13.5px 30px',background:'#ffffff',fontSize:15}} onClick={handleClickOpen}>Change Status</Button>
                        <SendMailDialog open={changeStatus} onClose={handleClose}/>
                    </Grid>
                    <Grid container item style={{marginTop:'50px'}}>
                        <Grid item container xs={12} sm={6} lg={4} style={{paddingLeft:10,marginBottom:50}}>
                          <Grid xs={12} sm={10} className={classes.userCard}>
                            <Grid container justify="space-between" alignItems="center">
                              <Typography variant="h5">Startup Detail </Typography>
                              <Link to={`/admin/startup-edit/${props.match.params.slug}`}><Button variant="containedSecondary" style={{padding:"5.5px 25px"}}> Edit </Button></Link>
                            </Grid>
                            <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                              {detail.postMoneyValuation > 0 &&
                              <Grid style={{marginTop:40}} container>
                                <Typography variant="h6"style={{marginRight:10}}>Total Valuation:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">${abbreviateNumber(detail.postMoneyValuation)}</Typography>
                              </Grid>
                              }
                              {(detail.establishedDate && detail.establishedDate !== "") &&<Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Founded Date:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.establishedDate}</Typography>
                              </Grid>
                              }
                              {(detail.foundersPrevStartupExp && detail.foundersPrevStartupExp !== "") &&<Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Previous startup experience:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.foundersPrevStartupExp}</Typography>
                              </Grid>
                              }
                              {(detail.foundersPrevCorporateExp && detail.foundersPrevCorporateExp !== "") &&<Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Previous corporate experience:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.foundersPrevCorporateExp}</Typography>
                              </Grid>
                              }
                              {(detail.competitorAmount && detail.competitorAmount !== "") &&<Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Competitor Amount:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.competitorAmount}</Typography>
                              </Grid>
                              }
                              {(detail.country && Object.keys(detail.country).length > 0) && <Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Location:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.country.title}</Typography>
                              </Grid>
                              }
                              { (detail.website && detail.website !== "")&&
                              <Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Product Link:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.website}</Typography>
                              </Grid>
                              }
                              {(detail.marketTotalSize && detail.marketTotalSize !== "") &&
                              <Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Market Size:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">${abbreviateNumber(detail.marketTotalSize)}</Typography>
                              </Grid>
                              }
                              {(detail.marketTargetRegion && detail.marketTargetRegion !== "") &&
                              <Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Target Market Region:</Typography>
                              <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.marketTargetRegion}</Typography>
                              </Grid>
                              }
                              {(detail.businessModel && detail.businessModel !== "") &&
                              <Grid style={{marginTop:20}} container>
                                <Typography variant="h6" style={{marginRight:10}}>Business Model:</Typography>
                                <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{detail.businessModel}</Typography>
                              </Grid>
                              }
                              {(detail.description && detail.description !== "")&&  
                              <div>                                 
                                <Typography variant="h6" style={{marginTop:40}}>Summary</Typography>
                                <Typography variant="body2" color="textSecondary" style={{marginTop:20,wordBreak: 'break-all'}}>{detail.description}</Typography>
                              </div>     
                              }
                              {get(detail,"founderMembers",[]).length > 0 && 
                              <div>
                                <Typography variant="h5" style={{marginTop:40}}>Founding Team</Typography>
                                {detail.founderMembers.map((teamMember, index) => (
                                  <div>
                                <Grid style={{marginTop:20}} container>
                                  <Typography variant="h6" style={{marginRight:10}}>{teamMember.jobTitle}:</Typography>
                                  <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{teamMember.nameSurname}</Typography>
                                </Grid>
                                {(teamMember.socialLinkedIn && teamMember.socialLinkedIn !== "") && 
                                <Grid style={{marginTop:20,flexWrap:'nowrap'}} container>
                                  <Typography variant="h6" style={{marginRight:10}}>LinkedIn:</Typography>
                                  <Typography variant="h6" style={{fontWeight:'normal',wordBreak: 'break-all'}} color="textSecondary">{teamMember.socialLinkedIn}</Typography>
                                </Grid>}
                                <Grid xs={12}>
                                  <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                                </Grid>
                                </div>))}
                              </div>}
                              <Typography variant="h5" style={{marginTop:40}}>Investment Status</Typography>
                              <Grid style={{marginTop:20}} xs={12} container alignItems="center">
                                      {(detail.investmentRaisedAmount && detail.investmentRaisedAmount !== "")&&  
                                      <Grid container xs={12} lg={12} xl={6} style={{marginBottom:15}} alignItems="center" className={classes.requests}>
                                          <Grid xs={12}>
                                          <Typography variant="h6" style={{marginRight:10,wordBreak:"break-word"}}>Investment to date</Typography>
                                            <Typography variant="h6" color="primary" style={{marginRight:10,marginTop:5,wordBreak:"break-word"}}>$ {abbreviateNumber(detail.investmentRaisedAmount)}</Typography>
                                          </Grid>
                                      </Grid>}
                                      {(detail.investmentNeededDD && detail.investmentNeededDD !== "")&&  
                                      <Grid container xs={12} lg={12} xl={6} alignItems="center" className={classes.requests}>
                                          <Grid xs={12}>
                                          <Typography variant="h6" style={{marginRight:10,wordBreak:"break-word"}}>Needed This Round</Typography>
                                            <Typography variant="h6"  color="primary" style={{marginRight:10,marginTop:5,wordBreak:"break-word"}}>$ {abbreviateNumber(detail.investmentNeededDD)}</Typography>
                                          </Grid>
                                      </Grid>}
                              </Grid>
                              {(detail.capTableAInvestors  && detail.capTableAInvestors !== "") && 
                              <div>
                                <Typography variant="h5" style={{marginTop:40}}>Cap Table Allocation</Typography>
                                <Typography color="primary" variant="h6" id="disabled-slider" style={{marginTop:20}}>
                                  %{detail.capTableAInvestors}
                                </Typography>
                                <Slider disabled defaultValue={detail.capTableAInvestors} aria-labelledby="disabled-slider" />
                                <Typography  variant="h6" id="disabled-slider">
                                  Investors
                                </Typography>
                              </div>
                              }
                              {(detail.capTableAFounding  && detail.capTableAFounding !== "") && 
                              <div>
                                <Typography color="primary" variant="h6" id="disabled-slider" style={{marginTop:20}}>
                                  %{detail.capTableAFounding}
                                </Typography>
                                <Slider disabled defaultValue={detail.capTableAFounding} aria-labelledby="disabled-slider" />
                                <Typography  variant="h6" id="disabled-slider">
                                  Funding
                                </Typography>
                              </div>
                              }
                              {(detail.competitorAnalysis && detail.competitorAnalysis !== "") &&
                              <div>
                                <Typography align="center" variant="h6" style={{marginTop:40}}>
                                  Competitor Analysis
                                </Typography>
                                <RichEditorReadOnly
                                  editorState={detail.competitorAnalysis}
                                  name="competitorAnalysis"
                                />  
                              </div>
                              }

                              <Typography variant="h5" style={{marginTop:40}}>Docs</Typography>
                              {detail.businessPlan &&
                              <Grid item xs={12} container alignItems={"center"} style={{marginTop:20}}>
                                <a href={detail.businessPlan} alt="businessPlan" style={{padding:0,width:'100%'}} download>
                                  <Grid item container xs alignItems="center">
                                      <img src={file} alt="file" style={{marginRight:20,marginLeft:10}}></img>
                                      <Typography align={"left"} variant="body2">
                                        Business Plan
                                      </Typography>
                                  </Grid>
                                </a>
                              </Grid>
                              }  
                               {detail.pitchDeck &&
                              <Grid item xs={12} container alignItems={"center"} style={{marginTop:20}}>
                                <a href={detail.pitchDeck} alt="pitch-deck" style={{padding:0,width:'100%'}} download>
                                  <Grid item container xs alignItems="center">
                                      <img src={folder} alt="folder" style={{marginRight:20}}></img>
                                      <Typography align={"left"} variant="body2">
                                          Pitch Deck
                                      </Typography>
                                  </Grid>
                                </a>
                              </Grid>
                              } 
                          </Grid>
                          <Snackbar autoHideDuration={6000} onClose={() => setIsSentApproved(false)} open={isSentApproved} >
                            <Alert severity="success">Status changed Successfully.</Alert>
                          </Snackbar>
                        </Grid>
                    </Grid>
                </Grid>
  </Grid> }
            </div>        
  );
}