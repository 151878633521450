import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import {useQuery} from "@apollo/react-hooks";
import gql from 'graphql-tag';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff'
    },
    textArea: {
        width: '100%',
        '& textarea': {
            padding: 8,
        },
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    buttonCardFirst: {
        minWidth: 120,
        borderRadius: 2,
        background: '#5bb3ff',
        boxShadow: 'none'
    },
}));

export default function NoteCard(props) {
        const classes = useStyles();
        const [value, setValue] = React.useState('');

        const adminStartupAddNote = (value) => {
          if (value !== ''){
            props.adminStartupAddNote({ variables: { id: props.startupId, note:value } })
              .then(data => {
                if (data.data.adminStartupAddNote) {
                  setValue('')
                  props.setIsSentNote(true)
                }
              });
          }
        };
        const handleChange = (event) => {
          setValue(event.target.value);
        };
        return (
            <Paper style={{padding: '35px 35px'}} className={classes.paper}>
                <Grid style={{marginBottom: '10px'}} item sm={12} container>
                    <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                        <Grid item xs>
                            <Typography style={{marginBottom: '20px'}}
                                        align={"left"}
                                        variant="h5">
                                Notes - Commands
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justify="end" xs={12} style={{marginBottom: '20px'}}>
                    <TextField
                        id="standard-multiline-flexible"
                        multiline
                        placeholder={"Please write your notes."}
                        className={classes.textArea}
                        rows={16}
                        value={value}
                        onChange={handleChange}
                    />
                    </Grid>
                    <Grid item xs={12} container alignItems={"center"}>
                        <Button classes={{label: classes.labelFirst, root: classes.buttonCardFirst}}
                                onClick={() => {adminStartupAddNote(value)}}
                                variant="contained" size={"medium"}
                                color={"primary"}>
                            Add Notes
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
}