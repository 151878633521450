import React from "react";
import Hidden from "@material-ui/core/Hidden";
import MuiAlert from '@material-ui/lab/Alert';
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import BottomNavigation from "../../Components/BottomNavigation";
import ReactPhoneInput from "react-phone-input-2";
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import arrow from '../../static/icon/arrow-left.svg';
import {makeStyles} from "@material-ui/core/styles";
import { useField, Form, Formik, Field} from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import get from 'lodash/get';
import TextField from "@material-ui/core/TextField";
import { addInvestorSchema } from '../../yupSchemas';
import MobileHeader from "../../Components/MobileHeader";

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
    padding: 40,
    },
    padding: 25,
    background: '#ffffff'
  },
  multiSelect: {
    '& div': {
      background: 'none!important',
    },
    marginBottom:25
  },
  topPart: {
      padding: '0 10px'
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  textArea: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
    maxWidth:'420px',
    },
    maxWidth:'315px',
    borderColor:'rgb(222, 222, 222);',
    borderRadius:'7px',
    marginTop:10,
    padding:'5px 10px',
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    color:"#777777"
  },
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
    justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },

}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const FormikMultiSelect = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          multiple
          style={{marginTop:10}}
          {...props}
          {...field}
          disableCloseOnSelect
          className={classes.multiSelect}
          onChange={ (_, value) => setFieldValue(name, value) }
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props }  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}
const FormikNewDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:10}}
          {...props}
          {...field}
          className={classes.multiSelect}
          onChange={ (_, value) => setFieldValue(name, value) }
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props }  {...textFieldProps} variant="outlined" />}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}
  const FormikTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
    <Grid style={{minHeight:95}}>
        <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            maxlength={props.maxlength}
        />
  
            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  } 

export default function SuperAdminInvestorAdd(props){
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const options = ['Social Media', 'Friends and Family', 'Investing Platforms', 'Angel Effect Shareholders'];
  const totalInvestedCompanies = ['Do not want to indicate', '0', '1-3', '3-5', '5-10', '10+'];
  const totalInvestmenAmount = ['0-$100K', '$100K - $250K', '$250K - $500K', '$500K+'];
  const budgetForAE = ['0-$100K', '$100K - $250K', '$250K - $500K', '$500K+'];
  const planInvestTwoYear = ['0', '1-3', '3-5', '5-10', '10+'];
  const role = ['Investor', 'Approved Investor'];
  const industry = ['Agriculture', 'Biotechnology', 'Business Services', 'Clean Technology', 'Construction', 'Consulting' , 'Consumer Products', 'Consumer Services', 'Digital Marketing',
   'Education', 'Electronics / Instrumentation', 'Fashion', 'Financial Services',	'Fintech', 'Food and Beverage', 'Gaming', 'Healthcare Services', 'Energy', 'Internet / Web Services', 'IT Services',
   'Legal', 'Lifestyle', 'Marine', 'Maritime/Shipping', 'Marketing / Advertising', 'Media and Entertainment', 'Medical Devices and Equipment', 'Mobility', 'Nanotechnology', 	'Other', 'Real Estate',
   'Retailing / Distribution', 'Robotics', 'Security', 'Software', 'Sports', 'Telecommunications', 'Transportation', 'Travel'	]
  const [serverMessage, setServerMessage] = React.useState({
      detail: '',
      message: '',
      type: false,
    });

      const handleSubmit = async variablesAll => {
      const { ...variables } = variablesAll;
      setIsSubmitting(true);
      props.adminAddInvestor({ variables })
        .then(data => {
          if (data.data.adminAddInvestor) {
            setIsEdited(true);
            props.history.push(`/admin/investors`);
          } else {
              setServerMessage({
                  detail: get(data, 'errors[0].functionError.detail', ' '),
                  message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                  type: false,
                });
                setServerSuccess(true);
          }
        }).then(() => {
              setIsSubmitting(false);
          }).catch(error => {
              setServerMessage({
                  message: 'Unexpected Error',
                  type: false,
                });
                setServerSuccess(true);
        });
    };
  const [initialValuess, setInitialValuess] = React.useState(
    {
        email:'',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        socialLinkedIn: '',
        hearAboutUs: '',
        totalInvestedCompanies: '',
        totalInvestmenAmount: '',
        budgetForAE: '',
        planInvestTwoYear: '',
        role:'',
        sendMail:true,
        industry:[]

    }
);
  const classes = useStyles();
  return(
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
              <Grid container className={classes.mainGrid}  style={{background:'#f7f7fc'}}>
                <LeftNavigation>
                </LeftNavigation>
                <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                alignItems="center"
                                className={classes.topPart}>
                        <Link to={"/admin/investors"}><img src={arrow} alt="arrow" style={{marginRight:15}}></img></Link>
                    </Grid>
                    <Grid container item style={{marginTop:'50px'}}>
                        <Grid item container xs={12} sm={6} lg={4} style={{paddingLeft:10,marginBottom:50}}>
                          <Grid xs={10} className={classes.userCard}>
                          <Formik
                              initialValues={initialValuess}
                              enableReinitialize={true}
                              validationSchema={addInvestorSchema}
                              onSubmit={values => {
                                handleSubmit(values);
                              }}
                            >
                               
                          {props => (
                            <Form>
                            <Grid container justify="space-between" alignItems="center">
                              <Typography variant="h5">Add Investor </Typography>
                              <Button variant="containedSecondary" style={{padding:"5.5px 25px"}} onClick={() => handleSubmit(props.values)} disabled={!props.isValid}> Add </Button>
                            </Grid>
                            <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                            <Typography variant="h6">Investor Type?</Typography>
                            <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="role" options={role}></Field>
                              <Typography variant="h6">First Name:</Typography>
                              <FormikTextField name="firstName" placeholder="Name"/>
                              <Typography variant="h6">Last Name:</Typography>
                              <FormikTextField name="lastName" placeholder="Surname"/>
                              <Typography variant="h6">Email Address</Typography>
                              <FormikTextField name="email" placeholder="Email Address"></FormikTextField>
                              <Typography variant="h6">Phone Number </Typography>
                                <Field
                                name="phoneNumber">
                                 {({ field, form, meta }) => {
                                    const { name, value } = field;
                                    return (
                                      <>
                                         <div>
                                       <Grid style={{minHeight:95}}>
                                          <ReactPhoneInput
                                            name={name}
                                            value={value}
                                            style={{marginTop:10,borderRadius:7}}
                                            className={classes.inputField}
                                            onChange={e  => form.setFieldValue("phoneNumber", e)}
                                            country={"tr"}
                                          />
                                          {meta.touched && meta.error ? (
                                            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                                              {meta.error}
                                            </Typography>
                                          ) : null}
                                        </Grid>  
                                    </div>    
                                      </>
                                    );
                                  }}
                                </Field>
                              <Typography variant="h6">Linkedin:</Typography>
                              <FormikTextField name="socialLinkedIn" placeholder="about Startup"/>
                              <Typography variant="h6">How did you hear about us?</Typography>
                              <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="hearAboutUs" options={options}></Field>
                              <Typography variant="h6">In total, how many startup companies have you invested in?</Typography>
                              <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="totalInvestedCompanies" options={totalInvestedCompanies}></Field>
                              <Typography variant="h6">How much did you invest in total?</Typography>
                              <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="totalInvestmenAmount" options={totalInvestmenAmount}></Field>
                              <Typography variant="h6">How much of your budget did you allocate for angel investment?</Typography>
                              <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="budgetForAE" options={budgetForAE}></Field>
                              <Typography variant="h6">How many startup companies do you plan to invest in the next 2 years?</Typography>
                              <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="planInvestTwoYear" options={planInvestTwoYear}></Field>
                              {props.values.industry &&
                              <div>
                                <Typography variant="h6">What industries would you like to invest in?</Typography>
                                <Field component={FormikMultiSelect} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="industry" options={industry}></Field>
                              </div>}
                            </Form> 
                            )}
                       </Formik>
                        <Snackbar data={serverMessage} onClose={() => setServerSuccess(false)} open={serverSuccess} >
                          <Alert severity="error">That email is already taken</Alert>
                        </Snackbar>
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid> 
            </div>        
  );
}