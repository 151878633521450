import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import sticker from '../../static/img/sticker.svg';
import moment from 'moment'
import BottomNavigation from "../../Components/BottomNavigation";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Pagination from "@material-ui/lab/Pagination";
import MobileHeader from "../../Components/MobileHeader";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa;',
    height: 30,
    fontSize: '12px',
    padding:'14px 10px',
  },
  body: {
    fontSize: 14,
    padding:'20px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        marginTop: 30
    }, 
  },
  accordionGrid: {
      height:'fit-content',
      marginBottom:40,
      justifyContent:'center',
      [theme.breakpoints.up('xl')]: {
          maxWidth: 330
      },  
  },
  multiSelect: {
    maxHeight: '40px',
    border: 'solid 1px #172b4d',
    background: '#ffffff',
    '& div': {
        background: 'none!important',
    }
  },
  exportButton: {
    padding:'9.5px 17px',
    color: '#28b1e7',
    borderRadius: 3,
    fontSize: 15,
    border: '1px solid #28b1e7',
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  }, 
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    height:'35px',
    borderRadius:'7px',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'1px solid' ,
    padding: '2px 8px',
    marginRight:15
  },
  accordionPanel: {
      maxWidth: 240,
      width: '90%',
      boxShadow: 'none',
      borderBottom: 'none',
      background: '#f6f9fc',
      marginTop: '25px!important',
      borderRadius: '7px'
  },
  sticker: {
      marginRight:20,
      [theme.breakpoints.down('sm')]: {
        marginBottom:10
    }, 

  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rightPart: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1290
  },
    height:'fit-content',
    background:"#ffffff"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  mainGrid: {
    justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  }
));
export default function SuperAdminApproveList(props){
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [rows, setRows] = React.useState([]);
  const [dataAll, setDataAll] = React.useState([]);
  const APPROVE_LIST = gql`
  query allAdminApprovals{
    allAdminApprovals{
      id
      createdAt
      lastUserEdit
      moderationStatus
      submitBy{
        firstName
        lastName
      }
      data{
        startup{
          title
          id
        }
      }
      status
      }

  }
  `;
  const { loading, data } = useQuery(APPROVE_LIST, {
      fetchPolicy:"network-only",
      variables: {},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          if (data.allAdminApprovals){
            setDataAll(data.allAdminApprovals);
            setRows(data.allAdminApprovals.slice(0, pageSize));
            setPage(1);
            setPageCount(1);
            setPageCount(Math.ceil(data.allAdminApprovals.length / pageSize))
          }else{
            setPage(0);
            setPageCount(0);
          }
            }
        }
},
[data, loading, pageSize])
    const classes = useStyles();

    const handlePageChange = (event, value) => {
      setPage(value);
      let temp = value * pageSize;
      setRows(dataAll.slice(temp - pageSize, temp));
    };

    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
            <Grid container className={classes.mainGrid} style={{background:'#f7f7fc'}}>
              <LeftNavigation>
              </LeftNavigation>
              <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className={classes.topPart}>
                        <Typography variant="h4"> Approve Startups </Typography>
                    </Grid>
                    <Grid container xs={12} style={{margin:'50px 0'}}>
                        <Grid item container xs={12} className={classes.rightPart}>
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell>Startup's Name</StyledTableCell>
                                  <StyledTableCell align="left">Request Time</StyledTableCell>
                                  <StyledTableCell align="left">Startup Status</StyledTableCell>
                                  <StyledTableCell align="left">Admin Status</StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                             {rows.length > 0 &&  <TableBody>
                                {rows.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600'}} scope="row">
                                      {row.data.startup.title}
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontWeight:'600'}} align="left">{moment(parseInt(row.lastUserEdit)).format('MMMM Do YYYY, h:mm:ss a')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.moderationStatus}</StyledTableCell>
                                    <StyledTableCell align="left">{row.status}</StyledTableCell>
                                      <StyledTableCell align="left">
                                      {(row.status === 'Pending' && row.moderationStatus !== 'Draft') &&
                                        <Grid container >
                                          <Link style={{padding:0}} to={`/admin/draft-startup/${row.id}`}><img src={sticker} alt="sticker" className={classes.sticker} /></Link>
                                        </Grid>
                                      }
                                      </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>} 
                             
                            </Table>
                          </TableContainer>
                          <Grid container item style={{margin:'30px 0'}} justify="center">
                            <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                        variant="outlined" color="primary" />
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}