import SuperAdminInvestmentList from "./SuperAdminInvestmentList";
import * as compose from "lodash.flowright";
import {graphql} from "react-apollo";
import gql from 'graphql-tag';

const MANAGE_INVESTMENT = gql`
    mutation adminHandleInvestmentRequest(
        $requestId: ID!
        $status: String!
    ){
      adminHandleInvestmentRequest
        (
            requestId: $requestId
            status: $status
        )
    }
`;
const JoinWithMutation = compose(
  graphql(MANAGE_INVESTMENT, {
    name: 'adminHandleInvestmentRequest',
  }),
)(SuperAdminInvestmentList);
export default JoinWithMutation;