import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ApolloProvider} from '@apollo/react-hooks';
import {createUploadLink} from 'apollo-upload-client';
import {ApolloLink} from 'apollo-link';
import {ApolloClient} from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {createHttpLink} from "apollo-link-http";


const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}`,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN) ? `Bearer ${localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN)}` : null,
    },
  });
  return forward(operation);
});

const httpLink = ApolloLink.from([
  authMiddleware,
  uploadLink,
  createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}`,
    credentials: 'include'
  }),
]);


const client = new ApolloClient({
  link:httpLink,
  cache: new InMemoryCache(),
});
console.log(client);

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App/>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
