import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import MyCompanyCard from "../../Components/MyCompanyCard";
import MyRatingCard from "../../Components/MyRatingCard";
import MyTeamCard from "../../Components/MyTeamCard";
import MyTabCompanyCard from "../../Components/MyTabCompanyCard";
import MyTabProductCard from "../../Components/MyTabProductCard";
import MyTabMarketCard from "../../Components/MyTabMarketCard";
import MyTabBusinessCard from "../../Components/MyTabBusinessCard";
import TabDocsCard from "../../Components/TabDocsCard";
import {makeStyles} from "@material-ui/core/styles";
import { useLazyQuery } from '@apollo/react-hooks';
import { fieldToTextField } from 'formik-material-ui';
import gql from 'graphql-tag';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import 'draft-js/dist/Draft.css';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import { Form, Formik, Field, useField } from 'formik';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import useFastField from '../../Components/useFastField.js'

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) =>({
        containerCss: {
            display: 'flex',
            justifyContent: 'center',
        },
        firstPart: {
            marginTop: 20,
            justifyContent:"center",
            maxWidth: 550,
            [theme.breakpoints.up('xl')]: {
                justifyContent:"start", 
            },
        },
        myDialog:{
            overflowY: 'unset',
            overflowX:'hidden',
            padding:40
        
          },
        secondPart: {
            marginTop: 20,
            [theme.breakpoints.up('lg')]: {
                paddingRight:20
            },
        },
        cardContainer: {
            marginBottom: '15px',
        },
        paper: {
            padding: '27px 0',
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            '& div': {
                [theme.breakpoints.up('xl')]: {
                    justifyContent: 'center',
                },
            }
        },
        tabButton: {
            fontSize: '16px',
            color: '#00000087',
            minWidth: 0,
            textTransform:'none',
            maxWidth: 'fit-content',
            padding: '6px 0',
            margin: '0 64px',
        },
        paddingZero: {
            padding: '0',
        },
    }
));
function FormikTextField({ type, ...props }) {
    const [field, meta] = useFastField(props);
    const classes = useStyles();
    return (
      <>
      <Grid style={{width:'100%'}}>
              <TextField
              variant="outlined"
              className={classes.inputField}
              {...field}
              {...props}
              error={meta.touched && meta.error}
              placeholder={props.placeholder}
              type={type}
              />
  
            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  }
export default function DraftProfile(props){
    const classes = useStyles();
    const [detail, setDetail] = React.useState({});
    const [isSentNote, setIsSentNote] = React.useState(false);
    const [changeStatus, setChangeStatus] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [sendChanges, setSendChanges] = React.useState(false);
    let status = ['Approved', 'Rejected']
    const handleClickOpen = () => {
        setChangeStatus(true);
      };
      const handleClose = (value) => {
        setChangeStatus(false);
      };

    const FormikNewDropdown = ({ textFieldProps, ...props }) => {
        const [meta] = useField(props);
        const { form: { setTouched, setFieldValue } } = props;
        const { ...field } = fieldToTextField(props);
        const { name } = field;
        const classes = useStyles();
        return (
            <>
                <Autocomplete
                style={{marginTop:10}}
                {...props}
                {...field}
                className={classes.multiSelect}
                onChange={ (_, value) => setFieldValue(name, value) }
                onBlur={ () => setTouched({ [name]: true }) }
                renderInput={(props ) => <TextField {...props } label="Please Select" {...textFieldProps} variant="outlined" />}
                >
                </Autocomplete>
    
            {meta.touched && meta.error ? (
                <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
                </Typography>
            ) : null}
            </>
        );
    }
    function AdminRequestDialog(props) {
        const classes = useStyles();
        const { onClose, open } = props;
      
        const handleClose = () => {
          onClose(null);
        };
      
        return (
          <Dialog style={{padding:20}} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">
              <Typography align="center" variant="h4" style={{marginBottom:20}}>Status</Typography>
            </DialogTitle>
            <Grid container justify="center" xs={12}>
                        <Grid item xs={12}>
                          <Formik
                          initialValues={{
                            status: '',
                            description: '',
                          }}
                          onSubmit={values => {
                            handleSubmit(values);
                          }}
                          > 
                          {props => (
                              <Form>
                                <Grid xs={12} justify="center">
                                  <Grid spacing={3} item container alignItems="flex-end">
                                    <Grid item container xs={12}>
                                      <Typography variant="h5" style={{marginBottom:10}}>Change Status</Typography> 
                                      <Field component={FormikNewDropdown} style={{width:'100%',marginBottom:20}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="status" options={status} />
                                    </Grid> 
                                    <Grid item container xs={12} style={{height:'fit-content'}}>
                                      <Typography variant="h5" style={{marginBottom:10}}>Status Change Description</Typography>  
                                      <FormikTextField name="description" style={{width:'100%',marginBottom:20}} type="text" placeholder="Description" />
                                    </Grid>  
                                    <Grid item container xs={12} justify="center" style={{marginBottom:20,height:'fit-content'}}> 
                                      <Button variant="containedPrimary" style={{fontWeight: 'normal',width:'100%'}} type="submit">Change</Button>
                                    </Grid>
                                  </Grid>     
                                </Grid>       
                              </Form> 
                             )}
                           </Formik> 
                        </Grid>
            </Grid>
          </Dialog>
        );
      }
      
      AdminRequestDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
      };

    const DRAFTS = gql`
    query startupGetDraft($draftId: ID!)
    {
        startupGetDraft(draftId:$draftId){
            id
            title
            website
            logo
            location
            founderSize
            description
            stage
            marketTotalSize
            marketTargetRegion
            businessModel
            establishedDate
            foundationDate
            lastRoundDate
            lastRoundInvestment
            foundersPrevStartupExp
            foundersPrevCorporateExp
            competitorAmount
            postMoneyValuation
            capTableAInvestors
            capTableAFounding
            productProblem
            productSolution
            investmentRaisedAmount
            capTableAInvestors
            competitorAnalysis
            investmentNeeded
            investmentNeededDD
            pitchDeck
            businessPlan
            payrollFA
            marketingFA
            salesFA
            developmentFA
            rdFA
            otherFA
            partnerships
            goToMarketStrategy
            exitStrategy
            rating
            docsRoadmap
            docsStatistics
            docsBreakdowns
            docsSummary
            docsCapTable
            industry
            totalRevenueGrowth
            averageRevenueGrowth
            revenueRecurringType
            productDifFromComp
            firstCompetitorsName
            firstCompetitorsWebsite
            secondCompetitorsName
            secondCompetitorsWebsite
            thirdCompetitorsName
            thirdCompetitorsWebsite
            sharePercent
            founderMembers{
              id
              nameSurname
              profilePhoto
              jobTitle
              socialLinkedIn
              career{
                typeStartOrCorporate
                company
                jobTitle
                isPresent
              }
              madeExit
              exitedCompany
              exitedCompanyStatus
            }
            otherMembers{
                id
                nameSurname
                jobTitle
                socialLinkedIn
            }
            city{
              id
              title
              country{
                  id
                  title
              }
            }
            country{
              id
              title
            }
           
        }

    }
    `;
    const [ getData, { loading:loadingDraft, data:dataDraft } ] = useLazyQuery(DRAFTS, {
        fetchPolicy:"network-only",
        variables: {draftId:props.match.params.id},
    });
    React.useEffect(() => {
        if(!loadingDraft){
            if(!dataDraft)
            {
                getData()
            }  
            if(dataDraft){
                if(dataDraft.startupGetDraft.productProblem){
                    let htmlProduct = dataDraft.startupGetDraft.productProblem
                    if(typeof(dataDraft.startupGetDraft.productProblem) === 'string'){
                        dataDraft.startupGetDraft.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{dataDraft.startupGetDraft.productProblem = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.productDifFromComp){
                    let htmlProduct = dataDraft.startupGetDraft.productDifFromComp
                    if(typeof(dataDraft.startupGetDraft.productDifFromComp) === 'string'){
                        dataDraft.startupGetDraft.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{dataDraft.startupGetDraft.productDifFromComp = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.productSolution){
                    let htmlProduct = dataDraft.startupGetDraft.productSolution
                    if(typeof(dataDraft.startupGetDraft.productSolution) === 'string'){
                        dataDraft.startupGetDraft.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{dataDraft.startupGetDraft.productSolution = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.partnerships){
                    let htmlAbt= dataDraft.startupGetDraft.partnerships
                    if(typeof(dataDraft.startupGetDraft.partnerships) === 'string'){
                        dataDraft.startupGetDraft.partnerships = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{dataDraft.startupGetDraft.partnerships = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.competitorAnalysis){
                    let htmlAbt= dataDraft.startupGetDraft.competitorAnalysis
                    if(typeof(dataDraft.startupGetDraft.competitorAnalysis) === 'string'){
                        dataDraft.startupGetDraft.competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{dataDraft.startupGetDraft.competitorAnalysis = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.goToMarketStrategy){
                    let htmlAbt= dataDraft.startupGetDraft.goToMarketStrategy
                    if(typeof(dataDraft.startupGetDraft.goToMarketStrategy) === 'string'){
                        dataDraft.startupGetDraft.goToMarketStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{dataDraft.startupGetDraft.goToMarketStrategy = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.exitStrategy){
                    let htmlAbt= dataDraft.startupGetDraft.exitStrategy
                    if(typeof(dataDraft.startupGetDraft.exitStrategy) === 'string'){
                        dataDraft.startupGetDraft.exitStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{dataDraft.startupGetDraft.exitStrategy = new EditorState.createEmpty()}
                if(dataDraft.startupGetDraft.founderMembers === null){
                    dataDraft.startupGetDraft.founderMembers = []
                }
                if(dataDraft.startupGetDraft.otherMembers === null){
                    dataDraft.startupGetDraft.otherMembers = []
                }
                if(!dataDraft.startupGetDraft.city){
                    dataDraft.startupGetDraft.city = " "
                }
                if(!dataDraft.startupGetDraft.country){
                    dataDraft.startupGetDraft.country = " "
                }
                if(dataDraft.startupGetDraft.founderMembers !== [] && dataDraft.startupGetDraft.founderMembers){
                    const newArray = dataDraft.startupGetDraft.founderMembers.map(({__typename,  ...value})  => value)   
                    dataDraft.startupGetDraft.founderMembers = newArray 
                    dataDraft.startupGetDraft.founderMembers.forEach(function(v){ 
                        if(v.career!== null ){
                            v.career.forEach(function(a){ 
                                if(a!== null ){
                                    delete a.__typename 
                                }

                            })
                        }
                        else{
                            v.career = [];
                        }
                      });
                    }
              setDetail(dataDraft.startupGetDraft);
              }
            }
            },
            [loadingDraft, dataDraft])

            const handleChange = (event, newValue) =>  {
                setValue(newValue)
            };

            const handleChangeIndex= (index) => {
                setValue(index)
            };

            const handleStartupApprove = () => {
                  let variables = {}
                  variables.id = props.match.params.id
                  variables.status = "Ready for moderation"
                  props.startupManageDraft({ variables }).then(r => {
                    if (r.data.startupManageDraft === true){
                        props.history.push('/my-startup')
                    }
                  })
            };

            const handleSubmit = variables => {
                variables.id = props.match.params.id
                props.adminManageApproval({ variables }).then(data => {
                  if (data.data.adminManageApproval){
                      props.history.push('/admin/approve-list')
                  }
                })
            };

            return(

            <div className="App">
            {!loadingDraft && 
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
                <Grid container justify="center" style={{background:'#f7f7fc'}}>
                    {console.log('detail',detail)}
                    <Grid className="main-container">
                        <Grid container justify="flex-end" className={classes.secondPart}>
                                { ((props.authRole === 'StartupContact' )) ?
                                    <Grid>
                                        {sendChanges && <Button variant="containedSecondary" onClick={handleStartupApprove}>
                                            Send Changes</Button>}
                                    </Grid> : <Grid container justify="flex-end">
                                        <Button style={{marginRight:15}} variant="containedSecondary" onClick={handleClickOpen}>
                                            Handle Request</Button>
                                            <AdminRequestDialog open={changeStatus} onClose={handleClose}/>
                                    </Grid>      
                                }
                            </Grid>
                        <Grid style={{marginBottom: '50px'}} item container xs={12} sm={12} lg={4} className={classes.firstPart}>
                            <Grid item xs={12} sm={11} xl={11}>
                                {(detail.country) &&  <MyCompanyCard id={detail.id} website={detail.website} isFavorited={detail.isFavorited} title={detail.title} businessModel={detail.businessModel}
                                logo={detail.logo} totalValuation={detail.postMoneyValuation} funding={detail.investmentRaisedAmount} foundationDate={detail.establishedDate} investmentNeededDD={detail.investmentNeededDD} location={detail.city}
                                country={detail.country} pitchDeck={detail.pitchDeck} businessPlan={detail.businessPlan}/>}                              
                                <MyRatingCard rating={detail.rating} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} xl className={classes.secondPart}>
                            <AppBar style={{boxShadow: 'none'}} position="static" color="default">
                                <Tabs className={classes.paper}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                >
                                    <Tab
                                        classes={{root: classes.tabButton}} label="Company" {...a11yProps(0)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Team" {...a11yProps(1)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Competition & Market" {...a11yProps(2)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Investment" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel className={classes.tabPanel} value={value} index={0}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 && 
                                            <MyTabCompanyCard detail={detail} setIsSentNote={setIsSentNote} getData={getData} history={props.history} params={props.match.params} authRole={props.authRole} setSendChanges={setSendChanges}/>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={value} index={1} >
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        <MyTeamCard founderMembers={detail.founderMembers} setIsSentNote={setIsSentNote} getData={getData} authRole={props.authRole} params={props.match.params} id={detail.id} otherMembers={detail.otherMembers} setSendChanges={setSendChanges}/>
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                                <MyTabMarketCard detail={detail} setIsSentNote={setIsSentNote} getData={getData} params={props.match.params} authRole={props.authRole} setSendChanges={setSendChanges}/>
                                            </div>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                                <MyTabBusinessCard detail={detail} setIsSentNote={setIsSentNote} getData={getData} params={props.match.params} authRole={props.authRole} setSendChanges={setSendChanges}/>
                                            </div>
                                        }
                                    </Grid>
                                </TabPanel>
                            </SwipeableViews>
                            <Snackbar autoHideDuration={5000} onClose={() => setIsSentNote(false)} open={isSentNote} >
                                <Alert severity="success">Successful.</Alert>
                            </Snackbar>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
                }  
            </div>
        )
    }
