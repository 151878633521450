import { graphql } from 'react-apollo';
import * as compose from 'lodash.flowright';
import gql from 'graphql-tag';
import NoteCard from "./NoteCard";

const ADMIN_STARTUP_ADD_NOTE = gql`
    mutation adminStartupAddNote(
        $id: ID!,
        $note: String!
    ) {
        adminStartupAddNote(
            id: $id,
            note: $note
        )
    }
`;

const JoinWithMutation = compose(
  graphql(ADMIN_STARTUP_ADD_NOTE, {
    name: 'adminStartupAddNote',
  }),
)(NoteCard);
export default JoinWithMutation;