import SuperAdminInvestorEdit from './SuperAdminInvestorEdit'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const EDIT_INVESTOR = gql`
  mutation editInvestor(
    $id: ID!
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $socialLinkedIn: String
    $hearAboutUs: String
    $totalInvestedCompanies: String
    $totalInvestmenAmount: String
    $budgetForAE: String
    $role: String
    $planInvestTwoYear: String
    $industry: [String]
) {
  editInvestor(
        id: $id
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        socialLinkedIn: $socialLinkedIn
        hearAboutUs: $hearAboutUs
        totalInvestedCompanies: $totalInvestedCompanies
        totalInvestmenAmount: $totalInvestmenAmount
        budgetForAE: $budgetForAE
        role: $role
        planInvestTwoYear: $planInvestTwoYear
        industry: $industry
    )
  }
`;
const JoinWithMutation = graphql(EDIT_INVESTOR, {
    name: 'editInvestor',
  })(SuperAdminInvestorEdit);
  export default JoinWithMutation;