import SuperAdminInvestorDetail from './SuperAdminInvestorDetail'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const ADMIN_INVESTOR_MUTATIONS = gql`
    mutation adminInvestorMutations($id:ID!, $inputType: String!) {
        adminInvestorMutations(id:$id, input: {type: $inputType})
    }
`;

const JoinWithMutation = graphql(ADMIN_INVESTOR_MUTATIONS, {
  name: 'adminInvestorMutations',
})(SuperAdminInvestorDetail);
export default JoinWithMutation