import { TextField, Typography, Grid } from '@material-ui/core';
import { useField } from 'formik';
import useFastField from './useFastField.js'
import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>({
  inputField: {
    [theme.breakpoints.up('sm')]: {
            width: '490px'
        },
        width: '100%',
        marginTop: 10
    },
}))


export default function FormikTextField({ label, ...props }) {
  const [showPassword] = React.useState(false);
  const [field, meta] = useFastField(props);
  const classes = useStyles();
  return (
    <>
    <Grid style={{minHeight:95}}>
      <TextField
        variant="outlined"
        className={classes.inputField}
        {...field}
        {...props}
        error={meta.touched && meta.error}
        placeholder={props.placeholder}
        type={props.type === 'password' ? (showPassword ? 'text' : 'password') : 'text'}
      />
      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
      </Grid>
    </>
  );
}
