import FirstLogin from "./FirstLogin"
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const FIRST_LOGIN = gql`
  mutation investorSignContract(
    $address: String
    $tcNumber: String
    $date: String
  ) {
    investorSignContract(
      address: $address
      tcNumber: $tcNumber
      date: $date
    ) 
  }
`;

const JoinWithMutation = graphql(FIRST_LOGIN, {
  name: 'investorSignContract',
})(FirstLogin);
export default JoinWithMutation;
