import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import MyCompanyCard from "../../Components/MyCompanyCard";
import MyRatingCard from "../../Components/MyRatingCard";
import MyTeamCard from "../../Components/MyTeamCard";
import MyTabCompanyCard from "../../Components/MyTabCompanyCard";
import editImg from '../../static/icon/edit.svg';
import MyTabMarketCard from "../../Components/MyTabMarketCard";
import MobileHeader from "../../Components/MobileHeader";
import MyTabBusinessCard from "../../Components/MyTabBusinessCard";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Typography from "@material-ui/core/Typography";
import {Form, Formik} from 'formik';
import {Link} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import 'draft-js/dist/Draft.css';
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import BlackEdit from '../../static/img/new.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import moment from 'moment'

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) =>({
        containerCss: {
            display: 'flex',
            justifyContent: 'center',
        },
        firstPart: {
            marginTop: 20,
            justifyContent:"center",
            maxWidth: 550,
            [theme.breakpoints.up('xl')]: {
                justifyContent:"start", 
            },

        },
        secondPart: {
            marginTop: 20,
            [theme.breakpoints.up('lg')]: {
                paddingRight:20
            },
        },
        myDialog:{
            overflowY: 'unset',
            overflowX:'hidden',
            padding:40      
          },
        cardContainer: {
            marginBottom: '15px',
        },
        paper: {
            padding: '27px 0',
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            '& div': {
                [theme.breakpoints.up('xl')]: {
                    justifyContent: 'center',
                },
            }
        },
        tabButton: {
            fontSize: '16px',
            color: '#00000087',
            minWidth: 0,
            textTransform:'none',
            maxWidth: 'fit-content',
            padding: '6px 0',
            margin: '0 64px',
        },
        paddingZero: {
            padding: '0',
        },
    }
));
const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#f1f3f9',
      color: '#8898aa;',
      height: 30,
      fontSize: '12px'
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#f7fafc'
      },
      color:'#172b4d',
      fontWeight:'600'
    },
  }))(TableRow);
export default function MyStartupProfile(props){
    const classes = useStyles();
    const [detail, setDetail] = React.useState({});
    const [draft, setDraft] = React.useState([]);
    const [changeStatus, setChangeStatus] = React.useState(false);
    const [id, setId] = React.useState();
    const [value, setValue] = React.useState(0);
    const handleClickOpen = () => {
        setChangeStatus(true);
      };
      const handleClose = (value) => {
        setChangeStatus(false);
      };
      const handleDraft = variables => {
        console.log(props)
        props.startupNewDraft()
            .then(data => {
                if (data.data.startupNewDraft) {
                    props.history.push(`/draft-startup/${data.data.startupNewDraft}`)
                } 
                else {

                }
          })
      };  
        function ChangeDialog(props) {
        const classes = useStyles();
        const { onClose, open } = props;
      
        const handleClose = () => {
          onClose(null);
        };   
        return (
          <Dialog style={{padding:20}} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog}} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <Grid container justify="center" xs={12}>
                        <Grid item xs={12}>
                          <Formik
                          initialValues={{
                            statusAdmin: '',
                            description: '',
                          }}
                          onSubmit={values => {
                            handleDraft(values);
                          }}
                          > 
                          {props => (
                              <Form>
                                <Typography variant="body2" style={{marginBottom:5}}>- All changes you make will go live after admin approval.</Typography>
                                    <Typography variant="body2" style={{marginBottom:20}}>
                                    - Please click "Send Changes" for Admin approval after save your edits.
                                    </Typography>
                                <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                                <Grid container justify="space-between" alignItems="center" style={{marginBottom:20}}>
                                    <Typography variant="h5">Draft History</Typography>
                                    <Button variant="outlinedPrimary" style={{fontWeight: 'normal', padding: '10.5px 20px'}} type="submit">Add Draft</Button>
                                </Grid>
                                <TableContainer component={Paper}>
                                  <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                      <TableRow>
                                        <StyledTableCell style={{padding:'5px 16px'}}>WHEN</StyledTableCell>
                                        <StyledTableCell style={{padding:'5px 16px'}} align="left">ADMIN STATUS</StyledTableCell>
                                        <StyledTableCell style={{padding:'5px 16px'}} align="left">STARTUP STATUS</StyledTableCell>
                                        <StyledTableCell style={{padding:'5px 16px'}} align="left"></StyledTableCell>
                                      </TableRow>
                                    </TableHead>
                                    {draft && 
                                    <TableBody>
                                    {draft.map((row) => (             
                                      <StyledTableRow key={row.id}>                                          
                                        <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600'}} scope="row">
                                        {moment(parseInt(row.lastUserEdit)).format('DD-MM-YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.status}</StyledTableCell>
                                        <StyledTableCell align="left">{row.moderationStatus}</StyledTableCell>
                                        {(row.status === 'Pending' && row.moderationStatus === 'Draft') &&
                                        <StyledTableCell align="left">
                                            <Grid container >
                                                <Link style={{padding:0}} to={`/draft-startup/${row.id}`}><img src={BlackEdit} alt="sticker" /></Link>
                                            </Grid>
                                        </StyledTableCell>
                                        }
                                      </StyledTableRow>
                                    ))}                                   
                                  </TableBody>}

                                  
                                  </Table>
                                </TableContainer>                
                              </Form> 
                             )}
                           </Formik> 
                        </Grid>
            </Grid>
          </Dialog>
        );
      }
      
      ChangeDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
      };
      
    const MY_STARTUP = gql`
    query myStartup {
            myStartup{
            id
            title
            website
            logo
            location
            founderSize
            description
            stage
            marketTotalSize
            marketTargetRegion
            businessModel
            establishedDate
            foundationDate
            lastRoundDate
            lastRoundInvestment
            foundersPrevStartupExp
            foundersPrevCorporateExp
            competitorAmount
            postMoneyValuation
            capTableAInvestors
            capTableAFounding
            productProblem
            productSolution
            investmentRaisedAmount
            capTableAInvestors
            competitorAnalysis
            investmentNeeded
            investmentNeededDD
            pitchDeck
            businessPlan
            payrollFA
            marketingFA
            salesFA
            developmentFA
            rdFA
            otherFA
            partnerships
            goToMarketStrategy
            exitStrategy
            isFavorited
            rating
            docsRoadmap
            docsStatistics
            docsBreakdowns
            docsSummary
            docsCapTable
            industry
            totalRevenueGrowth
            averageRevenueGrowth
            revenueRecurringType
            productDifFromComp
            firstCompetitorsName
            firstCompetitorsWebsite
            secondCompetitorsName
            secondCompetitorsWebsite
            thirdCompetitorsName
            thirdCompetitorsWebsite
            sharePercent
            founderMembers{
              id
              nameSurname
              profilePhoto
              jobTitle
              socialLinkedIn
              madeExit
              exitedCompany
              exitedCompanyStatus
              career{
                typeStartOrCorporate
                company
                jobTitle
                isPresent
              }
            }
            otherMembers{
                id
                nameSurname
                jobTitle
                socialLinkedIn
            }
            city{
              id
              title
              country{
                  id
                  title
              }
            }
            country{
              id
              title
            }
        }
  
    }
    `;
      const { loading, data } = useQuery(MY_STARTUP, {
        fetchPolicy:"network-only",
        variables: {},
    });

    const DRAFTS = gql`
    query startupAdminApprovals($startupId: ID)
    {
        startupAdminApprovals(startupId:$startupId){
            id
            status
            lastUserEdit
            moderationStatus
        }

    }
    `;
    const { loading:loadingDraft, data:dataDraft } = useQuery(DRAFTS, {
        fetchPolicy:"network-only",
        variables: {startupId:id },
    });
    React.useEffect(() => {
        if(!loading){
            if(data){
                setId(data.myStartup.id)
                if(data.myStartup.productProblem){
                    let htmlProduct = data.myStartup.productProblem
                    if(typeof(data.myStartup.productProblem) === 'string'){
                        data.myStartup.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.myStartup.productProblem = new EditorState.createEmpty()}
                if(data.myStartup.productSolution){
                    let htmlProduct = data.myStartup.productSolution
                    if(typeof(data.myStartup.productSolution) === 'string'){
                        data.myStartup.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.myStartup.productSolution = new EditorState.createEmpty()}
                if(data.myStartup.productDifFromComp){
                    let htmlProduct = data.myStartup.productDifFromComp
                    if(typeof(data.myStartup.productDifFromComp) === 'string'){
                        data.myStartup.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlProduct))
                    }
                }
                else{data.myStartup.productDifFromComp = new EditorState.createEmpty()}
                if(data.myStartup.partnerships){
                    let htmlAbt= data.myStartup.partnerships
                    if(typeof(data.myStartup.partnerships) === 'string'){
                        data.myStartup.partnerships = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.myStartup.partnerships = new EditorState.createEmpty()}
                if(data.myStartup.competitorAnalysis){
                    let htmlAbt= data.myStartup.competitorAnalysis
                    if(typeof(data.myStartup.competitorAnalysis) === 'string'){
                        data.myStartup.competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.myStartup.competitorAnalysis = new EditorState.createEmpty()}
                if(data.myStartup.goToMarketStrategy){
                    let htmlAbt= data.myStartup.goToMarketStrategy
                    if(typeof(data.myStartup.goToMarketStrategy) === 'string'){
                        data.myStartup.goToMarketStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                else{data.myStartup.goToMarketStrategy = new EditorState.createEmpty()}
                if(data.myStartup.exitStrategy){
                    let htmlAbt= data.myStartup.exitStrategy
                    if(typeof(data.myStartup.exitStrategy) === 'string'){
                        data.myStartup.exitStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
                    }
                }
                if(data.myStartup.founderMembers === null){
                    data.myStartup.founderMembers = []
                }
                if(data.myStartup.otherMembers === null){
                    data.myStartup.otherMembers = []
                }
                if(!data.myStartup.city){
                    data.myStartup.city = " "
                }
                if(!data.myStartup.country){
                    data.myStartup.country = " "
                }
                else{data.myStartup.exitStrategy = new EditorState.createEmpty()}
                if(data.myStartup.founderMembers !== []){
                    const newArray = data.myStartup.founderMembers.map(({__typename,  ...value})  => value)   
                    data.myStartup.founderMembers = newArray 
                    data.myStartup.founderMembers.forEach(function(v){ 
                        if(v.career!== null ){
                            v.career.forEach(function(a){ 
                                if(a!== null ){
                                    delete a.__typename 
                                }

                            })
                        }
                        else{
                            v.career = [];
                        }
                      });
                    }
                    setDetail(data.myStartup);
              }
            }
            if(!loadingDraft){
                if(dataDraft){
                    setDraft(dataDraft.startupAdminApprovals)
                }
            }
            },
            [data, loading, loadingDraft, dataDraft])

            const handleChange = (event, newValue) =>  {
                setValue(newValue)
            };

            const handleChangeIndex= (index) => {
                setValue(index)
            };
        return (

            <div className="App">
            {!loading && 
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <MobileHeader></MobileHeader>
                </Hidden>
                <Grid container justify="center" style={{background:'#f7f7fc'}}>
                    <Grid className="main-container">
                            <Grid container justify="flex-end" className={classes.secondPart}>
                                { ((props.authRole !== 'Admin' || props.authRole !== 'SuperAdmin')) &&
                                    <Grid>
                                            <Button variant="containedSecondary" onClick={handleClickOpen}>
                                            <img src={editImg} alt="edit" style={{marginRight:'5px'}}/>Edit</Button>
                                        <ChangeDialog open={changeStatus} onClose={handleClose}/> 
                                    </Grid>      
                                }
                            </Grid>
                        <Grid style={{marginBottom: '50px'}} item container xs={12} sm={12} lg={4} className={classes.firstPart}>
                            <Grid item  xs={12} sm={11} xl={11}>
                                {(detail.country) &&  <MyCompanyCard id={detail.id} website={detail.website} isFavorited={detail.isFavorited} title={detail.title} businessModel={detail.businessModel}
                                logo={detail.logo} totalValuation={detail.postMoneyValuation} investmentNeededDD={detail.investmentNeededDD} funding={detail.investmentRaisedAmount + detail.lastRoundInvestment} foundationDate={detail.establishedDate} location={detail.city}
                                country={detail.country} pitchDeck={detail.pitchDeck} businessPlan={detail.businessPlan}/>}                              
                                <MyRatingCard rating={detail.rating} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={8} xl className={classes.secondPart}>
                            <AppBar style={{boxShadow: 'none'}} position="static" color="default">
                                <Tabs className={classes.paper}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                >
                                    <Tab
                                        classes={{root: classes.tabButton}} label="Company" {...a11yProps(0)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Team" {...a11yProps(1)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Competition & Market" {...a11yProps(2)} />
                                    <Tab 
                                        classes={{root: classes.tabButton}} label="Investment" {...a11yProps(3)} />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel className={classes.tabPanel} value={value} index={0}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 &&
                                            <Grid>
                                                {
                                                    draft.length > 0 ? 
                                                    <Grid>
                                                        <MyTabCompanyCard detail={detail} authRole={props.authRole} history={props.history} draft={draft}/>
                                                    </Grid>     
                                                    : 
                                                    <MyTabCompanyCard detail={detail} authRole={props.authRole} history={props.history}/>
                                                }                                          
                                                
                                            </Grid>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel className={classes.tabPanel} value={value} index={1} >
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {draft.length > 0 ? 
                                        <MyTeamCard founderMembers={detail.founderMembers} authRole={props.authRole} id={detail.id} history={props.history} otherMembers={detail.otherMembers} draft={draft}/>
                                        : 
                                        <MyTeamCard founderMembers={detail.founderMembers} authRole={props.authRole} id={detail.id} history={props.history} otherMembers={detail.otherMembers}/>}

                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={2} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                                <MyTabMarketCard authRole={props.authRole} detail={detail} draft={draft}/>
                                            </div>
                                        }
                                    </Grid>
                                </TabPanel>
                                <TabPanel value={value} index={3} className={classes.tabPanel}>
                                    <Grid style={{marginBottom: '50px', marginTop: '35px'}} item xs={12} sm={12}>
                                        {Object.keys(detail).length > 0 && 
                                            <div>
                                                <MyTabBusinessCard authRole={props.authRole} detail={detail} draft={draft}/>
                                            </div>
                                        }
                                    </Grid>
                                </TabPanel>
                            </SwipeableViews>
                        </Grid>
                    </Grid>
                </Grid>
                </div>
                }  
            </div>
        )
    }
