import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import startButton from '../../static/img/start-button.svg';
import video from '../../static/img/video.svg';
import thumbUp from '../../static/img/thumb-up.svg';
import clipBoard from '../../static/img/clipboard.svg';
import Hidden from "@material-ui/core/Hidden";
import {Link} from "react-router-dom"
import Grid from "@material-ui/core/Grid";
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

const useStyles = makeStyles((theme) => ({
    link: {
        padding: 0,
        height: '100%',
        width: '100%'
    },
    item:{
        height: '100%'
    }
}));


export default function BottomNavigation(props) {

    const classes = useStyles();
    return(
            <Hidden lgUp>
              <Grid xs={12} className="bottom-navigation">
              {window.location.pathname === "/admin/investors" ? <Link className={classes.link} to="/admin/investors">
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <PeopleOutlineIcon style={{fontSize:32}} />
                            </Grid> 
                        </Link> :<Link className={classes.link} to="/admin/investors">
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <PeopleOutlineIcon style={{fontSize:32}} />
                            </Grid> 
                        </Link>} 
                        {window.location.pathname === "/admin/startups" ? <Link className={classes.link} to="/admin/startups">
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <img src={startButton} alt="startButton" />
                            </Grid> 
                        </Link> :<Link className={classes.link} to="/admin/startups">
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <img src={startButton} alt="startButton" />
                            </Grid> 
                        </Link>} 
                        {window.location.pathname === "/admin/videos" ? <Link className={classes.link} to="/admin/videos">
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <img src={video} alt="video" />
                            </Grid> 
                        </Link> :<Link className={classes.link} to="/admin/videos">
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <img src={video} alt="video" />
                            </Grid> 
                        </Link>}
                        {window.location.pathname === "/admin/logs" ? <Link className={classes.link} to="/admin/logs">
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <img src={thumbUp} alt="thumbUp" />
                            </Grid> 
                        </Link> :<Link className={classes.link} to="/admin/logs">
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <img src={thumbUp} alt="thumbUp" />
                            </Grid> 
                        </Link>}  
                        {window.location.pathname === "/admin/dashboard" ? <Link className={classes.link} to="/admin/dashboard">
                            <Grid container alignItems="center" justify="center" style={{background:'rgba(40, 177, 231, 0.18)'}} className={classes.item}>
                                <img src={clipBoard} alt="clipBoard" />
                            </Grid> 
                        </Link> :<Link className={classes.link} to="/admin/dashboard">
                            <Grid container alignItems="center" justify="center" style={{transition:'0.5s'}} className={classes.item}>
                                <img src={clipBoard} alt="clipBoard" />
                            </Grid> 
                        </Link>}  
              </Grid>
            </Hidden>  
    );
}