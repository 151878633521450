
import {graphql} from 'react-apollo';
import * as compose from 'lodash.flowright';
import InvestorJoinUs from './InvestorJoinUs';
import gql from 'graphql-tag';
const SIGNUP_USER_MUTATION = gql`
  mutation registerInvestor(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $socialLinkedIn: String
    $hearAboutUs: String
    $totalInvestedCompanies: String
    $totalInvestmenAmount: String
    $budgetForAE: String
    $planInvestTwoYear: String
    $industry: [String]
   ) {
    registerInvestor(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      socialLinkedIn: $socialLinkedIn
      hearAboutUs: $hearAboutUs
      totalInvestedCompanies: $totalInvestedCompanies
      totalInvestmenAmount: $totalInvestmenAmount
      budgetForAE: $budgetForAE
      planInvestTwoYear: $planInvestTwoYear
      industry: $industry
    ){
        token
        user{
            id
            firstName
            lastName
            email
            role
            contractSigned
            startup{
                id
                applicationStage
            }
        }
    }
  }
`;

const JoinWithMutation = compose(
graphql(SIGNUP_USER_MUTATION, {
    name: 'registerInvestor',
  }),
  )(InvestorJoinUs);
export default JoinWithMutation;