import Header from "../../Components/Header";
import React from "react";
import Input from "@material-ui/core/Input"
import Hidden from "@material-ui/core/Hidden";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MobileHeader from "../../Components/MobileHeader";
import {makeStyles} from "@material-ui/core/styles";
import StartupCard from "../../Components/StartupCard";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {Link} from 'react-router-dom';
import {DebounceInput} from 'react-debounce-input';
import arrow from '../../static/icon/arrow-left.svg';
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) =>({
  Title: {
    fontSize: 25,
    fontWeight: 'bold',
    lineHeight:  1.35,
    color: '#172b4d',
},
html:{
    background: '#ffffff'
},
favButton: {
    padding: '5.5px 18px',
    fontWeight:'normal',
    fontSize:15,
    marginLeft:15,
    height:'100%'
},
Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
        marginTop: 30,
        justifyContent: 'start',
    },
    [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
    },  
  },
accordionGrid: {
    height:'fit-content',
    marginBottom:50  
},
cardGrid: {
    [theme.breakpoints.down('xs')]: {
        margin: 0
    },
    height:'fit-content'
},
accordionPanel: {
    maxWidth: 240,
    width: '100%',
    boxShadow: 'none',
    borderBottom: 'none',
    background: '#f6f9fc',
    marginTop: '25px!important',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
    },
    '&:hover': {
      backgroundColor: '#e7eff7',
    },
},
logo: {
    width: 180,
    height: 50,
    marginTop:30,
},
multiSelect: {
    maxHeight: '40px',
    border: '1px solid #bdbdbd',
    background: '#ffffff',
    borderRadius:4,
    '& div': {
        background: 'none!important',
    }
},
heading: {
    fontWeight: 600,
    fontSize:16
},
Debounce: {
  width : '100%',
  maxWidth: '180px',
  fontSize:14,
  height:'35px',
  borderRadius:'4px',
  fontFamily:'Nunito Sans, Roboto, sans-serif',
  border:'1px solid #bdbdbd' ,
  outline:'none',
  padding: '2px 8px 2px 20px',
  marginRight:15,
  [theme.breakpoints.down('xs')]: {
      height:'40px',
  },
},
  ListItemText: {
    '& span': {
      fontSize: '14px',
    },
  },
  topPart: {
    width:'fit-content'
  }
  }
));
export default function Startups(props){
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(6);
  const [startupsCount, setStartupsCount] = React.useState(0);
  const classes = useStyles();
  const [sort, setSort] = React.useState([]);
  const [startups, setStartups] = React.useState([]);
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [stage, setStage] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [industry, setIndustry] = React.useState([]);
  const [businessModel, setBusinessModel] = React.useState([]);
  const [postMoneyValuation, setPostMoneyValuation] = React.useState([]);
  const [teamMemberSize, setTeamMemberSize] = React.useState([]);
  const [founderWithPrevExit, setfounderWithPrevExit] = React.useState([]);
  const [countryIDS, setCountryIDS] = React.useState([]);
  const [countryFilterOptions, setCountryFilterOptions] = React.useState([]);
  const [countryFilterOptionsLoaded, setCountryFilterOptionsLoaded] = React.useState(false);
  const FAVORITED_STARTUPS = gql`
      query favoritedStartups($order:String, $stage:[String], $status:[String], $industry:[String], $businessModel:[String], $countryIDS:[ID], $founderWithPrevExit:[String],  $postMoneyValuation:[String], $teamMemberSize:[String], $search: String, $page: Int, $pageSize: Int) {
          favoritedStartups(orderBy:[$order],filter : { stage : $stage , industry : $industry , status : $status , countryId : $countryIDS , businessModel : $businessModel, postMoneyValuation : $postMoneyValuation, teamMemberSize : $teamMemberSize,founderWithPrevExit : $founderWithPrevExit}, search:$search, pagination:{page:$page, pageSize:$pageSize}){
              count
              pageCount
              startups{
                  id
                  title
                  logo
                  slug
                  investmentNeededDD
                  businessModel
                  description
                  competitorAmount
                  isFavorited
              }
          }
      }
  `;
  const { loading, data, refetch } = useQuery(FAVORITED_STARTUPS, {
    variables: {order:order, stage:stage, industry:industry, status:status, businessModel:businessModel, countryIDS:countryIDS, postMoneyValuation:postMoneyValuation, founderWithPrevExit:founderWithPrevExit, teamMemberSize:teamMemberSize, search:search, page:page, pageSize:pageSize },
    fetchPolicy:'network-only'
  });
  React.useEffect(() => {
      if(!loading){
        if(data){
          data.favoritedStartups.startups.forEach(element => {
            element.investmentNeededDD = parseInt(element.investmentNeededDD)
          });
          setStartups(data.favoritedStartups.startups);
          setPageCount(data.favoritedStartups.pageCount)
          setStartupsCount(data.favoritedStartups.count)
        }
      }
    },
    [data, loading])


  const COUNTRIES_MOST_APPLICATION = gql`
      query countriesFilterForFavorites{
          countriesFilterForFavorites{
              id
              title
              count
          }

      }
  `;
  const { loading:loadingCountryMostApp, data:dataCountryMostApp } = useQuery(COUNTRIES_MOST_APPLICATION, {
    fetchPolicy:"network-only",
  });
  React.useEffect(() => {
      if (!loadingCountryMostApp){
        if (dataCountryMostApp){
          setCountryFilterOptions(dataCountryMostApp.countriesFilterForFavorites)
          setCountryFilterOptionsLoaded(true)
        }
      }
    },
    [dataCountryMostApp, loadingCountryMostApp]);


  const stageOptions = ['Idea', 'Prototype', 'MVP (Minimum Viable Product)', 'Early', 'Scale-up', 'Growth',];
  const statusOptions = ['Archive', 'Re-evaluate', 'Information Waiting', 'In Progress', 'Voting', 'Portfolio', 'Exited'];
  const businessModelOptions = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
  const postMoneyValuationOptions = ['0-1000', '1000-10000', '10000-500000', '500000-0'];
  const postMoneyValuationOptionsDict = {'0-1000':'0-1.000', '1000-10000':'1.000-10.000', '10000-500000':'10.000-500.000', '500000-0':'500.000+'};
  const teamMemberSizeOptions = ['1-3', '4-6', '6+'];
  const founderWithPrevExitOptions = ['Yes', 'No'];
  const industryOptions = ['Agriculture', 'Biotechnology', 'Business Services', 'Clean Technology', 'Construction', 'Consulting' , 'Consumer Products', 'Consumer Services', 'Digital Marketing',
  'Education', 'Electronics / Instrumentation', 'Fashion', 'Financial Services',	'Fintech', 'Food and Beverage', 'Gaming', 'Healthcare Services', 'Energy', 'Internet / Web Services', 'IT Services',
  'Legal', 'Lifestyle', 'Marine', 'Maritime/Shipping', 'Marketing / Advertising', 'Media and Entertainment', 'Medical Devices and Equipment', 'Mobility', 'Nanotechnology', 	'Other', 'Real Estate',
  'Retailing / Distribution', 'Robotics', 'Security', 'Software', 'Sports', 'Telecommunications', 'Transportation', 'Travel'	];
  const options = [
    {name:'A-Z', value:'title__ASC'},
    {name:'Z-A', value:'title__DESC'},
    {name:'Latest', value:'id__DESC'},
    {name:'Oldest', value:'id__ASC'},
  ];
  const [state, setState] = React.useState({
    filter1: false,
    filter2: false,
    filter3: false,
    filter4: false,
    filter5: false,
    filter6: false,
  });

  const handleStage = (event) => {
    setPage(1);
    setState({ ...state, [event.target.name]: event.target.checked });
    if (stage.includes(event.target.value)){
      let a = stage.filter(e => e !== event.target.value);
      setStage(a);
    }
    else{
      setStage([...stage, event.target.value]);
    }
  };

  const handleCountryFilter = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (countryIDS.includes(event.target.value)){
      let a = countryIDS.filter(e => e !== event.target.value);
      setCountryIDS(a);
    }
    else{
      setCountryIDS([...countryIDS, event.target.value]);
    }
  };

  const handleStatus= (event) => {
    setPage(1);
    setState({ ...state, [event.target.name]: event.target.checked });
    if (status.includes(event.target.value)){
      let a = status.filter(e => e !== event.target.value);
      setStatus(a);
    }
    else{
      setStatus([...status, event.target.value]);
    }
  };

  const handleBusinessModel = (event) => {
    setPage(1);
    setState({ ...state, [event.target.name]: event.target.checked });
    if (businessModel.includes(event.target.value)){
      let a = businessModel.filter(e => e !== event.target.value);
      setBusinessModel(a);
    }
    else{
      setBusinessModel([...businessModel, event.target.value]);
    }
  };

  const handleIndustry= (event) => {
    setPage(1);
    setState({ ...state, [event.target.name]: event.target.checked });
    if (industry.includes(event.target.value)){
     let a = industry.filter(e => e !== event.target.value);
     setIndustry(a);
    }
    else{
      setIndustry([...industry, event.target.value]);
    }
  };

  const handlePostMoneyValuation = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (postMoneyValuation.includes(event.target.value)){
      let a = postMoneyValuation.filter(e => e !== event.target.value);
      setPostMoneyValuation(a);
    }
    else{
      setPostMoneyValuation([...postMoneyValuation, event.target.value]);
    }
  };

  const handlefounderWithPrevExit = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (founderWithPrevExit.includes(event.target.value)){
      let a = founderWithPrevExit.filter(e => e !== event.target.value);
      setfounderWithPrevExit(a);
    }
    else{
      setfounderWithPrevExit([...founderWithPrevExit, event.target.value]);
    }
  };

  const handleTeamMemberSize = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    if (teamMemberSize.includes(event.target.value)){
      let a = teamMemberSize.filter(e => e !== event.target.value);
      setTeamMemberSize(a);
    }
    else{
      setTeamMemberSize([...teamMemberSize, event.target.value]);
    }
  };

  const handleSort = event => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleSearch = event => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };
  
  return(
    <Grid>
      <Hidden smDown>
        <Header></Header>
      </Hidden>
      <Hidden mdUp>
        <MobileHeader></MobileHeader>
      </Hidden>
      <Grid container style={{background:'#f7f7fc'}} justify="center">
        <Grid className={"main-container"} xs={12} style={{marginTop:50}}>
          <Grid
            container
            justify="space-between"
            alignItems="center">
            <Grid
              container
              alignItems="center"
              className={classes.topPart}>
              <Link style={{padding:0}} to={`/startups/`}><img src={arrow} alt="arrow" style={{marginRight:25}}></img></Link>
              <Typography variant="h3">Listing {startupsCount} Favorites </Typography>
            </Grid>
            <Grid item container className={classes.Select} md={4} xs={12}>
              <DebounceInput
                class="inputTypeSearch"
                name="search"
                value={search}
                placeholder="Search by Name.."
                className={classes.Debounce}
                minLength={2}
                debounceTimeout={800}
                onChange={handleSearch} />
              <FormControl placeholder="Please Select" style={{width : '100%', maxWidth: '129px'}}>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  displayEmpty
                  value={sort}
                  className={classes.multiSelect}
                  onChange={handleSort}
                  input={<Input />}
                  /*   MenuProps={MenuProps} */
                >
                  <MenuItem value="" disabled>
                    <ListItemText className={classes.ListItemText} primary="Sorting" />
                  </MenuItem>
                  {options.map(option => (
                    <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                      <ListItemText className={classes.ListItemText} primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{marginTop: 50}}>
            <Grid item container md={3} sm={5} xs={12} className={classes.accordionGrid}>
              <Grid item md={11} xs={12} alignItems="center" style={{background:'#ffffff', borderRadius:'7px', display:'flex', flexDirection:'column', paddingTop:'20px',paddingBottom:40}}>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Valuation</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {postMoneyValuationOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handlePostMoneyValuation} name="filter5" value={value} />}
                                                    label={postMoneyValuationOptionsDict[value]}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Industry</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {industryOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handleIndustry} name="filter4" value={value} />}
                                                    label={value}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Startup Stage</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {stageOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handleStage} name="filter4" value={value} />}
                                                    label={value}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Business Model</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {businessModelOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handleBusinessModel} name="filter4" value={value} />}
                                                    label={value}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Location</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {countryFilterOptionsLoaded && countryFilterOptions && countryFilterOptions.map((value) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handleCountryFilter} name="filter8" value={value.id} />}
                                                    label={value.title + ' ('+value.count+')'}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Team Size</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {teamMemberSizeOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handleTeamMemberSize} name="filter6" value={value} />}
                                                    label={value}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel
                  className={classes.accordionPanel}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading} style={{fontWeight:600}}>Founders with exit</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormGroup>
                        {founderWithPrevExitOptions.map((value, i) => {
                          // Return the element. Also pass key
                          return (<FormControlLabel className="dropdown"
                                                    control={<Checkbox color="primary" onChange={handlefounderWithPrevExit} name="filter7" value={value} />}
                                                    label={value}
                          />)
                        })}
                      </FormGroup>
                    </FormControl>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            </Grid>
            <Grid className={classes.cardGrid} container spacing={3} item xs sm={12} md>
              {startups.map(startup => (
                <>
                {startup.isFavorited && <StartupCard name={startup.title} slug={startup.slug} logo={startup.logo} desc={startup.description}
                             investmentNeededDD={startup.investmentNeededDD} isFavorited={startup.isFavorited} competitorAmount={startup.competitorAmount}
                             businessModel={startup.businessModel} refetch={refetch} id={startup.id}/>}
                </>             
              ))}
            </Grid>
            {startups.length > 0 && 
            <Grid container item style={{margin:'30px 0'}} justify="center">
              <Pagination count={pageCount} page={page} onChange={handlePageChange}
                          variant="outlined" color="primary" />
            </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

}