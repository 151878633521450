import React from "react";
import Hidden from "@material-ui/core/Hidden";
import MuiAlert from '@material-ui/lab/Alert';
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import MobileHeader from "../../Components/MobileHeader";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import {Link} from 'react-router-dom';
import Slider from '@material-ui/core/Slider';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import arrow from '../../static/icon/arrow-left.svg';
import {makeStyles} from "@material-ui/core/styles";
import { useQuery } from '@apollo/react-hooks';
import { useField, Form, Formik, Field } from 'formik';
import FormControl from "@material-ui/core/FormControl";
import ListItemText from '@material-ui/core/ListItemText';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import get from 'lodash/get';
import TextField from "@material-ui/core/TextField";
import Input from '@material-ui/core/Input';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import NumberFormat from 'react-number-format';
import gql from 'graphql-tag';
import { RichEditor } from "../../Components/RichEditor/RichEditor.js";
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import {stateToHTML} from 'draft-js-export-html'
import BottomNavigation from "../../Components/BottomNavigation";

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
    padding: 40,
    },
    padding: 25,
    background: '#ffffff'
  },
  multiSelect: {
    maxHeight: '60px',
    '& div': {
      background: 'none!important',
    },
  },
  topPart: {
      padding: '0 10px'
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  textArea: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
    maxWidth:'420px',
    },
    maxWidth:'315px',
    borderColor:'rgb(222, 222, 222);',
    borderRadius:'7px',
    marginTop:10,
    padding:'5px 10px',
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    color:"#777777"
},
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
      justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },  
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
const FormikDropdown = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
      <>
      <Grid style={{minHeight:95}}>
          <FormControl placeholder="Please Select" style={{width : '100%', marginTop: '10px'}}>
              <Select
                                  
                      {...field}
                      {...props}
                      className={classes.multiSelect}    
                      input={<Input />}>
                      {props.options.map(option => (
                          <MenuItem key={option} value={option}>
                              <ListItemText primary={option} />
                          </MenuItem>
                      ))}
                  </Select>
          </FormControl>
          {meta.touched && meta.error ? (
              <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
              </Typography>
          ) : null}
      </Grid>  
      </>
    );
  }
  const FormikTextEditor= ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
    <Grid style={{minHeight:95}}>
    <TextareaAutosize {...field}{...props} className={classes.textArea}
                                          aria-label="minimum height" rowsMin={8}
                                        />

            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  }
  const FormikTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
    <Grid style={{minHeight:95}}>
        <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            maxlength={props.maxlength}
        />
  
            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  }
  const FormikNumberField = ({ type,label,inputProps, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();

    return (
      <>
    <Grid style={{minHeight:95}}>
        <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
        />

            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  }  

export default function SuperAdminStartupEdit(props){
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState(false);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [id, setId] = React.useState();
  const [serverMessage, setServerMessage] = React.useState({
      detail: '',
      message: '',
      type: false,
    });

  const businessModel = ['B2B', 'B2C', 'Multisided', 'Marketplace']; 
  const [initialValuess, setInitialValuess] = React.useState(
    {
        id:0,
        title:'',
        slug:'',
        website:'',
        businessModel:'',
        partnerships:'',
        aboutStartup:'',
        competitorAnalysis:'',
        investmentRaisedAmount: 0,
        investmentNeededDD:'',
        $lastRoundInvestment:'',
        $postMoneyValuation:0,
        $capTableAInvestors:'',
        $capTableAFounding:''

    }
);
  const ALL_STARTUPS = gql`

  query allStartups($slug:String) {
          allStartups(slug:$slug){
            id
            title
            slug
            website
            businessModel
            partnerships
            competitorAnalysis
            description
            investmentRaisedAmount
            investmentNeededDD
            lastRoundInvestment
            postMoneyValuation
            capTableAInvestors
            capTableAFounding
      }

  }
  `;
    const { loading, data } = useQuery(ALL_STARTUPS, {
      variables: {slug:props.match.params.slug},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          if(data.allStartups[0].capTableAInvestors !== null){
            data.allStartups[0].capTableAInvestors = parseInt(data.allStartups[0].capTableAInvestors);
          }
          if(data.allStartups[0].capTableAFounding !== null){
            data.allStartups[0].capTableAFounding = parseInt(data.allStartups[0].capTableAFounding);
          }
          if(data.allStartups[0].competitorAnalysis){
            let htmlAbt= data.allStartups[0].competitorAnalysis
            if(typeof(data.allStartups[0].competitorAnalysis) === 'string'){
                data.allStartups[0].competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
            }           
          }
          else{
            data.allStartups[0].competitorAnalysis = new EditorState.createEmpty()
          }
          setInitialValuess(data.allStartups[0]);
          setId(data.allStartups[0].id)
          }
        }
},
[data, loading])

const handleSubmit = async variablesAll => {
  const { title ,...variables } = variablesAll;
  variables.competitorAnalysis = stateToHTML(variables.competitorAnalysis.getCurrentContent())
  setIsSubmitting(true);
  if(variables.postMoneyValuation !== null){
    variables.postMoneyValuation = parseInt(variables.postMoneyValuation.toString().split('.').join(""));
  }
  if(variables.investmentRaisedAmount !== null){
    variables.investmentRaisedAmount = parseInt(variables.investmentRaisedAmount.toString().split('.').join(""));
  }
  if(variables.capTableAInvestors !== null){
    variables.capTableAInvestors = variables.capTableAInvestors.toString();
  }
  if(variables.capTableAFounding !== null){
    variables.capTableAFounding = variables.capTableAFounding.toString();
  }
  variables.id = id
  props.editStartup({ variables })
    .then(data => {
      if (data.data.editStartup) {
        setIsEdited(true);
      } else {
          setServerMessage({
              detail: get(data, 'errors[0].functionError.detail', ' '),
              message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
              type: false,
            });
            setServerSuccess(true);
      }
    }).then(() => {
          setIsSubmitting(false);
      }).catch(error => {
          setServerMessage({
              message: 'Unexpected Error',
              type: false,
            });
            setServerSuccess(true);
    });
};
  const classes = useStyles();
  return(
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                  <MobileHeader></MobileHeader>
                </Hidden>
              {!loading && <Grid className={classes.mainGrid} container style={{background:'#f7f7fc'}}>
                <LeftNavigation>
                </LeftNavigation>
                <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                alignItems="center"
                                className={classes.topPart}>
                        <Link style={{padding:0}} to={`/admin/startup/${initialValuess.slug}`}><img src={arrow} alt="arrow" style={{marginRight:25}}></img></Link>
                        <Typography variant="h4">{initialValuess.title}</Typography>
                    </Grid>
                    <Grid container item style={{marginTop:'50px'}}>
                        <Grid item container xs={12} sm={6} lg={4} style={{paddingLeft:10,marginBottom:50}}>
                          <Grid xs={12} className={classes.userCard}>
                          <Formik
                              initialValues={initialValuess}
                              enableReinitialize={true}
                              onSubmit={values => {
                                handleSubmit(values);
                              }}
                            >
                               
                          {props => (
                            <Form>
                            <Grid container justify="space-between" alignItems="center">
                              <Typography variant="h5">Startup Detail </Typography>
                              <Button variant="containedSecondary" style={{padding:"5.5px 25px"}} type="submit" disabled={!props.isValid}> Save </Button>
                            </Grid>
                            <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                              <Typography variant="h6">Total Valuation:</Typography>
                              <FormikNumberField name="postMoneyValuation" placeholder="Eg:100.000"/>
                              <Typography variant="h6">Product Link:</Typography>
                              <FormikTextField name="website" placeholder="https://"/>
                              <Typography variant="h6">Business Model:</Typography>
                              <FormikDropdown name="businessModel" options={businessModel}/>
                              <Typography variant="h6">Summary:</Typography>
                              <FormikTextField name="description" placeholder="about Startup"/>
                              <Typography variant="h5" style={{marginTop:20}}>Investment Status</Typography>
                              <Typography variant="h6" style={{marginTop:20}}>Investment to date:</Typography>
                              <FormikNumberField name="investmentRaisedAmount" placeholder="İnvestment raised"/>
                              <Typography variant="h6" style={{marginTop:20}}>Needed This Round:</Typography>
                              <FormikNumberField name="investmentNeededDD" placeholder="İnvestment needed"/>
                              <Field>
                                                        {({ field, form, meta}) => (
                                                        <>
                                                            <Typography variant="h6">
                                                            Competitor Analysis
                                                            </Typography>
                                                            {form.values.competitorAnalysis && <RichEditor
                                                                editorState={form.values.competitorAnalysis}
                                                                onChange={(form.setFieldValue)}
                                                                name="competitorAnalysis"
                                                                onBlur={form.handleBlur}
                                                            />}
                                                        </>
                                                        )}
                                                    </Field>
                                <Typography variant="h6" style={{marginTop:40}}>Cap Table Allocation</Typography>
                                {get(initialValuess,"capTableAInvestors",null) !== null &&<Field
                                name="capTableAInvestors">{({ field, form , meta}) => (
                                    <div>
                                        <Grid container justify="space-between" alignItems="center" style={{marginTop:15}}>
                                          <Typography  variant="h6" id="disabled-slider">
                                            Investors
                                          </Typography>
                                          <Typography color="primary" variant="h6" id="disabled-slider" style={{marginTop:20}}>
                                            %{form.values.capTableAInvestors}
                                          </Typography>
                                        </Grid>
                                        <Slider
                                        defaultValue={initialValuess.capTableAInvestors}
                                        onChange={(e ,v) => form.setFieldValue("capTableAInvestors", v)}  aria-labelledby="continuous-slider" />    
                                    </div>    
                                  )}
                                </Field>
                                }
                                {get(initialValuess,"capTableAFounding",null) !== null &&<Field
                                name="capTableAFounding">{({ field, form , meta}) => (
                                    <div>
                                        <Grid container justify="space-between" alignItems="center" style={{marginTop:15}}>
                                          <Typography  variant="h6" id="disabled-slider">
                                            Founding
                                          </Typography>
                                          <Typography color="primary" variant="h6" id="disabled-slider" style={{marginTop:20}}>
                                            %{form.values.capTableAFounding}
                                          </Typography>
                                        </Grid>
                                        <Slider
                                        defaultValue={initialValuess.capTableAFounding}
                                        onChange={(e ,v) => form.setFieldValue("capTableAFounding", v)}  aria-labelledby="continuous-slider" />    
                                    </div>    
                                  )}
                                </Field>
                                }                              
                            </Form> 
                            )}
                       </Formik>
                       <Snackbar autoHideDuration={6000} onClose={() => setIsEdited(false)} open={isEdited} >
                            <Alert severity="success">Successfully Edited</Alert>
                        </Snackbar> 
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid> }
            </div>        
  );
}