import SuperAdminInvestorAdd from './SuperAdminInvestorAdd'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const ADD_INVESTOR = gql`
  mutation adminAddInvestor(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $socialLinkedIn: String
    $hearAboutUs: String
    $totalInvestedCompanies: String
    $totalInvestmenAmount: String
    $budgetForAE: String
    $role: String
    $planInvestTwoYear: String
    $industry: [String]
    $sendMail: Boolean
) {
  adminAddInvestor(
        email: $email
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        socialLinkedIn: $socialLinkedIn
        hearAboutUs: $hearAboutUs
        totalInvestedCompanies: $totalInvestedCompanies
        totalInvestmenAmount: $totalInvestmenAmount
        budgetForAE: $budgetForAE
        role: $role
        planInvestTwoYear: $planInvestTwoYear
        industry: $industry
        sendMail: $sendMail
    )
  }
`;
const JoinWithMutation = graphql(ADD_INVESTOR, {
    name: 'adminAddInvestor',
  })(SuperAdminInvestorAdd);
  export default JoinWithMutation;