import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, FieldArray, Formik } from 'formik';
import { useField, Field } from 'formik';
import Paper from "@material-ui/core/Paper";
import Dropzone from 'react-dropzone';
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import team from '../../static/img/team.jpg';
import Button from "@material-ui/core/Button";
import editImg from '../../static/icon/edit.svg';
import TableContainer from "@material-ui/core/TableContainer";
import TextField from '@material-ui/core/TextField';
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import BlackEdit from '../../static/img/new.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { fieldToTextField } from 'formik-material-ui';
import ImageCrop from "../../Components/ImageCrop";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#dbeeff',
    color: '#5bb3ff',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: '30px',
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '127px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 7,
    borderColor: '#dddddd',
    borderStyle: 'dashed',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  borderRight: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '0.2px solid'
    },
  },
  myDialog: {
    overflowY: 'unset',
    overflowX: 'hidden',
    padding: 40
  },
  secondExp: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '20px!important'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '5px'
    },
  },
  paper: {
    padding: 25,
    [theme.breakpoints.up('lg')]: {
      padding: 50,
      paddingTop: 25
    },
    textAlign: 'center',
    borderRadius: 4,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    background: '#ffffff'
  },
  workExperience: {
    textAlign: 'start',
  },
  labelFirst: {
    textTransform: 'capitalize',
    color: '#ffffff',
  },
  img: {
    width: 100,
    height: 100,
    border: '1px solid #f3f3f3',
    borderRadius: '5px',
    objectFit: 'contain'
  },
  namePart: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      marginTop: 5
    },
  }
}));

const FormikNewDropdownExperience = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        {...props}
        {...field}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Role" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}

const FormikNewDropdownExit = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        {...props}
        {...field}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Status" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}

export default function MyTeamCard(props) {
  const [files, setFiles] = React.useState([]);
  const classes = useStyles();
  const companyType = ['Startup', 'Corporate'];
  const [changeStatus, setChangeStatus] = React.useState(false);
  const [draft, setDraft] = React.useState(props.draft);
  function ChangeDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;

    const handleClose = () => {
      onClose(null);
    };

    return (
      <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                statusAdmin: '',
                description: '',
              }}
              onSubmit={values => {
                handleDraft(values);
              }}
            >
              {props => (
                <Form>
                  <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                    <Typography variant="h5">Draft History</Typography>
                    <Button variant="outlinedPrimary" style={{ fontWeight: 'normal', padding: '10.5px 20px' }} type="submit">Add Draft</Button>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell style={{ padding: '5px 16px' }}>WHEN</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left">ADMIN STATUS</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left">STARTUP STATUS</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {draft &&
                        <TableBody>
                          {draft.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell component="th" style={{ color: '#28b1e7', fontWeight: '600' }} scope="row">
                                {moment(parseInt(row.lastUserEdit)).format('DD-MM-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.moderationStatus}</StyledTableCell>
                              <StyledTableCell align="left">{row.status}</StyledTableCell>
                              {(row.status === 'Pending' && row.moderationStatus === 'Draft') &&
                                <StyledTableCell align="left">
                                  <Grid container >
                                    <Link style={{ padding: 0 }} to={`/draft-startup/${row.id}`}><img src={BlackEdit} alt="sticker" /></Link>
                                  </Grid>
                                </StyledTableCell>
                              }
                            </StyledTableRow>
                          ))}
                        </TableBody>}


                    </Table>
                  </TableContainer>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  ChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  const handleDraft = variables => {
    props.startupNewDraft()
      .then(data => {
        if (data.data.startupNewDraft) {
          props.history.push(`/draft-startup/${data.data.startupNewDraft}`)
        }
        else {

        }
      })
  };
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
    flexWrap: 'wrap',
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 7,
    border: '1px solid #eaeaea',
    width: 118,
    height: 150,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: 7
  };
  const thumbs = files.map(file => (
    <div id={file.name} style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview}
          style={img}
          alt="file"
        />
      </div>
    </div>
  ));
  function validateLinkedinUrl(value) {
    if (value !== '' && value !== null) {
      let error;
      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      error = !pattern.test(value) ? 'Not valid URL.' : false;
      return error;
    }
    return false;
  }
  function FounderPart(props) {
    const { index, remove, form } = props;
    const classes = useStyles();
    const [files, setFiles] = React.useState([]);
    const companyType = ['Startup Experience', 'Corporate Experience'];
    const role = ['CEO', 'CTO', 'CMO', 'Designer', 'Other'];
    const startupExperience = ['Founder', 'Team Member'];
    const corporateExperience = ['Manager', 'Employee'];
    const companyStatus = ['Alive', 'Dead'];
    const yesNo = ['Yes', 'No'];
    const [openPhoto, setOpenPhoto] = React.useState(false);

    const FormikRoleDropdown = ({ textFieldProps, ...props }) => {
      const [meta] = useField(props);
      const { form: { setTouched, setFieldValue } } = props;
      const { ...field } = fieldToTextField(props);
      const { name } = field;
      const classes = useStyles();
      return (
          <>
              <Autocomplete
                  style={{ marginTop: 15 }}
                  {...props}
                  {...field}
                  className={classes.multiSelect}
                  onChange={(_, value) => setFieldValue(name, value)}
                  onBlur={() => setTouched({ [name]: true })}
                  renderInput={(props) => <TextField {...props}  {...textFieldProps} variant="outlined" label="Role" />}
              >
              </Autocomplete>
  
              {meta.touched && meta.error ? (
                  <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                      {meta.error}
                  </Typography>
              ) : null}
          </>
      );
  }

    const img = {
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: 7,
      objectFit: 'contain'
    };

    const thumbsContainer = {
      display: 'flex',
      flexDirection: 'row',
      pointerEvents: 'none',
      flexWrap: 'wrap',
    };

    const thumb = {
      display: 'inline-flex',
      borderRadius: 7,
      border: '1px solid #eaeaea',
      maxWidth: 135,
      height: 150,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    return (
      <Grid
        item
        xs={12}
        md={6}
        container
        justify="center"
        style={{ height: 'fit-content', paddingTop: 0 }}
      >
        <Grid xs={12} container justify="flex-end">
          <Button
            variant="containedSecondary"
            style={{
              padding: '4.5px 15px',
              fontSize: 14,
              marginBottom: 5,
              height: 'fit-content',
            }}
            onClick={() => remove(index)}
          >
            Remove
          </Button>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          style={{
            border: 'solid 1px #172b4d',
            borderRadius: 7,
            height: 'fit-content',
          }}
        >
          <Grid item xs={11}>
            <Grid item container spacing={3} style={{ marginTop: 0 }}>
              <Grid item sm={4} xs={12}>
                <Dropzone
                  maxSize={20971520}
                  accept={'image/*'}
                  onDrop={(acceptedFiles) =>
                    setFiles(
                      acceptedFiles.map((file) =>
                        Object.assign(file, { preview: URL.createObjectURL(file) }),
                      ),
                      form.setFieldValue(
                        `founderMembers.${index}.profilePhoto`,
                        acceptedFiles,
                        setOpenPhoto(true),
                      ),
                    )
                  }
                >
                  {({ getRootProps, getInputProps, acceptedFiles }) => (
                    <section style={{ textAlign: 'center' }}>
                      <div {...getRootProps({ className: classes.baseStyle })}>
                        <input {...getInputProps()} />
                        {console.log(form.values.founderMembers[index], 'photo')}
                        {!form.values.founderMembers[index].profilePhoto ? (
                          <Typography variant="h6" style={{ display: 'flex' }}>
                            {' '}
                            Add Picture
                          </Typography>
                        ) : (
                          <aside style={thumbsContainer}>
                            {acceptedFiles[0] && (
                              <div
                                id={form.values.founderMembers[index].profilePhoto[0].name}
                                style={thumb}
                                key={form.values.founderMembers[index].profilePhoto[0].name}
                              >
                                <div style={thumbInner}>
                                  <img
                                    src={form.values.founderMembers[index].profilePhoto[0].preview}
                                    style={img}
                                    alt="file"
                                  />
                                </div>
                              </div>
                            )}
                            {form.values.founderMembers[index].profilePhoto && !acceptedFiles[0] && (
                              <div
                                id={form.values.founderMembers[index].profilePhoto}
                                style={thumb}
                                key={form.values.founderMembers[index].profilePhoto}
                              >
                                <div style={thumbInner}>
                                  <img
                                    src={form.values.founderMembers[index].profilePhoto}
                                    style={img}
                                    alt="file"
                                  />
                                </div>
                              </div>
                            )}
                          </aside>
                        )}
                      </div>
                      {form.values.founderMembers[index].profilePhoto && (
                        <Button
                          variant="containedSecondary"
                          style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5 }}
                          onClick={() =>
                            form.setFieldValue(
                              `founderMembers.${index}.profilePhoto`,
                              '',
                            )
                          }
                        >
                          Remove
                        </Button>
                      )}
                    </section>
                  )}
                </Dropzone>
              </Grid>
              <Field>
                {({ field, form, meta }) => (
                  <ImageCrop
                    setFieldValue={form.setFieldValue}
                    files={files}
                    index={index}
                    founder={form.values.founderMembers[index]}
                    setOpenPhoto={setOpenPhoto}
                    openPhoto={openPhoto}
                  />
                )}
              </Field>
              <Grid item sm={8} xs={12} style={{ paddingBottom: 0 }}>
                <FormikTextField
                  placeholder="Name Surname"
                  name={`founderMembers.${index}.nameSurname`}
                  style={{ paddingBottom: 0 }}
                ></FormikTextField>
                <Field component={FormikRoleDropdown} style={{marginTop:-20}} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.jobTitle`} options={role}></Field>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <FormikTextField
                  placeholder="https://linkedin.com/in/"
                  validate={validateLinkedinUrl}
                  name={`founderMembers.${index}.socialLinkedIn`}
                  style={{ paddingBottom: 0, width:'100%', marginTop:25 }}
                ></FormikTextField>
              </Grid>
              <FieldArray
                name={`founderMembers.${index}.career`}
                render={({ insert, remove, push, form }) => (
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      justify="space-between"
                      style={{ paddingTop: 0 }}
                    >
                      <Typography
                        color="textSecondary"
                        variant="h6"
                        style={{ marginLeft: 5 }}
                      >
                        Career
                      </Typography>
                      <Button
                        variant="containedSecondary"
                        style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                        onClick={() =>
                          push({
                            typeStartOrCorporate: '',
                            company: '',
                            jobTitle: '',
                          })
                        }
                      >
                        Add Career
                      </Button>
                    </Grid>
                    {form.values.founderMembers && form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career.map((label, ind) => (
                      <Grid
                        item
                        container
                        xs={12}
                        style={{ paddingTop: 0, marginBottom: 40 }}
                      >
                        <Grid
                          item
                          container
                          justify="center"
                          xs={12}
                          style={{ border: 'solid 1px #172b4d', borderRadius: 7 }}
                        >
                          <Button
                            variant="containedSecondary"
                            style={{
                              padding: '4.5px 15px',
                              fontSize: 14,
                              marginBottom: 5,
                              marginTop: 10,
                            }}
                            onClick={() => remove(ind)}
                          >
                            Remove
                          </Button>
                          <Grid
                            item
                            container
                            spacing={2}
                            xs={11}
                            style={{ marginTop: 15 }}
                          >
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                              <FormikRadioGroup
                                name={`founderMembers.${index}.career.${ind}.typeStartOrCorporate`}
                                values={companyType}
                              ></FormikRadioGroup>
                            </Grid>
                            {form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career[ind].typeStartOrCorporate &&
                              <>
                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                  <FormikTextField
                                    placeholder="Company Name"
                                    name={`founderMembers.${index}.career.${ind}.company`}
                                    style={{ paddingTop: 0 }}
                                  ></FormikTextField>
                                </Grid>
                                {form.values.founderMembers[index] && form.values.founderMembers[index].career && form.values.founderMembers[index].career[ind].typeStartOrCorporate === 'Startup Experience' ?
                                  <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                    <Field component={FormikNewDropdownExperience} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.career.${ind}.jobTitle`} options={startupExperience}></Field>
                                  </Grid>
                                  :
                                  <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                    <Field component={FormikNewDropdownExperience} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.career.${ind}.jobTitle`} options={corporateExperience}></Field>
                                  </Grid>

                                }
                              </>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              />
              <Grid
                container
                item
                xs={12}
                justify="space-between"
                style={{ paddingTop: 0 }}
              >
                <Typography
                  color="textSecondary"
                  variant="h6"
                  style={{ marginLeft: 5 }}
                >
                  Exit
                                  </Typography>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: 12, marginBottom: 40 }}
                >
                  <Grid
                    item
                    container
                    justify="center"
                    xs={12}
                    style={{ border: 'solid 1px #172b4d', borderRadius: 7 }}
                  >
                    <Grid
                      item
                      container
                      spacing={2}
                      xs={11}
                      style={{ padding: '10px 0' }}
                    >
                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" style={{ marginTop: 12 }}>Has he/she made an exit before?</Typography>
                        <FormikRadioGroup
                          name={`founderMembers.${index}.madeExit`}
                          values={yesNo}
                        ></FormikRadioGroup>
                      </Grid>
                      {form.values.founderMembers[index].madeExit === 'Yes' &&
                        <>
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <FormikTextField
                              placeholder="Company Name"
                              name={`founderMembers.${index}.exitedCompany`}
                              style={{ paddingTop: 0 }}
                            ></FormikTextField>
                          </Grid>
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <Field component={FormikNewDropdownExit} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name={`founderMembers.${index}.exitedCompanyStatus`} options={companyStatus}></Field>
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  const handleSubmit = variables => {
    variables.startupID = props.id
    if (variables.otherMembers.length > 0) {
      const newArray2 = variables.otherMembers.map(({ __typename, id, ...value }) => value)
      variables.otherMembers = newArray2
    }
    if (variables.founderMembers.length > 0) {
      const newArray = variables.founderMembers.map(({ __typename, ...value }) => value)
      variables.founderMembers = newArray
    }
    if (props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
      if (variables.founderMembers.length > 0) {
        variables.founderMembers.forEach(function (v) {
          if ((typeof (v.profilePhoto) === 'string')) {
            if (v.profilePhoto === "") {
              v.profilePhoto = 'empty'
            }
            else {
              v.profilePhoto = null
            }
          }
          if (v.career !== null) {
            v.career.forEach(function (a) {
              if (a !== null) {
                delete a.__typename
              }

            })
          }
          else {
            v.career = [];
          }
        });
      }
      props.deepDiveTeamMember({ variables })
        .then(data => {
          if (data.data.deepDiveTeamMember) {
            props.setIsSentNote(true)
            props.getData()
          }
          else { }
        })
    } else {
      if (variables.founderMembers.length > 0) {
        variables.founderMembers.forEach(function (v) {
          if ((typeof (v.profilePhoto) === 'string')) {
            if (v.profilePhoto === "") {
              v.profilePhoto = null
            }
          }
          if (!v.id) {
            v.id = '#' + parseInt(Math.random() * 1000)
          }
          else {
            v.id = v.id.toString()
          }
        });
      }
      if (variables.otherMembers.length > 0) {
        variables.otherMembers.forEach(function (v) {
          if (!v.id) {
            v.id = '#' + parseInt(Math.random() * 1000)
          }
          else {
            v.id = v.id.toString()
          }
        });
      }
      variables.id = props.params.id
      props.startupEditStartup({ variables })
        .then(data => {
          if (data.data.startupEditStartup) {
            props.setIsSentNote(true)
            props.setSendChanges(true)
            props.getData()
          }
          else { }
        })
    }
  };
  const FormikTextField = ({ type, autofocus, label, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          {disabled ? <TextField
            variant="outlined"
            disabled
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}

          /> : <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{ maxLength: props.maxLength }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}
            data-date-inline-picker="true"
            autoFocus={autofocus} />}

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
  const FormikRadioGroup = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
      <>
        <FormControl component="fieldset">
          <RadioGroup row aria-label="position" {...field} {...props}>
            {props.values.map(value => (
              <FormControlLabel style={{ marginRight: 30 }} value={value} control={<Radio color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label={value} />
            ))}
          </RadioGroup>
        </FormControl>
        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  }
  const [edit, setEdit] = React.useState(false);
  return (
    <Paper style={{ marginBottom: '30px' }} className={classes.paper}>
      {!edit ? (
        <div>
          <Grid container justify="flex-end" style={{ marginBottom: 15 }}>
            {(props.authRole === 'Admin' || props.authRole === 'SuperAdmin') &&
              window.location.href.indexOf('draft') === -1 && (
                <Button
                  variant="containedSecondary"
                  style={{ padding: '10px 25px' }}
                  onClick={() => setEdit(true)}
                >
                  <img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit{' '}
                </Button>
              )}
            {window.location.href.indexOf('draft') > -1 &&
              props.authRole !== 'Admin' &&
              props.authRole !== 'SuperAdmin' && (
                <Button
                  variant="containedSecondary"
                  style={{ padding: '10px 25px' }}
                  onClick={() => setEdit(true)}
                >
                  <img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit{' '}
                </Button>
              )}
          </Grid>
          <Grid style={{ marginBottom: '25px' }} item sm={12} container>
            <Grid item xs container alignItems={'center'} direction="row" spacing={2}>
              <Grid item container alignItems="center" justify="space-between">
                <Typography gutterBottom={true} align={'left'} variant="h5">
                  Founding Team Members
                      </Typography>
              </Grid>
            </Grid>
          </Grid>
          {props.founderMembers && (
            <div>
              {props.founderMembers.map((founderMember) => (
                <Grid xs={12} container classes={{ root: classes.cardContainer }}>
                  <Grid sm={12} container>
                    {founderMember.profilePhoto ? (
                      <Grid sm={2} xs={1} style={{ textAlign: 'start' }} item>
                        <img className={classes.img} alt="complex" src={founderMember.profilePhoto} />
                      </Grid>
                    ) : (
                      <Grid sm={2} xs={12} style={{ textAlign: 'start' }} item>
                        <img className={classes.img} alt="complex" src={team} />
                      </Grid>
                    )}
                    <Grid item sm={8} xs={12} style={{ textAlign: 'start' }}>
                      <Grid xs container alignItems={'center'} direction="row" spacing={2}>
                        <Grid item xs className={classes.namePart}>
                          <Typography style={{ marginBottom: '10px' }} align={'left'} variant="h5">
                            {founderMember.nameSurname}
                          </Typography>
                          <Grid container xs={12} direction="row" style={{ marginBottom: '10px' }}>
                            <Grid container xs={12} sm={2} xl={1} direction="row">
                              <a
                                align={'left'}
                                href={founderMember.socialLinkedIn}
                                target="_blank"
                                color={'textSecondary'}
                                variant="body1"
                                style={{ wordBreak: 'break-word', padding: 0, paddingTop: 5, paddingRight: 10 }}
                              >
                                in
                                    </a>
                              <Typography align={'left'} color={'textSecondary'} variant="body1">
                                {founderMember.jobTitle}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid sm={12} container>
                    <Grid sm={2} xs={12} item>
                      <Typography align={'left'} variant="h6" style={{ marginTop: 5 }}>
                        Experience
                      </Typography>
                    </Grid>
                    <Grid className={classes.workExperience} item sm={10} xl={8}>
                      <Grid xs container alignItems={'center'} direction="row" spacing={2}>
                        <Grid item xs className={classes.companyPart}>
                          <Grid container xs={12}>
                            <Grid container xs={12} sm={6} direction="row">
                              {founderMember.career && founderMember.career.map((career1) => (
                                <Grid container item xs={12} direction="row" style={{marginBottom:35}}>
                                  <Typography
                                    align={'left'}
                                    color={'textSecondary'}
                                    variant="body1"
                                    style={{ fontSize: 18 }}
                                  >
                                    {career1.jobTitle} -
                                </Typography>
                                  <Typography
                                    align={'left'}
                                    style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: 18 }}
                                    color={'textSecondary'}
                                    variant="body1"
                                  >
                                    {career1.company}
                                  </Typography>
                                  <Grid container xs={12}>
                                    <Typography
                                      align={'left'}
                                      className={classes.subtitle2}
                                      color={'textSecondary'}
                                      variant="body2"
                                    >
                                      {career1.typeStartOrCorporate}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                            <Grid container xs={12} sm={6} direction="row">
                              {founderMember.exitedCompany &&
                                <Grid container item xs={12} direction="row" style={{height:'max-content'}}>
                                  <Typography
                                    align={'left'}
                                    color={'textSecondary'}
                                    variant="body1"
                                    style={{ fontSize: 18 }}
                                  >
                                    Exited Company -
                                  </Typography>
                                  <Typography
                                    align={'left'}
                                    style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: 18 }}
                                    color={'textSecondary'}
                                    variant="body1"
                                  >
                                    {founderMember.exitedCompany}
                                  </Typography>
                                  <Grid xs={12}>
                                    <Typography
                                      align={'left'}
                                      className={classes.subtitle2}
                                      color={'textSecondary'}
                                      variant="body2"
                                    >
                                      {founderMember.exitedCompanyStatus}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
          <Grid xs={12}>
            <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
          </Grid>
          {props.otherMembers && props.otherMembers.length > 0 && (
            <Grid xs={12} container classes={{ root: classes.cardContainer }}>
              <Grid item xs container alignItems={'center'} direction="row" style={{ marginBottom: '15px' }}>
                <Grid item xs>
                  <Typography gutterBottom={true} align={'left'} variant="h5">
                    Team Members
                        </Typography>
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>#</StyledTableCell>
                      <StyledTableCell align="left">Name</StyledTableCell>
                      <StyledTableCell align="left">Role</StyledTableCell>
                      <StyledTableCell align="left">LinkedIn</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.otherMembers.map((row, index) => (
                      <StyledTableRow key={row.nameSurname}>
                        <StyledTableCell component="th" scope="row">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.nameSurname}</StyledTableCell>
                        <StyledTableCell align="left">{row.jobTitle}</StyledTableCell>
                        <StyledTableCell align="left">{row.socialLinkedIn}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </div>
      ) : (
        <Grid style={{ marginBottom: '25px' }} item sm={12} container>
          <Formik
            initialValues={{
              founderMembers: props.founderMembers,
              otherMembers: props.otherMembers,
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form style={{ width: '100%' }}>
                <Grid item xs container alignItems={'center'} direction="row" spacing={2}>
                  <Grid item container alignItems="center" justify="flex-end">
                    <Button variant="containedSecondary" style={{ padding: '10px 25px' }} type="submit">
                      {' '}
                            Save{' '}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Funding Team
                          </Typography>
                    <FieldArray
                      name="founderMembers"
                      render={({ insert, remove, push, form }) => (
                        <div>
                          {form.values.founderMembers.length < 6 && (
                            <Button
                              variant="containedSecondary"
                              style={{ float: 'left', marginTop: 25 }}
                              onClick={() =>
                                push({
                                  id: null,
                                  jobTitle: '',
                                  nameSurname: '',
                                  socialLinkedIn: '',
                                  profilePhoto: null,
                                  career: [],
                                })
                              }
                            >
                              Add Member
                            </Button>
                          )}
                          <Grid item container spacing={3} style={{ marginTop: 0 }}>
                            {form.values.founderMembers.length > 0 &&
                              form.values.founderMembers.map((teamMember, index) => (
                                <FounderPart index={index} remove={remove} form={form} />
                              ))}
                          </Grid>
                        </div>
                      )}
                    />
                    <Typography variant="h5" align="left" style={{ marginBottom: 10, marginTop: 50 }}>
                      Other Team Members (Max 10 People)
                    </Typography>
                    <FieldArray
                      name="otherMembers"
                      render={({ insert, remove, push, form }) => (
                        <div>
                          {form.values.otherMembers.length < 6 && (
                            <Button
                              variant="containedSecondary"
                              style={{ float: 'left', marginTop: 25 }}
                              className={classes.AddButton}
                              onClick={() =>
                                push({ id: null, jobTitle: '', nameSurname: '', socialLinkedIn: '' })
                              }
                            >
                              Add Member
                            </Button>
                          )}
                          <Grid item container spacing={3} style={{ marginTop: 0 }}>
                            {form.values.otherMembers.length > 0 &&
                              form.values.otherMembers.map((otherMember, index) => (
                                <Grid item xs={12} md={6} container justify="center">
                                  <Grid xs={12} container justify="flex-end">
                                    <Button
                                      variant="containedSecondary"
                                      style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                          </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    justify="center"
                                    xs={12}
                                    style={{ border: 'solid 1px #dddddd', borderRadius: 7 }}
                                  >
                                    <Grid item xs={11} style={{ marginTop: 10 }}>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="Name Surname"
                                          name={`otherMembers.${index}.nameSurname`}
                                          style={{ paddingTop: 0, width: '100%' }}
                                        ></FormikTextField>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="Role"
                                          name={`otherMembers.${index}.jobTitle`}
                                          style={{ paddingTop: 0, width: '100%' }}
                                        ></FormikTextField>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="https://linkedin.com/in/"
                                          validate={validateLinkedinUrl}
                                          name={`otherMembers.${index}.socialLinkedIn`}
                                          style={{ paddingTop: 0, width: '100%' }}
                                        ></FormikTextField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </Paper>
  );
}