import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ListItemText from "@material-ui/core/ListItemText";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import sticker from'../../static/img/sticker.svg';
import edit from'../../static/img/new.svg';
import BottomNavigation from "../../Components/BottomNavigation";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MobileHeader from "../../Components/MobileHeader";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {DebounceInput} from 'react-debounce-input';
import { CSVLink } from "react-csv";
import Pagination from "@material-ui/lab/Pagination";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa;',
    height: 30,
    fontSize: '12px',
    padding:'8px',
  },
  body: {
    fontSize: 14,
    padding:'20px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color:'#172b4d',
    fontWeight:'600'
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  Select: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'start',
      marginTop: 30
    },
  },
  accordionGrid: {
    height:'fit-content',
    marginBottom:40,
  },
  multiSelect: {
    maxHeight: '40px',
    border: '1px solid #bdbdbd',
    background: '#ffffff',
    borderRadius:4,
    '& div': {
        background: 'none!important',
    }
  },
  exportButton: {
    padding:'9.5px 16px',
    color: '#28b1e7',
    borderRadius: 3,
    fontWeight:'normal',
    display:'flex',
    alignItems:'center',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    border: '1px solid #28b1e7',
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  },
  investorButton: {
    padding:'9.5px 16px',
    color: '#172b4d',
    borderRadius: 3,
    fontWeight:'normal',
    fontSize: 14,
    [theme.breakpoints.down('xs')]: {
      marginTop: 15
    },
    border: '1px solid #172b4d',
    "&:hover" : {
      border: '1px solid #172b4d',
    },
    '& span': {
      fontSize: '15px',
  },
  boxShadow: '0 3px 6px 0 rgba(44, 40, 40, 0.11)'
  },  
  Debounce: {
    width : '100%',
    maxWidth: '180px',
    fontSize:14,
    height:'35px',
    borderRadius:'4px',
    fontFamily:'Nunito Sans, Roboto, sans-serif',
    border:'1px solid #bdbdbd' ,
    outline:'none',
    padding: '2px 8px 2px 20px',
    marginRight:15,
    [theme.breakpoints.down('xs')]: {
        height:'40px',
    },
  },
  accordionPanel: {
    maxWidth: 240,
    width: '100%',
    boxShadow: 'none',
    borderBottom: 'none',
    background: '#f6f9fc',
    marginTop: '25px!important',
    borderRadius: '7px',
    [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
    },
    '&:hover': {
      backgroundColor: '#e7eff7',
    },
  },
  sticker: {
      marginRight:20,
      [theme.breakpoints.down('sm')]: {
        marginBottom:10
    }, 

  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  rightPart: {
    height:'fit-content',
    background:"#ffffff",
    borderRadius:10
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  heading: {
    fontSize:16
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  ListItemText: {
    '& span': {
      fontSize: '16px',
    },
  },
  mainGrid: {
    justifyContent:'center'
  }  
  }));
export default function SuperAdminStartups(props){
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(8);
  const [rows, setRows] = React.useState([]);
  const [dataAll, setDataAll] = React.useState([]);
  const [order, setOrder] = React.useState("id__DESC");
  const [search, setSearch] = React.useState("");
  const [stage, setStage] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [count, setCount] = React.useState();
  const [businessModel, setBusinessModel] = React.useState([]);
  const [postMoneyValuation, setPostMoneyValuation] = React.useState([]);
  const [teamMemberSize, setTeamMemberSize] = React.useState([]);
  const [founderWithPrevExit, setfounderWithPrevExit] = React.useState([]);
  const [countryIDS, setCountryIDS] = React.useState([]);
  const [countryFilterOptions, setCountryFilterOptions] = React.useState([]);
  const [countryFilterOptionsLoaded, setCountryFilterOptionsLoaded] = React.useState(false);
  const ALL_STARTUPS = gql`
  query allStartups($order:String, $stage:[String], $status:[String], $businessModel:[String],  $countryIDS:[ID],  $postMoneyValuation:[String],  $founderWithPrevExit:[String],  $teamMemberSize:[String], $search: String) {
          allStartups(orderBy:[$order],filter : { stage : $stage , status : $status , businessModel : $businessModel, countryId : $countryIDS, postMoneyValuation : $postMoneyValuation, teamMemberSize : $teamMemberSize, founderWithPrevExit : $founderWithPrevExit}, search:$search){
          id
          title
          slug
          contactEmail
          user{
            email
          }
          country{
            id
            title
          }
          founderSize
          applicationStage
      }

  }
  `;
  const { loading, data } = useQuery(ALL_STARTUPS, {
      variables: {order:order, stage:stage, status:status, businessModel:businessModel, countryIDS:countryIDS, postMoneyValuation:postMoneyValuation, teamMemberSize:teamMemberSize, founderWithPrevExit:founderWithPrevExit, search:search},
  });
  const APPROVAL_COUNT = gql`
  query adminGetApprovalCount{
          adminGetApprovalCount
  }
  `;
  const { loading:loadingCount, data:dataCount } = useQuery(APPROVAL_COUNT, {
      variables: {},
      fetchPolicy:"network-only",
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          window.scrollTo(0,0);
          if (data.allStartups){
            data.allStartups.map(startup => {
              if(startup.country === null){
                startup.country = {
                  title:""
                }
              }
            })
            setDataAll(data.allStartups);
            setRows(data.allStartups.slice(0, pageSize));
            setPage(1);
            setPageCount(1);
            setPageCount(Math.ceil(data.allStartups.length / pageSize))
          }else{
            setPage(0);
            setPageCount(0);
          }
            }
        }
    if(!loadingCount){
        if(dataCount){
          setCount(dataCount.adminGetApprovalCount)
        }
    }
},
[data, loading, loadingCount, dataCount])


  const COUNTRIES_MOST_APPLICATION = gql`
      query countriesMostApplication{
          countriesMostApplication{
              id
              title
              count
          }

      }
  `;
  const { loading:loadingCountryMostApp, data:dataCountryMostApp } = useQuery(COUNTRIES_MOST_APPLICATION, {
    fetchPolicy:"network-only",
  });
  React.useEffect(() => {
      if (!loadingCountryMostApp){
        if (dataCountryMostApp){
          setCountryFilterOptions(dataCountryMostApp.countriesMostApplication)
          setCountryFilterOptionsLoaded(true)
        }
      }
    },
    [dataCountryMostApp, loadingCountryMostApp]);


    const classes = useStyles();
    const [sort, setSort] = React.useState([]);
    const options = [
      {name:'A-Z', value:'title__ASC'},
      {name:'Z-A', value:'title__DESC'},
      {name:'Latest', value:'id__DESC'},
      {name:'Oldest', value:'id__ASC'},
    ];
  const stageOptions = ['Idea', 'Prototype', 'MVP (Minimum Viable Product)', 'Early', 'Scale-up', 'Growth',];
  const statusOptions = ['Archive', 'Re-evaluate', 'Information Waiting', 'In Progress', 'Voting', 'Portfolio', 'Exited'];
  const businessModelOptions = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
  const postMoneyValuationOptions = ['0-1000', '1000-10000', '10000-500000', '500000-0'];
  const postMoneyValuationOptionsDict = {'0-1000':'0-1.000', '1000-10000':'1.000-10.000', '10000-500000':'10.000-500.000', '500000-0':'500.000+'};
  const teamMemberSizeOptions = ['1-3', '4-6', '6+'];
  const founderWithPrevExitOptions = ['Yes', 'No'];
  const [state, setState] = React.useState({
      filter1: false,
      filter2: false,
      filter3: false,
      filter4: false,
      filter5: false,
      filter6: false,
    });
  
    const handleStage = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (stage.includes(event.target.value)){
       let a = stage.filter(e => e !== event.target.value);
       setStage(a);
      }
      else{
      setStage([...stage, event.target.value]);
      }
    };
    const handleStatus= (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (status.includes(event.target.value)){
       let a = status.filter(e => e !== event.target.value);
       setStatus(a);
      }
      else{
      setStatus([...status, event.target.value]);
      }
    };
    const handleBusinessModel = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (businessModel.includes(event.target.value)){
        let a = businessModel.filter(e => e !== event.target.value);
        setBusinessModel(a);
      }
      else{
        setBusinessModel([...businessModel, event.target.value]);
      }
    };
    const handlePostMoneyValuation = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (postMoneyValuation.includes(event.target.value)){
        let a = postMoneyValuation.filter(e => e !== event.target.value);
        setPostMoneyValuation(a);
       }
       else{
        setPostMoneyValuation([...postMoneyValuation, event.target.value]);
       }
    };
    const handleTeamMemberSize = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (teamMemberSize.includes(event.target.value)){
        let a = teamMemberSize.filter(e => e !== event.target.value);
        setTeamMemberSize(a);
       }
       else{
        setTeamMemberSize([...teamMemberSize, event.target.value]);
       }
    };
    const handleCountryFilter = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (countryIDS.includes(event.target.value)){
        let a = countryIDS.filter(e => e !== event.target.value);
        setCountryIDS(a);
       }
       else{
        setCountryIDS([...countryIDS, event.target.value]);
       }
    };
    const handlefounderWithPrevExit = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      if (founderWithPrevExit.includes(event.target.value)){
        let a = founderWithPrevExit.filter(e => e !== event.target.value);
        setfounderWithPrevExit(a);
       }
       else{
        setfounderWithPrevExit([...founderWithPrevExit, event.target.value]);
       }
    };
    const handleSort = event => {
      setSort(event.target.value);
    };
    const handleSearch = event => {
      setSearch(event.target.value);
    };
    function getDataForCSV(data) {
      let result = []
      data.forEach((item) => {
        result.push({
          id: item.id,
          title: item.title,
          user: item.user ? item.user.email : '',
          country: item.country.title,
          founderSize: item.founderSize,
          applicationStage: item.applicationStage
        })
      })
      return result
    }

    const handlePageChange = (event, value) => {
      setPage(value);
      let temp = value * pageSize;
      setRows(dataAll.slice(temp - pageSize, temp));
    };

    return (
        <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                  <MobileHeader></MobileHeader>
                </Hidden>
            <Grid container className={classes.mainGrid} style={{background:'#f7f7fc'}}>
              <LeftNavigation>
              </LeftNavigation>
              <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                justify="space-between"
                                alignItems="center"
                                className={classes.topPart}>
                        <Typography variant="h4"> Listing Startups </Typography>
                        <Grid item container className={classes.Select} md={4} xs={12}>
                          <DebounceInput
                          class="inputTypeSearch"
                          name="search"
                          className={classes.Debounce}
                          value={search}
                          placeholder="Search by Name.."
                          minLength={2}
                          debounceTimeout={800}
                          onChange={handleSearch} />
                          <FormControl placeholder="Please Select" style={{width : '100%', maxWidth: '129px'}}>
                                <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox" 
                                            displayEmpty  
                                            value={sort}
                                            className={classes.multiSelect}    
                                            onChange={handleSort}
                                            input={<Input />}
                                            /*   MenuProps={MenuProps} */
                                            >
                                            <MenuItem value="" disabled>
                                                <ListItemText className={classes.ListItemText} primary="Sorting" />
                                            </MenuItem>
                                            {options.map(option => (
                                                <MenuItem key={option.name} value={option.value} onClick={() => setOrder(option.value)}>
                                                    <ListItemText className={classes.ListItemText} primary={option.name} />
                                                </MenuItem>
                                            ))}
                                    </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} style={{margin:'50px 0'}}>
                        <Grid item container md={3} xs={12} className={classes.accordionGrid}>
                            <Grid item md={11} xs={12} sm={5} alignItems="center" style={{background:'#ffffff', borderRadius:'7px', display:'flex', flexDirection:'column', paddingTop:'20px',paddingBottom:40}}>
                              <ExpansionPanel
                                className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className={classes.heading} style={{fontWeight:600}}>Valuation</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {postMoneyValuationOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                                                  control={<Checkbox color="primary" onChange={handlePostMoneyValuation} name="filter5" value={value} />}
                                                                  label={postMoneyValuationOptionsDict[value]}
                                        />)
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                              <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Startup Status</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {statusOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handleStatus} name="filter4" value={value} />}
                                          label={value} 
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Startup Stage</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {stageOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handleStage} name="filter4" value={value} />}
                                          label={value} 
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Business Model</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {businessModelOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handleBusinessModel} name="filter4" value={value} />}
                                          label={value}
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Location</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {countryFilterOptionsLoaded && countryFilterOptions && countryFilterOptions.map((value) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handleCountryFilter} name="filter8" value={value.id} />}
                                          label={value.title + ' ('+value.count+')'}
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Team Size</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {teamMemberSizeOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handleTeamMemberSize} name="filter6" value={value} />}
                                          label={value}
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                            className={classes.accordionPanel}>
                                <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography className={classes.heading} style={{fontWeight:600}}>Founders with exit</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormGroup>
                                      {founderWithPrevExitOptions.map((value, i) => {
                                        // Return the element. Also pass key
                                        return (<FormControlLabel className="dropdown"
                                          control={<Checkbox color="primary" onChange={handlefounderWithPrevExit} name="filter7" value={value} />}
                                          label={value}
                                        />)
                                      })}
                                    </FormGroup>
                                </FormControl>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            </Grid>
                      </Grid>
                        <Grid item container xs sm={12} md={9} className={classes.rightPart}>
                          <Grid item container justify="space-between" alignItems="center" style={{marginTop:'15px', marginBottom:'20px', padding:'0 40px'}}>
                          <Typography variant="h5">Startups</Typography>
                          <Grid item style={{display:"flex"}} >
                            <Link style={{padding:0}} to={`/admin/approve-list`}><Button variant="outlinedPrimary" className={classes.investorButton} style={{marginRight:20}}><strong style={{marginRight:5}}>{count}</strong> Edit Requests </Button></Link>
                              <CSVLink
                                data={getDataForCSV(dataAll)}
                                filename={"startups.csv"}
                                target="_blank"
                                variant="outlinedPrimary"
                                className={classes.exportButton}
                              >
                                Export Data
                              </CSVLink>
                            </Grid>
                          </Grid>
                          <TableContainer style={{boxShadow:'none'}} component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>
                                  <StyledTableCell style={{paddingLeft:40}}>STARTUP'S NAME</StyledTableCell>
                                  <StyledTableCell align="left">MAIL ADDRESS</StyledTableCell>
                                  <StyledTableCell align="left">FOUNDERS</StyledTableCell>
                                  <StyledTableCell align="left">LOCATION</StyledTableCell>
                                  <StyledTableCell align="left">APPLICATION STAGE</StyledTableCell>
                                  <StyledTableCell align="left"></StyledTableCell>
                                </TableRow>
                              </TableHead>
                             {rows.length > 0 &&  <TableBody>
                                {rows.map((row) => (
                                  <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" style={{color:'#28b1e7', fontWeight:'600', paddingLeft:40}} scope="row">
                                      <Link style={{padding:0,color:'#28b1e7', fontWeight:'600',fontSize:14}} to={`/admin/startup/${row.slug}`}>
                                        {row.title}
                                      </Link>  
                                    </StyledTableCell>
                                    <StyledTableCell style={{fontWeight:'600'}} align="left">{row.contactEmail ? row.contactEmail : ''}</StyledTableCell>
                                    <StyledTableCell align="left">{row.founderSize}</StyledTableCell>
                                    <StyledTableCell align="left">{row.country.title}</StyledTableCell>
                                    <StyledTableCell align="left">{row.applicationStage}</StyledTableCell>
                                    <StyledTableCell align="left" style={{paddingRight:15}}>
                                      <Grid container >
                                         <Link style={{padding:0}} to={`/admin/startup/${row.slug}`}><img src={sticker} alt="sticker" className={classes.sticker} /></Link>
                                         <Link style={{padding:0}} to={`/admin/startup-edit/${row.slug}`}><img src={edit} alt="sticker" /></Link>
                                      </Grid>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>} 
                             
                            </Table>
                          </TableContainer>
                          <Grid container item style={{margin:'30px 0',paddingRight:35}} justify="flex-end">
                            <Pagination count={pageCount} page={page} onChange={handlePageChange}
                                        variant="outlined" color="primary" />
                          </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}