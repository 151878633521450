
import { graphql } from 'react-apollo';
import Startups from './Startups';
import gql from 'graphql-tag';
const SIGNUP_USER_MUTATION = gql`
  mutation registerInvestor(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $socialLinkedIn: String
    $hearAboutUs: String
    $investmentHorizon: String
    $acceptRiskForInvestment: String
    $immediateLiquidWorth: String
  ) {
    registerInvestor(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      socialLinkedIn: $socialLinkedIn
      hearAboutUs: $hearAboutUs
      investmentHorizon: $investmentHorizon
      acceptRiskForInvestment: $acceptRiskForInvestment
      immediateLiquidWorth: $immediateLiquidWorth
    ) {
      id
      token
      email
      firstName
      createdAt
    }
  }
`;

const JoinWithMutation = graphql(SIGNUP_USER_MUTATION, {
    name: 'registerInvestor',
  })(Startups);
export default JoinWithMutation;