import { Button, Grid, withStyles } from '@material-ui/core';
import { Formik,Form } from 'formik';
import { withRouter } from 'react-router';
import React, { Fragment } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Hidden from '@material-ui/core/Hidden';
import Step from '@material-ui/core/Step';
import MuiAlert from '@material-ui/lab/Alert';
import StepLabel from '@material-ui/core/StepLabel';
import { applicationSchema } from '../../yupSchemas';
import Snackbar from '@material-ui/core/Snackbar';
import get from 'lodash/get';
import CircularProgress from '@material-ui/core/CircularProgress';
import {stateToHTML} from 'draft-js-export-html'

//import tealCheck from 'static/img/tealCheck.svg';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '73vh',
    width: '100%',
  },
  progresText: {
    backgroundColor: 'white',
    bottom: 0,
    justifyContent: 'flex-end',

    position: 'fixed',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },

  root: {
    padding: 0,
  },
});
class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      submitting:false,
      sentPitchDeck:false,
      sentLogo:false,
      values:[],
      sentBusinessPlan:false,
      serverSuccess:false,
      activeStep:0,
      id:0,
      page:0,
      serverMessage:{
        detail: '',
        message: '',
        type: false,
      }
    };
  }
  componentDidUpdate(prevProps){
    if (
      this.props.berk!== prevProps.berk 
    ) {
      this.setState({page:this.props.berk})
      this.setState({activeStep:this.props.berk})
    }

  }
  
  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

    handleStart = async (variablesAll, validateForm) => {
        const { exitedCompany, exitedCompany2, ...variables } = variablesAll;
        this.setState({submitting: true});
        this.props
        .registerStartupContact({ variables:{
          firstName:variables.firstName,
          lastName:variables.lastName,
          jobTitle:variables.jobTitle,
          email:variables.email.toLowerCase(),
          linkedin:variables.linkedin
        }  })
        .then(data => {
            if (data.data.registerStartupContact) {
              const token = data.data.registerStartupContact.token;
              localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN, token);
              this.setState({id : data.data.registerStartupContact.user.startup.id});
              this.setState({activeStep : this.state.activeStep + 1});
              this.setState({page : this.state.page + 1},() => {validateForm().then(r => {})});
              this.props.refetch()
              window.scrollTo(0,0);
               } else {
              this.setState({serverMessage:{
                detail: get(data, 'errors[0].functionError.detail', ' '),
                message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                type: false,
              }})
            this.setState({serverSuccess: true})
            }
        })
        .then(() => this.setState({submitting: false})).catch(error => {
          if (error.graphQLErrors[0].message){
            this.setState({serverMessage:{
                message: error.graphQLErrors[0].message,
                type: false,
              }});
          }else {
            this.setState({serverMessage:{
                message: "Unexpected error",
                type: false,
              }});
          }
        this.setState({serverSuccess: true})
        });
  };
  handleSubmit = async variablesAll => {
    const { exitedCompany, exitedCompany2, ...variables } = variablesAll;
    if(variables.productProblem)
    {
      variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent())
    }
    if(variables.productSolution)
    {
      variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent())
    }
    if(variables.productDifFromComp){
      variables.productDifFromComp = stateToHTML(variables.productDifFromComp.getCurrentContent());
    }
    this.setState({submitting: true});
    if(variables.id === 0){
      variables.id =this.state.id;
    }
    if(variables.founderMembers && variables.founderMembers.length > 0){
      const newArray = variables.founderMembers.map(({__typename, ...keepAttrs}) => keepAttrs)
      variables.founderMembers = newArray
    }
    variables.founderMembers.forEach(function (v) {
      if( typeof v.profilePhoto === 'string')
      {
        v.profilePhoto = ""
      }
    });
    if(variables.investmentRaisedAmount !== null){
    variables.investmentRaisedAmount = parseInt(variables.investmentRaisedAmount.toString().split('.').join(""));
    }
    if(variables.postMoneyValuation !== null){
    variables.postMoneyValuation = parseInt(variables.postMoneyValuation.toString().split('.').join(""));
    }
    if(this.state.sentPitchDeck){
      variables.pitchDeck = null
    }
    if(variables.otherMembers && variables.otherMembers.length > 0){
      const newArray2 = variables.otherMembers.map(({__typename,  ...value})  => value)   
      variables.otherMembers = newArray2
    }
    if(this.state.sentBusinessPlan){
      variables.businessPlan = null
    }
    if(variables.pitchDeck !== null){
      if(typeof variables.pitchDeck === 'string'){
        variables.pitchDeck = ""
      }
    }
    if(variables.businessPlan !== null){
      if(typeof variables.businessPlan === 'string'){
        variables.businessPlan = ""
      }
    }
    if(variables.logo !== null){
      if(typeof variables.logo === 'string'){
        variables.logo = ""
      }
    }
    if(variables.country === null){
      variables.country.id = null
    }
    this.props
    .firstApplicationStartup({ variables:{
      id:variables.id,
      title:variables.title,
      description:variables.description,
      logo:variables.logo,
      website:variables.website,
      productDifFromComp:variables.productDifFromComp,
      productProblem:variables.productProblem,
      productSolution:variables.productSolution,
      totalRevenueGrowth:variables.totalRevenueGrowth,
      averageRevenueGrowth:variables.averageRevenueGrowth,
      revenueRecurring:variables.revenueRecurring,
      revenueRecurringType:variables.revenueRecurringType,
      firstCompetitorsName:variables.firstCompetitorsName,
      firstCompetitorsWebsite:variables.firstCompetitorsWebsite,
      secondCompetitorsName:variables.secondCompetitorsName,
      secondCompetitorsWebsite:variables.secondCompetitorsWebsite,
      thirdCompetitorsName:variables.thirdCompetitorsName,
      thirdCompetitorsWebsite:variables.thirdCompetitorsWebsite,
      investmentNeededDD:variables.investmentNeededDD,
      roundPercent:variables.roundPercent,
      sharePercent:variables.sharePercent,
      payrollFA:variables.payrollFA,
      salesFA:variables.salesFA,
      rdFA:variables.rdFA,
      marketingFA:variables.marketingFA,
      developmentFA:variables.developmentFA,
      otherFA:variables.otherFA,
      industry:variables.industry,
      stage:variables.stage,
      businessModel:variables.businessModel,
      country:variables.country.id,
      establishedDate:variables.establishedDate,
      haveCustomer:variables.haveCustomer,
      havePayingCustomer:variables.havePayingCustomer,
      founderSize:variables.founderSize,
      foundersPrevStartupExp:variables.foundersPrevStartupExp,
      foundersPrevCorporateExp:variables.foundersPrevCorporateExp,
      teamMemberSize:variables.teamMemberSize,
      pitchDeck:variables.pitchDeck,
      businessPlan:variables.businessPlan,
      competitorHave:variables.competitorHave,
      founderMembers:variables.founderMembers,
      otherMembers:variables.otherMembers,
      competitorAmount:variables.competitorAmount,
      marketTotalSize:variables.marketTotalSize,
      marketAddressableSize:variables.marketAddressableSize,
      marketTargetRegion:variables.marketTargetRegion,
      investmentRaised:variables.investmentRaised,
      investmentRaisedAmount:variables.investmentRaisedAmount,
      investmentNeeded:variables.investmentNeeded,
      postMoneyValuation:variables.postMoneyValuation,
      submit:true,
    }  })
    .then(data => {
        if (data.data.firstApplicationStartup) {
        let params = new URLSearchParams(this.props.history.location.search);
        this.props.history.push(params.get('next') ? params.get('next') : `/application-success`);
        } else {
          this.setState({serverMessage:{
            detail: get(data, 'errors[0].functionError.detail', ' '),
            message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
            type: false,
          }})
        this.setState({serverSuccess: true})
        }
    })
    .then(() => this.setState({submitting: false}));
};
  handleNext = async (variablesAll, validateForm) => {
    const { exitedCompany, exitedCompany2, ...variables } = variablesAll;
    if(variables.productProblem)
    {
      variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent())
    }
    if(variables.productSolution)
    {
      variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent())
    }
    if(variables.productDifFromComp){
      variables.productDifFromComp = stateToHTML(variables.productDifFromComp.getCurrentContent());
    }
    this.setState({submitting: true});
    if(variables.id === 0){
      variables.id =this.state.id;
    }
    if(variables.founderMembers && variables.founderMembers.length > 0){
      const newArray = variables.founderMembers.map(({__typename, ...keepAttrs}) => keepAttrs)
      variables.founderMembers = newArray
    }
    variables.founderMembers.forEach(function (v) {
      if( typeof v.profilePhoto === 'string')
      {
        v.profilePhoto = ""
      }
    });
    if(variables.investmentRaisedAmount !== null){
      variables.investmentRaisedAmount = parseInt(variables.investmentRaisedAmount.toString().split('.').join(""));
      }
      if(variables.postMoneyValuation !== null){
      variables.postMoneyValuation = parseInt(variables.postMoneyValuation.toString().split('.').join(""));
      }
      if(this.state.sentPitchDeck){
        variables.pitchDeck = null
      }
      if(this.state.sentBusinessPlan){
        variables.businessPlan = null
      }
      if(variables.otherMembers && variables.otherMembers.length > 0){
        const newArray2 = variables.otherMembers.map(({__typename,  ...value})  => value)   
        variables.otherMembers = newArray2
      }
      if(variables.pitchDeck !== null){
        if(typeof variables.pitchDeck === 'string'){
          variables.pitchDeck = ""
        }
        else{
          this.setState({sentPitchDeck : true});
        }
      }
      if(variables.businessPlan !== null){
        if(typeof variables.businessPlan === 'string'){
          variables.businessPlan = ""
        }
        else{
          this.setState({sentBusinessPlan : true});
        }
      }
      if(this.state.sentLogo){
        variables.logo = null
      }
      if(variables.logo !== null){
        if(typeof variables.logo === 'string'){
          variables.logo = ""
        }
        else{
          this.setState({sentLogo : true});
        }
      }
      let country = null
      if(variables.country !== null){
        country = variables.country.id
      }
    this.props
    .firstApplicationStartup({ variables:{
      id:variables.id,
      title:variables.title,
      description:variables.description,
      website:variables.website,
      logo:variables.logo,
      productDifFromComp:variables.productDifFromComp,
      productProblem:variables.productProblem,
      productSolution:variables.productSolution,
      totalRevenueGrowth:variables.totalRevenueGrowth,
      averageRevenueGrowth:variables.averageRevenueGrowth,
      revenueRecurring:variables.revenueRecurring,
      revenueRecurringType:variables.revenueRecurringType,
      firstCompetitorsName:variables.firstCompetitorsName,
      firstCompetitorsWebsite:variables.firstCompetitorsWebsite,
      secondCompetitorsName:variables.secondCompetitorsName,
      secondCompetitorsWebsite:variables.secondCompetitorsWebsite,
      thirdCompetitorsName:variables.thirdCompetitorsName,
      thirdCompetitorsWebsite:variables.thirdCompetitorsWebsite,
      investmentNeededDD:variables.investmentNeededDD,
      roundPercent:variables.roundPercent,
      sharePercent:variables.sharePercent,
      payrollFA:variables.payrollFA,
      salesFA:variables.salesFA,
      rdFA:variables.rdFA,
      marketingFA:variables.marketingFA,
      developmentFA:variables.developmentFA,
      otherFA:variables.otherFA,
      industry:variables.industry,
      stage:variables.stage,
      businessModel:variables.businessModel,
      country:country,
      establishedDate:variables.establishedDate,
      haveCustomer:variables.haveCustomer,
      havePayingCustomer:variables.havePayingCustomer,
      founderSize:variables.founderSize,
      foundersPrevStartupExp:variables.foundersPrevStartupExp,
      foundersPrevCorporateExp:variables.foundersPrevCorporateExp,
      teamMemberSize:variables.teamMemberSize,
      founderMembers:variables.founderMembers,
      otherMembers:variables.otherMembers,
      pitchDeck:variables.pitchDeck,
      businessPlan:variables.businessPlan,
      competitorHave:variables.competitorHave,
      competitorAmount:variables.competitorAmount,
      marketTotalSize:variables.marketTotalSize,
      marketAddressableSize:variables.marketAddressableSize,
      marketTargetRegion:variables.marketTargetRegion,
      investmentRaised:variables.investmentRaised,
      investmentRaisedAmount:variables.investmentRaisedAmount,
      investmentNeeded:variables.investmentNeeded,
      postMoneyValuation:variables.postMoneyValuation,
      applicationStepFA:this.state.page + 1,
    }  })
    .then(data => {
        if (data.data.firstApplicationStartup) {
          this.setState({activeStep : this.state.activeStep + 1});
          this.setState({page : this.state.page + 1});
          window.scrollTo(0,0);
           } else {
          this.setState({serverMessage:{
            detail: get(data, 'errors[0].functionError.detail', ' '),
            message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
            type: false,
          }})
        this.setState({serverSuccess: true})
        }
    })
    .then(() => this.setState({submitting: false},() => {validateForm().then(r => {})}));
};
handleBack = async (variablesAll, validateForm) => {
  const { exitedCompany, exitedCompany2, ...variables } = variablesAll;
  this.setState({submitting: true});
  if(variables.id === 0){
    variables.id =this.state.id;
  }
  this.props
  .firstApplicationStartup({ variables:{
    id:variables.id,
    applicationStepFA:this.state.page - 1
  }  })
  .then(data => {
      if (data.data.firstApplicationStartup) {
        this.props.refetch()
        this.setState({activeStep : this.state.activeStep - 1});
        this.setState({page : this.state.page - 1});
        window.scrollTo(0,0);
         } else {
        this.setState({serverMessage:{
          detail: get(data, 'errors[0].functionError.detail', ' '),
          message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
          type: false,
        }})
      this.setState({serverSuccess: true})
      }
  })
  .then(() => this.setState({submitting: false},() => {validateForm().then(r => {})}));
};

  render() {
    const { children, classes } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const pageCount = React.Children.toArray(children).length - 1; // toArray removes null values count doesn't
    const isLastPage = page === pageCount;
    const steps = ['Contact', 'Team', 'Company', 'Competition & Market', 'Investment'];
    const isFirstPage = page === 0;
    return (
      <Fragment>
        <Formik
          validationSchema={applicationSchema[page]}
          initialValues={this.props.initialValues}
          enableReinitialize={true}
          validate={this.validate}
          validateOnMount={true}
          onSubmit={this.handleSubmit}
          render={({ values, handleSubmit, isSubmitting, handleReset, isValid, validateForm}) => (
            <Form onSubmit={handleSubmit} className={classes.form}>
                 <Grid container>
                    <Hidden smDown>
                    {(!isFirstPage && !isLastPage)  && 
                      <Grid container xs={12} justify="center">
                        <Grid item xs={12} xl={11}>
                          <Stepper style={{marginTop:25}} alternativeLabel activeStep={this.state.activeStep}>
                            {steps.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </Grid>
                      </Grid>
                      }
                    </Hidden>
                {activePage}
              
                {isLastPage ? 
                 <Grid item container xs={12} direction="row-reverse"  style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>
                    <Grid xs={12} item container justify="center">
                      <Button onClick={()=>this.handleBack(values, validateForm)} variant="outlinedSecondary" style={{fontWeight: 'normal', marginRight: '25px'}}>BACK</Button>  
                      <Button variant="containedPrimary" style={{fontWeight: 'normal'}} disabled={!isValid} onClick={()=>this.handleSubmit(values)}> SUBMIT </Button>
                    </Grid>
                 </Grid>
                  :isFirstPage ? 
                  <Grid item container xs={12}  direction="row-reverse"  style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>
                    <Grid xs={12} md={6}  item container justify="center">
                      <Button variant="containedPrimary" disabled={!isValid} style={{fontWeight: 'normal'}} onClick={()=>this.handleStart(values, validateForm)}> START </Button>
                    </Grid>
                  </Grid> 
                  :
                  <Grid item container xs={12} direction="row-reverse"  style={{marginTop: 30, maxHeight: 50, marginBottom: 30}}>   
                    <Grid item container xs={12} justify="center">
                    {page>1 && <Button onClick={()=>this.handleBack(values, validateForm)} variant="outlinedSecondary" style={{fontWeight: 'normal', marginRight: '25px'}}>BACK</Button> }
                      { this.state.submitting ? <Button variant="containedPrimary" disabled style={{fontWeight: 'normal'}}><CircularProgress style={{color:'#ffffff',width: '25px',height: '25px',marginRight: '10px',marginBottom: 0}}/> LOADING..</Button> :<Button variant="containedPrimary" disabled={!isValid} style={{fontWeight: 'normal'}} onClick={()=>this.handleNext(values, validateForm)}> NEXT </Button>}
                    </Grid>   
                  </Grid>
                }
                  </Grid>
            </Form>
          )}
        />
        <Snackbar data={this.state.serverMessage} onClose={() => this.setState({serverSuccess : false})} open={this.state.serverSuccess} >
          <Alert severity="error">{this.state.serverMessage.message}</Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(Wizard));
