import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import team from '../../static/img/team.jpg';
import TableContainer from "@material-ui/core/TableContainer";
import { Link } from 'react-router-dom';
import moment from 'moment'
import linkedin from '../../static/icon/linkedin.ico';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#dbeeff',
        color: '#5bb3ff',
        fontWeight: 'bold',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(id, name, role, email) {
    return { id, name, role, email };
}

const rows = [
    createData(1, 'Melek Kantar', 'Marketing Manager', 'melek@rentover.co'),
    createData(2, 'Kerim Halibi', 'Sales Manager', 'kerim@rentover.co'),
];


const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '30px',
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    paper: {
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff',
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 100,
        height: 100,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
        objectFit: 'contain'
    },
    namePart: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 10,
            marginTop: 5
        },
    }
}));

function https(value) {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix) {
        value = prefix + value;
    }
    return value
}

export default function TeamCard(props) {
    const classes = useStyles();
    return (
        <Paper style={{ marginBottom: '30px' }} className={classes.paper}>
            <Grid style={{ marginBottom: '25px' }} item sm={12} container>
                <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                    <Grid item xs>
                        <Typography gutterBottom={true} align={"left"}
                            variant="h4">
                            Founding Team
                            </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {props.founderMembers && (
                <div>
                    {props.founderMembers.map((founderMember) => (
                        <Grid xs={12} container classes={{ root: classes.cardContainer }}>
                            <Grid sm={12} container>
                                {founderMember.profilePhoto ? (
                                    <Grid sm={2} xs={1} style={{ textAlign: 'start' }} item>
                                        <img className={classes.img} alt="complex" src={founderMember.profilePhoto} />
                                    </Grid>
                                ) : (
                                    <Grid sm={2} xs={12} style={{ textAlign: 'start' }} item>
                                        <img className={classes.img} alt="complex" src={team} />
                                    </Grid>
                                )}
                                <Grid item sm={8} xs={12} style={{ textAlign: 'start' }}>
                                    <Grid xs container alignItems={'center'} direction="row" spacing={2}>
                                        <Grid item xs className={classes.namePart}>
                                            <Typography style={{ marginBottom: '10px' }} align={'left'} variant="h5">
                                                {founderMember.nameSurname}
                                            </Typography>
                                            <Grid container xs={12} direction="row" style={{ marginBottom: '10px' }}>
                                                <Grid container xs={12} sm={2} xl={1} direction="row">
                                                    <a
                                                        align={'left'}
                                                        href={founderMember.socialLinkedIn}
                                                        target="_blank"
                                                        color={'textSecondary'}
                                                        variant="body1"
                                                        style={{ wordBreak: 'break-word', padding: 0, paddingTop: 5, paddingRight: 10 }}
                                                    >
                                                        in
                                                    </a>
                                                    <Typography align={'left'} color={'textSecondary'} variant="body1">
                                                        {founderMember.jobTitle}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid sm={12} container>
                                <Grid sm={2} xs={12} item>
                                    <Typography align={'left'} variant="h6" style={{ marginTop: 5 }}>
                                        Experience
                                    </Typography>
                                </Grid>
                                <Grid className={classes.workExperience} item sm={10} xl={8}>
                                    <Grid xs container alignItems={'center'} direction="row" spacing={2}>
                                        <Grid item xs className={classes.companyPart}>
                                            <Grid container xs={12}>
                                                <Grid container xs={12} sm={6} direction="row">
                                                    {founderMember.career && founderMember.career.map((career1) => (
                                                        <Grid container item xs={12} direction="row" style={{ marginBottom: 35 }}>
                                                            <Typography
                                                                align={'left'}
                                                                color={'textSecondary'}
                                                                variant="body1"
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                {career1.jobTitle} -
                                                            </Typography>
                                                            <Typography
                                                                align={'left'}
                                                                style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: 18 }}
                                                                color={'textSecondary'}
                                                                variant="body1"
                                                            >
                                                                {career1.company}
                                                            </Typography>
                                                            <Grid container xs={12}>
                                                                <Typography
                                                                    align={'left'}
                                                                    className={classes.subtitle2}
                                                                    color={'textSecondary'}
                                                                    variant="body2"
                                                                >
                                                                    {career1.typeStartOrCorporate}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                                <Grid container xs={12} sm={6} direction="row">
                                                    {founderMember.exitedCompany &&
                                                        <Grid container item xs={12} direction="row" style={{ height: 'max-content' }}>
                                                            <Typography
                                                                align={'left'}
                                                                color={'textSecondary'}
                                                                variant="body1"
                                                                style={{ fontSize: 18 }}
                                                            >
                                                                Exited Company -
                                                            </Typography>
                                                            <Typography
                                                                align={'left'}
                                                                style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: 18 }}
                                                                color={'textSecondary'}
                                                                variant="body1"
                                                            >
                                                                {founderMember.exitedCompany}
                                                            </Typography>
                                                            <Grid xs={12}>
                                                                <Typography
                                                                    align={'left'}
                                                                    className={classes.subtitle2}
                                                                    color={'textSecondary'}
                                                                    variant="body2"
                                                                >
                                                                    {founderMember.exitedCompanyStatus}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            )}
            <Grid xs={12}>
                <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
            </Grid>
            {props.otherMembers && props.otherMembers.length > 0 && (
                <Grid xs={12} container classes={{ root: classes.cardContainer }}>
                    <Grid item xs container alignItems={'center'} direction="row" style={{ marginBottom: '15px' }}>
                        <Grid item xs>
                            <Typography gutterBottom={true} align={'left'} variant="h5">
                                Team Members
                        </Typography>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell align="left">Name</StyledTableCell>
                                    <StyledTableCell align="left">Role</StyledTableCell>
                                    <StyledTableCell align="left">LinkedIn</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.otherMembers.map((row, index) => (
                                    <StyledTableRow key={row.nameSurname}>
                                        <StyledTableCell component="th" scope="row">
                                            {index + 1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.nameSurname}</StyledTableCell>
                                        <StyledTableCell align="left">{row.jobTitle}</StyledTableCell>
                                        <StyledTableCell align="left">{row.socialLinkedIn}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            )}
        </Paper>
    );
}