import SuperAdminStartupDetail from './SuperAdminStartupDetail'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const CHANGE_STATUS = gql`
  mutation adminStartupChangeStatus(
    $id: ID!
    $status: String
    $statusAdmin: String
    $description: String
) {
    adminStartupChangeStatus(
        id: $id
        status: $status
        statusAdmin: $statusAdmin
        description: $description
    )
  }
`;
const JoinWithMutation = graphql(CHANGE_STATUS, {
    name: 'adminStartupChangeStatus',
  })(SuperAdminStartupDetail);
  export default JoinWithMutation;