import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../../static/img/new-ae-logo.png';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { MY_PROFILE } from '../../queries';
import { useQuery } from '@apollo/react-hooks';
import createBrowserHistory from 'history/createBrowserHistory';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logo: {
    width: 100
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MobileHeader(props) {
  const { window } = props;
  const classes = useStyles();
  const history = createBrowserHistory({forceRefresh:true});
  const theme = useTheme();
  const [user, setUser] = React.useState({});
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const logout = () =>  {
    localStorage.clear();
    history.push("/login");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { loading, data } = useQuery(MY_PROFILE, {
    fetchPolicy: "cache-first",
    variables: {},
  });
  React.useEffect(() => {
    if(!loading){
        if(data){
          setUser(data.myProfile)
        }
    }
  },
[data, loading,])
  const drawer = (
    <div>
      <div style={{padding:15}} className={classes.toolbar} >
        <img src={logo} className={classes.logo} alt="angel-effect"></img> 
      </div>    
      <Divider />
      <List>
        <Link to="/startups" style={{padding:0}}>
            <ListItem button key={'Startups'}>
                <ListItemText primary={'Startups'} />
            </ListItem>
        </Link>
      </List>
      <Divider />
      {Object.keys(user).length > 0 ? 
      <List>
            <ListItem button onClick={logout} key={'Logout'}>
                <ListItemText primary={'Logout'} />
            </ListItem>
      </List>
      : <List>
            <Link to="/" style={{padding:0}}>
                <ListItem button key={'Login'}>
                    <ListItemText primary={'Login'} />
                </ListItem>
            </Link>
        </List>}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" style={{background:'#ffffff'}} className={classes.appBar}>
        <Toolbar style={{justifyContent:'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <img src={logo} className={classes.logo} alt="angel-effect"></img> 
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
      </main>
    </div>
  );
}

MobileHeader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MobileHeader;