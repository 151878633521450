import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import { useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment'
import Avatar from '../../static/img/default-avatar.png';
import Default from '../../static/img/empty-company.png';
import BottomNavigation from "../../Components/BottomNavigation";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField, Field, Form, Formik } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
    padding: 25,
    borderRadius: 7,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)',
    background: '#ffffff'
  },
  topPart: {
      padding: '0 10px'
  },
  name: {
    [theme.breakpoints.down('xs')]: {
      marginBottom:25
    },
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  img: {
    maxWidth: 90,
    minHeight: 90,
    border: '1px solid #f3f3f3',
    borderRadius: '5px',
    boxShadow: '-1px -1px 5px 2px #f3f3f3',
},
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
    justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  favIcon: {
    cursor:'pointer',
  }, 
  hoverText: {
    trainsition:'0.5s',
    '&:hover': {
      color: '#28b1e7',
    },
  }
}));

const FormikNewDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
      <>
          <Autocomplete
          style={{marginTop:15}}
          {...props}
          {...field}
          className={classes.multiSelect}
          onChange={ (_, value) => setFieldValue(name, value) }
          onBlur={ () => setTouched({ [name]: true }) }
          renderInput={(props ) => <TextField {...props }  {...textFieldProps} variant="outlined" label="Please Select"/>}
          >
          </Autocomplete>

      {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
          </Typography>
      ) : null}
      </>
  );
}
function InvestmentDialog(props) {
  const classes = useStyles();
  const { onClose, open, refetch, faq } = props;
  const investmentSize = ['$5.000', '$10.000', '$15.000', '$20.000', '$25.000', 'Other']

  const handleSubmit = (variables) => {
    props.editInvestmentRequest({ variables })
      .then(data => {
        if (data.data.editInvestmentRequest) {
          onClose(null);
          refetch();
          props.setInvestmentSent(true)
        }else{
          props.setInvestmentError(true);
      } 
    }).then(() => {
    }).catch(error => {
      props.setInvestmentError(true);
      onClose(null);
    });
  };

  const handleClose = () => {
    onClose(null);
  };

  return (
    <Dialog
      style={{ padding: 20 }}
      fullWidth={true}
      maxWidth="xs"
      classes={{ paper: classes.myDialog }}
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle style={{ marginTop: 25 }} id="simple-dialog-title">
        <Typography align="center" variant="h5">
          Investment Request
        </Typography>
      </DialogTitle>
      <Grid container justify="center" style={{ marginTop: 15 }}>
        <Grid xs={'auto'} item>
          {props.logo ? (
            <img src={props.logo} className={classes.img} alt="logo"></img>
          ) : (
            <img src={Default} className={classes.img} alt="logo"></img>
          )}
        </Grid>
        <Grid item xs={7} container>
          <Grid item xs container alignItems={'center'} direction="row" style={{ paddingLeft: 20 }} spacing={2}>
            <Grid item xs>
              <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={'left'} variant="h4">
                {props.title}
              </Typography>
              <Typography
                align={'left'}
                color={'textPrimary'}
                style={{ color: '#172b4d', fontSize: 16 }}
                variant="body2"
              >
                {props.businessModel}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {props.status === 'Waiting for Approved' ? 
        <Formik
          initialValues={{
            requestId: props.id,
            size: props.size,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {(props) => (
            <Form style={{ width: '80%', marginTop: 15 }}>
              <Field
                component={FormikNewDropdown}
                textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                name="size"
                options={investmentSize}
              ></Field>
              <Grid item container xs={12} justify="center" style={{ margin: '20px 0', height: 'fit-content' }}>
                <Button
                  variant="containedPrimary"
                  style={{ fontWeight: 'normal', width: '100%' }}
                  onClick={() => handleSubmit(props.values)}
                >
                  Edit Request
                </Button>
              </Grid>
            </Form>
          )}
        </Formik>
        :
        <Grid container xs={10} justify="space-between" style={{margin:'30px 0', padding:'0 10px'}}>
          <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={'left'} variant="h5">
            Request Size:
          </Typography>
          <Typography style={{ wordBreak: 'break-word' }} gutterBottom={true} align={'left'} variant="h5">
            {props.size ? props.size : '-'}
          </Typography>
        </Grid>
        }
      </Grid>
    </Dialog>
  );
}

InvestmentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


function Favorite(props){
  const classes = useStyles();
  const [fav, setFav] = React.useState(true);

  const addFavorite = (id) => {
      props.addFavorite({ variables: { startupId: id } })
      .then(data => {
        if (data.data.addFavorite) {
          setFav(true)
        } 
      });
  };
  const removeFavorite = (id) => {
    props.removeFavorite({ variables: { startupId: id } })
    .then(data => {
      if (data.data.removeFavorite) {
        setFav(false)
      } 
    });
  };
  const { favorite } = props;
  return(
    <>
    {favorite.startup && 
      <Grid xs={12} container alignItems="center" style={{marginBottom:20}}>
        <Link to={`/startup/${favorite.startup.slug}`} style={{padding:0}}>
          {favorite.startup.logo ? <img src={favorite.startup.logo} style={{width:50, height:50, marginRight:10,objectFit:'contain'}} /> : <img src={Default} alt="avatar" style={{width:50, height:50, marginRight:10}}/>}
        </Link>
        <Grid container xs justify="space-between" alignItems="center" style={{paddingLeft:10}}>
          <Link to={`/startup/${favorite.startup.slug}`} style={{padding:0}}>
            <Typography variant="h6"style={{marginRight:10,wordBreak:"break-word"}}>{favorite.startup.title}</Typography>
          </Link>
          { fav ? 
            <BookmarkIcon  onClick={() => removeFavorite(favorite.startup.id)} style={{ color: '#ffe200',cursor:'pointer', fontSize:32 }} className={classes.favIcon}/>
            :
            <BookmarkBorderIcon onClick={() => addFavorite(favorite.startup.id)} className={classes.favIcon} style={{ color: '#ffe200',cursor:'pointer', fontSize:32 }}/>
          } 
        </Grid>
      </Grid>
    }
    </>
  )
}

export default function InvestorLanding(props){
  const [detail, setDetail] = React.useState({});
  const [trending, setTrending] = React.useState();
  const [events, setEvents] = React.useState();
  const [requests, setRequests] = React.useState();
  const [requestSelected, setRequestSelected] = React.useState();
  const [investment, setInvestment] = React.useState(false);
  const [investmentSent, setInvestmentSent] = React.useState(false);
  const [investmentError, setInvestmentError] = React.useState(false);

  const PhoneNumber = (value) => {
    return value.substr(0, 2) 
          + '-' + value.substr(2, 3) 
          + '-' + value.substr(5, 3) 
          + '-' + value.substr(8, 2)
          + '-' + value.substr(10, 2); 
  }

  function https(value) {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix)
    {
      value = prefix + value;
    }
    return value
  }

  const TRENDING_STARTUPS = gql`
  query trendingStartups{
        trendingStartups{
          id
          title
          logo
          slug
        }
 }
`;
const { loading:loadingTrending, data:dataTrending} = useQuery(TRENDING_STARTUPS, {
    fetchPolicy:"network-only",
    variables: {},
});

  const ALL_INVESTORS = gql`
  query myInvestorProfile{
        myInvestorProfile{
          id
          createdAt
          getRole
          email
          firstName
          lastName
          phoneNumber
          profilePhoto
          socialLinkedIn
          hearAboutUs
          totalInvestedCompanies
          totalInvestmenAmount
          budgetForAE
          planInvestTwoYear
          favorites {
              startup {
                id
                isFavorited
                title
                slug
                logo
              }
          }
          industry
      }

  }
  `;

  const { loading, data } = useQuery(ALL_INVESTORS, {
      fetchPolicy:"network-only",
      variables: {},
  });

  const ALL_EVENTS = gql`
  query getEvents {
    getEvents{
      id
      name
      startDate
      link
      startups{
        slug
        id
        title
        logo
      }
      status
    }
  }
  `;

  const { loading:loadingEvent, data:dataEvent} = useQuery(ALL_EVENTS, {
    fetchPolicy:"network-only",
    variables: {},
  });

  const ALL_REQUESTS = gql`
  query myInvestmentRequests {
    myInvestmentRequests{
      id
      status
      size
      startup{
        slug
        id
        title
        businessModel
        logo
      }
    }
  }
  `;

  const { loading:loadingRequests, data:dataRequests, refetch} = useQuery(ALL_REQUESTS, {
    fetchPolicy:"network-only",
    variables: {},
  });
  
  React.useEffect(() => {
    if(!loadingTrending){
      if(dataTrending){
        setTrending(dataTrending.trendingStartups);
      }
    }
    if(!loading){
        if(data){
          setDetail(data.myInvestorProfile);
        }
    }
    if(!loadingEvent){
      if(dataEvent){
        setEvents(dataEvent.getEvents);
      }
    }
    if(!loadingRequests){
      if(dataRequests){
        setRequests(dataRequests.myInvestmentRequests);
      }
    }
  },
  [data, loading, loadingTrending, dataTrending, loadingEvent, dataEvent, loadingRequests, dataRequests])

  const classes = useStyles();
  return (
    <div>
      <Hidden smDown>
        <Header></Header>
      </Hidden>
      <Hidden mdUp>
        <MobileHeader></MobileHeader>
      </Hidden>
      {Object.keys(detail).length > 0 && (
        <Grid container className={classes.mainGrid} style={{ background: '#f7f7fc' }}>
          <BottomNavigation></BottomNavigation>
          <Grid className={'main-container'} xs={12} style={{ height: 'fit-content' }}>
            <Grid container xs={12} spacing={3} style={{ marginTop: '50px' }}>
              <Grid
                item
                container
                xs={12}
                sm={6}
                lg={4}
                style={{ paddingLeft: 10, marginBottom: 50, height: 'fit-content' }}
              >
                <Grid xs={12} className={classes.userCard}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                      {detail.profilePhoto ? (
                        <Grid style={{ maxWidth: 50, borderRadius: '50%' }}>
                        <img src={detail.profilePhoto} alt="avatar" style={{ width:'100%', objectFit:'contain', borderRadius: '50%'}} />
                        </Grid>
                      ) : (
                        <img src={Avatar} alt="avatar" style={{ maxWidth: 50, maxHeight:50, borderRadius: '50%' }} />
                      )}
                      <Typography variant="h5" style={{ marginLeft: 15 }}>
                        {detail.firstName} {detail.lastName}
                      </Typography>
                    </Grid>
                    <Link to={`/edit-profile`}>
                      <Button variant="containedSecondary" style={{ padding: '5.5px 25px' }}>
                        {' '}
                        Edit{' '}
                      </Button>
                    </Link>
                  </Grid>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                  <Grid style={{ marginTop: 20 }} container>
                    <Typography variant="h6" style={{ marginRight: 10 }}>
                      E-mail:
                    </Typography>
                    <Typography variant="body1" color="textSecondary" style={{ wordBreak: 'break-word', fontSize: 15 }}>
                      {detail.email}
                    </Typography>
                  </Grid>
                  {detail.phoneNumber && (
                    <Grid style={{ marginTop: 20 }} container>
                      <Typography variant="h6" style={{ marginRight: 10 }}>
                        GSM:
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ wordBreak: 'break-word', fontSize: 15 }}
                      >
                        +{PhoneNumber(detail.phoneNumber)}
                      </Typography>
                    </Grid>
                  )}
                  <Grid style={{ marginTop: 20 }} container>
                    <Typography variant="h6" style={{ marginRight: 10 }}>
                      Linkedin:
                    </Typography>
                    <Typography variant="body1" color="textSecondary" style={{ wordBreak: 'break-word', fontSize: 15 }}>
                      {detail.socialLinkedIn}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12} className={classes.userCard} style={{ marginTop: 30, maxHeight: 465, overflowY: 'auto' }}>
                  <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h5">Favorite Startups</Typography>
                  </Grid>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                  {detail.favorites && (
                    <div>
                      <Grid container xs={12}>
                        {detail.favorites.map((favorite) => (
                          <Favorite
                            favorite={favorite}
                            addFavorite={props.addFavorite}
                            removeFavorite={props.removeFavorite}
                          />
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                lg={4}
                style={{ paddingLeft: 10, marginBottom: 50, height: 'fit-content' }}
              >
                <Grid xs={12} className={classes.userCard} style={{ maxHeight: 300, overflowY: 'auto' }}>
                  <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h5">Trending Startups</Typography>
                  </Grid>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                  {trending && (
                    <div>
                      <Grid container xs={12}>
                        {trending.map((trend) => (
                          <Grid xs={12} container alignItems="center" style={{ marginBottom: 20 }}>
                            <Link to={`/startup/${trend.slug}`} style={{ padding: 0, display: 'flex' }}>
                              {trend.logo ? (
                                <img
                                  src={trend.logo}
                                  style={{ width: 50, height: 50, marginRight: 10, objectFit: 'contain' }}
                                />
                              ) : (
                                <img src={Default} alt="avatar" style={{ width: 50, height: 50, marginRight: 10 }} />
                              )}
                              <Grid container xs justify="space-between" alignItems="center">
                                <Typography variant="h6" style={{ marginRight: 10, wordBreak: 'break-word' }}>
                                  {trend.title}
                                </Typography>
                              </Grid>
                            </Link>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
                <Grid xs={12} className={classes.userCard} style={{ marginTop: 30, maxHeight: 300, overflowY: 'auto' }}>
                  <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h5">Investment Requests</Typography>
                  </Grid>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                  {requests && (
                    <div>
                      <Grid container xs={12}>
                        {requestSelected && (
                          <InvestmentDialog
                            setInvestmentError={setInvestmentError}
                            setInvestmentSent={setInvestmentSent}
                            editInvestmentRequest={props.editInvestmentRequest}
                            logo={requestSelected.startup.logo}
                            title={requestSelected.startup.title}
                            refetch={refetch}
                            id={requestSelected.id}
                            size={requestSelected.size}
                            status={requestSelected.status}
                            businessModel={requestSelected.startup.businessModel}
                            open={investment}
                            onClose={() => setInvestment(false)}
                          />
                        )}
                        {requests.map((request) => (
                          <Grid xs={12} container alignItems="center" style={{ marginBottom: 20 }}>
                            <Grid style={{ display: 'flex', width: '100%' }}>
                              <Link to={`/startup/${request.startup.slug}`} style={{ padding: 0 }}>
                                {request.startup.logo ? (
                                  <img
                                    src={request.startup.logo}
                                    style={{ width: 50, height: 50, marginRight: 10, objectFit: 'contain' }}
                                  />
                                ) : (
                                  <img src={Default} alt="avatar" style={{ width: 50, height: 50, marginRight: 10 }} />
                                )}
                              </Link>
                              <Grid container justify="space-between" alignItems="center">
                                <Grid xs={4}>
                                  <Typography variant="h6" style={{ wordBreak: 'break-word' }}>
                                    {request.startup.title}
                                  </Typography>
                                </Grid>
                                <Typography
                                  variant="body2"
                                  onClick={() => {
                                    setInvestment(true);
                                    setRequestSelected(request);
                                  }}
                                  className={classes.hoverText}
                                  style={{
                                    wordBreak: 'break-word',
                                    fontWeight: 600,
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                  }}
                                >
                                  {request.status === 'Waiting for Approved' && (
                                    <FiberManualRecordIcon style={{ color: '#ffba00', fontSize: 15, marginRight: 3 }} />
                                  )}
                                  {request.status === 'Approved' && (
                                    <FiberManualRecordIcon style={{ color: '#28d09a', fontSize: 15, marginRight: 3 }} />
                                  )}
                                  {request.status === 'Rejected' && (
                                    <FiberManualRecordIcon style={{ color: '#ff5757', fontSize: 15, marginRight: 3 }} />
                                  )}
                                  {request.status === 'Waiting for Approved' ? 'Waiting for Approval' : request.status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                lg={4}
                style={{ paddingLeft: 10, marginBottom: 50, height: 'fit-content' }}
              >
                <Grid xs={12} className={classes.userCard}>
                  <Grid container justify="space-between" alignItems="center">
                    <Typography variant="h5">Broadcast Center</Typography>
                  </Grid>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                  {events && (
                    <div>
                      <Grid container xs={12}>
                        {events.map((event) => (
                          <Grid container>
                            <Grid container justify="space-between">
                              <Typography variant="h5" style={{ wordBreak: 'break-word', marginBottom: 5 }}>
                                {event.name}
                              </Typography>
                              <Typography
                                variant="h6"
                                color="primary"
                                style={{ wordBreak: 'break-word', marginBottom: 20 }}
                              >
                                {moment(parseInt(event.startDate)).local().format('Do MMMM YYYY, H:mm')}
                              </Typography>
                            </Grid>
                            {event.startups.map((startup) => (
                              <Grid xs={12} container alignItems="center" style={{ marginBottom: 20 }}>
                                <Link to={`/startup/${startup.slug}`} style={{ padding: 0, display: 'flex' }}>
                                  {startup.logo ? (
                                    <img
                                      src={startup.logo}
                                      style={{ width: 50, height: 50, marginRight: 10, objectFit: 'contain' }}
                                    />
                                  ) : (
                                    <img
                                      src={Default}
                                      alt="avatar"
                                      style={{ width: 50, height: 50, marginRight: 10 }}
                                    />
                                  )}
                                  <Grid
                                    container
                                    xs
                                    justify="space-between"
                                    alignItems="center"
                                    style={{ paddingLeft: 10 }}
                                  >
                                    <Typography variant="h6" style={{ marginRight: 10, wordBreak: 'break-word' }}>
                                      {startup.title}
                                    </Typography>
                                  </Grid>
                                </Link>
                              </Grid>
                            ))}
                            <Grid container justify="center">
                              <Grid xs={12} md={6}>
                                {event.status === 'Live' ? (
                                  <>
                                    {event.link && (
                                      <a href={https(event.link)} alt="link" style={{ padding: 0 }} target="_blank">
                                        <Button
                                          variant="containedPrimary"
                                          style={{
                                            fontWeight: 'normal',
                                            marginRight: '25px',
                                            marginTop: 5,
                                            width: '100%',
                                            padding: '11.5px 45px',
                                          }}
                                        >
                                          Join Now
                                        </Button>
                                      </a>
                                    )}
                                  </>
                                ) : (
                                  <Button
                                    variant="containedPrimary"
                                    disabled
                                    style={{
                                      fontWeight: 'normal',
                                      marginRight: '25px',
                                      marginTop: 5,
                                      width: '100%',
                                      padding: '11.5px 45px',
                                    }}
                                  >
                                    Join Now
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                            <Grid xs={12}>
                              <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
              </Grid>
              <Snackbar autoHideDuration={5000} onClose={() => setInvestmentSent(false)} open={investmentSent}>
                <Alert severity="success">Successfully Sent</Alert>
              </Snackbar>
              <Snackbar autoHideDuration={5000} onClose={() => setInvestmentError(false)} open={investmentError}>
                <Alert severity="error">Already Sent</Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}