import MyTabMarketCard from './MyTabMarketCard'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import * as compose from 'lodash.flowright';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $marketTargetRegion: String
    $marketTotalSize: String
    $productDifFromComp: String
    $firstCompetitorsName: String
    $firstCompetitorsWebsite: String
    $secondCompetitorsName: String
    $secondCompetitorsWebsite: String
    $thirdCompetitorsName: String
    $thirdCompetitorsWebsite: String
) {
  editStartup(
        id: $id
        marketTargetRegion: $marketTargetRegion
        marketTotalSize: $marketTotalSize
        productDifFromComp: $productDifFromComp
        firstCompetitorsName: $firstCompetitorsName
        firstCompetitorsWebsite: $firstCompetitorsWebsite
        secondCompetitorsName: $secondCompetitorsName
        secondCompetitorsWebsite: $secondCompetitorsWebsite
        thirdCompetitorsName: $thirdCompetitorsName
        thirdCompetitorsWebsite: $thirdCompetitorsWebsite

    )
  }
`;
const EDIT_MY_STARTUP = gql`
  mutation startupEditStartup(
    $id: ID!
    $marketTargetRegion: String
    $marketTotalSize: String
    $productDifFromComp: String
    $firstCompetitorsName: String
    $firstCompetitorsWebsite: String
    $secondCompetitorsName: String
    $secondCompetitorsWebsite: String
    $thirdCompetitorsName: String
    $thirdCompetitorsWebsite: String
) {
  startupEditStartup(
        id: $id
        marketTargetRegion: $marketTargetRegion
        marketTotalSize: $marketTotalSize
        productDifFromComp: $productDifFromComp
        firstCompetitorsName: $firstCompetitorsName
        firstCompetitorsWebsite: $firstCompetitorsWebsite
        secondCompetitorsName: $secondCompetitorsName
        secondCompetitorsWebsite: $secondCompetitorsWebsite
        thirdCompetitorsName: $thirdCompetitorsName
        thirdCompetitorsWebsite: $thirdCompetitorsWebsite

    )
  }
`;
const JoinWithMutation = compose(
  graphql(EDIT_STARTUP, {
  name: 'editStartup',
  }),
  graphql(EDIT_MY_STARTUP, {
    name: 'startupEditStartup',
  }),
)(MyTabMarketCard);
export default JoinWithMutation;