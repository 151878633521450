import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router} from "react-router-dom";
import Routes from "./Routes";
import createHistory from 'history/createBrowserHistory';
import theme from "./theme";
class DebugRouter extends Router {
  constructor(props) {
    super(props);
    // make this true to debug redirects.
    if (process.env.NODE_ENV !== 'production' && false) {
      // eslint-disable-next-line no-console
      console.log('initial history is: ', JSON.stringify(this.history, null, 2));
      this.history.listen((location, action) => {
        // eslint-disable-next-line no-console
        console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
        // eslint-disable-next-line no-console
        console.log(
          `The last navigation action was ${action}`,
          JSON.stringify(this.history, null, 2),
        );
      });
    }
  }
}

function App() {
  const history = createHistory();
  return (
    <DebugRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <Routes />
      </MuiThemeProvider>
    </DebugRouter>
  );
}

export default App;
