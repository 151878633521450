import React from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LaunchIcon from '@material-ui/icons/Launch';
import { RichEditorReadOnly } from "../RichEditorReadOnly/RichEditorReadOnly.js";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        marginBottom: '15px',
    },
    borderRight: {
        [theme.breakpoints.up('sm')]: {
            borderRight: '0.2px solid'
        },
    },
    secondExp: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '20px!important'
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px'
        },
    },
    namePart: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    companyPart: {
        [theme.breakpoints.up('md')]: {
            marginLeft: '50px'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25px'
        },
    },
    marketDiv: {
        justifyContent: 'space-around',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-start'
        },
    },
    paper: {
        textAlign: 'center',
        borderRadius: 4,
        color: theme.palette.text.secondary,
        boxShadow: 'none',
        background: '#ffffff',
        padding: 25,
        [theme.breakpoints.up('lg')]: {
            padding: 50,
            paddingTop: 25
        },
    },
    workExperience: {
        textAlign: 'start',
    },
    labelFirst: {
        textTransform: 'capitalize',
        color: '#ffffff',
    },
    img: {
        width: 120,
        height: 120,
        border: '1px solid #f3f3f3',
        borderRadius: '5px',
    },
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#dbeeff',
        color: '#5bb3ff;',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f7fafc'
        },
        color: '#172b4d',
        fontWeight: '600'
    },
}))(TableRow);

function https(value) {
    const prefix = 'https://';
    if (value.substr(0, prefix.length) !== prefix) {
        value = prefix + value;
    }
    return value
}


export default function TabMarketCard(props) {
    const classes = useStyles();
    const money = (value) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(parseInt(value))
    }
    return (
        <>
            <Paper style={{ marginBottom: '30px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} className={classes.paper}>
                <Grid style={{ marginBottom: '25px', marginTop: '25px' }} item sm={12} container>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell align="left">Company Name</StyledTableCell>
                                    <StyledTableCell align="left">Website</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.detail.firstCompetitorsName &&
                                    <StyledTableRow key={props.detail.firstCompetitorsName}>
                                        <StyledTableCell component="th" scope="row">
                                            {1}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{props.detail.firstCompetitorsName}</StyledTableCell>
                                        <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.firstCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                    </StyledTableRow>
                                }
                                {props.detail.secondCompetitorsName &&
                                    <StyledTableRow key={props.detail.secondCompetitorsName}>
                                        <StyledTableCell component="th" scope="row">
                                            {2}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{props.detail.secondCompetitorsName}</StyledTableCell>
                                        <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.secondCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                    </StyledTableRow>
                                }
                                {props.detail.thirdCompetitorsName &&
                                    <StyledTableRow key={props.detail.thirdCompetitorsName}>
                                        <StyledTableCell component="th" scope="row">
                                            {2}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{props.detail.thirdCompetitorsName}</StyledTableCell>
                                        <StyledTableCell align="left"><a target="_blank" rel="noopener noreferrer" href={https(props.detail.thirdCompetitorsWebsite)}><LaunchIcon /></a></StyledTableCell>
                                    </StyledTableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid xs={12}>
                        <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                    </Grid>
                    <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                        {props.detail.productDifFromComp &&
                            <>
                                <Grid item container alignItems="center" justify="space-between">
                                    <Typography gutterBottom={true} align={"left"}
                                        variant="h5">
                                        Differentiates & Key Advantages
                                            </Typography>
                                </Grid>
                                <Grid item container>
                                    <RichEditorReadOnly
                                        editorState={props.detail.productDifFromComp}
                                        name="productDifFromComp"
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                                </Grid>
                            </>
                        }
                        <Grid item container alignItems="center" className={classes.marketDiv}>
                            <Grid item xs="auto" style={{ marginBottom: 25 }}>
                                <Typography gutterBottom={true} align={"left"}
                                    variant="h5" style={{ display: 'flex' }}>
                                    Total Market Size
                                        </Typography>
                                <Typography variant="h4" color="primary" align={"left"}>{props.detail.marketTotalSize ? money(props.detail.marketTotalSize) : '-'}</Typography>
                            </Grid>
                            <Grid item xs="auto" style={{ marginBottom: 25 }}>
                                <Typography gutterBottom={true} align={"left"}
                                    variant="h5" style={{ display: 'flex' }}>
                                    Target Market Region
                                        </Typography>
                                <Typography variant="h4" color="primary" align={"left"}>{props.detail.marketTargetRegion ? props.detail.marketTargetRegion : '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}