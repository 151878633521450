import React from "react"
import {Grid, Typography, Hidden, Button} from "@material-ui/core"
import MuiAlert from '@material-ui/lab/Alert';
import {Link} from 'react-router-dom'
import login from '../../static/img/login.jpg';
import { loginSchema } from '../../yupSchemas';
import FormikTextField from "../../Components/FormikTextField";
import logo from '../../static/img/new-ae-logo.png';
import Snackbar from "@material-ui/core/Snackbar";
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import {makeStyles} from "@material-ui/core/styles";
import { useAuth } from "../../context/auth";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) =>({
    img: {
          maxWidth: '100%',
          display: 'block'
    },
    link: {
        textDecoration: 'none',
        color: '#777777',
        padding: 0,
        fontWeight: 'normal',
        marginTop: 20

    },
    leftGrid: {
    [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: '100%'
        },   
    backgroundImage: "url(" + login + ")",
    backgroundSize: "cover",
    backgroundPosition: "center",
      },
    logoGrid: {
        height: 'fit-content',
        [theme.breakpoints.up('xl')]: {
            paddingTop: 60,
            paddingRight: 60
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
        paddingTop: 30,
        paddingRight: 30
    },
    newAround: {
        [theme.breakpoints.up('xl')]: {
            marginTop: 110
        },
        marginTop: 15
    },
    signUp: {
        [theme.breakpoints.down('lg')]: {
            marginBottom: 30
        },
        marginTop: 15
    },
    loginGrid: {
        flexDirection:'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 50
        },
    },
    logo: {
        height: 50,
    },
    loginText: {
        fontSize: 35,
        fontWeight: 'bold',
        lineHeight:  1.35,
        color: '#172b4d',

    },
    welcomeText: {
        color: '#777777',
        [theme.breakpoints.up('xl')]: {
            marginTop: 40
        },
        marginTop: 25
    },
    EmailText: {
        [theme.breakpoints.up('xl')]: {
            marginTop: 50
        },
        marginTop: 30,
        fontWeight: 600
    },
    PasswordText: {
        marginTop: 15,
        fontWeight: 600
    }
  }));

  export default function Login(props){
    const { setAuthToken } = useAuth();

    document.body.style = 'background: #ffffff;margin:0';
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [serverSuccess, setServerSuccess] = React.useState(false);
    const [serverMessage, setServerMessage] = React.useState({
        detail: '',
        message: '',
        type: false,
      });
     
    const handleSubmit = variables => {
        setIsSubmitting(true);
        props.authenticate({ variables })
          .then(data => {
            if (data.data.authenticate) {
              const token = data.data.authenticate.token;

              let params = new URLSearchParams(props.history.location.search);

              /*  const userId = data.data.authenticateUser.id; */
              setAuthToken(token);

              if (data.data.authenticate.user.role === "StartupContact") {
                if (data.data.authenticate.user.startup.applicationStage === 'FirstApplication') {
                    props.history.push(params.get('next') ? params.get('next') : `/application-form`);
                }
                else {
                    props.history.push(params.get('next') ? params.get('next') : `/my-startup`);
                }
              }
              else if(data.data.authenticate.user.role ==="SuperAdmin" || data.data.authenticate.user.role ==="Admin"){
                    props.history.push(params.get('next') ? params.get('next') : `/admin/dashboard`);
              }
              else{
                  if(data.data.authenticate.user.contractSigned)
                  {
                    props.history.push(params.get('next') ? params.get('next') : `/landing`);
                  }
                  else{props.history.push(params.get('next') ? params.get('next') : `/first-login`);}
              }
            } else {
                setServerMessage({
                    detail: get(data, 'errors[0].functionError.detail', ' '),
                    message: get(data, 'errors[0].functionError.title', 'Unexpected Error'),
                    type: false,
                  });
                  setServerSuccess(true);
            }
          }).then(() => {
                setIsSubmitting(false);
            }).catch(error => {
                setServerMessage({
                    message: 'Unexpected Error',
                    type: false,
                  });
                  setServerSuccess(true);
          });
      };   
        const classes = useStyles();
        const preventDefault = (event) => event.preventDefault();
        return (
        <Grid container style={{height : '100vh'}}>
            <Hidden mdDown>
                <Grid item sm={6} xs={12}>
                    <Grid className={classes.leftGrid}></Grid>
                </Grid>
            </Hidden>
            <Grid container item lg={6} md={12} xs={12}>
                <Grid xs={12} container item justify="flex-end" className={classes.logoGrid}>
                    <img src={logo} alt="angel-effect" className={classes.logo}></img>
                </Grid>
                <Grid sm={12} xl={12} xs={12} alignItems="center" className={classes.loginGrid} container item>
                    <Grid item sm={12} xs={11}>
                      <Formik
                      initialValues={{
                        email: '',
                        password: '',
                      }}
                      validationSchema={loginSchema}
                      onSubmit={values => {
                        handleSubmit(values);
                      }}
                      > 
                      {props => (
                          <Form>
                            <Typography className={classes.loginText}>Login</Typography>
                            <Typography className={classes.welcomeText}>Welcome! Please fill in the blanks for us to login.</Typography>
                            <Typography className={classes.EmailText}>Email</Typography>
                            <FormikTextField name="email" type="email" placeholder="Email Address" />
                            <Typography className={classes.PasswordText}>Password</Typography>
                            <FormikTextField name="password" type="password" placeholder="Password" />
                            <Grid item xs={12}> 
                                <Link to="/forgot-password" className={classes.link} >
                                    {'Forgot Password?'}
                                </Link>
                            </Grid>
                            <Grid item container xs={12} justify="center" style={{marginTop: 40}}> 
                                <Button variant="containedPrimary" style={{fontWeight: 'normal'}} type="submit" disabled={!props.isValid}>Login</Button>
                            </Grid>
                        </Form> 
                         )}
                       </Formik> 

                       <Snackbar autoHideDuration={6000} onClose={() => setServerSuccess(false)} open={serverSuccess} >
                            <Alert severity="error">Password or Email are not valid</Alert>
                        </Snackbar>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );}