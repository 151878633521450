import { graphql } from 'react-apollo';
import SetPassword from './SetPassword';
import gql from 'graphql-tag';

const SET_PASSWORD_MUTATION = gql`
  mutation setPasswordStartupContact(
    $code: String!
    $password: String!
    $passwordAgain: String!
  ) {
    setPasswordStartupContact(
      code: $code
      password: $password
      passwordAgain: $passwordAgain
    ) 
  }
`;

const JoinWithMutation = graphql(SET_PASSWORD_MUTATION, {
  name: 'setPasswordStartupContact',
})(SetPassword);
export default JoinWithMutation;