import { graphql } from 'react-apollo';
import LoginWithCode from './LoginWithCode';
import gql from 'graphql-tag';
import * as compose from "lodash.flowright";

const AUTHENTICATE_WITH_CODE_MUTATION = gql`
  mutation authenticateWithCode($code: String!) {
    authenticateWithCode(code: $code) {
      token
      user{
        id
      }
    }
  }
`;

const JoinWithMutation = compose(
    graphql(AUTHENTICATE_WITH_CODE_MUTATION, {
      name: 'authenticateWithCode',
    }),
)(LoginWithCode);
export default JoinWithMutation;