import React from 'react';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {RichEditor} from "../../Components/RichEditor/RichEditor.js";
import Hidden from "@material-ui/core/Hidden";
import TextField from "@material-ui/core/TextField";
import TableCell from "@material-ui/core/TableCell";
import FormikTextField from "../../Components/FormikTextField 2";
import Checkbox from "@material-ui/core/Checkbox";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import Wizard from './Wizard.js';
import {Field, FieldArray, useField} from 'formik';
import logo from '../../static/img/new-ae-logo.png';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OpenInBrowser from '@material-ui/icons/OpenInBrowser';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import StepLabel from '@material-ui/core/StepLabel';
import FormControl from "@material-ui/core/FormControl";
import deepDive from '../../static/img/giveaway.png';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {useQuery} from '@apollo/react-hooks';
import NumberFormat from 'react-number-format';
import gql from 'graphql-tag';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import createBrowserHistory from 'history/createBrowserHistory';
import {EditorState} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html'
import 'draft-js/dist/Draft.css';
import {fieldToTextField} from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageCrop from "../../Components/ImageCrop";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: '0px 100px',
  },
  baseStyle: {
    flex: 1,
    marginTop: 10,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '149px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 7,
    borderColor: '#dddddd',
    borderStyle: 'dashed',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  angelLogo: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: '150px'
    },
  },
  multiSelect: {
    maxHeight: '60px',
    '& div': {
      background: 'none!important',
    },
  },
  startImage: {
    width: '100%',
    objectFit: 'contain',
    marginTop: 60
  },
  contactInfo: {
    textDecoration: 'underline',
    marginLeft: 5
  },
  twoLines: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 35
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    },
  },
  inputSpace: {
    marginTop: 30
  },
  MuiStepConnector: {
    vertical: {
      minHeight: 40
    }
  },
  AddButton: {
    marginBottom: 30,
    marginTop: 30
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  percentageField: {
    width: '100%',
    marginTop: 10,
    '& div': {
      borderRadius: 0
    },
  },
  applicationCard: {
    background: '#ffffff',
    marginTop: 100,
    marginBottom: 50,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 100
    },
  },
  specify: {
  },
  tableRows: {
    border: '1px solid #f7f7fc',
    borderRadius: '7px'
  },
  SpacingName: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '0!important',
    }
  },
  SpacingSurname: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0!important',
    }
  },
  textArea: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      maxWidth: '500px',
    },
    maxWidth: '420px',
    borderColor: 'rgb(222, 222, 222);',
    borderRadius: '7px',
    marginTop: 10,
    padding: '5px 10px',
    fontSize: 18,
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    color: "#172b4d"
  },

}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#dbeeff',
    color: '#5bb3ff',
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}
function sumCap(capTableAInvestors, capTableAFounding) {
  if (capTableAInvestors === null || capTableAInvestors === '') {
    capTableAInvestors = "0"
  }
  if (capTableAFounding === null || capTableAFounding === '') {
    capTableAFounding = "0"
  }
  return parseInt(capTableAInvestors) + parseInt(capTableAFounding)
}
function sumFund(payrollFA, marketingFA, salesFA, developmentFA, rdFA, otherFA) {
  if (payrollFA === null || payrollFA === '') {
    payrollFA = "0"
  }
  if (marketingFA === null || marketingFA === '') {
    marketingFA = "0"
  }
  if (salesFA === null || salesFA === '') {
    salesFA = "0"
  }
  if (developmentFA === null || developmentFA === '') {
    developmentFA = "0"
  }
  if (rdFA === null || rdFA === '') {
    rdFA = "0"
  }
  if (otherFA === null || otherFA === '') {
    otherFA = "0"
  }
  return parseInt(payrollFA) + parseInt(marketingFA) + parseInt(salesFA) + parseInt(developmentFA) + parseInt(rdFA) + parseInt(otherFA)
}
function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix="%"
    />
  );
}
function validateLinkedinUrl(value) {
  if (value !== '' && value !== null) {
    let error;
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    error = !pattern.test(value) ? 'Not valid URL.' : false;
    return error;
  }
  return false;
}
const FormikNewDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 10 }}
        {...props}
        {...field}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props} label="Please Select" {...textFieldProps} variant="outlined" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}
const FormikTextEditor = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
      <Grid style={{ minHeight: 95 }}>
        <TextareaAutosize {...field}{...props} className={classes.textArea}
          aria-label="minimum height" rowsMin={8}
        />

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
const FormikRadioGroup = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormControl component="fieldset">
        <RadioGroup row aria-label="position" {...field} {...props}>
          {props.values.map(value => (
            <FormControlLabel style={{ marginRight: 30 }} value={value} control={<Radio color="primary" icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} />} label={value} />
          ))}
        </RadioGroup>
      </FormControl>
      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}
const FormikNumberField = ({ type, label, inputProps, maxLen, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '(1  )    -    ',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Grid style={{ minHeight: 95 }}>
        <TextField
          variant="outlined"
          className={classes.inputField}
          {...field}
          {...props}
          inputProps={{
            maxLength: maxLen
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          error={meta.touched && meta.error}
          placeholder={props.placeholder}
        />

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
const FormikPercentageField = ({ type, label, inputProps, maxLen, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '(1  )    -    ',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Grid style={{ minHeight: 95 }}>
        <TextField
          variant="outlined"
          className={classes.percentageField}
          {...field}
          {...props}
          inputProps={{
            maxLength: maxLen
          }}
          InputProps={{
            inputComponent: NumberFormatPercentage,
          }}
          error={meta.touched && meta.error}
          placeholder={props.placeholder}
        />

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
const FormikPercentageField2 = ({ type, label, inputProps, maxLen, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  const [values, setValues] = React.useState({
    textmask: '(1  )    -    ',
    numberformat: '1320',
  });
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <>
      <Grid style={{ minHeight: 95 }}>
        <TextField
          variant="outlined"
          className={classes.inputField}
          {...field}
          {...props}
          inputProps={{
            maxLength: maxLen
          }}
          InputProps={{
            inputComponent: NumberFormatPercentage,
          }}
          error={meta.touched && meta.error}
          placeholder={props.placeholder}
        />

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </Grid>
    </>
  );
}
const FormikCheckBox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  return (
    <>
      <FormControlLabel style={{ marginRight: 10 }} control={<Checkbox {...field} {...props} checked={field.value} icon={<CircleUnchecked />}
        checkedIcon={<CircleCheckedFilled />} color="primary" />} label={label} />

      {meta.touched && meta.error ? (
        <Typography className={classes.label} style={{ color: '#FF0000' }} variant="subtitle2">
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
};
const FormikProvinceDropdown = ({ textFieldProps, ...props }) => {
  const [meta] = useField(props);
  const { form: { setTouched, setFieldValue } } = props;
  const { ...field } = fieldToTextField(props);
  const { name } = field;
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        style={{ marginTop: 10 }}
        {...props}
        {...field}
        options={props.options}
        getOptionLabel={(option) => option.title}
        className={classes.multiSelect}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        renderInput={(props) => <TextField {...props} label="Please Select"  {...textFieldProps} variant="outlined" />}
      >
      </Autocomplete>

      {meta.touched && meta.error ? (
        <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
          {meta.error}
        </Typography>
      ) : null}
    </>
  );
}
function getSteps() {
  return [
    {
      head: 'Startup Application',
      label:
        'We will measure your startup’s compatibility score with Angel Effect criteria, and contact you within 24 hours regarding our decision.',
    },
    {
      head: 'Deep Dive',
      label:
        'We will get detailed information about your team, product or service, market, competition, improvement, and investment rounds as an insight to your startup.',
    },
    {
      head: 'Online Pitch Day',
      label:
        'This is when you get to meet us, and share a 15-minute presentation about your team and hear the comments from your potential investors.',
    },
    {
      head: 'Deep Dive Session',
      label:
        'You will be invited to an hour-long meeting with our team and stakeholders, to discuss your startup’s experience so far and goals moving forward.',
    },
    {
      head: 'Investment Decision',
      label:
        'After reviewing all the relevant information about your startup, our shareholders will vote on an investment decision. We will also share your startup with our member network.',
    },
  ];
}
function FounderPart(props) {
  const { index, remove, form } = props;
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const companyType = ['Startup', 'Corporate'];
  const [openPhoto, setOpenPhoto] = React.useState(false);

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    borderRadius: 7,
    objectFit: 'contain'
  };

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
    flexWrap: 'wrap',
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 7,
    border: '1px solid #eaeaea',
    maxWidth: 135,
    height: 150,
    padding: 4,
    boxSizing: 'border-box'
  };

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      container
      justify="center"
      style={{ height: 'fit-content' }}
    >
      <Grid xs={12} container justify="flex-end">
        <Button
          variant="containedSecondary"
          style={{
            padding: '4.5px 15px',
            fontSize: 14,
            marginBottom: 5,
            height: 'fit-content',
          }}
          onClick={() => remove(index)}
        >
          Remove
            </Button>
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
        style={{
          border: 'solid 1px #172b4d',
          borderRadius: 7,
          height: 'fit-content',
        }}
      >
        <Grid item xs={11}>
          <Grid item container spacing={3} style={{ marginTop: 0 }}>
            <Grid item sm={4} xs={12}>
              <Dropzone
                maxSize={20971520}
                accept={'image/*'}
                onDrop={(acceptedFiles) =>
                  setFiles(
                    acceptedFiles.map((file) =>
                      Object.assign(file, { preview: URL.createObjectURL(file) }),
                    ),
                    form.setFieldValue(
                      `founderMembers.${index}.profilePhoto`,
                      acceptedFiles,
                      setOpenPhoto(true),
                    ),
                  )
                }
              >
                {({ getRootProps, getInputProps, acceptedFiles }) => (
                  <section style={{ textAlign: 'center' }}>
                    <div {...getRootProps({ className: classes.baseStyle })}>
                      <input {...getInputProps()} />
                      {!form.values.founderMembers[index].profilePhoto ? (
                        <Typography variant="h6" style={{ display: 'flex' }}>
                          {' '}
                              Add Picture
                        </Typography>
                      ) : (
                        <aside style={thumbsContainer}>
                          {acceptedFiles[0] && (
                            <div
                              id={form.values.founderMembers[index].profilePhoto[0].name}
                              style={thumb}
                              key={form.values.founderMembers[index].profilePhoto[0].name}
                            >
                              <div style={thumbInner}>
                                <img
                                  src={form.values.founderMembers[index].profilePhoto[0].preview}
                                  style={img}
                                  alt="file"
                                />
                              </div>
                            </div>
                          )}
                          {form.values.founderMembers[index].profilePhoto[0] && !acceptedFiles[0] && (
                            <div
                              id={form.values.founderMembers[index].profilePhoto[0].name}
                              style={thumb}
                              key={form.values.founderMembers[index].profilePhoto[0].name}
                            >
                              <div style={thumbInner}>
                                <img
                                  src={form.values.founderMembers[index].profilePhoto[0].preview}
                                  style={img}
                                  alt="file"
                                />
                              </div>
                            </div>
                          )}
                        </aside>
                      )}
                    </div>
                    {form.values.founderMembers[index].profilePhoto && (
                      <Button
                        variant="containedSecondary"
                        style={{ padding: '4.5px 15px', fontSize: 14, marginTop: 5 }}
                        onClick={() =>
                          form.setFieldValue(
                            `founderMembers.${index}.profilePhoto`,
                            '',
                          )
                        }
                      >
                        Remove
                      </Button>
                    )}
                  </section>
                )}
              </Dropzone>
            </Grid>
            <Field>
              {({ field, form, meta }) => (
                <ImageCrop
                  setFieldValue={form.setFieldValue}
                  files={files}
                  index={index}
                  founder={form.values.founderMembers[index]}
                  setOpenPhoto={setOpenPhoto}
                  openPhoto={openPhoto}
                />
              )}
            </Field>
            <Grid item sm={8} xs={12} style={{ paddingBottom: 0 }}>
              <FormikTextField
                placeholder="Name Surname"
                name={`founderMembers.${index}.nameSurname`}
                style={{ paddingBottom: 0 }}
              ></FormikTextField>
              <FormikTextField
                placeholder="Title"
                name={`founderMembers.${index}.jobTitle`}
                style={{ paddingTop: 0 }}
              ></FormikTextField>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <FormikTextField
                placeholder="https://linkedin.com/in/"
                validate={validateLinkedinUrl}
                name={`founderMembers.${index}.socialLinkedIn`}
                style={{ paddingBottom: 0 }}
              ></FormikTextField>
            </Grid>
            <FieldArray
              name={`founderMembers.${index}.career`}
              render={({ insert, remove, push, form }) => (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    style={{ paddingTop: 0 }}
                  >
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      style={{ marginLeft: 5 }}
                    >
                      Career
                        </Typography>
                    <Button
                      variant="containedSecondary"
                      style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                      onClick={() =>
                        push({
                          typeStartOrCorporate: '',
                          company: '',
                          jobTitle: '',
                          startDate: '',
                          endDate: '',
                        })
                      }
                    >
                      Add Career
                        </Button>
                  </Grid>
                  {form.values.founderMembers[index].career.map((label, ind) => (
                    <Grid
                      item
                      container
                      xs={12}
                      style={{ paddingTop: 0, marginBottom: 40 }}
                    >
                      <Grid
                        item
                        container
                        justify="center"
                        xs={12}
                        style={{ border: 'solid 1px #172b4d', borderRadius: 7 }}
                      >
                        <Button
                          variant="containedSecondary"
                          style={{
                            padding: '4.5px 15px',
                            fontSize: 14,
                            marginBottom: 5,
                            marginTop: 10,
                          }}
                          onClick={() => remove(ind)}
                        >
                          Remove
                            </Button>
                        <Grid
                          item
                          container
                          spacing={2}
                          xs={11}
                          style={{ marginTop: 15 }}
                        >
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <FormikRadioGroup
                              name={`founderMembers.${index}.career.${ind}.typeStartOrCorporate`}
                              values={companyType}
                            ></FormikRadioGroup>
                          </Grid>
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <FormikTextField
                              placeholder="Company Name"
                              name={`founderMembers.${index}.career.${ind}.company`}
                              style={{ paddingTop: 0 }}
                            ></FormikTextField>
                          </Grid>
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <FormikTextField
                              placeholder="Role"
                              name={`founderMembers.${index}.career.${ind}.jobTitle`}
                              style={{ paddingTop: 0 }}
                            ></FormikTextField>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            <Typography color="textSecondary" variant="h6">
                              Start Date
                                </Typography>
                            <FormikTextField
                              placeholder="Start Date"
                              type="date"
                              name={`founderMembers.${index}.career.${ind}.startDate`}
                              style={{ paddingTop: 0 }}
                            ></FormikTextField>
                          </Grid>
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            {!form.values.founderMembers[index].career[ind]
                              .isPresent && (
                                <>
                                  <Typography color="textSecondary" variant="h6">
                                    End Date
                                    </Typography>
                                  <FormikTextField
                                    placeholder="End Date"
                                    type="date"
                                    name={`founderMembers.${index}.career.${ind}.endDate`}
                                    style={{ paddingTop: 0 }}
                                  ></FormikTextField>
                                </>
                              )}
                            <FormikCheckBox
                              style={{
                                display: 'flex',
                                marginTop: -20,
                                marginBottom: 20,
                              }}
                              name={`founderMembers.${index}.career.${ind}.isPresent`}
                              handleChange={
                                form.values.founderMembers[index].career[ind]
                                  .isPresent
                                  ? (form.values.founderMembers[index].career[
                                    ind
                                  ].endDate = '')
                                  : null
                              }
                              label={
                                <Typography
                                  className={classes.mobileFont}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: -20,
                                    marginBottom: 20,
                                  }}
                                >
                                  Present
                                    </Typography>
                              }
                            ></FormikCheckBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default function DeepDiveForm(props) {
  const classes = useStyles();
  const [files, setFiles] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(1);
  const [startupKey, setStartupKey] = React.useState(0);
  const revenueModel = ['Add Based', 'Affiliate', 'Transactional', 'Subscriptional', 'Websales', 'Direct Sales', 'Channel (indirect) Sales', 'Retail Sales', 'Free Product', 'Paid Service', 'Freemium'];
  const stage = ['Idea', 'Prototype', 'MVP (Minimum Viable Product)', 'Early', 'Scale-up', 'Growth',];
  const businessModel = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
  const yesNo = ['Yes', 'No'];
  const [code, setCode] = React.useState('');
  const [berk, setBerk] = React.useState(0);
  const steps = getSteps();
  const [city, setCity] = React.useState([]);
  const history = createBrowserHistory({ forceRefresh: true });
  const [businessPlanName, setBusinessPlanName] = React.useState();
  const [logoName, setLogoName] = React.useState();

  const [initialValuess, setInitialValuess] = React.useState(
    {
      id: 0,
      title: '',
      contactEmail: '',
      website: '',
      city: '',
      socialLinkedin: '',
      socialFacebook: '',
      socialTwitter: '',
      socialInstagram: '',
      founderMembers: [
        {
          jobTitle: "",
          nameSurname: "",
          socialLinkedIn: "",
          profilePhoto: null,
          career: []
        },
      ]
      ,
      otherMembers: [
        {
          jobTitle: "",
          nameSurname: "",
          socialLinkedin: "",
        },
      ],
      webApp: "",
      iosApp: "",
      androidApp: "",
      productProblem: "",
      productSolution: "",
      productDifFromComp: "",
      productAdvantages: "",
      aboutStartup: "",
      kpi: [
        {
          name: "",
          status: "",
        },
      ],
      havePayingCustomer: "",
      payingCustomerAmount: 0,
      averageUserGrowth: 0,
      channelsGainedCustomer: "",
      revenueModel: "",
      revenueExplain: "",
      averageRevenueGrowth: 0,
      revenueGross: 0,
      revenueNet: 0,
      netProfit: 0,
      revenueRecurring: "",
      revenueRecurringTypeMRR: false,
      revenueRecurringTypeARR: false,
      longOfMonthlyRecRevARR: "",
      longOfMonthlyRecRevMRR: "",
      valueMRR: 0,
      valueARR: 0,
      businessPlanDD: null,
      businessModel: "",
      partnerships: "",
      competitorAnalysis: "",
      goToMarketStrategy: "",
      exitStrategy: "",
      logo: null,
      stage: "",
      investmentRaisedAmount: 0,
      investmentNeededDD: "",
      lastRoundDate: "",
      lastRoundInvestment: "",
      longOfSustainBusiness: "",
      postMoneyValuation: 0,
      capTableAInvestors: "",
      capTableAFounding: "",
      payrollFA: "",
      marketingFA: "",
      salesFA: "",
      developmentFA: "",
      rdFA: "",
      otherFA: "",
      haveReferenceAEInvestor: "",
      ReferenceAEInvestor: "",
      pressNewsLinks: [],
      applicationStepDD: 0,

    }
  );
  const MY_STARTUP = gql`
        query myStartup {
            myStartup{
                id
                title
                website
                city{
                    id
                    title
                }
                country{
                    id
                    code
                }
                contactEmail
                socialLinkedin
                socialFacebook
                socialTwitter
                socialInstagram
                founderMembers {
                      jobTitle
                      nameSurname
                      profilePhoto
                      socialLinkedIn
                      career{
                          typeStartOrCorporate
                          company
                          jobTitle
                          startDate
                          endDate
                          isPresent
                      }
                }
                otherMembers{
                        jobTitle
                        nameSurname
                        socialLinkedIn
                }
                webApp
                iosApp
                androidApp
                productProblem
                productSolution
                productDifFromComp
                productAdvantages
                socialPrimary
                socialSecondary
                aboutStartup
                kpi{
                        name
                        status
                }
                havePayingCustomer
                payingCustomerAmount
                averageUserGrowth
                channelsGainedCustomer
                revenueModel
                revenueExplain
                averageRevenueGrowth
                revenueGross
                revenueNet
                netProfit
                revenueRecurring
                revenueRecurringTypeMRR
                revenueRecurringTypeARR
                longOfMonthlyRecRevMRR
                longOfMonthlyRecRevARR
                valueMRR
                valueARR
                businessModel
                businessPlanDD
                logo
                partnerships
                competitorAnalysis
                goToMarketStrategy
                exitStrategy
                stage
                investmentRaisedAmount
                investmentNeededDD
                lastRoundDate
                lastRoundInvestment
                longOfSustainBusiness
                postMoneyValuation
                capTableAInvestors
                capTableAFounding
                haveReferenceAEInvestor
                referenceAEInvestor
                pressNewsLinks
                payrollFA
                marketingFA
                salesFA
                developmentFA
                rdFA
                otherFA
                applicationStepDD
                applicationStage                  
            }
    
        }
        `;
  const ALL_CITIES = gql`
            query allCities($country:String) {
                allCities(country:$country){
                    id
                    title
                }        
            }
            `;
  const { loading, data } = useQuery(MY_STARTUP, {
    variables: {},
  });
  const { loading: loading2, data: data2 } = useQuery(ALL_CITIES, {
    variables: { country: code },
  });
  React.useEffect(() => {
    if (!loading) {
      if (data) {
        if (!businessPlanName) {
          setBusinessPlanName(data.myStartup.businessPlanDD)
        }
        if (!logoName) {
          setLogoName(data.myStartup.logo)
        }
        if (data.myStartup.pressNewsLinks === null) {
          data.myStartup.pressNewsLinks = [];
        }
        if (data.myStartup.productProblem) {
          let htmlProduct = data.myStartup.productProblem
          if (typeof (data.myStartup.productProblem) === 'string') {
            data.myStartup.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
          }
        }
        else { data.myStartup.productProblem = new EditorState.createEmpty() }
        if (data.myStartup.productSolution) {
          let htmlSolution = data.myStartup.productSolution
          if (typeof (data.myStartup.productSolution) === 'string') {
            data.myStartup.productSolution = EditorState.createWithContent(stateFromHTML(htmlSolution))
          }
        }
        else { data.myStartup.productSolution = new EditorState.createEmpty() }
        if (data.myStartup.productDifFromComp) {
          let htmlDiff = data.myStartup.productDifFromComp
          if (typeof (data.myStartup.productDifFromComp) === 'string') {
            data.myStartup.productDifFromComp = EditorState.createWithContent(stateFromHTML(htmlDiff))
          }
        }
        else { data.myStartup.productDifFromComp = new EditorState.createEmpty() }
        if (data.myStartup.productAdvantages) {
          let htmlAdv = data.myStartup.productAdvantages
          if (typeof (data.myStartup.productAdvantages) === 'string') {
            data.myStartup.productAdvantages = EditorState.createWithContent(stateFromHTML(htmlAdv))
          }
        }
        else { data.myStartup.productAdvantages = new EditorState.createEmpty() }
        if (data.myStartup.aboutStartup) {
          let htmlAbt = data.myStartup.aboutStartup
          if (typeof (data.myStartup.aboutStartup) === 'string') {
            data.myStartup.aboutStartup = EditorState.createWithContent(stateFromHTML(htmlAbt))
          }
        }
        else { data.myStartup.aboutStartup = new EditorState.createEmpty() }
        if (data.myStartup.partnerships) {
          let htmlAbt = data.myStartup.partnerships
          if (typeof (data.myStartup.partnerships) === 'string') {
            data.myStartup.partnerships = EditorState.createWithContent(stateFromHTML(htmlAbt))
          }
        }
        else { data.myStartup.partnerships = new EditorState.createEmpty() }
        if (data.myStartup.competitorAnalysis) {
          let htmlAbt = data.myStartup.competitorAnalysis
          if (typeof (data.myStartup.competitorAnalysis) === 'string') {
            data.myStartup.competitorAnalysis = EditorState.createWithContent(stateFromHTML(htmlAbt))
          }
        }
        else { data.myStartup.competitorAnalysis = new EditorState.createEmpty() }
        if (data.myStartup.goToMarketStrategy) {
          let htmlAbt = data.myStartup.goToMarketStrategy
          if (typeof (data.myStartup.goToMarketStrategy) === 'string') {
            data.myStartup.goToMarketStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
          }
        }
        else { data.myStartup.goToMarketStrategy = new EditorState.createEmpty() }
        if (data.myStartup.exitStrategy) {
          let htmlAbt = data.myStartup.exitStrategy
          if (typeof (data.myStartup.exitStrategy) === 'string') {
            data.myStartup.exitStrategy = EditorState.createWithContent(stateFromHTML(htmlAbt))
          }
        }
        else { data.myStartup.exitStrategy = new EditorState.createEmpty() }

        if (data.myStartup.capTableAInvestors === null) {
          data.myStartup.capTableAInvestor = "0";
        }
        if (data.myStartup.founderMembers !== []) {
          const newArray = data.myStartup.founderMembers.map(({ __typename, ...value }) => value)
          data.myStartup.founderMembers = newArray
          data.myStartup.founderMembers.forEach(function (v) {
            v.profilePhoto = null
            if (v.career !== null) {
              v.career.forEach(function (a) {
                if (a !== null) {
                  delete a.__typename
                }

              })
            }
            else {
              v.career = [];
            }
          });
        }
        setStartupKey(data.myStartup.id)
        setBerk(data.myStartup.applicationStepDD)
        setInitialValuess(data.myStartup)
        setCode(data.myStartup.country.code)
      }
    }
    if (!loading2) {
      if (data2) {
        setCity(data2.allCities)
      }
    }
  },
    [data, loading, history, data2, loading2])

  return (
    <div>
      <Hidden mdDown>
        <AppBar position="static" style={{ background: '#ffffff' }} elevation={1}>
          <Toolbar variant="dense" disableGutters className={classes.toolbar}>
            <img src={logo} className={classes.angelLogo} alt="angel-effect"></img>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <Grid container justify="center" style={{ background: '#ffffff', paddingBottom: 30 }}>
          <img src={logo} alt="angel-effect" className={classes.logo} style={{ marginRight: '10px' }}></img>
        </Grid>
      </Hidden>
      <Grid container justify="center">
        <Grid className={classes.applicationCard} container xs={12} lg={10} xl={9}>
          {!loading && (
            <Wizard
              initialValues={initialValuess}
              deepDiveStartup={props.deepDiveStartup}
              deepDiveTeamMember={props.deepDiveTeamMember}
              startupKey={startupKey}
              berk={berk}
              enableReinitialize={true}
              onSubmit={async (values) => { }}
            >
              <Wizard.Page>
                <Grid item container xs={12} md={6} style={{ marginTop: 75 }} justify="center">
                  <Grid item xs={8}>
                    <Typography variant="h3" align="center">
                      Congratulations!
                          </Typography>
                    <Typography variant="body1" align="center" color="secondary" style={{ marginTop: 30 }}>
                      You passed to the<strong> second stage</strong> of the process.
                            <br />
                    </Typography>
                    <img src={deepDive} alt="start-image" className={classes.startImage} />
                  </Grid>
                </Grid>
                <Grid item container xs={12} md={6} style={{ marginTop: 75 }} justify="center">
                  <Grid item xs={8}>
                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                      Next Steps
                          </Typography>
                    <Stepper style={{ padding: 0, marginTop: 25 }} activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={index}>
                          <StepLabel>
                            <Typography style={{ marginLeft: 10 }} variant="body1">
                              {step.head}
                            </Typography>
                            <Typography style={{ marginLeft: 10 }} variant="body2" color="textSecondary">
                              {step.label}
                            </Typography>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                  <Grid item xs={10} xl={9}>
                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                      Profile
                          </Typography>
                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                          Startup's Name{' '}
                          <span style={{ color: '#28b1e7' }} placeholder="Startup’s Name">
                            *
                                </span>
                        </Typography>
                        <FormikTextField name="title" type="text"></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                          Contact Email <span style={{ color: '#28b1e7' }}>*</span>
                        </Typography>
                        <FormikTextField name="contactEmail" disabled="disabled" type="text"></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                          Company Website <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          name="website"
                          type="text"
                          validate={validateLinkedinUrl}
                          placeholder="https://"
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h5">
                          Location<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <Field
                          component={FormikProvinceDropdown}
                          textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                          name="city"
                          options={city}
                        ></Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Please write down your primary social media account.
                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <FormikTextField name="socialPrimary" validate={validateLinkedinUrl}></FormikTextField>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          Please write down your secondary social media account.
                              </Typography>
                        <Grid item xs={12} style={{ paddingBottom: 0 }}>
                          <FormikTextField
                            name="socialSecondary"
                            validate={validateLinkedinUrl}
                          ></FormikTextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                  <Grid item xs={10} xl={9}>
                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                      Founding Team (Max 6 People)
                          </Typography>

                    <FieldArray
                      name="founderMembers"
                      render={({ insert, remove, push, form }) => (
                        <div>
                          {form.values.founderMembers.length < 6 && (
                            <Button
                              variant="containedSecondary"
                              className={classes.AddButton}
                              onClick={() =>
                                push({
                                  jobTitle: '',
                                  nameSurname: '',
                                  socialLinkedIn: '',
                                  profilePhoto: null,
                                  career: [],
                                })
                              }
                            >
                              Add Member
                            </Button>
                          )}
                          <Grid item container spacing={6} style={{ marginTop: 0 }}>
                            {form.values.founderMembers.length > 0 &&
                              form.values.founderMembers.map((teamMember, index) => (
                                <FounderPart
                                  index={index}
                                  remove={remove}
                                  form={form}
                                />
                              ))}
                          </Grid>
                        </div>
                      )}
                    />
                    <Typography variant="h4" style={{ marginBottom: 10, marginTop: 50 }}>
                      Other Team Members (Max 10 People)
                          </Typography>
                    <FieldArray
                      name="otherMembers"
                      render={({ insert, remove, push, form }) => (
                        <div>
                          {form.values.otherMembers.length < 6 && (
                            <Button
                              variant="containedSecondary"
                              className={classes.AddButton}
                              onClick={() => push({ jobTitle: '', nameSurname: '', socialLinkedIn: '' })}
                            >
                              Add Member
                            </Button>
                          )}
                          <Grid item container spacing={6} style={{ marginTop: 0 }}>
                            {form.values.otherMembers.length > 0 &&
                              form.values.otherMembers.map((otherMember, index) => (
                                <Grid item xs={12} md={6} container justify="center">
                                  <Grid xs={12} container justify="flex-end">
                                    <Button
                                      variant="containedSecondary"
                                      style={{ padding: '4.5px 15px', fontSize: 14, marginBottom: 5 }}
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                          </Button>
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    justify="center"
                                    xs={12}
                                    style={{ border: 'solid 1px #dddddd', borderRadius: 7 }}
                                  >
                                    <Grid item xs={11} style={{ marginTop: 10 }}>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="Name Surname"
                                          name={`otherMembers.${index}.nameSurname`}
                                          style={{ paddingTop: 0 }}
                                        ></FormikTextField>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="Role"
                                          name={`otherMembers.${index}.jobTitle`}
                                          style={{ paddingTop: 0 }}
                                        ></FormikTextField>
                                      </Grid>
                                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                                        <FormikTextField
                                          placeholder="https://linkedin.com/in/"
                                          validate={validateLinkedinUrl}
                                          name={`otherMembers.${index}.socialLinkedIn`}
                                          style={{ paddingTop: 0 }}
                                        ></FormikTextField>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                        </div>
                      )}
                    />
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                  <Grid item xs={10} xl={9}>
                    <Typography variant="h4" style={{ marginBottom: 10 }}>
                      {'Product & Services'}
                    </Typography>
                    <Grid item container spacing={2} style={{ marginTop: 15 }}>
                      <Grid item xs={12} md={4}>
                        <Typography variant="h5">Web App</Typography>
                        <FormikTextField
                          name="webApp"
                          validate={validateLinkedinUrl}
                          placeholder="https://"
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="h5">IOS App</Typography>
                        <FormikTextField
                          name="iosApp"
                          validate={validateLinkedinUrl}
                          placeholder="https://"
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="h5">Android App</Typography>
                        <FormikTextField
                          name="androidApp"
                          validate={validateLinkedinUrl}
                          placeholder="https://"
                        ></FormikTextField>
                      </Grid>
                      <Grid item container spacing={6} style={{ marginTop: 0 }}>
                        <Field>
                          {({ field, form, meta }) => (
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                              <Typography variant="h5">
                                Need / Problem <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                              </Typography>
                              <RichEditor
                                editorState={form.values.productProblem}
                                onChange={form.setFieldValue}
                                name="productProblem"
                                onBlur={form.handleBlur}
                              />
                            </Grid>
                          )}
                        </Field>
                        <Field>
                          {({ field, form, meta }) => (
                            <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                              <Typography variant="h5">
                                Solutions<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                              </Typography>
                              <RichEditor
                                editorState={form.values.productSolution}
                                onChange={form.setFieldValue}
                                name="productSolution"
                                onBlur={form.handleBlur}
                              />
                            </Grid>
                          )}
                        </Field>

                        <Field>
                          {({ field, form, meta }) => (
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                              <Typography variant="h5">
                                Please specify three points that differentiate you from the competition.
                                      <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                              </Typography>
                              <RichEditor
                                editorState={form.values.productDifFromComp}
                                onChange={form.setFieldValue}
                                name="productDifFromComp"
                                onBlur={form.handleBlur}
                              />
                            </Grid>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ marginBottom: 30, marginTop: 50 }}>
                      {"KPI's (Required minimum 1 KPI)"}
                      <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                    </Typography>
                    <Grid item xs={12}>
                      <FieldArray
                        name="kpi"
                        render={({ insert, remove, push, form }) => (
                          <div>
                            <TableContainer component={Paper}>
                              <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell align="center">KPI</StyledTableCell>
                                    <StyledTableCell align="left">Name</StyledTableCell>
                                    <StyledTableCell align="left">Status</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {form.values.kpi.length > 0 &&
                                    form.values.kpi.map((kpı, index) => (
                                      <TableRow key={index}>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          className={classes.tableRows}
                                        >
                                          <Typography>{index + 1}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          className={classes.tableRows}
                                        >
                                          <FormikTextField
                                            name={`kpi.${index}.name`}
                                            placeholder="Name of KPI"
                                            type="text"
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          className={classes.tableRows}
                                        >
                                          <FormikTextField
                                            name={`kpi.${index}.status`}
                                            placeholder="KPI status"
                                            type="text"
                                          />
                                        </StyledTableCell>
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          className={classes.tableRows}
                                        >
                                          <Button
                                            variant="containedSecondary"
                                            style={{ padding: '4.5px 15px', fontSize: 14, bottom: 10 }}
                                            onClick={() => remove(index)}
                                          >
                                            Remove
                                                </Button>
                                        </StyledTableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                            {form.values.kpi.length < 5 && (
                              <Button
                                variant="containedSecondary"
                                className={classes.AddButton}
                                onClick={() => push({ name: '', status: '' })}
                              >
                                Add KPI
                              </Button>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                    <Typography variant="h4" style={{ marginBottom: 30, marginTop: 30 }}>
                      {'Traction'}
                    </Typography>
                    <Field>
                      {({ field, form, meta }) => (
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          <Typography variant="h5">
                            Do you have paying customers?
                                  <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                          </Typography>
                          <Field
                            handleChange={
                              form.values.havePayingCustomer === 'No'
                                ? ((form.values.payingCustomerAmount = ''),
                                  (form.values.averageUserGrowth = ''),
                                  (form.values.channelsGainedCustomer = ''),
                                  (form.values.revenueExplain = ''),
                                  (form.values.averageRevenueGrowth = ''),
                                  (form.values.revenueGross = ''),
                                  (form.values.revenueNet = ''),
                                  (form.values.netProfit = ''),
                                  (form.values.revenueRecurring = 'No'),
                                  (form.values.revenueRecurringTypeMRR = false),
                                  (form.values.revenueRecurringTypeARR = false))
                                : null
                            }
                            component={FormikNewDropdown}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="havePayingCustomer"
                            options={yesNo}
                          ></Field>
                        </Grid>
                      )}
                    </Field>
                    <Field>
                      {({ field, form, meta }) => (
                        <>
                          {form.values.havePayingCustomer === 'Yes' && (
                            <div>
                              <Grid item container spacing={6} style={{ marginTop: 25 }}>
                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                  <Typography variant="h5">
                                    How many paying customers do you have?
                                          <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                  </Typography>
                                  <FormikNumberField
                                    name="payingCustomerAmount"
                                    maxLen={15}
                                    placeholder="Please Write"
                                  ></FormikNumberField>
                                </Grid>
                                <Grid className="textfield" item xs={12} md={6} style={{ paddingTop: 0 }}>
                                  <Typography variant="h5">
                                    Average user growth per month for last six months.
                                          <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                  </Typography>
                                  <FormikNumberField
                                    name="averageUserGrowth"
                                    maxLen={15}
                                    placeholder="Please Write"
                                  ></FormikNumberField>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography variant="h5">
                                  Through what channels you gained those customers?
                                        <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                </Typography>
                                <FormikTextField
                                  name="channelsGainedCustomer"
                                  placeholder="ie: from my network, from marketing (Please elaborate on your answer)"
                                ></FormikTextField>
                              </Grid>

                              <Typography variant="h4" style={{ marginBottom: 30, marginTop: 30 }}>
                                {'Revenue'}
                              </Typography>
                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography variant="h5">
                                  What is your revenue model?
                                        <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                </Typography>
                                <Field
                                  component={FormikNewDropdown}
                                  textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                                  name="revenueModel"
                                  options={revenueModel}
                                ></Field>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: 25 }}>
                                <Typography variant="h5">
                                  Please explain your revenue model, ie. “the way you generate revenue”
                                        <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                </Typography>
                                <FormikTextField
                                  name="revenueExplain"
                                  placeholder="How can you generate capital, how can you expand your options of generating capital?"
                                ></FormikTextField>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography variant="h5">
                                  Average revenue growth per month for last six months.
                                        <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                </Typography>
                                <FormikNumberField
                                  name="averageRevenueGrowth"
                                  maxLen={15}
                                  placeholder="Average revenue growth"
                                ></FormikNumberField>
                              </Grid>
                              <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                  <Typography variant="h5">
                                    Gross Revenue ($)
                                          <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                  </Typography>
                                  <FormikNumberField
                                    name="revenueGross"
                                    maxLen={15}
                                    placeholder="Gross Revenue"
                                  ></FormikNumberField>
                                </Grid>
                                <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                  <Typography variant="h5">
                                    Net Revenue ($)<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                  </Typography>
                                  <FormikNumberField
                                    name="revenueNet"
                                    maxLen={15}
                                    placeholder="Net Revenue"
                                  ></FormikNumberField>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography variant="h5">
                                  Net Profit ($)<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                </Typography>
                                <FormikNumberField
                                  name="netProfit"
                                  maxLen={15}
                                  placeholder="Net Profit"
                                ></FormikNumberField>
                              </Grid>
                              <Field>
                                {({ field, form, meta }) => (
                                  <Grid item xs={12}>
                                    <Typography variant="h5">
                                      Do you have recurring revenue?
                                            <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                    </Typography>
                                    <FormikRadioGroup
                                      name="revenueRecurring"
                                      handleChange={
                                        form.values.revenueRecurring === 'No'
                                          ? ((form.values.revenueRecurringTypeMRR = ''),
                                            (form.values.revenueRecurringTypeARR = ''))
                                          : null
                                      }
                                      values={yesNo}
                                    ></FormikRadioGroup>
                                  </Grid>
                                )}
                              </Field>
                              <Field>
                                {({ field, form, meta }) => (
                                  <>
                                    {form.values.revenueRecurring === 'Yes' && (
                                      <Grid item xs={12}>
                                        <Typography variant="h5">
                                          Please choose your recurring revenue types
                                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                        </Typography>
                                        <FormikCheckBox
                                          style={{ display: 'flex', marginRight: 5 }}
                                          name="revenueRecurringTypeMRR"
                                          type="Checkbox"
                                          handleChange={
                                            form.values.revenueRecurringTypeMRR === false
                                              ? ((form.values.valueMRR = ''),
                                                (form.values.longOfMonthlyRecRevMRR = ''))
                                              : null
                                          }
                                          label={
                                            <Typography
                                              className={classes.mobileFont}
                                              style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              MRR
                                                  </Typography>
                                          }
                                        ></FormikCheckBox>
                                        <FormikCheckBox
                                          style={{ display: 'flex' }}
                                          name="revenueRecurringTypeARR"
                                          handleChange={
                                            form.values.revenueRecurringTypeARR === false
                                              ? ((form.values.valueARR = ''),
                                                (form.values.longOfMonthlyRecRevARR = ''))
                                              : null
                                          }
                                          label={
                                            <Typography
                                              className={classes.mobileFont}
                                              style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                              ARR
                                                  </Typography>
                                          }
                                        ></FormikCheckBox>
                                      </Grid>
                                    )}
                                  </>
                                )}
                              </Field>
                              <Field>
                                {({ field, form, meta }) => (
                                  <>
                                    {form.values.revenueRecurringTypeMRR === true && (
                                      <div>
                                        <Typography variant="h4" style={{ marginBottom: 30, marginTop: 30 }}>
                                          {'MRR Details'}
                                        </Typography>
                                        <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5" className={classes.twoLines}>
                                              What is your MRR value? ($)
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikNumberField
                                              name="valueMRR"
                                              maxLen={15}
                                              placeholder="Gross Revenue"
                                              style={{ display: 'flex' }}
                                            ></FormikNumberField>
                                          </Grid>
                                          <Grid
                                            className="textfield"
                                            item
                                            xs={12}
                                            md={6}
                                            style={{ paddingTop: 0 }}
                                          >
                                            <Typography variant="h5">
                                              For how long you have monthly recurring revenue?
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikTextField
                                              name="longOfMonthlyRecRevMRR"
                                              placeholder="Net Revenue"
                                            ></FormikTextField>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    )}
                                  </>
                                )}
                              </Field>
                              <Field>
                                {({ field, form, meta }) => (
                                  <>
                                    {form.values.revenueRecurringTypeARR === true && (
                                      <div>
                                        <Typography variant="h4" style={{ marginBottom: 30, marginTop: 30 }}>
                                          {'ARR Details'}
                                        </Typography>
                                        <Grid item container spacing={6} style={{ marginTop: 0 }}>
                                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                                            <Typography variant="h5" className={classes.twoLines}>
                                              What is your ARR value? ($)
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikNumberField
                                              name="valueARR"
                                              maxLen={15}
                                              placeholder="Gross Revenue"
                                              style={{ display: 'flex' }}
                                            ></FormikNumberField>
                                          </Grid>
                                          <Grid
                                            className="textfield"
                                            item
                                            xs={12}
                                            md={6}
                                            style={{ paddingTop: 0 }}
                                          >
                                            <Typography variant="h5">
                                              For how long you have monthly recurring revenue?
                                                    <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                                            </Typography>
                                            <FormikTextField
                                              name="longOfMonthlyRecRevARR"
                                              placeholder="Net Revenue"
                                            ></FormikTextField>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    )}
                                  </>
                                )}
                              </Field>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                  <Grid item xs={10} xl={9}>
                    <Typography variant="h4" style={{ marginBottom: 25 }}>
                      {'Business & Competition'}
                    </Typography>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Business Plan <span style={{ color: '#28b1e7' }}>*</span>
                      </Typography>
                      <Field
                        name="businessPlanDD"
                        render={({ field, form }) => (
                          <div>
                            <Dropzone
                              maxSize={20971520}
                              onDrop={(acceptedFiles) => form.setFieldValue('businessPlanDD', acceptedFiles)}
                            >
                              {({ getRootProps, getInputProps, acceptedFiles }) => (
                                <section>
                                  <div {...getRootProps({ className: classes.baseStyle })}>
                                    <input {...getInputProps()} />
                                    <Typography variant="h6" style={{ display: 'flex' }}>
                                      <OpenInBrowser style={{ marginRight: '5px' }}></OpenInBrowser> Browse...
                                          </Typography>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            {form.values.businessPlanDD ? (
                              <Grid
                                container
                                justify="space-between"
                                direction="row-reverse"
                                style={{ marginBottom: 25 }}
                              >
                                <Typography variant="body2" align="right">
                                  <span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded
                                      </Typography>
                                {typeof form.values.businessPlanDD !== 'string' ? (
                                  <Typography variant="h6" align="left">
                                    {form.values.businessPlanDD[0].name} is existing file
                                  </Typography>
                                ) : (
                                  <Typography variant="h6" align="left">
                                    {form.values.businessPlanDD.split('/').pop().replace('.png', '')} is existing
                                          file
                                  </Typography>
                                )}
                              </Grid>
                            ) : (
                              <Grid
                                container
                                justify="space-between"
                                direction="row-reverse"
                                style={{ marginBottom: 25 }}
                              >
                                <Typography variant="body2" align="right">
                                  <span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded
                                      </Typography>
                                {businessPlanName && (
                                  <Typography variant="body2" align="left">
                                    {businessPlanName.split('/').pop().replace('.png', '')} is existing file
                                  </Typography>
                                )}
                              </Grid>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 25 }}>
                      <Typography variant="h5">
                        What is your business model?<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                      </Typography>
                      <Field
                        component={FormikNewDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="businessModel"
                        options={businessModel}
                      ></Field>
                    </Grid>
                    <Grid item container spacing={6} style={{ marginTop: 25 }}>
                      <Field>
                        {({ field, form, meta }) => (
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <Typography variant="h5">
                              Partnerships <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <RichEditor
                              editorState={form.values.partnerships}
                              onChange={form.setFieldValue}
                              name="partnerships"
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        )}
                      </Field>
                      <Field>
                        {({ field, form, meta }) => (
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <Typography variant="h5">
                              Competitor Analysis <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <RichEditor
                              editorState={form.values.competitorAnalysis}
                              onChange={form.setFieldValue}
                              name="competitorAnalysis"
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        )}
                      </Field>
                      <Field>
                        {({ field, form, meta }) => (
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <Typography variant="h5">
                              Go-to Market Strategy<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <RichEditor
                              editorState={form.values.goToMarketStrategy}
                              onChange={form.setFieldValue}
                              name="goToMarketStrategy"
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        )}
                      </Field>
                      <Field>
                        {({ field, form, meta }) => (
                          <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                            <Typography variant="h5">
                              Exit Strategy<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                            </Typography>
                            <RichEditor
                              editorState={form.values.exitStrategy}
                              onChange={form.setFieldValue}
                              name="exitStrategy"
                              onBlur={form.handleBlur}
                            />
                          </Grid>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Grid>
              </Wizard.Page>
              <Wizard.Page>
                <Grid item container xs={12} style={{ marginTop: 25 }} justify="center">
                  <Grid item xs={10} xl={9}>
                    <Typography variant="h4" style={{ marginBottom: 25 }}>
                      {'Company'}
                    </Typography>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        Company Logo <span style={{ color: '#28b1e7' }}>*</span>
                      </Typography>
                      <Field
                        name="logo"
                        render={({ field, form }) => (
                          <div>
                            <Dropzone
                              maxSize={20971520}
                              accept={'image/*'}
                              onDrop={(acceptedFiles) => form.setFieldValue('logo', acceptedFiles)}
                            >
                              {({ getRootProps, getInputProps, acceptedFiles }) => (
                                <section>
                                  <div {...getRootProps({ className: classes.baseStyle })}>
                                    <input {...getInputProps()} />
                                    <Typography variant="h6" style={{ display: 'flex' }}>
                                      <OpenInBrowser style={{ marginRight: '5px' }}></OpenInBrowser> Browse...
                                          </Typography>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                            {form.values.logo ? (
                              <Grid
                                container
                                justify="space-between"
                                direction="row-reverse"
                                style={{ marginBottom: 25 }}
                              >
                                <Typography variant="body2" align="right">
                                  <span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded
                                      </Typography>
                                {typeof form.values.logo !== 'string' ? (
                                  <Typography variant="h6" align="left">
                                    {form.values.logo[0].name} is existing file
                                  </Typography>
                                ) : (
                                  <Typography variant="h6" align="left">
                                    {form.values.logo.split('/').pop().replace('.png', '')} is existing file
                                  </Typography>
                                )}
                              </Grid>
                            ) : (
                              <Grid
                                container
                                justify="space-between"
                                direction="row-reverse"
                                style={{ marginBottom: 25 }}
                              >
                                <Typography variant="body2" align="right">
                                  <span style={{ color: '#28b1e7' }}>*</span>Max 20MB files can be uploaded
                                      </Typography>
                                {logoName && (
                                  <Typography variant="h6" align="left">
                                    {logoName.split('/').pop().replace('.png', '')} is existing file
                                  </Typography>
                                )}
                              </Grid>
                            )}
                          </div>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 25 }}>
                      <Typography variant="h5">
                        Stage<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                      </Typography>
                      <Field
                        component={FormikNewDropdown}
                        textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                        name="stage"
                        options={stage}
                      ></Field>
                    </Grid>
                    <Grid item container spacing={6} style={{ marginTop: 25 }}>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          How much investment have you raised so far?
                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikNumberField
                          name="investmentRaisedAmount"
                          maxLen={15}
                          placeholder="Please write"
                        ></FormikNumberField>
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          How much investment do you need in this round?
                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField name="investmentNeededDD" placeholder="Please write"></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          When was the last round?<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          name="lastRoundDate"
                          type="date"
                          placeholder="Eg: XX/XX/XXXX"
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          Last round’s investment<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikNumberField
                          name="lastRoundInvestment"
                          maxLen={15}
                          placeholder="Eg: $10000"
                        ></FormikNumberField>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          How long can you sustain your business with your investment until the next investment
                                round?<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikTextField
                          name="longOfSustainBusiness"
                          placeholder="Please Write"
                        ></FormikTextField>
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          What is your post-money valuation?
                                <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikNumberField
                          name="postMoneyValuation"
                          maxLen={15}
                          placeholder="Please Write"
                        ></FormikNumberField>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ marginBottom: 25, marginTop: 20 }}>
                      {'Cap Table Allocation'}
                    </Typography>
                    <Grid item container spacing={6} style={{ marginTop: 0 }}>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Typography variant="h5">Investors</Typography>
                        <FormikPercentageField2
                          name="capTableAInvestors"
                          maxLen={3}
                          placeholder="Eg: 50%"
                        ></FormikPercentageField2>
                      </Grid>
                      <Grid item xs={12} md={6} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Typography variant="h5">Funding Team</Typography>
                        <FormikPercentageField2
                          name="capTableAFounding"
                          maxLen={3}
                          placeholder="Eg: 50%"
                        ></FormikPercentageField2>
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 0, marginTop: -15 }}>
                        <Field>
                          {({ field, form, meta }) => (
                            <>
                              {sumCap(form.values.capTableAInvestors, form.values.capTableAFounding) > 100 && (
                                <Typography align="left" variant="body2" style={{ color: '#ff0000' }}>
                                  You entered values over 100%
                                </Typography>
                              )}
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 0, marginTop: -15 }}>
                        <Field>
                          {({ field, form, meta }) => (
                            <Typography align="right" variant="h5">
                              {sumCap(form.values.capTableAInvestors, form.values.capTableAFounding)}%
                            </Typography>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ marginBottom: 25, marginTop: 20 }}>
                      {'Fund Allocation'}
                    </Typography>
                    <Grid item container>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          Payroll<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikPercentageField
                          name="payrollFA"
                          maxLen={3}
                          placeholder="%"
                        ></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          Marketing<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikPercentageField
                          name="marketingFA"
                          maxLen={3}
                          placeholder="%"
                        ></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          Sales<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikPercentageField name="salesFA" maxLen={3} placeholder="%"></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          Development<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikPercentageField
                          name="developmentFA"
                          maxLen={3}
                          placeholder="%"
                        ></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">
                          {'R & D'}
                          <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                        </Typography>
                        <FormikPercentageField name="rdFA" maxLen={3} placeholder="%"></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} sm={4} md={2} style={{ paddingTop: 0 }}>
                        <Typography variant="h5">Other</Typography>
                        <FormikPercentageField name="otherFA" maxLen={3} placeholder="%"></FormikPercentageField>
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 0, marginTop: -15 }}>
                        <Field>
                          {({ field, form, meta }) => (
                            <>
                              {sumFund(
                                form.values.payrollFA,
                                form.values.marketingFA,
                                form.values.salesFA,
                                form.values.developmentFA,
                                form.values.rdFA,
                                form.values.otherFA,
                              ) > 100 && (
                                  <Typography align="left" variant="body2" style={{ color: '#ff0000' }}>
                                    You entered values over 100%
                                  </Typography>
                                )}
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Grid item xs={6} style={{ paddingTop: 0, marginTop: -15 }}>
                        <Field>
                          {({ field, form, meta }) => (
                            <Typography align="right" variant="h5">
                              {sumFund(
                                form.values.payrollFA,
                                form.values.marketingFA,
                                form.values.salesFA,
                                form.values.developmentFA,
                                form.values.rdFA,
                                form.values.otherFA,
                              )}
                                    %
                            </Typography>
                          )}
                        </Field>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" style={{ marginBottom: 25, marginTop: 20 }}>
                      {'References'}
                    </Typography>
                    <FieldArray
                      name="pressNewsLinks"
                      render={({ insert, remove, push, form }) => (
                        <div>
                          <Typography variant="h5">
                            If you have any press news, please share their website link.
                                </Typography>
                          <Button
                            variant="containedSecondary"
                            className={classes.AddButton}
                            onClick={() => push('')}
                          >
                            Add News
                                </Button>
                          <Grid item xs={12} style={{ paddingTop: 0 }}>
                            {form.values.pressNewsLinks.length > 0 &&
                              form.values.pressNewsLinks.map((news, index) => (
                                <div>
                                  <Hidden mdUp>
                                    <Grid xs={12} container justify="flex-end">
                                      <Button
                                        variant="containedSecondary"
                                        style={{ padding: '4.5px 15px', fontSize: 14, top: 5 }}
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                            </Button>
                                    </Grid>
                                    <FormikTextField
                                      name={`pressNewsLinks.${index}`}
                                      validate={validateLinkedinUrl}
                                      placeholder="https://"
                                    ></FormikTextField>
                                  </Hidden>
                                  <Hidden mdDown>
                                    <Grid xs={12} container justify="space-between">
                                      <Grid xs={11} item style={{ paddingRight: 10 }}>
                                        <FormikTextField
                                          name={`pressNewsLinks.${index}`}
                                          validate={validateLinkedinUrl}
                                          placeholder="https://"
                                        ></FormikTextField>
                                      </Grid>
                                      <Grid xs={1} item style={{ paddingTop: 20 }}>
                                        <Button
                                          variant="containedSecondary"
                                          style={{ padding: '4.5px 15px', fontSize: 14, top: 5 }}
                                          onClick={() => remove(index)}
                                        >
                                          Remove
                                              </Button>
                                      </Grid>
                                    </Grid>
                                  </Hidden>
                                </div>
                              ))}
                          </Grid>
                        </div>
                      )}
                    />
                    <Field>
                      {({ field, form, meta }) => (
                        <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 25 }}>
                          <Typography variant="h5">
                            Do you have any reference from Angel Effect investors?
                                  <span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                          </Typography>
                          <Field
                            handleChange={
                              form.values.haveReferenceAEInvestor === 'No'
                                ? (form.values.referenceAEInvestor = '')
                                : null
                            }
                            component={FormikNewDropdown}
                            textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                            name="haveReferenceAEInvestor"
                            options={yesNo}
                          ></Field>
                        </Grid>
                      )}
                    </Field>
                    <Field>
                      {({ field, form, meta }) => (
                        <>
                          {form.values.haveReferenceAEInvestor === 'Yes' && (
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                              <Typography variant="h5">
                                From Who<span style={{ color: '#28b1e7', marginLeft: '5px' }}>*</span>
                              </Typography>
                              <FormikTextField
                                name="referenceAEInvestor"
                                placeholder="Name Surname"
                              ></FormikTextField>
                            </Grid>
                          )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Wizard.Page>
            </Wizard>
          )}
        </Grid>
      </Grid>
    </div>
  );
}