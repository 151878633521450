import React from 'react';
import { makeStyles} from "@material-ui/core/styles";
import { RichEditorReadOnly } from "../../Components/RichEditorReadOnly/RichEditorReadOnly.js";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>({
        cardContainer: {
            marginBottom: '15px',
        },
        borderRight: {
            [theme.breakpoints.up('sm')]: {
                borderRight: '0.2px solid'
            },
        },
        secondExp: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: '20px!important'
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '5px'
            },
        },
        namePart: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },  
        },
        companyPart: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '50px'
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },    
        },
        paper: {
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            padding: 25,
            [theme.breakpoints.up('lg')]: {
                padding: 50,
                paddingTop: 25
            },
        },
        workExperience: {
            textAlign: 'start',
        },
        labelFirst: {
            textTransform: 'capitalize',
            color: '#ffffff',
        },
        img: {
            width: 120,
            height:120,
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
        },
    }));

export default function TabProductCard(props) {
        function https(value) {
            const prefix = 'https://';
            if (value.substr(0, prefix.length) !== prefix)
            {
            value = prefix + value;
            }
            return value
        }
        const classes = useStyles();
        return (
            <Paper style={{marginBottom: '30px'}} className={classes.paper}>
                <Grid style={{marginBottom: '25px'}} item sm={12} container>
                    {props.website && 
                    <>
                    <Grid item xs container alignItems={"center"} direction="row" spacing={2}>
                        <Grid item container alignItems="center" justify="space-between">
                            <Typography gutterBottom={true} align={"left"}
                                        variant="h5" style={{display:'flex',alignItems:'center',marginTop:25}}>
                                Product Link : <a target="_blank" href={https(props.website)} color="primary" style={{marginLeft:'15px',padding:0,color:'#28b1e7',wordBreak:'break-word'}}>{props.website}</a>
                            </Typography>
                        </Grid> 
                    </Grid>
                    <Grid xs={12}>
                        <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '50px 0'}}/>
                     </Grid>
                     </>
                     }
                     {props.productProblem && 
                     <>
                     <Grid item container alignItems="center" justify="space-between">
                            <Typography gutterBottom={true} align={"left"}
                                        variant="h5">
                                    Problem
                            </Typography>
                     </Grid>
                     <Grid item container>
                        <RichEditorReadOnly
                            editorState={props.productProblem}
                            name="productProblem"
                        />  
                     </Grid>
                     </>
                     }
                     <Grid xs={12}>
                        <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '50px 0'}}/>
                     </Grid>
                     <Grid item container alignItems="center" justify="space-between">
                            <Typography gutterBottom={true} align={"left"}
                                        variant="h5">
                                    Solution
                            </Typography>
                     </Grid>
                     <Grid item container>
                     <RichEditorReadOnly
                            editorState={props.productSolution}
                            name="productProblem"
                        />     
                     </Grid>
                </Grid>
            </Paper>
        );
}