import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Header from "../../Components/Header";
import LeftNavigation from "../../Components/LeftNavigation";
import MobileHeader from "../../Components/MobileHeader";
import Grid from "@material-ui/core/Grid";
import {Link} from 'react-router-dom';
import arrow from '../../static/icon/arrow-left.svg';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import { useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment'
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import BottomNavigation from "../../Components/BottomNavigation";

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
      padding: 40,
    },
    padding: 25,
    background: '#ffffff'
  },
  topPart: {
      padding: '0 10px'
  },
  name: {
    [theme.breakpoints.down('xs')]: {
      marginBottom:25
    },
  },
  logo: {
    width: 180,
    height: 50,
    marginTop:30,
  },
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
    justifyContent:'center'
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  }, 

}));

function SendMailDialog(props) {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose(null);
  };
  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title"><center>Mail Type</center></DialogTitle>
      <List>
        <ListItem autoFocus button onClick={() => handleListItemClick('forgotPassword')}>
          <ListItemText style={{textAlign:'center'}} primary="Forgot Password Mail" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SendMailDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default function SuperAdminInvestorDetail(props){
  const [detail, setDetail] = React.useState({});
  const [sendMailPopupOpen, setSendMailPopupOpen] = React.useState(false);
  const [isSentMail, setIsSentMail] = React.useState(false);
  const ALL_INVESTORS = gql`
  query allInvestors($id:ID) {
          allInvestors(id:$id){
          id
          createdAt
          getRole
          email
          firstName
          lastName
          phoneNumber
          profilePhoto
          socialLinkedIn
          hearAboutUs
          totalInvestedCompanies
          totalInvestmenAmount
          budgetForAE
          planInvestTwoYear
          favorites {
              startup {
                title
                logo
              }
          }
          industry
      }

  }
  `;
    const { loading, data } = useQuery(ALL_INVESTORS, {
      variables: {id:props.match.params.id},
  });
    const handleClickOpen = () => {
      setSendMailPopupOpen(true);
  };
  const handleClose = (value) => {
    if (value){
      let variables = {}
      variables.id = props.match.params.id
      variables.inputType = value
      props.adminInvestorMutations({variables}).then(r => {
        if (r.data.adminInvestorMutations === true){
          setIsSentMail(true);
        }
        setSendMailPopupOpen(false);
      })
    }else{
      setSendMailPopupOpen(false);
    }
  };
  React.useEffect(() => {
    if(!loading){
        if(data){
          data.allInvestors[0].createdAt = new Date(parseInt(data.allInvestors[0].createdAt))
          setDetail(data.allInvestors[0]);
          }
        }
  },
[data, loading])
  const classes = useStyles();
  return(
            <div>
                <Hidden smDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                  <MobileHeader></MobileHeader>
                </Hidden>
              {Object.keys(detail).length > 0 && <Grid container className={classes.mainGrid} style={{background:'#f7f7fc'}}>
                <LeftNavigation>
                </LeftNavigation>
                <BottomNavigation></BottomNavigation>
                <Grid className={classes.adminContainer} container xs={12} lg={11} xl={12} style={{marginTop:50,height:'fit-content'}}>
                    <Grid
                                container
                                alignItems="center"
                                justify="space-between"
                                className={classes.topPart}>
                        <Grid className={classes.name} item style={{display:'flex'}}
                                alignItems="center">
                          <Link style={{padding:0}} to={"/admin/investors"}><img src={arrow} alt="arrow" style={{marginRight:15}}></img></Link>
                          <Typography variant="h4">{detail.firstName} {detail.lastName}</Typography>
                        </Grid>
                      <Button variant="outlinedPrimary" style={{padding: '13.5px 30px',background:'#ffffff',fontSize:15}} onClick={handleClickOpen}> Send Mail </Button>
                      <SendMailDialog open={sendMailPopupOpen} onClose={handleClose}/>
                    </Grid>
                    <Grid container item style={{marginTop:'50px'}}>
                        <Grid item container xs={12} sm={6} lg={4} style={{paddingLeft:10,marginBottom:50}}>
                          <Grid xs={12} sm={10} className={classes.userCard}>
                            <Grid container justify="space-between" alignItems="center">
                              <Typography variant="h5">User Detail </Typography>
                              <Link to={`/admin/investor-edit/${props.match.params.id}`}><Button variant="containedSecondary" style={{padding:"5.5px 25px"}}> Edit </Button></Link>
                            </Grid>
                            <hr size="1" style={{border: '0.2px solid #f5f5f5', margin: '25px 0'}}/>
                              <Typography variant="body1" color="textSecondary" style={{marginTop:30,wordBreak:"break-word",fontSize:15}}>{detail.getRole}</Typography>
                              <Typography variant="body1" color="textSecondary" style={{marginTop:20,wordBreak:"break-word",fontSize:15}}>{detail.email}</Typography>
                              <Typography variant="body1" color="textSecondary" style={{marginTop:20,wordBreak:"break-word",fontSize:15}}>{detail.socialLinkedIn}</Typography>
                              <Grid style={{marginTop:40}} container>
                                <Typography variant="h6"style={{marginRight:10}}>Registration Date:</Typography>
                                <Typography variant="h6" color="textSecondary">{moment(detail.createdAt).format('DD-MM-YYYY') }</Typography>
                              </Grid>
                              {/* <Grid style={{marginTop:20}} container>
                                <Typography variant="h5" style={{marginRight:10}}>Contract Signing Date</Typography>
                                <Typography variant="h5" color="textSecondary">01.01.2020</Typography>
                              </Grid>                              */}
                              {detail.favorites && 
                              <div>
                                <Typography variant="h6" style={{marginTop:40}}>Favorite Startups</Typography>
                                <Grid  container xs={12}>
                                {detail.favorites.map((favorite) => (
                                    <Grid style={{marginTop:20}} xs={12} md={6} container alignItems="center">
                                        <img src={favorite.startup.logo} style={{width:50, height:50, marginRight:10}} />
                                        <Grid container xs={7}>
                                          <Typography variant="h6"style={{marginRight:10,wordBreak:"break-word"}}>{favorite.startup.title}</Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                                </Grid>
                              </div>
                              }
                              {/*<Typography variant="h5" style={{marginTop:40}}>Investment Request</Typography>*/}
                              <Grid  container xs={12}>
                                  {/* <Grid style={{marginTop:20}} xs={12} container alignItems="center">
                                    <Grid container xs={12} lg={8} xl={5} alignItems="center" >
                                        <Grid style={{width:50, height:50, background:'#28b1e7', marginRight:10}} />
                                        <Grid container xs={6}>
                                          <Typography variant="h5"style={{marginRight:10,wordBreak:"break-word"}}>Rentover</Typography>
                                        </Grid>
                                    </Grid>
                                      <Grid container xs={6} lg={6} xl={3} alignItems="center" className={classes.requests}>
                                          <Grid container xs={12}>
                                            <Typography variant="h5" color="primary" style={{marginRight:10,wordBreak:"break-word"}}>+ $25.000</Typography>
                                          </Grid>
                                      </Grid>
                                      <Grid container xs={6} lg={6} xl={4} alignItems="center" className={classes.requests}>
                                          <Grid container xs={12}>
                                            <Typography variant="h5" color="textSecondary" style={{marginRight:10,wordBreak:"break-word"}}>01.01.2020</Typography>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                                  <Grid style={{marginTop:20}} xs={12} container alignItems="center">
                                          <Grid container xs={12} lg={8} xl={5} alignItems="center" >
                                              <Grid style={{width:50, height:50, background:'#28b1e7', marginRight:10}} />
                                              <Grid container xs={6}>
                                                <Typography variant="h5"style={{marginRight:10,wordBreak:"break-word"}}>Rentover</Typography>
                                              </Grid>
                                          </Grid>
                                          <Grid container xs={6} lg={6} xl={3} alignItems="center" className={classes.requests}>
                                              <Grid container xs={12}>
                                                <Typography variant="h5" color="primary" style={{marginRight:10,wordBreak:"break-word"}}>+ $25.000</Typography>
                                              </Grid>
                                          </Grid>
                                          <Grid container xs={6} lg={6} xl={4} alignItems="center" className={classes.requests}>
                                              <Grid container xs={12}>
                                                <Typography variant="h5" color="textSecondary" style={{marginRight:10,wordBreak:"break-word"}}>01.01.2020</Typography>
                                              </Grid>
                                          </Grid>
                                  </Grid> */}
                                  <Grid container xs={12} direction="column" style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>How did you hear about us?</Typography>
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20}}>{detail.hearAboutUs}</Typography>
                                  </Grid>
                                  <Grid container xs={12} direction="column"  style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>In total, how many startup companies have you invested in?</Typography>
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20}}>{detail.totalInvestedCompanies}</Typography>
                                  </Grid>
                                  <Grid container xs={12} direction="column"  style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>How much did you invest in total?</Typography>
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20}}>{detail.totalInvestmenAmount}</Typography>
                                  </Grid>
                                  <Grid container xs={12} direction="column"  style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>How much of your budget did you allocate for angel investment?</Typography>
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20}}>{detail.budgetForAE}</Typography>
                                  </Grid>
                                  <Grid container xs={12} direction="column"  style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>How many startup companies do you plan to invest in the next 2 years?</Typography>
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20}}>{detail.planInvestTwoYear}</Typography>
                                  </Grid>

                                   {detail.industry &&<Grid container xs={12} direction="column"  style={{marginTop:40}}>
                                    <Typography variant="h6" style={{wordBreak:"break-word"}}>What industries would you like to invest in?</Typography>
                                    {detail.industry.map((industry) => (
                                    <Typography variant="h6" color="textSecondary" style={{wordBreak:"break-word",marginTop:20,marginRight:5,width:'100%'}}>- {industry}</Typography>
                                    ))}
                                  </Grid>}
                                  {/* <Grid container xs={12} style={{marginTop:40}}>
                                    <Link style={{color:'#28b1e7', textDecoration:'underline', paddingLeft:'0'}} to="/">Accept Non-Disclosure Agreement</Link>
                                  </Grid> */}
                              </Grid>
                          </Grid>
                          <Snackbar autoHideDuration={6000} onClose={() => setIsSentMail(false)} open={isSentMail} >
                            <Alert severity="success">Mail sent successfully.</Alert>
                          </Snackbar>
                        </Grid>
                    </Grid>
                </Grid>
              </Grid>
              }
            </div>        
  );
}