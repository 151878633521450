import SuperAdminStartupEdit from './SuperAdminStartupEdit'
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const EDIT_STARTUP = gql`
  mutation editStartup(
    $id: ID!
    $title: String
    $website: String
    $businessModel: String
    $partnerships: String
    $competitorAnalysis: String
    $investmentRaisedAmount: BigInt
    $investmentNeeded: String
    $lastRoundInvestment: String
    $aboutStartup: String
    $postMoneyValuation: BigInt
    $capTableAInvestors: String
) {
  editStartup(
        id: $id
        title: $title
        website: $website
        businessModel: $businessModel
        partnerships: $partnerships
        competitorAnalysis: $competitorAnalysis
        investmentRaisedAmount: $investmentRaisedAmount
        investmentNeeded: $investmentNeeded
        lastRoundInvestment: $lastRoundInvestment
        postMoneyValuation: $postMoneyValuation
        capTableAInvestors: $capTableAInvestors
        aboutStartup: $aboutStartup
    )
  }
`;
const JoinWithMutation = graphql(EDIT_STARTUP, {
    name: 'editStartup',
  })(SuperAdminStartupEdit);
  export default JoinWithMutation;