import gql from 'graphql-tag';
export const MY_PROFILE = gql`
query myProfile {
    myProfile{
        id
        role
        firstName
        lastName
        profilePhoto
        contractSigned
        isApprovedInvestor
    }

}
`;
export const DASHBOARD_SUMMARY= gql`
query dashboardSummary {
    dashboardSummary{
        archive
        reevaluate
        informationwaiting
        inprogress
        voting
        portfolio
        countries
        total
    }

}
`;
export const FIRST_EVA= gql`
query dashboardAboutFirstEva {
    dashboardAboutFirstEva{
        modes
        average
        standardDeviation
        median
        firstQ{
            average
            standardDeviation
            median
        }
        secondQ{
            average
            standardDeviation
            median
        }
        thirdQ{
            average
            standardDeviation
            median
        }
        fourthQ{
            average
            standardDeviation
            median
        }
    }

}
`;