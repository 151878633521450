import React from 'react';
import { makeStyles} from "@material-ui/core/styles";
import { RichEditorReadOnly } from "../RichEditorReadOnly/RichEditorReadOnly.js";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>({
        cardContainer: {
            marginBottom: '15px',
        },
        borderRight: {
            [theme.breakpoints.up('sm')]: {
                borderRight: '0.2px solid'
            },
        },
        secondExp: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: '20px!important'
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '5px'
            },
        },
        namePart: {
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },  
        },
        companyPart: {
            [theme.breakpoints.up('md')]: {
                marginLeft: '50px'
            },
            [theme.breakpoints.up('sm')]: {
                marginLeft: '25px'
            },    
        },
        paper: {
            textAlign: 'center',
            borderRadius: 4,
            color: theme.palette.text.secondary,
            boxShadow: 'none',
            background: '#ffffff',
            padding: 25,
            [theme.breakpoints.up('lg')]: {
                padding: 50,
                paddingTop: 25
            },
        },
        workExperience: {
            textAlign: 'start',
        },
        labelFirst: {
            textTransform: 'capitalize',
            color: '#ffffff',
        },
        img: {
            width: 120,
            height:120,
            border: '1px solid #f3f3f3',
            borderRadius: '5px',
        },
    }));

export default function TabCompanyCard(props) {
        const classes = useStyles();
        const money = (value) => {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              });
              if (formatter.format(parseInt(value)) !== '$NaN'){
                return formatter.format(parseInt(value))
              }
              else{
                  return '-'
              }
        }
        const running = (investmentRaisedAmount, investmentNeededDD) => {
            if(parseInt(investmentNeededDD) > parseInt(investmentRaisedAmount)) {
             let percent =(parseInt(investmentRaisedAmount) * 100 ) / parseInt(investmentNeededDD)
             percent = ~~percent
                 return percent;
            }
            return 100;
         }
        return (
          <Paper style={{ marginBottom: '30px' }} className={classes.paper}>
          <Grid style={{ marginBottom: '25px' }} item sm={12} container>
            {props.detail.description && props.detail.description !== '' && (
              <>
                <Grid item xs container alignItems={'center'} direction="row" spacing={2}>
                  <Grid item container alignItems="center" justify="space-between">
                    <Typography gutterBottom={true} align={'left'} variant="h5">
                      One-liner
                          </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography gutterBottom={true} align={'left'} variant="body1">
                      {props.detail.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            )}
            {props.detail.productProblem &&
              <>
                <Grid item container alignItems="center" justify="space-between">
                  <Typography gutterBottom={true} align={"left"}
                    variant="h5">
                    Problem
                            </Typography>
                </Grid>
                <Grid item container>
                  <RichEditorReadOnly
                    editorState={props.detail.productProblem}
                    name="productProblem"
                  />
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            }
            {props.detail.productSolution &&
              <>
                <Grid item container alignItems="center" justify="space-between">
                  <Typography gutterBottom={true} align={"left"}
                    variant="h5">
                    Solution
                            </Typography>
                </Grid>
                <Grid item container>
                  <RichEditorReadOnly
                    editorState={props.detail.productSolution}
                    name="productSolution"
                  />
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            }
            <Grid item container justify="space-around">
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Business Model
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.businessModel}</Typography>
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Stage
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.stage}</Typography>
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Industry
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.industry && props.detail.industry.length > 0 && props.detail.industry.map((row) => (row + ', '))}</Typography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
            </Grid>
            <Grid item container justify="space-between">
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Total Revenue
                  <Grid style={{ display: 'flex', borderRadius: '7px', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginLeft: 10 }}>
                    <Typography align={"left"}
                      variant="body2" style={{ color: '#fff' }}>
                      i
                  </Typography>
                  </Grid>
                </Typography>
                {props.detail.totalRevenueGrowth &&
                  <Typography variant="h5" color="primary" align={"left"}>{money(props.detail.totalRevenueGrowth)}</Typography>
                }
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Avg. Revenue Growth
                  <Grid style={{ display: 'flex', borderRadius: '7px', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginLeft: 10 }}>
                    <Typography align={"left"}
                      variant="body2" style={{ color: '#fff' }}>
                      i
                    </Typography>
                  </Grid>
                </Typography>
                {props.detail.averageRevenueGrowth &&
                  <Typography variant="h5" color="primary" align={"left"}>%{props.detail.averageRevenueGrowth}</Typography>
                }
              </Grid>
            </Grid>
            <Grid xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
            </Grid>
            <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
              <Typography gutterBottom={true} align={"left"}
                variant="h5" style={{ display: 'flex' }}>
                  Recurring Revenue Type
              </Typography>
              {props.detail.revenueRecurringType &&
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.revenueRecurringType}</Typography>
              }
            </Grid>

          </Grid>
          </Paper>
        );
}