import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Form, Formik } from 'formik';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import NumberFormat from 'react-number-format';
import editImg from '../../static/icon/edit.svg';
import { useField, Field } from 'formik';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import PropTypes from 'prop-types';
import BlackEdit from '../../static/img/new.svg';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import { RichEditorReadOnly } from "../RichEditorReadOnly/RichEditorReadOnly.js";
import { RichEditor } from "../../Components/RichEditor/RichEditor.js";
import { stateToHTML } from 'draft-js-export-html'
import { EditorState, } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html'

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: '15px',
  },
  borderRight: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '0.2px solid'
    },
  },
  secondExp: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '20px!important'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '5px'
    },
  },
  namePart: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '25px'
    },
  },
  companyPart: {
    [theme.breakpoints.up('md')]: {
      marginLeft: '50px'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '25px'
    },
  },
  myDialog: {
    overflowY: 'unset',
    overflowX: 'hidden',
    padding: 40
  },
  paper: {
    padding: 25,
    textAlign: 'center',
    borderRadius: 4,
    color: theme.palette.text.secondary,
    boxShadow: 'none',
    background: '#ffffff'
  },
  workExperience: {
    textAlign: 'start',
  },
  labelFirst: {
    textTransform: 'capitalize',
    color: '#ffffff',
  },
  img: {
    width: 120,
    height: 120,
    border: '1px solid #f3f3f3',
    borderRadius: '5px',
  },
  percentageField: {
    width: '100%',
    marginTop: 10,
    '& div': {
      borderRadius: 0
    },
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f1f3f9',
    color: '#8898aa;',
    height: 30,
    fontSize: '12px'
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: '#f7fafc'
    },
    color: '#172b4d',
    fontWeight: '600'
  },
}))(TableRow);

export default function MyTabCompanyCard(props) {
  const classes = useStyles();
  const stage = ['Idea', 'Prototype', 'MVP (Minimum Viable Product)', 'Early', 'Scale-up', 'Growth',];
  const businessModel = ['B2B', 'B2C', 'Multisided', 'Marketplace'];
  const industry = ['Agriculture', 'Biotechnology', 'Business Services', 'Clean Technology', 'Construction', 'Consulting', 'Consumer Products', 'Consumer Services', 'Digital Marketing',
    'Education', 'Electronics / Instrumentation', 'Fashion', 'Financial Services', 'Fintech', 'Food and Beverage', 'Gaming', 'Healthcare Services', 'Energy', 'Internet / Web Services', 'IT Services',
    'Legal', 'Lifestyle', 'Marine', 'Maritime/Shipping', 'Marketing / Advertising', 'Media and Entertainment', 'Medical Devices and Equipment', 'Mobility', 'Nanotechnology', 'Other', 'Real Estate',
    'Retailing / Distribution', 'Robotics', 'Security', 'Software', 'Sports', 'Telecommunications', 'Transportation', 'Travel'];
  const revenueRecurringTypeMRR = ['MRR', 'ARR'];
  const [edit, setEdit] = React.useState(false);
  const [changeStatus, setChangeStatus] = React.useState(false);
  const [draft, setDraft] = React.useState(props.draft);
  const [detail, setDetail] = React.useState(props.detail);
  const handleClickOpen = () => {
    setChangeStatus(true);
  };
  const handleClose = (value) => {
    setChangeStatus(false);
  };
  function ChangeDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;

    const handleClose = () => {
      onClose(null);
    };

    return (
      <Dialog style={{ padding: 20 }} fullWidth={true} maxWidth="sm" classes={{ paper: classes.myDialog }} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12}>
            <Formik
              initialValues={{
                statusAdmin: '',
                description: '',
              }}
              onSubmit={values => {
                handleDraft(values);
              }}
            >
              {props => (
                <Form>
                  <Grid container justify="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                    <Typography variant="h5">Draft History</Typography>
                    <Button variant="outlinedPrimary" style={{ fontWeight: 'normal', padding: '10.5px 20px' }} type="submit">Add Draft</Button>
                  </Grid>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell style={{ padding: '5px 16px' }}>WHEN</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left">ADMIN STATUS</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left">STARTUP STATUS</StyledTableCell>
                          <StyledTableCell style={{ padding: '5px 16px' }} align="left"></StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {draft &&
                        <TableBody>
                          {draft.map((row) => (
                            <StyledTableRow key={row.id}>
                              <StyledTableCell component="th" style={{ color: '#28b1e7', fontWeight: '600' }} scope="row">
                                {moment(parseInt(row.lastUserEdit)).format('DD-MM-YYYY')}
                              </StyledTableCell>
                              <StyledTableCell align="left">{row.moderationStatus}</StyledTableCell>
                              <StyledTableCell align="left">{row.status}</StyledTableCell>
                              {(row.status === 'Pending' && row.moderationStatus === 'Draft') &&
                                <StyledTableCell align="left">
                                  <Grid container >
                                    <Link style={{ padding: 0 }} to={`/draft-startup/${row.id}`}><img src={BlackEdit} alt="sticker" /></Link>
                                  </Grid>
                                </StyledTableCell>
                              }
                            </StyledTableRow>
                          ))}
                        </TableBody>}


                    </Table>
                  </TableContainer>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    );
  }

  ChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  const FormikNewDropdown = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
      <>
        <Autocomplete
          style={{ marginTop: 10 }}
          {...props}
          {...field}
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={() => setTouched({ [name]: true })}
          renderInput={(props) => <TextField {...props} label="Please Select" {...textFieldProps} variant="outlined" />}
        >
        </Autocomplete>

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  }
  const FormikMultiSelect = ({ textFieldProps, ...props }) => {
    const [meta] = useField(props);
    const { form: { setTouched, setFieldValue } } = props;
    const { ...field } = fieldToTextField(props);
    const { name } = field;
    const classes = useStyles();
    return (
      <>
        <Autocomplete
          multiple
          style={{ marginTop: 15 }}
          {...props}
          {...field}
          disableCloseOnSelect
          className={classes.multiSelect}
          onChange={(_, value) => setFieldValue(name, value)}
          onBlur={() => setTouched({ [name]: true })}
          renderInput={(props) => <TextField {...props} label="Please Select"  {...textFieldProps} variant="outlined" />}
        >
        </Autocomplete>

        {meta.touched && meta.error ? (
          <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
            {meta.error}
          </Typography>
        ) : null}
      </>
    );
  }
  const FormikTextField = ({ type, autofocus, label, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          {disabled ? <TextField
            variant="outlined"
            disabled
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}

          /> : <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{ maxLength: props.maxLength }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            type={type}
            data-date-inline-picker="true"
            autoFocus={autofocus} />}

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
  const FormikPercentageField2 = ({ type, label, inputProps, maxLen, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
      textmask: '(1  )    -    ',
      numberformat: '1320',
    });
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          <TextField
            variant="outlined"
            className={classes.percentageField}
            {...field}
            {...props}
            inputProps={{
              maxLength: maxLen
            }}
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
          />

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
      />
    );
  }
  function NumberFormatPercentage(props) {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        suffix="%"
      />
    );
  }
  function sumCap(capTableAInvestors, capTableAFounding) {
    if (capTableAInvestors === null || capTableAInvestors === '') {
      capTableAInvestors = "0"
    }
    if (capTableAFounding === null || capTableAFounding === '') {
      capTableAFounding = "0"
    }
    return parseInt(capTableAInvestors) + parseInt(capTableAFounding)
  }
  function sumFund(payrollFA, marketingFA, salesFA, developmentFA, rdFA, otherFA) {
    if (payrollFA === null || payrollFA === '') {
      payrollFA = "0"
    }
    if (marketingFA === null || marketingFA === '') {
      marketingFA = "0"
    }
    if (salesFA === null || salesFA === '') {
      salesFA = "0"
    }
    if (developmentFA === null || developmentFA === '') {
      developmentFA = "0"
    }
    if (rdFA === null || rdFA === '') {
      rdFA = "0"
    }
    if (otherFA === null || otherFA === '') {
      otherFA = "0"
    }
    return parseInt(payrollFA) + parseInt(marketingFA) + parseInt(salesFA) + parseInt(developmentFA) + parseInt(rdFA) + parseInt(otherFA)
  }
  const FormikNumberField = ({ type, label, inputProps, maxLen, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
      textmask: '(1  )    -    ',
      numberformat: '1320',
    });
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };
    return (
      <>
        <Grid style={{ minHeight: 95 }}>
          <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{
              maxLength: maxLen
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
          />

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
  const FormikPercentageField = ({ type, label, inputProps, maxLen, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    const [values, setValues] = React.useState({
      textmask: '(1  )    -    ',
      numberformat: '1320',
    });
    const handleChange = (event) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };
    return (
      <>
        <Grid style={{ minHeight: 95, marginTop: 10 }}>
          <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            inputProps={{
              maxLength: maxLen
            }}
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
          />

          {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
              {meta.error}
            </Typography>
          ) : null}
        </Grid>
      </>
    );
  }
  const money = (value) => {
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (formatter.format(parseInt(value)) !== '$NaN') {
      return formatter.format(parseInt(value))
    }
    else {
      return '-'
    }

  }
  const running = (investmentRaisedAmount, investmentNeededDD) => {
    if (parseInt(investmentNeededDD) > parseInt(investmentRaisedAmount)) {
      let percent = (parseInt(investmentRaisedAmount) * 100) / parseInt(investmentNeededDD)
      percent = ~~percent
      return percent;
    }
    return 100;
  }
  const handleSubmit = variables => {
    variables.productProblem = stateToHTML(variables.productProblem.getCurrentContent());
    variables.productSolution = stateToHTML(variables.productSolution.getCurrentContent());
    if (props.authRole === 'Admin' || props.authRole === 'SuperAdmin') {
      props.editStartup({ variables })
        .then(data => {
          if (data.data.editStartup) {
            if (variables.productProblem) {
              let htmlProduct = variables.productProblem
              if (typeof (variables.productProblem) === 'string') {
                variables.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
              }
            }
            else { variables.productProblem = new EditorState.createEmpty() }
            if (variables.productSolution) {
              let htmlProduct = variables.productSolution
              if (typeof (variables.productSolution) === 'string') {
                variables.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
              }
            }
            else { variables.productSolution = new EditorState.createEmpty() }
            props.setIsSentNote(true)
            props.getData()
          }
          else {

          }
        })
    } else {
      variables.id = props.params.id
      props.startupEditStartup({ variables })
        .then(data => {
          if (data.data.startupEditStartup) {
            if (variables.productProblem) {
              let htmlProduct = variables.productProblem
              if (typeof (variables.productProblem) === 'string') {
                variables.productProblem = EditorState.createWithContent(stateFromHTML(htmlProduct))
              }
            }
            else { variables.productProblem = new EditorState.createEmpty() }
            if (variables.productSolution) {
              let htmlProduct = variables.productSolution
              if (typeof (variables.productSolution) === 'string') {
                variables.productSolution = EditorState.createWithContent(stateFromHTML(htmlProduct))
              }
            }
            else { variables.productSolution = new EditorState.createEmpty() }
            props.setIsSentNote(true)
            props.setSendChanges(true)
            props.getData()
          }
          else {

          }
        })
    }
  };
  const handleDraft = variables => {

    props.startupNewDraft()
      .then(data => {
        if (data.data.startupNewDraft) {
          props.history.push(`/draft-startup/${data.data.startupNewDraft}`)
        }
        else {

        }
      })
  };
  return (
    <Paper style={{ marginBottom: '30px' }} className={classes.paper}>
      {!edit ? (
        <div>
          <Grid container justify="flex-end" style={{ marginBottom: 15 }}>
            {(props.authRole === 'Admin' || props.authRole === 'SuperAdmin') &&
              window.location.href.indexOf('draft') === -1 && (
                <Button
                  variant="containedSecondary"
                  style={{ padding: '10px 25px' }}
                  onClick={() => setEdit(true)}
                >
                  <img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit{' '}
                </Button>
              )}
            {window.location.href.indexOf('draft') > -1 &&
              props.authRole !== 'Admin' &&
              props.authRole !== 'SuperAdmin' && (
                <Button
                  variant="containedSecondary"
                  style={{ padding: '10px 25px' }}
                  onClick={() => setEdit(true)}
                >
                  <img src={editImg} alt="edit" style={{ marginRight: '5px' }} /> Edit{' '}
                </Button>
              )}
          </Grid>
          <Grid style={{ marginBottom: '25px' }} item sm={12} container>
            {props.detail.description && props.detail.description !== '' && (
              <>
                <Grid item xs container alignItems={'center'} direction="row" spacing={2}>
                  <Grid item container alignItems="center" justify="space-between">
                    <Typography gutterBottom={true} align={'left'} variant="h5">
                      One-liner
                          </Typography>
                  </Grid>
                  <Grid item container>
                    <Typography gutterBottom={true} align={'left'} variant="body1">
                      {props.detail.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            )}
            {props.detail.productProblem &&
              <>
                <Grid item container alignItems="center" justify="space-between">
                  <Typography gutterBottom={true} align={"left"}
                    variant="h5">
                    Problem
                            </Typography>
                </Grid>
                <Grid item container>
                  <RichEditorReadOnly
                    editorState={props.detail.productProblem}
                    name="productProblem"
                  />
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            }
            {props.detail.productSolution &&
              <>
                <Grid item container alignItems="center" justify="space-between">
                  <Typography gutterBottom={true} align={"left"}
                    variant="h5">
                    Solution
                            </Typography>
                </Grid>
                <Grid item container>
                  <RichEditorReadOnly
                    editorState={props.detail.productSolution}
                    name="productSolution"
                  />
                </Grid>
                <Grid xs={12}>
                  <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
                </Grid>
              </>
            }
            <Grid item container justify="space-around">
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Business Model
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.businessModel}</Typography>
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Stage
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.stage}</Typography>
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Industry
                </Typography>
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.industry && props.detail.industry.length > 0 && props.detail.industry.map((row) => (row + ', '))}</Typography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
            </Grid>
            <Grid item container justify="space-between">
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Total Revenue
                  <Grid style={{ display: 'flex', borderRadius: '7px', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginLeft: 10 }}>
                    <Typography align={"left"}
                      variant="body2" style={{ color: '#fff' }}>
                      i
                  </Typography>
                  </Grid>
                </Typography>
                {props.detail.totalRevenueGrowth &&
                  <Typography variant="h5" color="primary" align={"left"}>{money(props.detail.totalRevenueGrowth)}</Typography>
                }
              </Grid>
              <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
                <Typography gutterBottom={true} align={"left"}
                  variant="h5" style={{ display: 'flex' }}>
                  Avg. Revenue Growth
                  <Grid style={{ display: 'flex', borderRadius: '7px', width: 24, height: 24, justifyContent: 'center', alignItems: 'center', background: '#28b1e7', marginLeft: 10 }}>
                    <Typography align={"left"}
                      variant="body2" style={{ color: '#fff' }}>
                      i
                    </Typography>
                  </Grid>
                </Typography>
                {props.detail.averageRevenueGrowth &&
                  <Typography variant="h5" color="primary" align={"left"}>%{props.detail.averageRevenueGrowth}</Typography>
                }
              </Grid>
            </Grid>
            <Grid xs={12}>
              <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '50px 0' }} />
            </Grid>
            <Grid item md="4" xs="8" sm="3" style={{ marginBottom: 25 }}>
              <Typography gutterBottom={true} align={"left"}
                variant="h5" style={{ display: 'flex' }}>
                  Recurring Revenue Type
              </Typography>
              {props.detail.revenueRecurringType &&
                <Typography variant="h5" color="primary" align={"left"}>{props.detail.revenueRecurringType}</Typography>
              }
            </Grid>

          </Grid>
        </div>
      ) : (
        <Grid style={{ marginBottom: '25px' }} item sm={12} container>
          <Formik
            initialValues={{
              id: props.detail.id,
              description: props.detail.description,
              stage: props.detail.stage,
              businessModel: props.detail.businessModel,
              industry: props.detail.industry ? props.detail.industry : [],
              productProblem: props.detail.productProblem,
              productSolution: props.detail.productSolution,
              totalRevenueGrowth: props.detail.totalRevenueGrowth,
              averageRevenueGrowth: props.detail.averageRevenueGrowth,
              revenueRecurringType: props.detail.revenueRecurringType
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {(props) => (
              <Form style={{ width: '100%' }}>
                <Grid item xs container alignItems={'center'} direction="row" spacing={2}>
                  <Grid item container alignItems="center" justify="flex-end">
                    <Button variant="containedSecondary" style={{ padding: '10px 25px' }} type="submit">
                      {' '}
                            Save{' '}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      One-liner
                          </Typography>
                    <FormikTextField
                      style={{ width: '100%' }}
                      name="description"
                      placeholder="Oneliner"
                    ></FormikTextField>
                  </Grid>
                  <Grid item container spacing={3} style={{ marginTop: 0 }}>
                    <Field>
                      {({ field, form, meta }) => (
                        <Grid item xs={12} md={6} style={{ paddingTop: 0, marginBottom: 25 }}>
                          <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                            Product Problem
                          </Typography>
                          <RichEditor
                            editorState={form.values.productProblem}
                            onChange={(form.setFieldValue)}
                            name="productProblem"
                            onBlur={form.handleBlur}
                          />
                        </Grid>
                      )}
                    </Field>
                    <Field>
                      {({ field, form, meta }) => (
                        <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
                          <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                            Product Solutions
                          </Typography>
                          <RichEditor
                            editorState={form.values.productSolution}
                            onChange={(form.setFieldValue)}
                            name="productSolution"
                            onBlur={form.handleBlur}
                          />
                        </Grid>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 25 }}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Stage
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="stage"
                      options={stage}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 25 }}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Business Model
                    </Typography>
                    <Field
                      component={FormikNewDropdown}
                      textFieldProps={{ fullWidth: true, variant: 'outlined' }}
                      name="businessModel"
                      options={businessModel}
                    ></Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0, marginBottom: 25 }}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Industry
                    </Typography>
                    <Field component={FormikMultiSelect} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="industry" options={industry}></Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Total revenue for the last 6 months? ($)
                    </Typography>
                    <FormikNumberField
                      name="totalRevenueGrowth"
                      onKeyDown={e => (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                      maxLen={15}
                      style={{ width: '100%' }}
                      placeholder="Average revenue growth"
                    ></FormikNumberField>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: 0 }}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Avg. revenue growth per month for the last 6 months?
                    </Typography>
                    <FormikPercentageField name="averageRevenueGrowth" style={{ width: '100%' }} maxLen={4} placeholder="%"></FormikPercentageField>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" align="left" style={{ marginBottom: 15 }}>
                      Please choose your recurring revenue types
                    </Typography>
                    <Field component={FormikNewDropdown} textFieldProps={{ fullWidth: true, variant: 'outlined' }} name="revenueRecurringType" options={revenueRecurringTypeMRR}></Field>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </Paper>
  );
}