import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Header from "../../Components/Header";
import Hidden from "@material-ui/core/Hidden";
import logo from '../../static/img/new-ae-logo.png';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import idea from '../../static/img/success-form.png';


const useStyles = makeStyles((theme) =>({
    baseStyle: {
        flex: 1,
        marginTop:10,
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        borderWidth: 2,
        borderRadius: 7,
        borderColor: '#dddddd',
        borderStyle: 'dashed',
        backgroundColor: '#ffffff',
        color: '#172b4d',
        fontWeight: '600',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    logo: {
        width: 180,
        height: 50,
        marginTop:30,
    },
    multiSelect: {
        maxHeight: '60px',
        '& div': {
          background: 'none!important',
        },
      },
    startImage: {
        width:'100%',
        objectFit:'contain',
        marginTop:60
    },
    contactInfo:{
        textDecoration:'underline',
        marginLeft:5
    },
    twoLines:{
        [theme.breakpoints.down('lg')]: {
            marginBottom:35   
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom:0  
        },
    },
    howMany:{
        [theme.breakpoints.down('lg')]: {
            marginBottom:25   
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom:0  
        },
    },
    inputSpace:{
        marginTop:30
    },
    MuiStepConnector:{
        vertical:{
            minHeight:40
        }
    },
    AddButton:{
        marginBottom:30,
        marginTop:30
    },
    inputField: {
        width: '100%',
        marginTop: 10,
      },
    applicationCard: {
        background:'#ffffff',
        marginTop:100,
        marginBottom:50,
        [theme.breakpoints.down('lg')]: {
            marginBottom:100   
        },
    },
    tableRows: {
        border:'1px solid #f7f7fc',
        borderRadius:'7px'
    },
    SpacingName: {
        [theme.breakpoints.down('sm')]: {
            paddingBottom:'0!important',
        }
    },
    SpacingSurname: {
        [theme.breakpoints.down('sm')]: {
            paddingTop:'0!important',
        }
    },
    textArea: {
        width: '100%',
        maxWidth:'500px',
        borderColor:'rgb(222, 222, 222);',
        borderRadius:'7px',
        marginTop:10,
        padding:'5px 10px',
        fontFamily: "Nunito Sans, Roboto, sans-serif",
        color:"#777777"
    },
    
}));
    export default function SuccessApplication(props){
        const classes = useStyles();

        React.useEffect(() => {
            setTimeout(function(){ 
                props.history.push('/my-startup') }, 3000);
        })
    
        return(
            <div>
                <Hidden mdDown>
                    <Header></Header>
                </Hidden>
                <Hidden mdUp>
                    <Grid container justify="center" style={{background: '#ffffff',paddingBottom:30}}>
                        <img src={logo} alt="angel-effect" className={classes.logo} style={{marginRight: '10px'}}></img>
                    </Grid>
                </Hidden>
                <Grid container justify="center">
                    <Grid className={classes.applicationCard} container xs={12} lg={10} xl={9}>
                        <Grid item container direction="column" xs={12} style={{marginTop:60}} alignItems="center">
                                <img src={idea} alt="idea" style={{maxWidth:'460px'}}></img>
                                <Typography variant="h5" style={{marginTop:'25px',marginBottom:'50px'}} align="center">
                                    Hi there! Thank you for application. <br /> We’ve just received it and sent an e-mail about this. Don’t forget to check out your junk folder!
                                </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>  
        )      
    }    