import React from "react";
import Hidden from "@material-ui/core/Hidden";
import MuiAlert from '@material-ui/lab/Alert';
import Header from "../../Components/Header";
import MobileHeader from "../../Components/MobileHeader";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import account from '../../static/icon/account.svg';
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import { useField, Form, Formik, Field} from 'formik';
import get from 'lodash/get';
import TextField from "@material-ui/core/TextField";
import BottomNavigation from "../../Components/BottomNavigation";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { useApolloClient } from '@apollo/react-hooks';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { changePasswordSchema } from '../../yupSchemas';
import ReactPhoneInput from "react-phone-input-2";
import Dropzone from 'react-dropzone';
import ImageCrop from "../../Components/ImageCrop";

const useStyles = makeStyles((theme) => ({
  userCard: {
    [theme.breakpoints.up('xl')]: {
    padding: 40,
    },
    padding: 35,
    background: '#ffffff'
  },
  multiSelect: {
    marginBottom:25,
    '& div': {
      background: 'none!important',
    },
  },
  topPart: {
      padding: '0 10px',
      flexWrap:'nowrap'
  },
  inputField: {
    width: '100%',
    marginTop: 10,
  },
  textArea: {
    width: '100%',
    [theme.breakpoints.up('xl')]: {
    maxWidth:'420px',
    },
    maxWidth:'315px',
    borderColor:'rgb(222, 222, 222);',
    borderRadius:'7px',
    marginTop:10,
    padding:'5px 10px',
    fontFamily: "Nunito Sans, Roboto, sans-serif",
    color:"#777777"
},
  requests: {
    [theme.breakpoints.down('lg')]: {
      marginTop:10
    }
  },
  mainGrid: {
    justifyContent:'center'
  },
  settingsTab:{
    padding:15,
    background:'#f7f7f7',
    borderRadius:4,
    cursor:'pointer',
    marginBottom:25,
    '&:hover': {
      background:'#28b1e726'
    }
  },
  adminContainer:{
    maxWidth: '1320px',
    padding:0,
    [theme.breakpoints.down('lg')]: {
      padding:' 0 0 0 70px'
    },
    [theme.breakpoints.down('md')]: {
      padding:' 0 16px'
    },
  },
  baseStyle: {
    flex: 1,
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'center',
    height: '80px',
    width:'90px',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 4,
    border: 'solid 1px #dddddd6b',
    backgroundColor: '#ffffff',
    color: '#172b4d',
    fontWeight: '600',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },  

}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
  const FormikTextField = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const classes = useStyles();
    return (
      <>
    <Grid>
        <TextField
            variant="outlined"
            className={classes.inputField}
            {...field}
            {...props}
            error={meta.touched && meta.error}
            placeholder={props.placeholder}
            maxlength={props.maxlength}
        />
  
            {meta.touched && meta.error ? (
            <Typography style={{ color: '#FF0000', fontSize: '12px', marginBottom: 4, marginTop: 4 }}>
                {meta.error}
            </Typography>
            ) : null}
    </Grid>        
      </>
    );
  } 

export default function InvestorEdit(props){
  const [isEdited, setIsEdited] = React.useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [serverSuccess, setServerSuccess] = React.useState(false);
  const [error, setError] = React.useState('');
  const [files, setFiles] = React.useState([]);
  const client = useApolloClient();
  const [openPhoto, setOpenPhoto] = React.useState(false);

      const handleSubmit = (variablesAll, initialValuess) => {
        const { ...variables } = variablesAll;
        let a = variables.profilePhoto
        if (variables !== initialValuess) {
          if(typeof variables.profilePhoto === 'string' && variables.profilePhoto !== '') {
            variables.profilePhoto = null;
          }
          props
            .editProfile({ variables: variables })
            .then((data) => {
              if (data.data.editProfile) {
                variables.profilePhoto = a;
                setIsEdited(true);
                setInitialValuess(variables);
                props.refetchMyProfile();
              }
            })
        } else {
          setIsChanged(true);
        }
      };

    const handlePassword = async variablesAll => {
      const { ...variables } = variablesAll;
      props.changePassword({ variables })
        .then(data => {
          if (data.data.changePassword) {
              window.location.reload();
          } 
          else {
                setServerSuccess(true);
          }
          }).then(() => {
              }).catch(error => {
                  if(error.toString().includes("Wrong password")){
                      setError('The old password is wrong.')
                  }
                  if(error.toString().includes("New password")){
                      setError('The new password you create should not be the same as your old password. Please set another password.')
                  }
                  setServerSuccess(true);
          });
    };
    const logout = () =>  {
      localStorage.clear();
      client.clearStore().then(() => props.history.push('/login'));
    };
    
    function validateLinkedinUrl2(value) {
      if(value !== '' && value !== null){
          let error;
          var pattern = new RegExp( /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/ ); // query string
          error = !pattern.test(value) ? 'Girdiğiniz uzantı hatalı, lütfen yeniden deneyin.' : false;
          return error;
      }
      return false;
    }
    const [initialValuess, setInitialValuess] = React.useState(
      {
          firstName: props.firstName,
          lastName: props.lastName,
          email:props.email,
          phoneNumber: props.phoneNumber,
          socialLinkedIn: props.socialLinkedIn,
          profilePhoto: props.profilePhoto,
      }
    )

    const classes = useStyles();

    const thumbsContainer = {
      display: 'flex',
      flexDirection: 'row',
      pointerEvents: 'none',
      flexWrap: 'wrap',
    };
    
    const thumb = {
      display: 'inline-flex',
      borderRadius: 4,
      border: '1px solid #eaeaea',
      width: 90,
      height: 80,
      boxSizing: 'border-box'
    };
    
    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };
    
    const img = {
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius:7
    };
    return (
      <div>
        <Hidden smDown>
          <Header></Header>
        </Hidden>
        <Hidden mdUp>
          <MobileHeader></MobileHeader>
        </Hidden>
        <Grid container className={classes.mainGrid} style={{ background: '#f7f7fc' }}>
          <BottomNavigation></BottomNavigation>
          <Grid className={'main-container'} xs={12} style={{ height: 'fit-content' }}>
            <Grid container spacing={3} style={{ marginTop: '50px' }}>
              <Grid item xs={12} sm={4} style={{ marginBottom: 50 }}>
                <Grid xs={12} className={classes.userCard} style={{ marginBottom: 25, textAlign: 'center' }}>
                  <Typography variant="h3">
                    {props.firstName} {props.lastName}
                  </Typography>
                  <Typography variant="body1" style={{ color: '#777777' }}>
                    {props.authRole}
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.userCard} style={{ marginBottom: 25, textAlign: 'center' }}>
                  {page === 1 ? (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.settingsTab}
                      style={{ background: '#28b1e726' }}
                    >
                      <img src={account} alt="account" style={{ marginRight: 15 }} />
                      <Typography variant="h5">Account Settings</Typography>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" onClick={() => setPage(1)} className={classes.settingsTab}>
                      <img src={account} alt="account" style={{ marginRight: 15 }} />
                      <Typography variant="h5">Account Settings</Typography>
                    </Grid>
                  )}
                  {page === 2 ? (
                    <Grid
                      container
                      alignItems="center"
                      className={classes.settingsTab}
                      style={{ background: '#28b1e726' }}
                    >
                      <SettingsOutlinedIcon style={{ marginRight: 15 }} />
                      <Typography variant="h5">Change Password</Typography>
                    </Grid>
                  ) : (
                    <Grid container alignItems="center" onClick={() => setPage(2)} className={classes.settingsTab}>
                      <SettingsOutlinedIcon style={{ marginRight: 15 }} />
                      <Typography variant="h5">Change Password</Typography>
                    </Grid>
                  )}
                  <Grid container alignItems="center" onClick={() => logout()} className={classes.settingsTab}>
                    <ExitToAppOutlinedIcon style={{ marginRight: 15 }} />
                    <Typography variant="h5">Log out</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} style={{ marginBottom: 50 }}>
                <Grid xs={12} className={classes.userCard}>
                  {page === 1 ? (
                    <Formik
                      initialValues={initialValuess}
                      enableReinitialize={true}
                      onSubmit={(values) => {
                        handleSubmit(values, initialValuess);
                      }}
                    >
                      {(props) => (
                        <Form>
                          <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h5">Account Settings</Typography>
                          </Grid>
                          <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Grid container alignItems="center">
                                <Field>
                                  {({ field, form, meta }) => (
                                    <Dropzone
                                      maxSize={20971520}
                                      accept={'image/*'}
                                      onDrop={(acceptedFiles) =>
                                        setFiles(
                                          acceptedFiles.map((file) =>
                                            Object.assign(file, { preview: URL.createObjectURL(file) }),
                                          ),
                                          form.setFieldValue(`profilePhoto`, acceptedFiles),
                                          setOpenPhoto(true),
                                        )
                                      }
                                    >
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <section style={{ textAlign: 'center' }}>
                                          {typeof props.values.profilePhoto !== 'string' ||
                                          props.values.profilePhoto === '' ? (
                                            <div {...getRootProps({ className: classes.baseStyle })}>
                                              <input {...getInputProps()} />
                                              {!props.values.profilePhoto ? (
                                                <Grid
                                                  item
                                                  container
                                                  direction="column"
                                                  justify="center"
                                                  alignItems="center"
                                                >
                                                  {props.values.firstName ? (
                                                    <Grid container alignItems="center" justify="center">
                                                      <Typography variant="h4">
                                                        {props.values.firstName.charAt(0)}
                                                      </Typography>
                                                      <AddIcon style={{ color: '#777777', fontSize: 20 }} />
                                                    </Grid>
                                                  ) : (
                                                    <>
                                                      <AddIcon style={{ color: '#777777', fontSize: 25 }} />
                                                      <Typography
                                                        variant="body2"
                                                        style={{ display: 'flex', color: '#777777' }}
                                                      >
                                                        Logo
                                                      </Typography>
                                                    </>
                                                  )}
                                                </Grid>
                                              ) : (
                                                <aside style={thumbsContainer}>
                                                  {acceptedFiles[0] && (
                                                    <div
                                                      id={props.values.profilePhoto[0].name}
                                                      style={thumb}
                                                      key={props.values.profilePhoto[0].name}
                                                    >
                                                      <div style={thumbInner}>
                                                        <img src={props.values.profilePhoto[0].preview} style={img} alt="file" />
                                                      </div>
                                                    </div>
                                                  )}
                                                  {props.values.profilePhoto[0] && !acceptedFiles[0] && (
                                                    <div
                                                      id={form.values.profilePhoto[0].name}
                                                      style={thumb}
                                                      key={form.values.profilePhoto[0].name}
                                                    >
                                                      <div style={thumbInner}>
                                                        <img
                                                          src={props.values.profilePhoto[0].preview}
                                                          style={img}
                                                          alt="file"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </aside>
                                              )}
                                            </div>
                                          ) : (
                                            <div {...getRootProps({ className: classes.baseStyle })}>
                                              <img
                                                src={props.values.profilePhoto}
                                                alt="avatar"
                                                style={{ width: '100%', height: '100%' }}
                                              />
                                            </div>
                                          )}
                                          <Grid item style={{ textAlign: 'left' }}>
                                            {props.values.profilePhoto && (
                                              <Button
                                                variant="containedSecondary"
                                                style={{
                                                  padding: '4.5px 15px',
                                                  fontSize: 14,
                                                  marginTop: 5,
                                                  width: 95,
                                                }}
                                                onClick={() => form.setFieldValue(`profilePhoto`, '')}
                                              >
                                                Delete
                                              </Button>
                                            )}
                                          </Grid>
                                        </section>
                                      )}
                                    </Dropzone>
                                  )}
                                </Field>
                                <Typography variant="h6" style={{ marginLeft: 15 }}>
                                  * JPG, GIF or PNG. Max size of 800K
                                </Typography>
                              </Grid>
                            </Grid>
                            <Field>
                              {({ field, form, meta }) => (
                                <ImageCrop
                                  setFieldValue={form.setFieldValue}
                                  files={files}
                                  profilePhoto={form.values.profilePhoto}
                                  setOpenPhoto={setOpenPhoto}
                                  openPhoto={openPhoto}
                                />
                              )}
                            </Field>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6">Name:</Typography>
                              <FormikTextField name="firstName" placeholder="Name" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6">Surname:</Typography>
                              <FormikTextField name="lastName" placeholder="Surname" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6">Email Address:</Typography>
                              <FormikTextField name="email" placeholder="Email Address" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6">Phone Number:</Typography>
                              <Field name="phoneNumber">
                                {({ field, form, meta }) => {
                                  const { name, value } = field;
                                  return (
                                    <>
                                      <div>
                                        <Grid>
                                          <ReactPhoneInput
                                            name={name}
                                            value={value}
                                            style={{ marginTop: 10, borderRadius: 7 }}
                                            className={classes.inputField}
                                            onChange={(e) => form.setFieldValue('phoneNumber', e)}
                                            country={'tr'}
                                          />
                                          {meta.touched && meta.error ? (
                                            <Typography
                                              style={{
                                                color: '#FF0000',
                                                fontSize: '12px',
                                                marginBottom: 4,
                                                marginTop: 4,
                                              }}
                                            >
                                              {meta.error}
                                            </Typography>
                                          ) : null}
                                        </Grid>
                                      </div>
                                    </>
                                  );
                                }}
                              </Field>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Typography variant="h6">LinkedIn:</Typography>
                              <FormikTextField name="socialLinkedIn" placeholder="LinkedIn" />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                variant="containedPrimary"
                                onClick={() => handleSubmit(props.values, initialValuess)}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <Formik
                      initialValues={{
                        oldPassword: '',
                        newPassword1: '',
                        newPassword2: '',
                      }}
                      enableReinitialize={true}
                      validationSchema={changePasswordSchema}
                      onSubmit={(values) => {
                        handlePassword(values, initialValuess);
                      }}
                    >
                      {(props) => (
                        <Form>
                          <Grid container justify="space-between" alignItems="center">
                            <Typography variant="h5">Change Password</Typography>
                          </Grid>
                          <hr size="1" style={{ border: '0.2px solid #f5f5f5', margin: '25px 0' }} />
                          <Grid xs={12} sm={6}>
                            <Typography variant="h6">Current Password:</Typography>
                            <FormikTextField name="oldPassword" type="password" style={{ marginBottom: 15 }} />
                            <Typography variant="h6">New Password:</Typography>
                            <FormikTextField name="newPassword1" type="password" style={{ marginBottom: 15 }} />
                            <Typography variant="h6">Confirm Password:</Typography>
                            <FormikTextField name="newPassword2" type="password" style={{ marginBottom: 15 }} />
                            <Button variant="containedPrimary" onClick={() => handlePassword(props.values)}>
                              {' '}
                              Change Password{' '}
                            </Button>
                          </Grid>
                        </Form>
                      )}
                    </Formik>
                  )}
                  <Snackbar autoHideDuration={6000} onClose={() => setIsEdited(false)} open={isEdited}>
                    <Alert severity="success">Successfully Edited</Alert>
                  </Snackbar>
                  <Snackbar autoHideDuration={6000} onClose={() => setIsChanged(false)} open={isChanged}>
                    <Alert severity="warning">Nothing Changed</Alert>
                  </Snackbar>
                  <Snackbar autoHideDuration={6000} onClose={() => setServerSuccess(false)} open={serverSuccess}>
                    <Alert severity="error">{error}</Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
}